import React from "react";
import "./Formule.scss";
import { auth, api } from "@mitim/react-mitim";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";

export class Formule extends React.Component {
	componentDidMount() {
		this.getLastRevue();
	}

	getLastRevue() {
		const tokenPublic = "cHVibGljOjk4SE85SE9JVUdPSTk4SA==";
		const defaultHeaders = {
			Accept: "application/json",
			"Content-Type": "application/json",
			Authorization: "Basic " + tokenPublic
		};

		fetch(`${api.timediaUrl}/public/lastRevue`, {
			method: "GET",
			headers: defaultHeaders
		})
			.then(response => {
				if (response.status !== 200) {
					console.log("Error: " + response.status);
					return;
				}
				response.json().then(data => {
					const lastRevue = data.data;
					lastRevue &&
						this.props.app.setState({
							lastRevue: lastRevue
						});
					console.log(data.data);
				});
			})
			.catch(err => {
				console.log(err);
			});
	}

	handleChoixFormule = (nombreRevues, montant) => {
		auth.userHaveAuthorities(["ROLE_CONNECTED"])
			? this.props.app.setState(
					{
						elements: {
							...this.props.app.state.elements,
							// ["nombreRevues"]: nombreRevues,
							["montant"]: montant
						},
						tabFormulaire: 2
					},
					() => {
						sessionStorage.setItem("tabFormulaire", 2);
						console.log(this.props.app.state.elements);
					}
			  )
			: this.props.history.push({
					pathname: "/login",
					state: { from: "/revue" }
			  });
	};

	render() {
		const BlocOption = ({ titre, l1, l2, prix, nombreRevues, montant }) => (
			<div className="col-md-auto">
				<div className="blocOption m-2">
					<section className="p-4  text-center">
						<h3>{titre}</h3>
						<div>{l1}</div>
						<div>{l2}</div>

						<h1 className="text-black pt-3 pb-3 text-center">{prix}</h1>
						<div className="text-center">
							<button
								type="button"
								className="btn btn-success text-center"
								onClick={() => this.handleChoixFormule(nombreRevues, montant)}
							>
								Choisir
							</button>
						</div>
					</section>
				</div>
			</div>
		);
		return (
			<>
				<div className="text-center">
					<p className="text-center">
						L'abonnement ne comprend pas les numéros hors série
						<br />
						Vous pouvez acheter toutes les revues à l'unité sur le site de la{" "}
						<a
							className="lien"
							target="_blank"
							href="https://www.librairiejeancalvin.fr/index.php/ljc/content/search?SearchText=lumi%C3%A8res+re%C3%A7ues+au+fil+du+temps&x=0&y=0"
						>
							librairie Jean Calvin
						</a>
					</p>
					{!this.props.app.state.revueDejaCommande ? (
						<form>
							<div className="form-check">
								<input
									type="checkbox"
									className="form-check-input"
									id="exampleCheck1"
									defaultChecked={
										this.props.app.state.elements.recevoirDerniereRevue
									}
									onChange={() =>
										this.props.app.setState(
											{
												elements: {
													...this.props.app.state.elements,
													["recevoirDerniereRevue"]: !this.props.app.state
														.elements.recevoirDerniereRevue
												}
											},
											() => console.table(this.props.app.state.elements)
										)
									}
								/>
								<label className="form-check-label" htmlFor="exampleCheck1">
									Je souhaite recevoir la dernière revue (vous recevrez la revue
									n°{" "}
									<Link
										target="_blank"
										className="lien"
										to={"/media/" + this.props.app.state.lastRevue.id}
									>
										{this.props.app.state.lastRevue.numRevue}
									</Link>
									, voir le détail ici:{" "}
									<Link
										target="_blank"
										className="lien"
										to={"/media/" + this.props.app.state.lastRevue.id}
									>
										dernière revue
									</Link>{" "}
									)
								</label>
							</div>
						</form>
					) : (
						<>
							Vous avez déjà reçu la dernière revue (n°{" "}
							<Link
								target="_blank"
								className="lien"
								to={"/media/" + this.props.app.state.lastRevue.id}
							>
								{this.props.app.state.lastRevue.numRevue}
							</Link>
							, voir le détail ici:{" "}
							<Link
								target="_blank"
								className="lien"
								to={"/media/" + this.props.app.state.lastRevue.id}
							>
								dernière revue
							</Link>
							),
							<br /> vous recevrez la prochaine quand elle sortira, nous vous
							enverrons un email pour vous prévenir.
							<br />
						</>
					)}
				</div>

				<div className="row pt-4 justify-content-center">
					{/* <div className="col-md-3"></div> */}
					<BlocOption
						titre="1 an"
						l1="2 numéros papier"
						l2="5€/revue"
						prix="10€"
						nombreRevues={2}
						montant={10}
					/>
					{/* <div className="col-md-2"></div> */}
					<BlocOption
						titre="2 ans"
						l1="4 numéros papier"
						l2="4,5€/revue"
						prix="18€"
						nombreRevues={4}
						montant={18}
					/>
					<BlocOption
						titre="4 ans"
						l1="8 numéros papier"
						l2="4,5€/revue"
						prix="36€"
						nombreRevues={8}
						montant={36}
					/>
					{/* <div className="col-md-3"></div> */}
				</div>
			</>
		);
	}
}

export default withRouter(Formule);
