import React, { Component } from "react";
import "./FormulaireInformations.scss";
import { render } from "react-dom";
import Modal from "react-bootstrap/Modal";
import {
	ModuleCaroussel,
	Icones,
	BlockImgNlt,
	ModuleEncartListMt,
	ModuleCard,
	tools,
	api,
	auth,
	Storage
} from "@mitim/react-mitim";

export class FormulaireInformations extends Component {
	state = {
		messageModal: false
	};

	componentDidMount() {
		// const user = JSON.parse(localStorage.getItem("user"));
		const user = Storage.getUserParsed();

		console.table(user);

		!this.props.app.state.abonne &&
			this.props.app.setState({
				elements: {
					...this.props.app.state.elements,
					["nom"]: user.lastname,
					["user"]: user.id,
					["prenom"]: user.firstname,
					["email"]: user.email,
					["tel"]: user.telMobile || ""
				}
			});
	}

	handleChangeText = name => event => {
		this.props.app.setState(
			{
				elements: {
					...this.props.app.state.elements,
					[name]: event.target.value
				}
			},
			() => console.table(this.props.app.state.elements)
		);
		console.log("Valeur de : " + name + " -> " + event.target.value);
	};

	inputText(label, name, type, nbrCol) {
		return (
			<div className={"form-group col-md-" + nbrCol}>
				<label htmlFor="" className="col-form-label ">
					{label}
				</label>
				<input
					style={{ backgroundColor: "#f6f6f6", color: "#000" }}
					type={type}
					value={this.props.app.state.elements[name]}
					onChange={this.handleChangeText(name)}
					className="form-control"
					id={"Id" + name}
					placeholder={label + "*"}
					name={name + "Name"}
					required
				/>
			</div>
		);
	}

	inputNumber(label, name, type, nbrCol) {
		return (
			<div className={"form-group col-md-" + nbrCol}>
				<label htmlFor="" className="col-form-label ">
					{label}
				</label>
				<div className="input-group input-group-lg">
					<input
						type={type}
						value={this.props.app.state.elements[name]}
						onChange={this.handleChangeText(name)}
						className="form-control text-center montantDonInput"
						id={"Id" + name}
						name={name + "Name"}
						required
					/>
					<span className="input-group-addon euro"> €</span>
				</div>
			</div>
		);
	}

	handleChange = event => {
		console.log(event);
		let target = event.target;
		let value = target.value;
		let id = target.id;
		console.log(event.target.checked);
		console.log(value);
		console.log(id);
		if (id === "civilite" && value === "M.") {
			this.setState({
				avecEnfants: false,
				conjoint: false,
				mmeReferente: false,
				mlle: false,
				elements: { ...this.props.app.state.elements, ["civilite"]: value }
			});
		}
		if (id === "civilite" && value === "Mme") {
			this.setState({
				avecEnfants: false,
				conjoint: false,
				mmeReferente: true,
				mlle: false,
				elements: { ...this.props.app.state.elements, ["civilite"]: value }
			});
		}
		if (id === "civilite" && value === "Mlle") {
			this.setState({
				avecEnfants: false,
				conjoint: false,
				mmeReferente: true,
				mlle: true,
				elements: { ...this.props.app.state.elements, ["civilite"]: value }
			});
		}
	};

	handleSubmitFormulaire = event => {
		event.preventDefault();
		auth.userHaveAuthorities(["ROLE_CONNECTED"])
			? this.props.app.setState({ tabFormulaire: 3 }, () => {
					sessionStorage.setItem("tabFormulaire", 3);
					console.log(this.props.app.state.elements);
			  })
			: this.props.history.push({
					pathname: "/login",
					state: { from: "/revue" }
			  });
	};

	render() {
		const BoiteDialogue = () => (
			<Modal
				show={this.state.showModal}
				onHide={() =>
					this.setState({ showModal: false }, () =>
						this.props.reloadPage ? null : window.location.reload()
					)
				}
			>
				<Modal.Body>
					<button
						type="button"
						class="close"
						data-dismiss="modal"
						aria-label="Close"
						onClick={() => this.setState({ showModal: false })}
					>
						<span aria-hidden="true">&times;</span>
					</button>
					<h4 className="text-center">Veuillez remplir tous les champs !</h4>
				</Modal.Body>
			</Modal>
		);
		return (
			<div className="formulaireInformations">
				<form onSubmit={this.handleSubmitFormulaire} method="POST">
					{/* <form> */}
					<div className="form-row">
						<div className="form-group col-md-1">
							<label htmlFor="civilite" className="col-form-label">
								Civilité
							</label>
							<select
								style={{ backgroundColor: "#f6f6f6" }}
								onChange={this.handleChange}
								className="form-control"
								name="civilite"
								id="civilite"
							>
								<option>M.</option>
								<option>Mme</option>
								<option>Mlle</option>
							</select>
						</div>
						{this.inputText("Nom", "nom", "text", "5")}
						{this.inputText("Prénom", "prenom", "text", "6")}
					</div>
					<div className="form-row">
						{this.inputText("Adresse postale", "adressePostale", "text", "12")}
					</div>
					<div className="form-row">
						{this.inputText("Ville", "ville", "text", "6")}
						{this.inputText("Pays", "pays", "text", "4")}
						{this.inputText("Code postal", "codePostal", "text", "2")}
					</div>
					<div className="form-row">
						{this.inputText("Email", "email", "email", "6")}
						{this.inputText("Tel", "tel", "tel", "6")}
					</div>

					<div className="text-right pt-2">
						<button
							type="submit"
							// onClick={this.handleSubmitFormulaire}
							className="btn btn-primary center-block pl-5 pr-5"
						>
							Suivant
						</button>
					</div>
				</form>
				<BoiteDialogue />
			</div>
		);
	}
}
