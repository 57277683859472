import React from "react";
import "../AGVote/AGVote.scss";
import Modal from "react-bootstrap/Modal";
import { Storage, api } from "@mitim/react-mitim";
import { ContenuTaj } from "./ContenuTaj";
import { ContenuMt } from "./ContenuMt";
import { EnteteTaj, EnteteMT } from "../AGVote/AGVote";
import Page from "../Page";

export class AGVote2021 extends Page {
	state = {
		messageModal: "",
		messageModal2: "",
		showModal: false,
		showModal2: false,
		suivantClicked: false,

		menu: 1,

		voteOk: false,
		elements: {
			//
			tajAgPre: "Pour",
			tajAg1: "Pour",
			tajAg2: "Pour",
			tajAg3: "Pour",
			tajAg4: "Pour",
			tajAg5: "Pour",
			tajAg6: "Pour",
			//tajAgo6Commentaire: "",
			tajAg7: "Pour",
			tajAg8: "Pour",
			tajAg9: "Pour",
			tajAg10: "Pour",

			//%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
			//
			mtAgPre: "Pour",
			mtAg1: "Pour",
			mtAg2: "Pour",
			mtAg3: "Pour",
			mtAg4: "Pour",
			mtAg5: "Pour",
			mtAg6: "Pour",
			//mtAgo6Commentaire: "",
			mtAg7: "Pour",
			mtAg8: "Pour"
			//rgpd: ""
		},
		disabledBoutonSuivant1: false,
		voteCloture: true
	};

	componentDidMount() {
		window.scrollTo(0, 0);
		// const user = JSON.parse(localStorage.getItem("user"));
		const user = Storage.getUserParsed();

		const defaultHeaders = {
			Accept: "application/json",
			"Content-Type": "application/json",
			Authorization: "Basic " + user.token
		};

		if (!this.state.voteCloture) {
			fetch(`${api.timediaUrl}/votes/isVote`, {
				method: "GET",
				headers: defaultHeaders
			})
				.then(response => {
					if (response.status !== 200) {
						console.log("Error: " + response.status);
						return;
					}
					response.json().then(data => {
						if (!data.success) {
							this.setState({
								messageModal: (
									<>
										Vous avez déjà voté !<br />
										Vous ne pouvez pas voter une seconde fois
										<br />
										Merci
									</>
								),
								showModal: true,
								voteOk: true
							});
						}
					});
				})
				.catch(err => {
					console.log(err);
				});
		} else {
			this.setState({
				messageModal: (
					<>
						Les votes ont été clôturés !<br />
						Vous pouvez seulement consulter
						<br />
						Merci
					</>
				),
				showModal: true,
				voteOk: true
			});
		}
	}

	handleSubmitFormulaire = event => {
		//event.preventDefault();
		const data = this.state.elements;
		var message = {};
		message = JSON.stringify(data);
		// const user = JSON.parse(localStorage.getItem("user"));
		const user = Storage.getUserParsed();

		const defaultHeaders = {
			Accept: "application/json",
			"Content-Type": "application/json",
			Authorization: "Basic " + user.token
		};

		fetch(`${api.timediaUrl}/votes/voter`, {
			method: "POST",
			body: message,
			headers: defaultHeaders
		})
			.then(response => {
				if (response.status !== 200) {
					console.log("Error: " + response.status);
					return;
				}
				response.json().then(data => {
					this.setState({
						messageModal: (
							<>
								Votre vote à bien été pris en compte
								<br />
								Merci
							</>
						),
						showModal: true,
						voteOk: true
					});
				});

				// 	this.setState({
				// 		messageModal: (
				// 			<>
				// 				Votre vote à bien été pris en compte
				// 				<br />
				// 				Merci
				// 			</>
				// 		),
				// 		showModal: true,
				// 		voteOk: true
				// 	}, () =>
				// 	this.state.voteOk
				// 		? this.props.history.push({
				// 				pathname: "/",
				// 				state: { from: "/" }
				// 		  })
				// 		: window.location.reload());
				// });
			})
			.catch(err => {
				console.log(err);
			});
	};

	suivant = event => {
		event.preventDefault();
		this.setState({ suivantClicked: true });
		window.scrollTo(0, 500);
	};

	precedant = event => {
		event.preventDefault();
		this.setState({ suivantClicked: false });
		window.scrollTo(0, 500);
	};

	afficheContenu = () => {
		switch (this.state.menu) {
			case 1:
				return <ContenuTaj self={this} />;
			case 2:
				return <ContenuMt self={this} />;
			default:
				return <></>;
		}
	};

	render() {
		const BoiteDialogue = () => (
			<Modal
				show={this.state.showModal}
				onHide={() => this.setState({ showModal: false }, () => {})}
			>
				<Modal.Body>
					<button
						type="button"
						className="close"
						data-dismiss="modal"
						aria-label="Close"
						onClick={() =>
							this.setState({ showModal: false }, () =>
								this.state.voteOk
									? this.props.history.push({
											pathname: "/",
											state: { from: "/" }
									  })
									: window.location.reload()
							)
						}
					>
						<span aria-hidden="true">&times;</span>
					</button>
					<h4 className="text-center">{this.state.messageModal}</h4>
				</Modal.Body>
			</Modal>
		);
		const BoiteDialogue2 = () => (
			<Modal
				show={this.state.showModal2}
				onHide={() => this.setState({ showModal2: false })}
			>
				<Modal.Body>
					<button
						type="button"
						className="close"
						data-dismiss="modal"
						aria-label="Close"
						onClick={() => this.setState({ showModal2: false })}
					>
						<span aria-hidden="true">&times;</span>
					</button>
					<h4 className="text-center">{this.state.messageModal2}</h4>
				</Modal.Body>
			</Modal>
		);
		return (
			<div className="container-fluid voteAG no-gutters">
				<div className="container">
					<div className="formulaireInformations">{this.afficheContenu()}</div>
					<BoiteDialogue />
					<BoiteDialogue2 />
				</div>
			</div>
		);
	}
}
