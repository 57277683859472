// Libraries
import React from 'react';
// Component
import { NonBreakingSpace, titleCase } from '../../utils/functions';
import { getCouverture, ResultIcons } from './utils';
import AsyncImage from '../AsyncImage';
import { Link } from 'react-router-dom';

class ResultListView extends React.Component {
	render() {
		/**
		 * Commun
		 * - titre : text
		 * - id : int
		 * - contenu : html
		 * - couverture
		 * - isPublic
		 * - lang {id, nom,abreg}
		 * - mediaType
		 * - sousTitre
		 * - datePublication
		 * - typeObjet
		 */
		/**
		 * Cantique
		 * - arrangements [{id, nom}]
		 * - origine [{id, nom}]
		 * - remarque
		 * - typeCantiques [{id, nom}]
		 * - langues [{id,nom,abreg}]
		 */
		/**
		 * Recueil
		 * - dateEdition
		 * - isbn
		 * - lienLjc
		 * - prix
		 * - nbrPages *
		 */
		/**
		 * AlbumCd
		 * - dateEdition
		 * - isbn
		 * - lienLjc
		 * - prix
		 * - nbrFichiers *
		 * - dureeTotale *
		 */
		let infos = '';
		if (this.props.result.typeObjet === 'Cantique') {
			infos = [];
			infos.push(
				this.props.result.typeCantiques
					.map(typeCantique => titleCase(typeCantique.nom))
					.sort()
					.join(', ')
			);
			// infos.push(
			// 	titleCase(this.props.result.origine && this.props.result.origine.nom)
			// );
			infos.push(
				this.props.result.arrangements
					.map(arrangements => titleCase(arrangements.nom))
					.sort()
					.join(', ')
			);
			infos = infos
				.filter(item => item.length > 0)
				.join(NonBreakingSpace(3) + '|' + NonBreakingSpace(3));
		}
		return (
			<div className="col-12 mt-1 result">
				<Link
					to={
						'/media/' +
						this.props.result.id +
						'/' +
						this.props.result.titre.split(' ').join('-')
					}
				>
					<hr />
					<AsyncImage
						source={getCouverture(this.props.result)}
						placeholderColor="#ececec"
						height={75}
						width={75}
						cssClass="float-left img-fluid mr-4 result-list-img"
					/>
					<ResultIcons
						classes={{ root: 'float-right' }}
						fichierTypePresent={this.props.result.fichierTypePresent}
					/>
					<h6 className="mt-3 result-title">{this.props.result.titre}</h6>
					<span className="result-infos">{infos}</span>
				</Link>
			</div>
		);
	}
}

export default ResultListView;
