import React from "react";
import Page from "../Page";
import ModuleMaisonAccueil from "../ModuleMaisonAccueil/ModuleMaisonAccueil";
import "./Soutien.scss";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { api, tools } from "@mitim/react-mitim";

import CheckoutForm from "./CheckoutForm";
import Modal from "react-bootstrap/Modal";
import { cle_stripe_taj_publique } from "../Constants";

const texteRecusImpots = (
	<>
		Si vous souhaitez un reçu pour une réduction d’impôt sur le revenu, vous
		pouvez nous envoyer votre demande à cette adresse : <br />
		contact@missiontimothee.fr ou via le formulaire de contact de ce site.
	</>
);

export class Soutien extends Page {
	state = {
		formulaireDonSubmited: false,
		messageEntete: "Formulaire pour effectuer un don",
		messageModal: "",
		reloadPage: false,
		elements: {
			civilite: "M.",
			nom: "",
			prenom: "",
			montant: 10,
			dateNaissance: "",
			lieuNaissance: "",
			adressePostale: "",
			ville: "",
			pays: "",
			codePostal: "",
			tel: "",
			email: ""
		},
		choixPaiement: "",
		showModal: false,
		choixMenuFormulairePaiement: 1
	};

	handleChangeText = name => event => {
		this.setState(
			{ elements: { ...this.state.elements, [name]: event.target.value } },
			() => console.log(this.state.elements)
		);
		console.log("Valeur de : " + name + " -> " + event.target.value);
	};

	inputText(label, name, type, nbrCol) {
		return (
			<div className={"form-group col-md-" + nbrCol}>
				<label htmlFor="" className="col-form-label ">
					{label}
				</label>
				<input
					style={{ backgroundColor: "#f6f6f6", color: "#000" }}
					type={type}
					value={this.state.elements[name]}
					onChange={this.handleChangeText(name)}
					className="form-control"
					id={"Id" + name}
					placeholder={label + "*"}
					name={name + "Name"}
					required
				/>
			</div>
		);
	}

	inputNumber(label, name, type, nbrCol) {
		return (
			<div className={"form-group col-md-" + nbrCol}>
				<label htmlFor="" className="col-form-label ">
					{label}
				</label>
				<div className="input-group input-group-lg">
					<input
						type={type}
						value={this.state.elements[name]}
						onChange={this.handleChangeText(name)}
						className="form-control text-center montantDonInput"
						id={"Id" + name}
						name={name + "Name"}
						required
						min="1"
					/>
					<span className="input-group-addon euro"> €</span>
				</div>
			</div>
		);
	}

	handleChange = event => {
		console.log(event);
		let target = event.target;
		let value = target.value;
		let id = target.id;
		console.log(event.target.checked);
		console.log(value);
		console.log(id);
		if (id === "civilite" && value === "M.") {
			this.setState({
				avecEnfants: false,
				conjoint: false,
				mmeReferente: false,
				mlle: false,
				elements: { ...this.state.elements, ["civilite"]: value }
			});
		}
		if (id === "civilite" && value === "Mme") {
			this.setState({
				avecEnfants: false,
				conjoint: false,
				mmeReferente: true,
				mlle: false,
				elements: { ...this.state.elements, ["civilite"]: value }
			});
		}
		if (id === "civilite" && value === "Mlle") {
			this.setState({
				avecEnfants: false,
				conjoint: false,
				mmeReferente: true,
				mlle: true,
				elements: { ...this.state.elements, ["civilite"]: value }
			});
		}
	};

	handleChangeText = name => event => {
		this.setState(
			{ elements: { ...this.state.elements, [name]: event.target.value } },
			() => console.log(this.state.elements)
		);
		console.log("Valeur de : " + name + " -> " + event.target.value);
	};

	handleSubmitFormulaireDon = event => {
		event.preventDefault();
		const data = this.state.elements;
		var message = {};
		console.table(data);
		message = JSON.stringify(data);
		console.log(message);
		const tokenPublic = "cHVibGljOjk4SE85SE9JVUdPSTk4SA==";
		const defaultHeaders = {
			Accept: "application/json",
			"Content-Type": "application/json",
			Authorization: "Basic " + tokenPublic
		};

		fetch(`${api.timediaUrl}/public/donations`, {
			method: "POST",
			body: message,
			headers: defaultHeaders
		})
			.then(response => {
				if (response.status !== 200) {
					console.log("Error: " + response.status);
					return;
				}
				response.json().then(data => {
					// self.setState({ success: data.success, swow: true });
					// self.resetFields();
					console.log(data);
					sessionStorage.setItem("idDonation", data.id);
					this.setState({
						messageEntete: "Sélectionnez votre moyen de paiement",
						formulaireDonSubmited: true,
						choixMenuFormulairePaiement: 2
					});
				});
			})
			.catch(err => {
				// self.setState({ loading: false, error: true });
				console.log(err);
				this.setState({
					messageModal:
						"Le serveur est indisponible, veuillez réessayer plus tard",
					showModal: true
				});
			});
	};

	handleSubmitChoixPaiement = props => {
		const tokenPublic = "cHVibGljOjk4SE85SE9JVUdPSTk4SA==";
		const defaultHeaders = {
			Accept: "application/json",
			"Content-Type": "application/json",
			Authorization: "Basic " + tokenPublic
		};

		fetch(
			`${api.timediaUrl}/public/update-choice/${sessionStorage.getItem(
				"idDonation"
			)}/${props}`,
			{
				method: "GET",
				headers: defaultHeaders
			}
		)
			.then(response => {
				if (response.status !== 200) {
					console.log("Error: " + response.status);
					return;
				}
				response.json().then(data => {
					// self.setState({ success: data.success, swow: true });
					// self.resetFields();
					console.log(data);
				});

				this.setState(
					{
						messageModal: (
							<>
								Votre promesse de don a bien été enregistrée, vous recevrez un
								E-Mail de confirmation.
								<br />
								Merci
							</>
						),
						showModal: true,
						formulaireDonSubmited: false,
						reloadPage: true
					},
					() => {
						sessionStorage.setItem("idDonation", "null");
					}
				);
			})
			.catch(err => {
				// self.setState({ loading: false, error: true });
				console.log(err);
				this.setState({
					messageModal:
						"Le serveur est indisponible, veuillez réessayer plus tard",
					showModal: true
				});
			});
	};

	formulaireDon() {
		return (
			<div className="formulaireDons">
				<form onSubmit={this.handleSubmitFormulaireDon} method="POST">
					<div className="form-row">
						{this.inputNumber("Montant du don", "montant", "number", "3")}
					</div>
					<div className="form-row">
						<div className="form-group col-md-1">
							<label htmlFor="civilite" className="col-form-label">
								Civilité
							</label>
							<select
								style={{ backgroundColor: "#f6f6f6" }}
								onChange={this.handleChange}
								className="form-control"
								name="civilite"
								id="civilite"
							>
								<option>M.</option>
								<option>Mme</option>
								<option>Mlle</option>
							</select>
						</div>
						{this.inputText("Nom", "nom", "text", "5")}
						{this.inputText("Prénom", "prenom", "text", "6")}
					</div>
					{/* <div className="form-row">
						{this.inputText("Né(e) le", "dateNaissance", "date", "6")}
						{this.inputText("à", "lieuNaissance", "text", "6")}
					</div> */}
					<div className="form-row">
						{this.inputText("Adresse postale", "adressePostale", "text", "12")}
					</div>
					<div className="form-row">
						{this.inputText("Ville", "ville", "text", "6")}
						{this.inputText("Pays", "pays", "text", "4")}
						{this.inputText("Code postal", "codePostal", "text", "2")}
					</div>
					<div className="form-row">
						{this.inputText("Email", "email", "email", "6")}
						{this.inputText("Tel", "telFixe", "tel", "6")}
					</div>

					<div className="text-right pt-2">
						<button
							type="submit"
							className="btn btn-primary center-block pl-5 pr-5"
						>
							Suivant
						</button>
					</div>
				</form>
			</div>
		);
	}

	choixPaiementFormulaire() {
		switch (this.state.choixMenuFormulairePaiement) {
			case 1:
				return this.formulaireDon();
			case 2:
				return (
					<>
						<div className="" style={{ width: "100%", position: "relative" }}>
							<div
								className=""
								style={{
									position: "absolute",
									left: "50%",
									transform: "translate(-50%,0)",
									top: "40px"
								}}
							>
								<div className="paiementCarte" style={{}}>
									<Elements
										stripe={loadStripe(cle_stripe_taj_publique)}
										// stripe={loadStripe(
										// 	"pk_live_51GxtKoLVOUtYxwngPkSWfHmkXpkb9BCSgJPggB5Yg1EnwBQTHjWlHueMukroZZPSO71JfUzXT507QTiTzcQX4YRQ006DKa651M"
										// )}
									>
										<CheckoutForm self={this} />
										{/* <CardSetupForm /> */}
									</Elements>
								</div>
							</div>
							<p
								className="text-center"
								style={{
									top: "200px",
									position: "absolute",
									left: "50%",
									transform: "translate(-50%,0)"
								}}
							>
								<div className="textImpots">{texteRecusImpots}</div>
							</p>
						</div>
					</>
				);
			case 3:
				return (
					<>
						<p className="text-center">
							<br />
							<br />
							<br />
							<br />
						</p>
						<h4 className="text-center">
							Vous pouvez effectuer votre virement en téléchargant le RIB :{" "}
							<a
								target="_blank"
								className="lien"
								href={`${tools.lienStorage}/MissionTimothee/data/RIB_Timothee_Accueil_Jeunesse.pdf`}
							>
								Télécharger
							</a>
						</h4>
						<p className="text-center">{texteRecusImpots}</p>

						<div className="text-center pt-3">
							<button
								type="button"
								className="btn btn-success"
								onClick={() => this.handleSubmitChoixPaiement("RIB")}
							>
								Valider mon choix
							</button>
						</div>
					</>
				);
			case 4:
				return (
					<>
						<p className="text-center">
							<br />
							<br />
							<h5>
								Envoyez votre chèque établi à l'ordre de L'Association Timothée
								Accueil et Jeunesse, à l'adresse suivante :<br />
								<br />
							</h5>
							<h3>
								Mission Timothée
								<br />
								1654 Chemin de Recoulin
								<br />
								30140 ANDUZE
							</h3>
						</p>
						<p className="text-center">{texteRecusImpots}</p>
						<div className="text-center pt-3">
							<button
								type="button"
								className="btn btn-success"
								onClick={() => this.handleSubmitChoixPaiement("CHEQUE")}
							>
								Valider mon choix
							</button>
						</div>
					</>
				);

			default:
				return <></>;
		}
	}

	formulairePaiement() {
		return (
			<>
				<ul className="nav nav-tabs">
					<li className="nav-item">
						<div
							className={
								this.state.choixMenuFormulairePaiement === 1
									? "nav-link active"
									: "nav-link"
							}
							style={{ cursor: "pointer" }}
							onClick={() =>
								this.state.formulaireDonSubmited
									? this.setState({
											choixMenuFormulairePaiement: 1,
											formulaireDonSubmited: false
									  })
									: null
							}
						>
							Informations
						</div>
					</li>
					<li className="nav-item">
						<div
							className={
								this.state.choixMenuFormulairePaiement === 2
									? "nav-link active"
									: "nav-link"
							}
							style={{ cursor: "pointer" }}
							onClick={() =>
								this.state.formulaireDonSubmited
									? this.setState({ choixMenuFormulairePaiement: 2 })
									: null
							}
						>
							Carte bancaire
						</div>
					</li>
					<li className="nav-item" style={{ cursor: "pointer" }}>
						<div
							className={
								this.state.choixMenuFormulairePaiement === 3
									? "nav-link active"
									: "nav-link"
							}
							style={{ cursor: "pointer" }}
							onClick={() =>
								this.state.formulaireDonSubmited
									? this.setState({ choixMenuFormulairePaiement: 3 })
									: null
							}
						>
							Virement
						</div>
					</li>
					<li className="nav-item" style={{ cursor: "pointer" }}>
						<div
							className={
								this.state.choixMenuFormulairePaiement === 4
									? "nav-link active"
									: "nav-link"
							}
							style={{ cursor: "pointer" }}
							onClick={() =>
								this.state.formulaireDonSubmited
									? this.setState({ choixMenuFormulairePaiement: 4 })
									: null
							}
						>
							Chèque
						</div>
					</li>
				</ul>

				<div className="pt-4 pb-4" style={{ minHeight: "400px" }}>
					{this.choixPaiementFormulaire()}
				</div>
			</>
		);
	}

	contenu() {
		return (
			<div className="faireUnDon">
				<h3 className="text-center ft-parisienne text-noir">
					« Car le secours de cette assistance non seulement pourvoit aux
					besoins des saints, mais il est encore une source abondante de
					nombreuses actions de grâces envers Dieu »
				</h3>
				<h5 className="text-center ft-lato-light text-noir"> 2 Co 9:12</h5>
				<p className="mt-4 text-justify pb-5">
					L’association Mission Timothée est une association cultuelle loi 1905.
					Tout don versé à cette association permet de bénéficier d’une
					réduction d’impôt sur le revenu.
				</p>

				<div className="card">
					<div className="card-header">{this.state.messageEntete}</div>
					<div className="card-body">{this.formulairePaiement()}</div>
				</div>
				<p className="pt-5 pb-5"></p>
			</div>
		);
	}

	render() {
		const BoiteDialogue = () => (
			<Modal
				show={this.state.showModal}
				onHide={() =>
					this.setState({ showModal: false }, () =>
						this.props.reloadPage ? null : window.location.reload()
					)
				}
			>
				<Modal.Body>
					<button
						type="button"
						class="close"
						data-dismiss="modal"
						aria-label="Close"
						onClick={() =>
							this.setState({ showModal: false }, () =>
								this.props.reloadPage ? null : window.location.reload()
							)
						}
					>
						<span aria-hidden="true">&times;</span>
					</button>
					<h4 className="text-center">{this.state.messageModal}</h4>
				</Modal.Body>
			</Modal>
		);

		return (
			<>
				<ModuleMaisonAccueil
					titre="NOUS SOUTENIR"
					sousTitre=""
					icone="false"
					contact={{
						nom: "Anduze",
						titreAdresse: "Mission Timothée",
						adresse: ["1654 chemin de Recoulin", "30140 ANDUZE"],
						tel: ["04 66 61 73 61"],
						mail: ["contact@missiontimothee.fr"]
					}}
					contenu={this.contenu()}
				/>
				<BoiteDialogue />
			</>
		);
	}
}
