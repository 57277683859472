import React from "react";
import Page from "../Page";
import "./AffichageRevues.scss";
import {
	ModuleEncartListMt,
	tools,
	api,
	BlockImgNlt
} from "@mitim/react-mitim";
import { NavLink, Link } from "react-router-dom";
import { titleCase } from "../../utils/functions";
import * as Outils from "../MediaSearch/outils";
import { getCouverture, ResultIcons } from "../MediaSearch/utils";
import ElasticArticlesRevuesProvider from "../ElasticArticlesRevues/ElasticArticlesRevuesProvider";

export class AffichageRevues extends Page {
	state = {
		acces: true,
		type: "",
		medias: []
	};

	componentDidMount() {
		super.componentDidMount();
		this.setState({ type: this.props.match.params.type });
		if (this.props.match.params.type === "revues") {
			api.getGetMediasSimple(this.props.match.params.type, this, () => {});
		}
	}

	componentDidUpdate(prevProps) {
		if (this.props.match.params.type !== prevProps.match.params.type) {
			console.log("update");
			this.setState({ type: this.props.match.params.type });
			if (this.props.match.params.type === "revues") {
				api.getGetMediasSimple(this.props.match.params.type, this, () => {});
			}
		}
	}

	render() {
		const { t } = this.props;
		let classes = {
			filter: "col-12 col-md-4 col-lg-3 col-xl-3 col-xxl-2",
			result: "col-12 col-md-8 col-lg-9 col-xl-9 col-xxl-10",
			pagination: "pt-5"
		};
		const CATEGORIES = [
			{ type: "revues", nom: "Revues" },
			{ type: "articles-revues", nom: "Articles de revues" }
		];
		const categories = CATEGORIES.map(cat => (
			<div className="col-auto category pl-5" key={cat.type}>
				<NavLink exact to={"/revues/" + cat.type}>
					{titleCase(cat.nom)}
				</NavLink>
			</div>
		));

		const Element = props => (
			<div className="col-6 col-lg-6 col-xl-3 text-center mt-5 result">
				<Link
					to={
						"/media/" +
						props.data.media.id +
						"/" +
						props.data.media.titre.split(" ").join("-")
					}
				>
					<div className="img-zoom mx-auto">
						<BlockImgNlt imgSrc={getCouverture(props.data.media)} />
					</div>

					<div className="text-center pt-3">
						<span
							style={{
								backgroundColor: tools.getColorOfMediaType(
									props.data.media.mediaType.id
								),
								fontSize: "1.2em"
							}}
							className="text-white pl-2 pr-2"
						>
							{props.data.media.mediaType.nom}
						</span>

						<div className="pt-2 lien" style={{ fontSize: "1.1em" }}>
							{Outils.getTitre(props.data.media) || ""}
						</div>
						<div className="lien" style={{ fontSize: "0.8em" }}>
							{props.data.media.sousTitre || ""}
						</div>
						<div className="lien" style={{ fontSize: "0.6em" }}>
							{props.data.media.datePublication || ""}
						</div>
					</div>
				</Link>
			</div>
		);

		const AffichageDatas = () => (
			<>
				{this.state.medias &&
					this.state.medias.map(elem => <Element key={elem.id} data={elem} />)}
			</>
		);

		const AffichageCollection = props => (
			<div className="text-center pt-5 pb-5">
				<h2 className="text-uppercase">{props.titre}</h2>
				<h4>{props.sousTitre}</h4>
			</div>
		);

		return (
			<>
				<div className="container-fluid">
					<div className="row align-items-center categories-navbar">
						<div className={classes.filter + " mt-3 my-md-3"}></div>

						<div className={classes.result}>
							<div className="row categories my-3">{categories}</div>
						</div>
					</div>

					{this.props.match.params.type === "revues" ? (
						<>
							<div className="row pr-4">
								<div className={classes.filter + " filter-list"}>
									{this.props.match.params.type === "revues" && (
										<AffichageCollection
											titre="Revues"
											sousTitre="« Lumières reçues au fil du temps »"
										/>
									)}
								</div>
								<div
									className={classes.result}
									style={{ backgroundColor: "#fff" }}
								>
									<p>
										<br />
									</p>
									<div className="row pl-md-3 pb-5">
										<AffichageDatas />
									</div>
								</div>
							</div>
						</>
					) : (
						<ElasticArticlesRevuesProvider />
					)}
				</div>
			</>
		);
	}
}
