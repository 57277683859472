import React from "react";
import { withTranslation } from "react-i18next";

import Menu from "./Menu/Menu";

class MenuWrapper extends React.Component {
	render() {
		const { t } = this.props;
		return (
			<Menu
				items={[
					{
						rank: 1,
						name: "Mission",
						children: [
							{
								rank: 1,
								link: "/confession-de-foi",
								name: "Confession de foi",
								roles: ["ROLE_PUBLIC"]
							},
							{
								rank: 2,
								link: "/historique",
								name: "Historique",
								roles: ["ROLE_PUBLIC"]
							},
							{
								rank: 3,
								link: "/soutien",
								name: "Soutien",
								roles: ["ROLE_PUBLIC"]
							},
							// {
							// 	rank: 4,
							// 	link: "/reunion-information",
							// 	name: "Réunion 3 octobre",
							// 	roles: ["ROLE_REUNION_MISSIONNAIRE"]
							// },
							// {
							// 	rank: 5,
							// 	link: "/ag-ateliers-pelissou",
							// 	name: "AG Ateliers Pelissou",
							// 	roles: ["ROLE_AG_ATELIERS_PELISSOU"]
							// },
							{
								rank: 6,
								link: "/vote2024",
								name: "Vote AG 2024",
								roles: ["ROLE_AG_VOTE"]
							},
							{
								rank: 7,
								link: "/pastorale",
								name: "Pastorale 23 mai 2021",
								roles: ["ROLE_PASTORALE"]
							}
						],
						roles: ["ROLE_PUBLIC"]
					},
					{
						rank: 2,
						link: "/eglises",
						name: "Églises",
						roles: ["ROLE_PUBLIC"]
					},
					{
						rank: 3,
						link: "/parole-partagee",
						name: "Parole partagée",
						roles: ["ROLE_PUBLIC"]
					},
					{
						rank: 4,
						link: "https://www.avecdescrisdejoie.fr",
						name: "Cantiques",
						roles: ["ROLE_PUBLIC"]
					},
					{
						rank: 5,
						link: "https://www.timotheeaccueiljeunesse.fr",
						name: "Accueil & Jeunesse",
						roles: ["ROLE_PUBLIC"]
					},
					{
						rank: 6,
						link: "/formation",
						name: "Formation",
						roles: ["ROLE_PUBLIC"]
					},
					{
						rank: 7,
						link: "/enfants",
						name: "Enfants",
						roles: ["ROLE_PUBLIC"]
					},
					{
						rank: 8,
						link: "/actualites",
						// link: "/media/1609/actualites",
						name: "Actualités",
						roles: ["ROLE_PUBLIC"]
					},
					{
						rank: 9,
						link: "/reunions-direct",
						name: "direct",
						roles: ["ROLE_REU_DIRECT"]
					},
					{
						rank: 10,
						link: "/contact",
						name: "contact",
						roles: ["ROLE_PUBLIC"]
					}
				]}
			/>
		);
	}
}

export default withTranslation()(MenuWrapper);
