import React from "react";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";

// import iconeMaison from '../../resources/icons/icone_maison.svg';
import iconeMaison from "../../resources/icons/mt-logo-mains.svg";
// import anduze1 from '../../resources/photos/anduze-1.jpg';
// import anduze2 from '../../resources/photos/anduze-2.jpg';
import { tools, Icones } from "@mitim/react-mitim";
import isExternal from "is-url-external";

const COULEUR_THEME = "#39beb7";

class QuiSommesNous extends React.Component {
	afficheBoutons = data => {
		const styleBouton = {
			fontSize: "1.1em",
			color: "#333",
			paddingTop: "20px",
			paddingBottom: "100px"
		};
		const classeBouton = "text-center mt-5 ft-lato-light text-uppercase";
		return (
			<React.Fragment>
				{data.boutons.map(element => {
					return (
						<div
							className="text-center p-3 ml-5 mr-5 mt-4 mb-4"
							style={{ border: "1px solid #000" }}
							key={element.nom}
						>
							{isExternal(element.lien) ? (
								<a
									href={element.lien}
									target="_blank"
									className={classeBouton}
									style={styleBouton}
								>
									{element.nom}
								</a>
							) : (
								<Link
									to={element.lien}
									className={classeBouton}
									style={styleBouton}
								>
									{element.nom}
								</Link>
							)}
						</div>
					);
				})}
			</React.Fragment>
		);
	};

	grandEcran() {
		return (
			<div className="d-none d-lg-block">
				<div
					className="mb-5"
					style={{ paddingTop: "140px", display: "flex", paddingRight: "20%" }}
				>
					<div
						style={{
							width: 500,
							height: 400,
							flex: "0.6",
							position: "relative",
							zIndex: "1"
						}}
					>
						<div
							className="p-2"
							style={{
								borderRadius: "20px",
								marginTop: "-60px",
								marginLeft: "30px",
								// backgroundColor: '#333',
								backgroundColor: "#FFF",
								height: "120px",
								width: "120px",
								position: "absolute"
							}}
						>
							{/* <img src={iconeMaison} className="img-fluid" /> */}
							<div className="text-center pt-3">
								<Icones.logoMtMains width={70} />
							</div>
						</div>
						<img
							// src={anduze1}
							src={`${tools.lienPhotos}/mt-qui-sommes-nous.jpg`}
							className="img-fluid"
							style={{ maxHeight: "600px" }}
						/>
					</div>
					<div style={{ flex: "0.4", zIndex: "2" }}>
						<span
							className="ft-parisienne text-center"
							style={{
								paddingLeft: "20%",
								fontSize: "300%",
								color: "rgb(173, 173, 172)"
							}}
						>
							Qui sommes-nous ?
						</span>

						<div
							style={{
								background: "white",
								padding: "25px 50px 60px 50px",
								margin: "5px -120px 0 -130px",
								textAlign: "justify"
							}}
							className="blocHome"
						>
							<div
								style={{
									marginLeft: "-110px",
									backgroundColor: "#39beb7",
									width: "120px",
									height: "3px"
								}}
							></div>
							{tools.titreSousTitre(
								this.props.data.titre,
								this.props.data.sousTitre
							)}
							{this.props.data.contenu}
							<div className="pb-4"></div>
							{this.afficheBoutons(this.props.data)}
						</div>
						{/* <div
							className="text-center text-white ft-roboto-mono"
							style={{
								background: '#f2c718',
								margin: '-50px 50px 0 50px',
								padding: 30,
								transform: 'rotate(-5deg)'
							}}
						>
							Timothée Accueil &amp; Jeunesse
							<br />
							est reconnue d'intérêt général
							<br />
							depuis 2007 et membre de
							<br />
							la Fédération d'Entraide Protestante de France
						</div> */}
					</div>
				</div>
			</div>
		);
	}

	mobile() {
		return (
			<>
				<div className="d-block d-lg-none">
					<div className="pt-5">
						{/* *** LE LOGO *** */}
						<div
							className="p-2"
							style={{
								borderRadius: "20px",
								backgroundColor: "#FFFFFF",
								// backgroundColor: '#333',
								height: "120px",
								width: "120px",
								margin: "0 auto"
							}}
						>
							<img src={iconeMaison} className="img-fluid" />
						</div>

						{/* ****** */}

						{/* *** QUI SOMMES NOUS ? *** */}
						<div
							className="ft-parisienne text-center"
							style={{
								// paddingLeft: '20%',
								fontSize: "300%",
								color: "rgb(173, 173, 172)"
							}}
						>
							Qui sommes-nous ?
						</div>
						{/* ****** */}

						{/* *** IMAGE *** */}
						<img
							// src={anduze1}
							src={`${tools.lienPhotos}/anduze-1.jpg`}
							className="img-fluid"
							style={{ maxHeight: "600px" }}
						/>
						{/* ****** */}

						<div
							style={{
								background: "white",
								padding: "25px 50px 60px 50px",
								textAlign: "justify"
							}}
							className="blocHome"
						>
							<div
								style={{
									marginLeft: "-110px",
									backgroundColor: COULEUR_THEME,
									width: "120px",
									height: "3px"
								}}
							></div>
							{tools.titreSousTitre(
								this.props.data.titre,
								this.props.data.sousTitre
							)}
							<div className="text-justify pt-3">
								{this.props.data.contenu}
								<div className="pb-4"></div>
								{this.afficheBoutons(this.props.data)}
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}

	render() {
		const { t } = this.props;

		return (
			<>
				{this.grandEcran()}
				{this.mobile()}
			</>
		);
	}
}

export default withTranslation()(QuiSommesNous);
