import React from "react";
import Page from "../Page";
import { tools, ModuleCardActu, ModuleCard } from "@mitim/react-mitim";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { ThemeProvider } from "@material-ui/core";
import { getKeyValuePublique } from "../Constants";

export class ReunionInformation extends Page {
	constructor(props) {
		super(props);
		this.handleShow = this.handleShow.bind(this);
		this.handleClose = this.handleClose.bind(this);
	}

	state = {
		annonceReuDirect: "",
		show: false,
		acces: true,
		lienAudio: "",
		lienYoutube: false,
		fichiersATelecharger: []
	};

	componentDidMount() {
		super.componentDidMount();
		getKeyValuePublique("ag-reunion-information", val => {
			this.setState({
				fichiersATelecharger: val.fichiersATelecharger,
				lienAudio: val.lienAudio,
				lienYoutube: val.lienYoutube
			});
		});
	}

	handleClose() {
		this.setState({ show: false });
	}

	handleShow() {
		this.setState({ show: true });
	}

	render() {
		return (
			<>
				{this.state.acces && (
					<>
						<div className="container reunionsDirect">
							<div className="mt-4 mb-5">
								<h2 className="text-center mb-5"></h2>
								{tools.titreSousTitre(
									"Réunion du samedi 3 octobre 2020",
									"Diffusion en direct"
								)}
								<p>
									<ul className="liste">
										{/* <li>
											À 9H00 une réunion d’information administrative et de
											présentation des principaux éléments des comptes 2019.
										</li> */}
										<li>
											à 10H00, méditation de la Parole par Daniel Issarte.
										</li>
									</ul>
								</p>

								{/* <p className="pt-3 pb-3">
									<h4 className="pb-3">
										Liste des fichiers de présentation en pdf à télécharger :
									</h4>
									<ul className="liste">
										{this.state.fichiersATelecharger.map(fichier => (
											<>
												<li key={fichier.nom}>
													<a className="lien" href={fichier.lien}>
														{fichier.nom}
													</a>
												</li>
											</>
										))}
									</ul>
								</p> */}

								{/* {this.state.lienYoutube && ( */}
								<div className="video-container" style={{ top: "24px" }}>
									<div className="contenu-page">
										<div className="container pt-5 pb-5">
											<center>
												<iframe
													width={560}
													height={315}
													// src="https://www.youtube.com/embed/playlist?list=PLvqXsLJhAiXGbMU-QMxwT4MbxeuRRkINt"
													src={this.state.lienYoutube}
													frameBorder={0}
													allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
													allowFullScreen
												/>
											</center>
											<br />
										</div>
									</div>
								</div>
								{/* )} */}

								<div className="text-center pt-3 pb-5 mt-5">
									<h4 className="pt-5 pb-3 text-center">
										Si la vidéo ne fonctionne pas, vous pouvez suivre l'audio
										ci-dessous
									</h4>
									<audio
										controls="controls"
										controlsList="nodownload"
										preload="auto"
										// src="https://radio.timothee.fr/OIY97U8987N09Ukjh09ezrg8NHNergHNI879898IUHNKJHKJHOIHO.mp3"
										src={this.state.lienAudio}
									/>
								</div>
							</div>
						</div>
						<Modal show={this.state.show} onHide={this.handleClose}>
							<Modal.Body>
								<button
									type="button"
									class="close"
									data-dismiss="modal"
									aria-label="Close"
									onClick={this.handleClose}
								>
									<span aria-hidden="true">&times;</span>
								</button>
								<h3 className="text-center">{this.state.annonceReuDirect}</h3>
							</Modal.Body>
						</Modal>
					</>
				)}
			</>
		);
	}
}
