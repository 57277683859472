import React from "react";
import { withTranslation } from "react-i18next";
import "./Eglises.scss";
import { tools, api } from "@mitim/react-mitim";
import mtIconeCarteFrance from "../../resources/icons/carteFrance.svg";
import mtIconeCarteBulgarie from "../../resources/icons/carte-bulgarie.svg";
import mtIconeCarteGuinee from "../../resources/icons/carte-guinee.svg";
import mtIconeCarteMadagascar from "../../resources/icons/carte-madagascar.svg";
import ImageMapper from "react-image-mapper";
import ImageMapperWrapper from "./ImageMapperWrapper";
import { Redirect } from "react-router-dom";
import isExternal from "is-url-external";
import { Link } from "react-router-dom";
import Page from "../Page";
import * as data from "./data";
import Modal from "react-bootstrap/Modal";
import { Route } from "react-router-dom";

class Eglises extends Page {
	state = {
		show: false,
		popupValue: ""
	};

	handleClose() {
		this.setState({ show: false });
	}

	handleShow() {
		this.setState({ show: true });
	}

	grandEcran = () => <div className="d-none d-lg-block"></div>;

	render() {
		const { t } = this.props;

		const Eglise = props => (
			<div>
				<div
					className="lien text-decoration-none"
					style={{ fontSize: "1.5em" }}
					onClick={() => {
						if (props.element.popup) {
							this.setState({
								popupValue: props.element.popupValue,
								show: "true"
							});
						} else {
							window.open(`https://${props.element.lien}`, "_blank");
							return null;
						}
					}}
				>
					<div className="pl-5 text-uppercase pt-2 pb-2 eglise">
						{props.element.name}{" "}
						{props.element.cp && "(" + props.element.cp + ")"}
					</div>
				</div>
			</div>
		);

		const Titre = props => (
			<div
				className="bg-gris4 text-white pt-2 pb-2 pl-5 ft-lato-regular"
				style={{ fontSize: "1.5em" }}
			>
				<strong>{props.val}</strong>
			</div>
		);

		const EglisesMobile = props => (
			<div className="d-block d-lg-none eglisesMobile mt-5">
				<div className="row no-gutters">
					<div className="col bg-gris" style={{}}>
						<div>
							<Titre val="FRANCE" />
							{props.map
								.filter(e => e.country === "FR")
								.slice()
								.sort((a, b) => a.name.localeCompare(b.name))
								.map(element => (
									<Eglise key={element.name} element={element} />
								))}

							<Titre val="BELGIQUE" />
							{props.map
								.filter(e => e.country === "BE")
								.slice()
								.sort((a, b) => a.name.localeCompare(b.name))
								.map(element => (
									<Eglise key={element.name} element={element} />
								))}

							<Titre val="SUISSE" />
							{props.map
								.filter(e => e.country === "CH")
								.slice()
								.sort((a, b) => a.name.localeCompare(b.name))
								.map(element => (
									<Eglise key={element.name} element={element} />
								))}

							<Titre val="BULGARIE" />
							<Eglise key={"montana"} element={data.MAP_BULGARIE.areas[0]} />

							<Titre val="MADAGASCAR" />
							<Eglise key={"antsirabe"} element={data.MAP_MADA.areas[0]} />

							<Titre val="GUINÉE" />
							<Eglise key={"montana"} element={data.MAP_GUINEE.areas[0]} />
						</div>
					</div>
				</div>
			</div>
		);

		return (
			<>
				<div className="container-fluid">
					<div className="container">
						<h1 className="contenu-h1 pt-5 pb-5 text-uppercase">
							LES ÉGLISES RATTACHÉES
							<br />À LA MISSION TIMOTHÉE
						</h1>
						<p className="text-justify">
							Depuis la création de la maison d’accueil à Anduze, plusieurs
							personnes touchées par l’Évangile ont voulu s’associer au travail
							de la Mission Timothée en ouvrant leur maison pour accueillir des
							rencontres bibliques. Certaines assemblées se sont constituées
							pour former des églises locales, d’autres ont gardé une petite
							structure de groupe de maison. La Mission compte environ une
							trentaine de lieux de rencontre en France mais aussi en Suisse, en
							Belgique, à Madagascar, en Bulgarie et en Guinée Conakry.
						</p>
						<p>
							Les églises se réunissent régulièrement lors de rassemblements
							régionaux. Ces regroupements permettent d’organiser des weekends
							de jeunes et des chorales régionales.
						</p>
						<h3 className="ft-parisienne text-noir text-center pt-5 d-none d-lg-block">
							En France, Belgique, Luxembourg et Suisse
						</h3>
						<h5 className="text-center pb-5">
							Vous pouvez cliquer sur l'église sélectionnée pour accéder à son
							site internet
						</h5>
					</div>
				</div>

				<div
					className="container-fluid pb-lg-5 no-gutters d-none d-lg-block"
					style={{ minHeight: "480px", backgroundColor: "#e2e3e4" }}
				>
					<div className="container pt-5 pb-5">
						<ImageMapperWrapper
							map={data.MAP}
							imgSrc={mtIconeCarteFrance}
							width="1024"
						/>
					</div>
				</div>
				<div
					className="container-fluid pb-lg-5 no-gutters d-none d-lg-block"
					style={{ minHeight: "480px", backgroundColor: "#c9cacc" }}
				>
					<div className="container">
						<h3 className="ft-parisienne text-noir text-center pt-5 pb-5">
							À l’étranger
						</h3>
						<div className="row">
							<div className="col-lg-4">
								{/* <img src={mtIconeCarteBulgarie} className="img-fluid" /> */}

								<div className="">
									<div
										className=""
										style={{
											margin: "0 auto",
											width: "300px",
											height: "217px"
										}}
									>
										<ImageMapperWrapper
											map={data.MAP_BULGARIE}
											width="300"
											imgSrc={mtIconeCarteBulgarie}
										/>
									</div>
									<tools.Bouton
										lien="https://www.timotheeaccueiljeunesse.fr/centre-accueil-bulgarie"
										nom="Bulgarie"
									/>
								</div>
							</div>
							<div className="col-lg-4">
								{/* <img src={mtIconeCarteGuinee} className="img-fluid" /> */}
								<div
									className=""
									style={{ margin: "0 auto", width: "300px", height: "217px" }}
								>
									<ImageMapperWrapper
										className="img-fluid"
										map={data.MAP_GUINEE}
										width="300"
										imgSrc={mtIconeCarteGuinee}
									/>
								</div>
								{/* <bouton lien="https://missiontimothee.fr" /> */}
								<tools.Bouton
									lien="https://www.timotheeaccueiljeunesse.fr/orphelinat"
									nom="GUINÉE CONAKRY"
								/>
							</div>
							<div className="col-lg-4">
								{/* <img src={mtIconeCarteMadagascar} className="img-fluid"/> */}
								<div
									className=""
									style={{ margin: "0 auto", width: "100px", height: "217px" }}
								>
									<ImageMapperWrapper
										className="img-fluid"
										map={data.MAP_MADA}
										width="130"
										imgSrc={mtIconeCarteMadagascar}
									/>
								</div>
								<tools.Bouton
									lien="https://www.timotheeaccueiljeunesse.fr/centre-accueil-madagascar"
									nom="MADAGASCAR"
								/>
							</div>
						</div>
					</div>
				</div>
				<EglisesMobile map={data.MAP.areas} />
				<Modal show={this.state.show} onHide={this.handleClose.bind(this)}>
					<Modal.Body>
						<button
							type="button"
							class="close"
							data-dismiss="modal"
							aria-label="Close"
							onClick={this.handleClose.bind(this)}
						>
							<span aria-hidden="true">&times;</span>
						</button>
						<div dangerouslySetInnerHTML={{ __html: this.state.popupValue }} />
					</Modal.Body>
				</Modal>
			</>
		);
	}
}
export default withTranslation()(Eglises);
