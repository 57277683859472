// Libraries
import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";
// Images
import CouvertureCantiqueDefault1 from "../../resources/4.jpg";
import CouvertureCantiqueDefault2 from "../../resources/2.jpg";
import CouvertureCantiqueDefault3 from "../../resources/3.jpg";
import CouvertureAlbumCDDefault from "../../resources/albumCd_default.png";
import CouvertureRecueilDefault from "../../resources/recueil_default.png";
// import CouvertureCantiqueDefault1 from '../../resources/cantique_default.png';
// import CouvertureCantiqueDefault2 from '../../resources/cantique_default_2.png';
// import CouvertureCantiqueDefault3 from '../../resources/cantique_default_3.png';
// import CouvertureAlbumCDDefault from '../../resources/albumCd_default.png';
// import CouvertureRecueilDefault from '../../resources/recueil_default.png';

const cantiqueDefaults = [
	CouvertureCantiqueDefault1,
	CouvertureCantiqueDefault2,
	CouvertureCantiqueDefault3
];

const getCouverture = result => {
	let couvertureDefault = CouvertureCantiqueDefault1; // TODO remove when all case are defined
	if (result.mediaType.nom === "Cantique") {
		couvertureDefault = cantiqueDefaults[result.id % 3];
	} else if (result.mediaType.nom === "AlbumCd") {
		couvertureDefault = CouvertureAlbumCDDefault;
	} else if (result.mediaType.nom === "Recueil") {
		couvertureDefault = CouvertureRecueilDefault;
	}
	let couverture = couvertureDefault;
	if (
		result.mediaType.id === 1 ||
		result.mediaType.id === 4 ||
		result.mediaType.id === 6 ||
		result.mediaType.id === 8 ||
		result.mediaType.id === 9 ||
		result.mediaType.id === 10 ||
		result.mediaType.id === 11
	) {
		if (result.couverture && result.couverture.thumbnail_std.publicLink) {
			// couverture = result.couverture.thumbnail_std.sharedLink;
			couverture = result.couverture.thumbnail_std.publicLink;
		}
		// if (result.couverture && result.couverture.thumbnail_std.link) {
		// 	couverture = result.couverture.thumbnail_std.link;
		// }
	}
	return couverture;
};

const ResultIcons = props => {
	let fakeState = {
		audio: false,
		document: false,
		video: false,
		download: false
	};
	for (let indice in props.fichierTypePresent) {
		let id = props.fichierTypePresent[indice].id;
		if (id === 6 || id === 4) {
			fakeState.audio = true;
		} else if (id === 5) {
			fakeState.document = true;
		} else if (id === 2) {
			fakeState.video = true;
		}
		if (id === 4 || id === 5) {
			fakeState.download = true;
		}
	}
	let classes = props.classes || { root: "" };
	return (
		<div
			className={"mt-1 row media-icons justify-content-center " + classes.root}
		>
			{fakeState.audio && (
				<div className="col-auto audio">
					<SvgIcon viewBox="0 0 2810 2770">
						<g id="layer101" fill="#2d5467" stroke="none">
							<path d="M1185 2756 c-310 -52 -563 -181 -780 -400 -200 -201 -332 -452 -381 -726 -26 -140 -23 -374 4 -506 66 -311 217 -573 445 -772 191 -166 398 -268 657 -323 149 -32 410 -32 557 -1 190 41 360 113 523 221 105 69 287 249 357 352 101 148 181 334 219 509 26 120 27 429 1 550 -117 543 -525 954 -1076 1081 -112 26 -408 35 -526 15z" />
						</g>
						<g id="layer102" fill="#fefeff" stroke="none">
							<path d="M1398 1911 c-18 -9 -112 -67 -209 -129 l-177 -112 -159 0 c-220 0 -203 23 -203 -287 0 -231 1 -241 21 -259 19 -17 40 -19 186 -22 l164 -3 196 -125 c109 -68 207 -124 219 -124 12 0 32 9 45 19 l24 19 3 489 c2 477 2 490 -17 515 -27 34 -54 40 -93 19z" />
							<path d="M1742 1887 c-41 -44 -28 -89 37 -121 161 -82 249 -232 239 -407 -4 -64 -11 -91 -41 -151 -41 -84 -86 -131 -177 -186 -75 -45 -93 -77 -69 -120 26 -47 58 -50 135 -12 94 46 190 143 238 241 83 170 85 338 4 505 -60 125 -233 274 -318 274 -15 0 -36 -10 -48 -23z" />
							<path d="M1631 1631 c-12 -12 -21 -34 -21 -51 0 -24 10 -37 49 -67 64 -49 88 -102 71 -161 -8 -33 -22 -50 -66 -83 -51 -37 -55 -43 -52 -76 2 -25 11 -42 30 -56 25 -18 31 -19 69 -8 48 15 116 79 144 136 23 49 31 146 16 201 -33 123 -181 224 -240 165z" />
						</g>
					</SvgIcon>
				</div>
			)}
			{fakeState.document && (
				<div className="col-auto pdf">
					<SvgIcon viewBox="0 0 2930 2930">
						<g id="layer101" fill="#404142" stroke="none">
							<path d="M1296 2850 c-306 -39 -585 -169 -804 -375 -466 -438 -569 -1149 -246 -1695 215 -364 587 -615 1004 -676 159 -23 384 -15 530 19 684 161 1140 780 1080 1467 -30 343 -169 631 -420 873 -202 196 -427 314 -700 368 -110 22 -343 32 -444 19z" />
						</g>
						<g id="layer102" fill="#fdfefe" stroke="none">
							<path d="M875 2309 c-17 -4 -44 -22 -60 -39 l-30 -31 0 -719 0 -718 25 -27 c52 -56 40 -55 562 -55 l483 1 167 152 166 152 1 595 c1 580 0 596 -19 628 -12 19 -38 41 -63 52 -40 19 -71 20 -622 19 -319 0 -594 -5 -610 -10z m1135 -659 l0 -500 -45 0 c-42 0 -45 2 -35 19 16 32 12 60 -15 86 l-24 25 -419 0 c-409 0 -420 -1 -446 -21 -31 -25 -34 -66 -7 -100 l19 -24 356 -3 357 -3 -16 -22 c-11 -16 -15 -48 -15 -119 l0 -98 -380 0 -380 0 0 630 0 630 525 0 525 0 0 -500z" />
							<path d="M1043 1850 c-48 -20 -58 -87 -17 -119 26 -20 37 -21 446 -21 l419 0 24 25 c30 29 32 64 6 96 l-19 24 -419 2 c-230 1 -428 -2 -440 -7z" />
							<path d="M1044 1656 c-50 -22 -60 -78 -21 -114 23 -22 26 -22 449 -22 l427 0 20 26 c28 36 26 65 -5 95 l-26 24 -406 2 c-326 2 -413 0 -438 -11z" />
							<path d="M1044 1466 c-50 -22 -60 -78 -21 -114 23 -22 26 -22 446 -22 l422 0 24 25 c41 40 28 93 -29 115 -44 16 -803 13 -842 -4z" />
						</g>
					</SvgIcon>
				</div>
			)}
			{fakeState.video && (
				<div className="col-auto video">
					<SvgIcon viewBox="0 0 2860 2820">
						<g id="layer101" fill="#b01125" stroke="none">
							<path d="M1305 2813 c-92 -7 -237 -38 -334 -69 -503 -164 -877 -605 -957 -1129 -21 -132 -13 -384 14 -505 64 -275 187 -497 386 -695 201 -199 424 -321 705 -386 128 -30 392 -37 536 -14 589 92 1057 535 1175 1113 136 671 -235 1345 -878 1593 -197 75 -437 110 -647 92z" />
						</g>
						<g id="layer102" fill="#fffefe" stroke="none">
							<path d="M720 1415 l0 -695 93 0 92 0 88 88 88 89 -87 81 -87 82 88 0 87 0 84 -82 85 -82 -88 -88 -88 -88 130 0 130 0 83 83 c45 45 82 86 82 91 0 5 -37 44 -82 87 l-83 78 101 1 101 0 87 -82 87 -81 -88 -89 -88 -88 125 0 125 0 87 87 88 88 -82 82 -83 83 84 0 83 0 84 -82 84 -82 0 607 0 607 -705 0 -705 0 0 -695z m1230 430 l0 -85 -525 0 -525 0 0 85 0 85 525 0 525 0 0 -85z m0 -340 l0 -85 -525 0 -525 0 0 85 0 85 525 0 525 0 0 -85z" />
							<path d="M2040 805 l-85 -85 88 0 87 0 0 85 c0 47 -1 85 -3 85 -1 0 -40 -38 -87 -85z" />
						</g>
					</SvgIcon>
				</div>
			)}
			{fakeState.download && (
				<div className="col-auto download">
					<SvgIcon viewBox="0 0 2690 2220">
						<g id="layer101" fill="#404142" stroke="none">
							<path d="M20 2195 c0 -14 -4 -25 -10 -25 -6 0 -10 -137 -10 -390 l0 -390 105 0 105 0 0 310 0 310 1130 0 1130 0 0 -310 0 -310 110 0 110 0 0 390 0 390 -35 0 c-31 0 -35 3 -35 25 l0 25 -1300 0 -1300 0 0 -25z" />
							<path d="M1294 1526 c-32 -14 -658 -615 -668 -642 -11 -30 -6 -100 10 -122 39 -57 131 -69 186 -25 18 14 114 106 213 202 l180 176 5 -522 5 -522 29 -30 c51 -53 120 -54 179 -2 l32 29 5 522 5 522 195 -189 c107 -105 205 -195 217 -201 30 -17 88 -15 123 3 54 28 76 106 46 163 -20 37 -629 624 -663 639 -36 16 -61 15 -99 -1z" />
						</g>
					</SvgIcon>
				</div>
			)}
		</div>
	);
};

const indices = {
	category: "c",
	queryString: "qs",
	filters: "f",
	pageNumber: "pn",
	pageSize: "ps"
};
const indicex = {
	c: "category",
	qs: "queryString",
	f: "filters",
	pn: "pageNumber",
	ps: "pageSize"
};

const cleanFilterSearch = localFilterSearch => {
	let cleanedFilterSearch = {};
	let urlFilterSearch = {};
	for (var indice in localFilterSearch) {
		if (indice !== "filters" && indices.hasOwnProperty(indice)) {
			if (localFilterSearch[indice]) {
				cleanedFilterSearch[indice] = localFilterSearch[indice];
				if (indice !== "category") {
					urlFilterSearch[indices[indice]] = localFilterSearch[indice];
				}
			}
		}
	}
	if (localFilterSearch.pageNumber === 1) {
		delete urlFilterSearch[indices["pageNumber"]];
	}
	if (localFilterSearch.pageSize === 30) {
		delete urlFilterSearch[indices["pageSize"]];
	}
	for (var filterIndice in localFilterSearch.filters) {
		let filter = localFilterSearch.filters[filterIndice];
		var filterItems = [];
		var urlFilterItems = {};
		for (var filterItemIndice in filter.filterItems) {
			let item = filter.filterItems[filterItemIndice];
			if (item.value) {
				filterItems.push({ id: item.id, value: item.value });
				urlFilterItems[item.id] = item.value;
			}
		}
		if (filterItems.length > 0) {
			if (!cleanedFilterSearch.hasOwnProperty("filters")) {
				cleanedFilterSearch["filters"] = [];
				urlFilterSearch[indices["filters"]] = {};
			}
			cleanedFilterSearch.filters.push({
				type: filter.type,
				filterItems: filterItems,
				valueType: filter.valueType
			});
			urlFilterItems["vt"] = filter.valueType;
			urlFilterSearch[indices["filters"]][filter.type] = urlFilterItems;
		}
	}
	return {
		cleanedFilterSearch: cleanedFilterSearch,
		urlFilterSearch: urlFilterSearch
	};
};

const parseUrl = stringFilter => {
	let jsonFilter = JSON.parse(stringFilter);
	let parseFilter = {};
	for (var indice in jsonFilter) {
		if (indice !== "f" && indicex.hasOwnProperty(indice)) {
			if (jsonFilter[indice]) {
				parseFilter[indicex[indice]] = jsonFilter[indice];
			}
		}
	}
	for (var filterIndice in jsonFilter.f) {
		let listItems = jsonFilter.f[filterIndice];
		var filterItems = [];
		let valueType;
		for (var filterItemIndice in listItems) {
			let id = Number.parseInt(filterItemIndice);
			if (Number.isNaN(id)) {
				if (filterItemIndice === "vt") {
					valueType = listItems[filterItemIndice];
				}
				continue;
			}
			filterItems.push({ id: id, value: listItems[id] });
		}
		if (filterItems.length > 0) {
			if (!parseFilter.hasOwnProperty("filters")) {
				parseFilter["filters"] = [];
			}
			parseFilter.filters.push({
				type: filterIndice,
				filterItems: filterItems,
				valueType: valueType
			});
		}
	}
	return parseFilter;
};

export { getCouverture, ResultIcons, cleanFilterSearch, parseUrl };
