// Libraries
import React from "react";

class AsyncImage extends React.Component {
	constructor(props) {
		super(props);
		this.state = { loaded: false };
		this.bgImg = React.createRef();
	}

	componentDidMount() {
		var bgImg = new Image();
		bgImg.onload = () => {
			this.setState(() => ({ loaded: true }));
		};
		bgImg.src = this.props.source;
		this.bgImg.current = bgImg;
	}

	componentWillUnmount() {
		this.bgImg.current.onload = () => {};
	}

	render() {
		const { source, placeholderColor, width, height } = this.props;
		return (
			<div
				style={{
					width: width,
					height: height,
					backgroundImage: this.state.loaded
						? "url(" + source + ")"
						: undefined,
					backgroundColor: this.state.loaded ? undefined : placeholderColor,
					backgroundRepeat: "no-repeat",
					backgroundSize: "contain",
					backgroundPosition: "center",
					borderRadius: "5px"
				}}
				className={this.props.cssClass}
			/>
		);
	}
}

export default AsyncImage;
