import React from "react";
import { withTranslation } from "react-i18next";
// import ModuleContact from '../ModuleContact/ModuleContact';
import { ModuleContact } from "@mitim/react-mitim";
import "@mitim/react-mitim/dist/styles.css";
import ModuleGallerie from "../ModuleGallerie/ModuleGallerie";
import { tools } from "@mitim/react-mitim";

class ModuleMaisonAccueil extends React.Component {
	render() {
		return (
			<>
				<div className="container-fluid">
					<div className="row">
						<ModuleContact data={this.props.contact} />
						<div className="col-xl-1"></div>
						<div className="col-xl-7">
							<div className="text-center pt-5">
								{!this.props.icone && (
									<img
										src={require(`../../resources/icons/information.svg`)}
										className="img-fluid"
										style={{ height: "90px" }}
									/>
								)}
							</div>

							{tools.titreSousTitre(this.props.titre, this.props.sousTitre)}
							<div className="pb-5" style={{}}>
								{this.props.contenu}
							</div>
						</div>
						<div className="col-xl-1"></div>
					</div>
				</div>

				{this.props.idGallerie && (
					<ModuleGallerie idGallerie={this.props.idGallerie} />
				)}
			</>
		);
	}
}
export default withTranslation()(ModuleMaisonAccueil);
