import React from "react";
import "./ElementArticle.scss";
import { Link } from "react-router-dom";
import { tools, api } from "@mitim/react-mitim";

export class ElementArticle extends React.Component {
	componentDidMount() {}

	state = {
		selected: false
	};

	render() {
		const couleurTheme = theme => {
			switch (theme) {
				case "Sans thème":
					return tools.BLEU;
				case "Exhortation":
					return tools.BLEU_TURQUOISE;
				case "Histoire":
					return tools.ORANGE;
				case "Témoignage":
					return tools.ORANGE_CLAIR;
				case "Réflexion":
					return tools.ORANGE2;
				case "Étude":
					return tools.MARRON;
				case "Théologie":
					return tools.VIOLET;
				case "Enseignement":
					return tools.VERT_FONCE;
				case "Édification":
					return tools.VERT;
				case "Éthique":
					return tools.ROSE;
				case "Médical":
					return tools.ROUGE;
				case "Étude Biblique":
					return tools.JAUNE_CLAIR;
				case "Doctrine":
					return tools.JAUNE;
				case "Société":
					return tools.ORANGE2;
				case "Témoignage historique":
					return tools.VERT;
				default:
					return tools.JAUNE_CLAIR;
			}
		};

		return (
			<div className="col-12 mt-1 result" style={{ backgroundColor: "#fff" }}>
				<div className="row pl-md-3">
					<div className="col-12 mt-1 result affichageCantiques">
						<Link
							to={`/media/${this.props.result.id.raw}/${this.props.result.titre.raw}`}
							className="lien"
						>
							<div>
								<span
									className="text-white pl-3 pr-3 ft-lato-regular pt-2 pb-1 text-uppercase"
									style={{
										fontSize: "0.8em",
										backgroundColor: couleurTheme(this.props.result.theme.raw)
									}}
								>
									<>{this.props.result.theme.raw}</>
								</span>
							</div>
							<div className="select pr-2">
								<div
									className="pt-2 text-black ft-alegreya-sans-light pb-2"
									style={{ fontSize: "1em" }}
								>
									<span style={{ verticalAlign: "bottom" }}>
										<span className="pb-5">
											<strong>{this.props.result.titre.raw}</strong>

											{this.props.result.auteurs.raw &&
												this.props.result.auteurs.raw.length > 1 && (
													<>
														{" | "}
														{this.props.result.auteurs.raw.map(
															(data, index) => (
																<>
																	{data}
																	{this.props.result.auteurs.raw.length > 1 &&
																		index <
																			this.props.result.auteurs.raw.length -
																				1 &&
																		", "}
																</>
															)
														)}
													</>
												)}

											{this.props.result.auteurs.raw &&
												this.props.result.auteurs.raw.length === 1 && (
													<>
														{" | "}
														{this.props.result.auteurs.raw.map(
															(data, index) => (
																<>{data}</>
															)
														)}
													</>
												)}
											{" | " + this.props.result.date_parution.raw}
										</span>
									</span>
									<span style={{ float: "right" }}></span>
								</div>
							</div>
						</Link>
						<hr className="pt-2 pl-2" />
					</div>
				</div>
			</div>
		);
	}
}
