// Libraries
import React from "react";
import { Link } from "react-router-dom";
import { getCouverture, ResultIcons } from "./utils";
import AsyncImage from "../AsyncImage";
import { tools, BlockImgNlt } from "@mitim/react-mitim";
import * as Outils from "./outils";

class ResultGridView extends React.Component {
	render() {
		/**
		 * Commun
		 * - titre : text
		 * - id : int
		 * - contenu : html
		 * - couverture
		 * - isPublic
		 * - lang {id, nom,abreg}
		 * - mediaType
		 * - sousTitre
		 * - datePublication
		 * - typeObjet
		 */
		/**
		 * Cantique
		 * - arrangements [{id, nom}]
		 * - origine [{id, nom}]
		 * - remarque
		 * - typeCantiques [{id, nom}]
		 * - langues [{id, nom, abreg}]
		 */
		/**
		 * Recueil
		 * - dateEdition
		 * - isbn
		 * - lienLjc
		 * - prix
		 * - nbrPages *
		 */
		/**
		 * AlbumCd
		 * - dateEdition
		 * - isbn
		 * - lienLjc
		 * - prix
		 * - nbrFichiers *
		 * - dureeTotale *
		 */

		const { result } = this.props;

		return (
			<div className="col-6 col-lg-6 col-xl-3 text-center mt-5 result">
				<Link
					to={
						"/media/" +
						this.props.result.id +
						"/" +
						this.props.result.titre.split(" ").join("-")
					}
				>
					<div className="img-zoom mx-auto">
						{/* <AsyncImage
							source={getCouverture(this.props.result)}
							placeholderColor="#ececec"
							cssClass="mx-auto result-img async-img-result"
							className="img-fluid pl-3 pr-3"
						/> */}
						<BlockImgNlt
							imgSrc={getCouverture(this.props.result)}
							fichierTypePresent={this.props.result.fichierTypePresent}
						/>
					</div>

					<div className="text-center pt-3">
						<span
							style={{
								backgroundColor: tools.getColorOfMediaType(
									this.props.result.mediaType.id
								),
								fontSize: "1.2em"
							}}
							className="text-white pl-2 pr-2"
						>
							{this.props.result.mediaType.nom}
						</span>

						{/* <h6 className="mt-2 result-title">{this.props.result.titre}</h6> */}
						<div className="pt-2 lien" style={{ fontSize: "1.1em" }}>
							{Outils.getTitre(result) || ""}
						</div>
						{/* <div className="pt-2 lien" style={{ fontSize: '1.1em' }}>{result.titre || ""}</div> */}
						<div className="lien" style={{ fontSize: "0.8em" }}>
							{result.sousTitre || ""}
						</div>
						<div className="lien" style={{ fontSize: "0.6em" }}>
							{result.datePublication || ""}
						</div>
					</div>
				</Link>
			</div>
		);
	}
}

export default ResultGridView;
