import React from 'react';

export default function IconTemplate(path)
{
	return (props) =>
	{
		const {size, ...attrs} = props; 
		if (size)
		{
			if (typeof(size) == 'object')
			{
				attrs.height = size.height;
				attrs.width = size.width;
			}
			else if (typeof(size) == 'number' || typeof(size) == 'string')
			{
				attrs.height = attrs.width = size;
			}
		}
		
		return (
			<svg {...attrs} viewBox="0 0 24 24"><path d={path} /></svg>
		);
	};
}
