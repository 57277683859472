// Libraries
import React from "react";
import { withTranslation } from "react-i18next";
// Components
import Page from "../Page";
import Media from "./Media";
import Header from "../Header/Header";
// Images
import play1 from "../../resources/albumCd/play.svg";
import play2 from "../../resources/albumCd/play2.png";
// CSS
// import '../../styles/Media.css';
// import '../../styles/MediaAlbumCd.scss';
import $ from "jquery";

class MediaAlbumCd extends Media {
	constructor(props) {
		super(props);
		this.audioPlayer = React.createRef();
	}

	handleClick = mediaMedia => {
		if (mediaMedia.fichier) {
			console.log("this is:", mediaMedia.fichier.sharedLink);
			console.log("this is:", mediaMedia.fichier.id);
			this.props.app.setState(
				{
					cantiqueListen: mediaMedia.fichier.sharedLink,
					id: mediaMedia.fichier.id,
					rangMediaMedia: mediaMedia.rang,
					classementMediaMedia: mediaMedia.classement
				},
				() => {
					this.audioPlayer.current.type = "audio/mp3";
					this.audioPlayer.current.play();
				}
			);
		}
	};

	playAll = () => {
		for (const [index, value] of this.props.app.state.mediaMedias
			.filter(mediaMedia => mediaMedia.fichier)
			.entries()) {
			this.props.app.setState(
				{
					cantiqueListen: value.fichier.sharedLink,
					id: value.fichier.id,
					rangMediaMedia: value.rang,
					classementMediaMedia: value.classement
				},
				() => {
					this.audioPlayer.current.type = "audio/mp3";
					this.audioPlayer.current.play();
				}
			);
			break;
		}
	};

	handleEnded = () => {
		for (const [index, value] of this.props.app.state.mediaMedias
			.filter(mediaMedia => mediaMedia.fichier)
			.entries()) {
			if (
				this.props.app.state.classementMediaMedia < value.classement ||
				(this.props.app.state.classementMediaMedia === value.classement &&
					this.props.app.state.rangMediaMedia < value.rang)
			) {
				this.props.app.setState(
					{
						cantiqueListen: value.fichier.sharedLink,
						id: value.fichier.id,
						rangMediaMedia: value.rang,
						classementMediaMedia: value.classement
					},
					() => {
						this.audioPlayer.current.type = "audio/mp3";
						this.audioPlayer.current.play();
					}
				);
				break;
			}
		}
	};

	audio() {
		return (
			<>
				<audio
					style={{}}
					src={this.props.app.state.cantiqueListen}
					preload="true"
					ref={this.audioPlayer}
					id="audio1"
					controls="controls"
					controlsList="nodownload"
					onEnded={this.handleEnded}
					type="audio/mpeg"
				>
					Your browser does not support HTML5 Audio!
				</audio>
			</>
		);
	}

	classOfCantique(mediaMedia) {
		var classValue = "";
		if (mediaMedia.fichier) {
			if (mediaMedia.fichier.id === this.props.app.state.id) {
				classValue = "ml-5 my-3 ligne lineSelected";
			} else {
				classValue = "ml-5 my-3 ligne";
			}
		} else {
			classValue = "ml-5 my-3";
		}
		return classValue;
	}

	getImageSrcByRang(rang) {
		var result = "";
		this.props.app.state.fichiers.forEach(function(element) {
			if (
				element.rang === rang &&
				element.fichier.thumbnail_std &&
				element.couverture
			) {
				result = element.fichier.thumbnail_std.publicLink;
			}
		});

		if (result === "") {
			this.props.app.state.fichiers.forEach(function(element) {
				if (
					element.rang === 1 &&
					element.fichier.thumbnail_std &&
					element.couverture
				) {
					result = element.fichier.thumbnail_std.publicLink;
				}
			});
		}
		return result;
	}

	render() {
		const { t } = this.props;
		return (
			<>
				<Header
					titre={this.props.app.state.media.titre}
					sousTitre={this.props.app.state.media.sousTitre}
					images={"mt-enfants.jpg"}
					metaTagKeyword={this.props.app.state.metaTagKeyword}
				/>
				<div className="Media">
					<h3 className="container text-center title">
						{this.props.app.state.media.titre}
						<br />
					</h3>
					<h3 className="container text-center">
						{this.props.app.state.media.sousTitre}
					</h3>

					<div
						className="container-fluid bg-grey-light mt-5"
						style={{ minHeight: "480px" }}
					>
						<div className="container pl-0 pr-0">
							<div className="col-lg-12 col-md-12">
								<div className="row">
									<div className="block col-md-6 col-sm-12 mt-3 mb-3">
										<div className="pt-5 mt-2">
											<img
												src={this.getImageSrcByRang(1)}
												className="img-fluid w-100"
												alt=""
											/>
										</div>
									</div>
									<div className="block col-md-6 col-sm-12 mt-3 mb-3">
										<div className="mt-5">
											<div
												dangerouslySetInnerHTML={{
													__html: this.props.app.state.media.contenu
												}}
											/>
											<div style={{ color: "#bbbdc0" }}>
												{this.props.app.state.media.dureeTotale && (
													<>
														Durée totale :{" "}
														{this.props.app.state.media.dureeTotale}
													</>
												)}
												{this.props.app.state.media.dateEdition && (
													<>, {this.props.app.state.media.dateEdition}</>
												)}
												{this.props.app.state.media.nbrFichiers && (
													<>, {this.props.app.state.media.nbrFichiers} chants</>
												)}
												{this.props.app.state.media.isbn && (
													<>, isbn: {this.props.app.state.media.isbn}</>
												)}
												{this.props.app.state.media.prix > 0 && (
													<>, prix: {this.props.app.state.media.prix}€</>
												)}
												{this.props.app.state.media.lienLjc !== "" && (
													<>
														,{" "}
														<a
															href={
																"https://www.librairiejeancalvin.fr/index.php/ljc/Publications/(id)/" +
																this.props.app.state.media.lienLjc
															}
															target="_blank"
															style={{ color: "#bbbdc0" }}
														>
															Commander
														</a>
													</>
												)}
											</div>
										</div>
									</div>
								</div>
								<div className="row align-self-center">
									<div className="col-12 align-self-center my-4">
										{/* <img src={play2} style={{ width: '40px' }} /> Regarder la
										présentation vidéo du CD */}
									</div>
								</div>
							</div>
						</div>
					</div>

					<div className="container-fluid" style={{ minHeight: "480px" }}>
						<div className="container home pl-0 pr-0">
							<div className="col-lg-12 col-md-12 pl-0 pr-0">
								<div className="container">
									<div className="row align-self-center">
										<div
											className="col-12 align-self-center mt-5 mb-4 cursor-pointer"
											onClick={this.playAll}
										>
											<img src={play1} style={{ width: "40px" }} /> Écouter
											l’album entier / Écouter des extraits de l’album (pour CD
											de - d’1 an)
										</div>
									</div>
									<div className="row align-self-center" />
									<div className="row">
										<div className="col-md-6 col-sm-12">
											<div className="row">
												<div className="col-sm-12 pt-3">
													<img
														src={this.getImageSrcByRang(2)}
														className="img-fluid w-100"
														alt=""
													/>
												</div>
												<div className="col-sm-12 mt-5 text-center pl-0">
													{this.audio()}
												</div>
											</div>
										</div>
										<div className="block col-md-6 col-sm-12 mt-3 mb-3">
											<table className="table table-condensed player">
												<tbody>
													{this.props.app.state.mediaMedias.map(mediaMedia => (
														<tr
															key={mediaMedia.mediaChild.id}
															className={this.classOfCantique(mediaMedia)}
															onClick={this.handleClick.bind(this, mediaMedia)}
														>
															<td className="player rang">
																<span
																	style={{ color: "#666" }}
																	className="pl-md-4 pr-md-3"
																>
																	{mediaMedia.rang}{" "}
																</span>
															</td>
															<td>{mediaMedia.mediaChild.titre}</td>
														</tr>
													))}
												</tbody>
											</table>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}

export default withTranslation()(MediaAlbumCd);
