import React from "react";
import { withTranslation } from "react-i18next";
import BackgroundSlider from "react-background-slider";
import SlideTemplate1 from "./SlideTemplate1";
import SlideTemplate2 from "./SlideTemplate2";
import { tools } from "@mitim/react-mitim";

class Slide extends React.Component {
	// state = { images: '' };

	video() {
		if (
			this.props.slide.slideBackgroud &&
			this.props.slide.slideBackgroud === "video"
		) {
			return (
				<>
					{/* https://www.dropbox.com/s/rk8odsgwwp71lkg/6eb5182146d8dcd909811df8e8309a57a827d867.webm?raw=1 */}
					<figure
						className="figureReactBackgroundSlider d-none d-lg-block"
						style={{
							filter: "brightness(70%)",
							overflow: "hidden",
							maxHeight: "550px",
							// marginTop: "50px",
							backgroundSize: "auto",
							backgroundPositionY: "-200px"
						}}
					>
						<video
							autoPlay
							loop
							playsInline
							muted
							// preload="autobuffer"
							preload="none"
							style={{
								marginLeft: "auto",
								marginRight: "auto",
								width: "1920px",
								marginTop: "-180px",
								zIndex: "-100"
							}}
							poster={`${tools.lienWallpaper}/${this.props.slide.image}`}
						>
							<source
								src={`${tools.lienVideos}/banniere-accueil.mp4`}
								type="video/mp4"
							/>
							<source
								src={`${tools.lienVideos}/banniere-accueil.webm`}
								type="video/webm"
							/>
							Sorry, your browser doesn't support embedded videos.
						</video>
					</figure>
					<figure
						className="figureReactBackgroundSlider d-block d-lg-none"
						style={{
							backgroundImage:
								"url(" +
								`${tools.lienWallpaper}/${this.props.slide.image}` +
								")",
							filter: "brightness(60%)",
							maxHeight: "500px",
							marginTop: "0px",
							backgroundSize: "auto",
							backgroundPositionY: "-50px"
						}}
					/>
				</>
			);
		} else {
			return <></>;
		}
	}

	wallpaper() {
		return (
			<figure
				className="figureReactBackgroundSlider"
				style={{
					backgroundImage:
						"url(" + `${tools.lienWallpaper}/${this.props.slide.image}` + ")",
					filter: "brightness(60%)",
					maxHeight: "500px",
					backgroundSize: "auto",
					backgroundPositionY: "-50px"
				}}
			/>
		);
	}

	render() {
		const data = this.props.slide;

		const images = this.props.images;
		const { t } = this.props;

		return (
			<section className="slider-background">
				<div id="ReactBackgroundSlider">
					{this.props.slide.template === 1 && this.video()}
					{this.props.slide.template === 2 && this.wallpaper()}
					{this.props.slide.template === 3 && this.video()}
				</div>

				<div className="container-fluid">
					<div className="row" style={{ marginTop: "50px" }}>
						{this.props.slide.template === 1 && (
							<SlideTemplate1 slide={this.props.slide} />
						)}
						{this.props.slide.template === 2 && (
							<SlideTemplate2 slide={this.props.slide} />
						)}
						{this.props.slide.template === 3 && (
							<SlideTemplate2 slide={this.props.slide} />
						)}
					</div>
				</div>
			</section>
		);
	}
}
export default withTranslation()(Slide);
