import React, { useState, useEffect } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { api } from "@mitim/react-mitim";

export default function PaiementStripeForm(props) {
	const [succeeded, setSucceeded] = useState(false);
	const [error, setError] = useState(null);
	const [processing, setProcessing] = useState("");
	const [disabled, setDisabled] = useState(true);
	const [clientSecret, setClientSecret] = useState("");
	const stripe = useStripe();
	const elements = useElements();

	useEffect(() => {
		// Create PaymentIntent as soon as the page loads
		const idTransaction = sessionStorage.getItem("idTransaction");

		const tokenPublic = "cHVibGljOjk4SE85SE9JVUdPSTk4SA==";
		const defaultHeaders = {
			Accept: "application/json",
			"Content-Type": "application/json",
			Authorization: "Basic " + tokenPublic
		};
		window
			.fetch(
				api.timediaUrl + "/public/abonnements/transaction/" + idTransaction,
				{
					method: "GET",
					headers: defaultHeaders
					// body: JSON.stringify({items: [{ id: "xl-tshirt" }]})
				}
			)
			.then(res => {
				return res.json();
			})
			.then(data => {
				setClientSecret(data.data.idIntentClientSecretStripe);
			});
	}, []);

	const cardStyle = {
		hidePostalCode: true,
		style: {
			base: {
				color: "#32325d",
				fontFamily: "Arial, sans-serif",
				fontSmoothing: "antialiased",
				fontSize: "16px",
				"::placeholder": {
					color: "#32325d"
				}
			},
			invalid: {
				color: "#fa755a",
				iconColor: "#fa755a"
			}
		}
	};

	const handleChange = async event => {
		// Listen for changes in the CardElement
		// and display any errors as the customer types their card details
		setDisabled(event.empty);
		setError(event.error ? event.error.message : "");
	};

	const handleSubmit = async ev => {
		ev.preventDefault();
		setProcessing(true);

		const payload = await stripe.confirmCardPayment(clientSecret, {
			payment_method: {
				card: elements.getElement(CardElement),
				billing_details: {
					name: ev.target.name.value
				}
			}
		});

		if (payload.error) {
			setError(`Payment failed ${payload.error.message}`);
			setProcessing(false);
			props.self.setState({
				messageModal: (
					<>
						Votre paiement n'a pas pu être effectué, veuillez réessayer plus
						tard ou nous contacter via la page contact
					</>
				),
				showModal: true
			});
		} else {
			console.log(payload);

			const tokenPublic = "cHVibGljOjk4SE85SE9JVUdPSTk4SA==";
			const defaultHeaders = {
				Accept: "application/json",
				"Content-Type": "application/json",
				Authorization: "Basic " + tokenPublic
			};

			let createPaymentResponse = {
				clientSecret: payload.paymentIntent.client_secret,
				statusApresPaiement: payload.paymentIntent.status,
				montantPaye: payload.paymentIntent.amount
			};
			fetch(`${api.timediaUrl}/public/abonnements/transaction`, {
				method: "PUT",
				body: JSON.stringify(createPaymentResponse),
				headers: defaultHeaders
			})
				.then(response => {
					if (response.status !== 200) {
						console.log("Error: " + response.status);
						return;
					}
					response.json().then(data => {
						console.log(data);
						sessionStorage.setItem("idDonation", data.id);
						this.setState({
							messageEntete: "Paiement par carte bancaire",
							formulaireDonSubmited: true,
							choixMenuFormulairePaiement: 2
						});
					});
				})
				.catch(err => {
					console.log(err);
					this.props.self.setState({
						messageModal:
							"Le serveur est indisponible, veuillez réessayer plus tard",
						showModal: true
					});
				});

			setError(null);
			setProcessing(false);
			setSucceeded(true);
			props.self.setState(
				{
					messageModal: (
						<>
							Votre paiement a bien été effectué, vous recevrez un E-Mail de
							confirmation.
							<br />
							Merci
						</>
					),
					showModal: true,
					reloadPage: true
				},
				() => {
					sessionStorage.removeItem("idTransaction");
					sessionStorage.removeItem("tabFormulaire");
					sessionStorage.removeItem("montantAPayer");
				}
			);
		}
	};

	return (
		<>
			<form id="payment-form" onSubmit={handleSubmit}>
				<CardElement
					id="card-element"
					options={cardStyle}
					onChange={handleChange}
				/>

				<button
					disabled={processing || disabled || succeeded}
					id="submit"
					style={{}}
					className="mt-4"
				>
					<span id="button-text">
						{processing ? (
							<div className="spinner" id="spinner"></div>
						) : (
							"Payer"
						)}
					</span>
				</button>
			</form>
		</>
	);
}
