import React from "react";
import { VoteRadioBouton } from "../AGVote/VoteRadioBouton";
import { EnteteTaj, EnteteMT } from "../AGVote/AGVote";
import { tools } from "@mitim/react-mitim";
import { FichiersATelecharger, FICHIER_TELECHARGER_MT } from "./DATA";
import {
	Titre2,
	Titre3,
	TableauMembresMT,
	TableauSignature
} from "../AGVote/Fonctions";

export const ContenuMt = props => (
	<>
		<div className="voteAg pt-5 mb-5">
			<br />
			<EnteteMT />
			<h1 className="">BULLETIN DE VOTE PAR CORRESPONDANCE</h1>

			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* // PREALABLE */}
			<Titre2
				couleur={tools.JAUNE_CLAIR}
				texte="DELIBERATION PREALABLE – COMPOSITION DU BUREAU"
			/>
			<p className="pt-2">
				<b>
					Le conseil d’administration propose à l’assemblée la composition
					suivante de son Bureau :
				</b>
			</p>
			<ul>
				<li>
					Daniel ISSARTE, préside la séance en sa qualité de président de
					l'association
				</li>
				<li>
					David GUTSCHER membre du conseil d'administration assure le
					secrétariat de la séance
				</li>
				<li>
					Jérémie LOIRET membre du conseil d’administration assure la fonction
					de coordinateur
				</li>
			</ul>
			<p>
				La fonction des membres du bureau prend fin avec lors du renouvellement
				du mandat des membres du conseil d’administration concernés. Une
				nouvelle élection sera alors proposée aux membres.
			</p>
			<VoteRadioBouton
				numVote="mtAgPre"
				self={props.self}
				defaultPour={true}
				titre="VOTE d’approbation de la composition du bureau ainsi proposée"
			/>
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* // 1 */}
			<Titre2
				couleur={tools.VERT}
				texte="PREMIÈRE DELIBERATION - APPROBATION DU PROCES-VERBAL DE L’ASSEMBLEE GENERALE DE 2020"
			/>
			<p>
				Chacun a reçu copie du procès-verbal de l’Assemblée générale tenue
				l’année précédente portant sur l’activité de l’année 2019.
			</p>
			<VoteRadioBouton
				numVote="mtAg1"
				self={props.self}
				defaultPour={true}
				titre="Vote d’approbation du procès-verbal d’Assemblée générale de l’année 2018"
			/>
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* // 2 */}
			<Titre2
				couleur={tools.BLEU_FONCE}
				texte="DEUXIÈME DELIBERATION - RAPPORT MORAL ET D’ACTIVITE"
			/>
			<p>
				Le rapport n’est pas exhaustif mais vise à rappeler quelques grandes
				lignes dans ce contexte tout à fait particulier de la pandémie.
				L’activité de l’association a bien entendu été fortement impactée, dans
				chaque lieu, les consignes ont été respectées en relation avec le Bureau
				des Cultes et les Préfectures locales.
			</p>
			<Titre3
				couleur={tools.BLEU_FONCE}
				texte="A. COMPTE RENDU DU COORDINATEUR. JEREMIE LOIRET"
			/>
			<p>
				L’activité cultuelle a été fortement impactée durant les périodes de
				confinement. Nous avons pu mesurer à quel point les rassemblements
				communautaires avaient une place importante chez beaucoup de croyants.
				Si nous pouvons nous en réjouir, il y a aussi matière à s’interroger
				lorsque plusieurs se sont trouvé désemparés, étant incapable de vivre
				leur foi comme s’ils n’avaient rien reçu de personnel. Ces confinements
				ont donc constitué un puissant révélateur quant à l’état de la vie
				intérieure de chacun. Il a été rappelé lors de la dernière assemblée
				générale sur quel fondement se base notre communion. La réalité de
				l’Église dépasse l’aspect de la proximité géographique.
			</p>
			<Titre3
				couleur={tools.BLEU_FONCE}
				texte="B. COMITE DE REDACTION. STEPHANE ZEHR"
			/>
			<p>
				En 2020 a paru le troisième tome du Commentaire d’Esaïe, très attendu,
				rédigé par Luc Warnon à partir du cours de formation et des prédications
				de Daniel Issarte (280 ex vendus). Ont paru aussi 2 numéros de la LRFT
				(n°20 et 21).
			</p>
			<VoteRadioBouton
				numVote="mtAg2"
				self={props.self}
				defaultPour={true}
				titre="Vote d’approbation sans réserve du rapport morale et d’activité de l’année 2020"
			/>
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* // 3 */}
			<Titre2
				couleur={tools.ROSE}
				texte="TROISIÈME DELIBERATION – APPROBATION DES COMPTES ANNUELS 2020"
			/>
			<p>
				Le trésorier communique les comptes annuels tels qu’établis le 31
				décembre 2020. Son rapport de gestion est également communiqué. Ces
				documents sont communiqués en pièce jointe aux membres.
			</p>
			<p>
				Sur le compte de résultat, les recettes ont augmenté de 153 000 euros
				environ sur l’année 2020. Le trésorier souligne une stabilité
				remarquable des dons. Les dépenses sont principalement et logiquement
				composées par la charge salariale et par le financement des lieux de
				culte.
			</p>
			<VoteRadioBouton
				numVote="mtAg3"
				self={props.self}
				defaultPour={true}
				titre="Vote d’approbation sans réserve des comptes et du rapport de gestion de l’année 2020 en donnant quitus aux membres du conseil d'administration de l’association de l'exécution de leur mandat au titre de l'exercice écoulé"
			/>
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* // 4 */}
			<Titre2
				couleur={tools.VIOLET}
				texte="QUATRIÈME DELIBERATION – AFFECTATION DE L’EXCEDENT"
			/>
			<p>
				Le bureau propose à l'assemblée générale d'affecter l'excédent de
				l'exercice clos le 31 décembre 2020 comme suit : 170 937 € aux fonds
				associatifs sans droit de reprise.
			</p>
			<VoteRadioBouton
				numVote="mtAg4"
				self={props.self}
				defaultPour={true}
				titre="Vote d’approbation sans réserve de l’affectation de l’excédent de trésorerie sur l’année écoulée"
			/>
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* // 5 */}
			<Titre2
				couleur={tools.ORANGE_CLAIR}
				texte="CINQUIEME DELIBERATION – APPROBATION DU BUDGET 2021"
			/>
			<p>
				Le bureau propose à l'assemblée générale un projet de budget 2021.
				Ventilé comme suit :
			</p>
			<ul>
				<li>Budget de fonctionnement : 696 637 euros </li>
				<li>Budget d’investissement : 82 000 euros</li>
			</ul>
			<p>Un graphique récapitulatif est joint aux présentes.</p>
			<p>
				Vote d’approbation sans réserve du budget prévisionnel de l’association
				tel que présenté Sur l’ensemble des éléments comptables sus évoqués, les
				rapports du commissaire aux comptes Monsieur Jean Paul Lacombe sont
				joints aux présentes.
			</p>
			<p>
				Pour rappel, l’objet de sa mission légale qui est d’apporter un regard
				différent pour s’assurer que l’ensemble des éléments qui rentrent dans
				les comptes de l’association soient conformes à l’objet social. Il
				précise que sa mission s’effectue en toute transparence et indépendance
				juridique et financière. Il a aussi la responsabilité d’exprimer une
				opinion sur les comptes : pour cet exercice il s’agit d’une
				certification sans réserve.
			</p>
			<VoteRadioBouton
				numVote="mtAg5"
				self={props.self}
				defaultPour={true}
				titre=""
			/>
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* // 6 */}
			<Titre2
				couleur={tools.BLEU_TURQUOISE}
				texte="SIXIEME DELIBERATION – RENOUVELLEMENT DES MEMBRES DU CONSEIL D’ADMINISTRATION"
			/>
			<p>
				L’activité d’administrateur est une activité bénévole. Chacune des
				personnes, ci-après évoquées s’engagent en raison de son intérêt
				personnel pour la poursuite de l’objet associatif. Il ne peut, en aucune
				façon s’agir d’une activité entrant dans le cadre d’une rémunération et
				ce, en raison du principe de non-lucrativité de l’engagement associatif.
			</p>
			<p>
				Enfin, les organes de direction proposent à l’assemblée de renouveler
				une partie des membres du conseil d’administration conformément à
				l’article 7 des statuts, ces personnes seront nommées pour une durée de
				3 ans : Matthias FEHLMANN, Jean-Luc LOIRET, Jean-Luc MEIRLEAN, Patrick
				RICARD, Martin TRUMPFF, Gérard TYNEVEZ, Luc WARNON, Jean-Martin WEHREY,
				Samuel ZEHR (rayer en cas de non-approbation).
			</p>
			<p className="pt-2 text-center">
				<b>Le conseil d’administration serait alors composé comme suit :</b>
			</p>
			<TableauMembresMT />
			<VoteRadioBouton
				numVote="mtAg6"
				self={props.self}
				defaultPour={true}
				titre="VOTE - Approbation du renouvellement des membres du conseil d'administration"
			/>
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* // 7 */}
			<Titre2
				couleur={tools.ROSE}
				texte="SEPTIEME DELIBERATION : CONSEIL D’ADMINISTRATION ET BUREAU"
			/>
			<Titre3 couleur={tools.ROSE} texte="Membres Conseil d’administration" />
			<p>
				Le conseil d'administration de l'association est composé de neuf à
				quarante membres élus par l'assemblée générale, le nombre exact étant
				fixé par décision spéciale de l'assemblée générale prise ou modifiée
				avant l'ouverture de scrutin pour les élections triennales.
			</p>
			<p>
				Le conseil d'administration est renouvelé tous les trois ans par
				l'élection d'un tiers des membres élus. Cette élection a lieu à bulletin
				secret et à la majorité des membres présents ou représentés.
			</p>
			<p>
				Tout membre majeur est éligible et les membres sortants sont
				rééligibles. Après chaque renouvellement triennal, le conseil
				d'administration élit pour trois ans son bureau composé d'un président,
				d'un secrétaire et d'un trésorier. Si, au cours du mandat, un des postes
				du bureau devient vacant, le conseil pourvoit au remplacement,
				l'expiration du mandat du nouveau membre du bureau étant la même que
				celle du membre qu'il remplace.
			</p>
			<p>
				Le conseil d'administration se réunit sur convocation du secrétaire
				ordonnée par le président. Les questions à l'ordre du jour y sont
				mentionnées.
			</p>
			<p>
				Les conseils d’administration peuvent se tenir de manière dématérialisée
				( par exemple par vidéo-conférence, mailing….)
			</p>
			<p>
				Pour les réunions avec support audio ou vidéo-conférence, les décisions
				sont prises à la majorité absolue des membres présents ou représentés.
				La présence d’un tiers des membres est indispensable à la validité des
				déclarations. Pour les consultations par mailing, il n’y a pas de quorum
				applicable, les décisions sont prises à la majorité absolue des membres
				consultés. Le conseil d'administration a les pouvoirs les plus étendus
				pour gérer les affaires de l'association (y compris pour l’acceptation
				des dons et legs comprenant des immeubles) et la représenter au regard
				des tiers.
			</p>
			<p>La liste du CA est ci-dessus. Résolution précédente</p>
			<Titre3 couleur={tools.ROSE} texte="Nomination du bureau" />
			<p>
				Le conseil d'administration élit en son sein un Bureau comprenant un
				président, un secrétaire et un trésorier.
			</p>
			<ul>
				<li>
					Le président représente l'association auprès des pouvoirs publics,
					ordonnance les dépenses, signe valablement tout acte et représente
					l'association en justice.
				</li>
				<li>
					Le secrétaire est chargé de tout ce qui concerne la correspondance. Il
					rédige les procès-verbaux tant des assemblées générales que des
					conseils d'administration. C'est également lui qui tient le registre
					spécial.
				</li>
				<li>
					Le trésorier perçoit les recettes, paye les dépenses et fait tous les
					versements, virements et retraits sur les comptes de l'association. Un
					autre membre du conseil d’administration peut recevoir délégation du
					conseil pour accomplir tout ou partie des mêmes opérations sous sa
					seule signature.{" "}
				</li>
			</ul>
			<p>Il a été élu l’année passée pour 3 ans :</p>
			<ul>
				<li>
					Monsieur Daniel Issarte, demeurant mas de Pelissou (30) Tornac en tant
					que Président
				</li>
				<li>
					Monsieur Jérémie Loiret, demeurant Chemin de Laucire à Tornac (30) en
					tant que secrétaire
				</li>
				<li>
					Andréas FEHLMANN, demeurant Grand Rue à LE VAUD ( Suisse) en tant que
					trésorier
				</li>
			</ul>
			<VoteRadioBouton
				numVote="mtAg7"
				self={props.self}
				defaultPour={true}
				titre="VOTE de la Résolution – approbation de la désignation du bureau"
			/>
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* // 8 */}
			<Titre2
				couleur={tools.ORANGE}
				texte="HUITIEME DELIBERATION - DELEGATION DE POUVOIR EN VUE D'ACCOMPLIR LES FORMALITES"
			/>
			<p>
				Le bureau propose à l’assemblée de donner tous pouvoirs à Monsieur David
				GUTSCHER et Monsieur Benjamin DROUILLY, et, plus généralement au porteur
				d’une copie ou d’un extrait du procès-verbal de la présente assemblée
				pour accomplir les formalités nécessaires.
			</p>
			<VoteRadioBouton
				numVote="mtAg8"
				self={props.self}
				defaultPour={true}
				titre="VOTE de la Résolution - Délégation de pouvoir en vue d'accomplir les formalités"
			/>
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			<p className="pt-3 pb-3 text-center">
				<b>
					L’ordre du jour étant épuisé, le vote est laissé à la discrétion des
					membres.
				</b>
			</p>
			<TableauSignature />
			<h5 className="pt-5">Liste des annexes Mission Timothée</h5>
			<FichiersATelecharger liste={FICHIER_TELECHARGER_MT} />
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
		</div>

		<div className="pt-5 pb-5">
			<div className="row">
				<div className="col-md-5 text-center pb-3">
					<button
						onClick={event => {
							event.preventDefault();
							props.self.setState(
								{
									menu: 1
								},
								() => window.scrollTo(0, 450)
							);
						}}
						className="btn btn-primary center-block pl-5 pr-5"
					>
						Précédent
					</button>
				</div>
				<div className="col-md-2"></div>
				<div className="col-md-5 text-center">
					{!props.self.state.voteOk && (
						<button
							onClick={event => {
								event.preventDefault();
								props.self.handleSubmitFormulaire();
								// if (props.self.state.elements.rgpd !== "") {
								// 	props.self.handleSubmitFormulaire(event);
								// } else {
								// 	console.log("pas de validation");
								// 	props.self.setState({
								// 		showModal2: true,
								// 		messageModal2:
								// 			"Vous devez accepter ou refuser la clause de protection des données"
								// 	});
								// }
							}}
							className="btn btn-primary center-block pl-5 pr-5"
						>
							Valider mon vote
						</button>
					)}
				</div>
			</div>
		</div>
	</>
);
