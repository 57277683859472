// Libraries
import React from "react";
import Filter from "./Filter";
import { Button } from "@material-ui/core";
import { cleanFilterSearch } from "./utils";
import { withTranslation } from "react-i18next";

class FilterList extends React.Component {
	constructor(props) {
		super(props);
		this.filters = React.createRef();
	}

	toggleFilters = () => {
		this.filters.current.classList.toggle("d-none");
	};

	render() {
		const { t } = this.props;
		const filters = this.props.filterList.map(filter => {
			const filterItems = filter.filterItems.sort((a, b) => {
				if (filter.type === "livreBible") {
					return a.id - b.id;
				} else {
					if (a.label && b.label) {
						return a.label
							.trim()
							.toLowerCase()
							.localeCompare(b.label.trim().toLowerCase(), "fr", {
								sensitivity: "base"
							});
					} else {
						return 0;
					}
				}
			});
			return (
				<Filter
					onFilterItemChange={this.props.onFilterItemChange}
					key={filter.type}
					filterItems={filterItems}
					{...filter}
				/>
			);
		});
		const filterSearch = { filters: this.props.filterList };
		const { cleanedFilterSearch } = cleanFilterSearch(filterSearch);
		const haveFilters =
			cleanedFilterSearch.filters && cleanedFilterSearch.filters.length > 0;

		return (
			<>
				<div className={this.props.className + " filter-list"}>
					<div className="m-2 mb-3 d-block d-sm-none">
						<Button
							variant="contained"
							color="secondary"
							fullWidth
							onClick={this.toggleFilters}
						>
							{t("recherche.toggle-filters")}
						</Button>
					</div>
					{haveFilters && (
						<div className="m-2 mb-3">
							<Button
								fullWidth
								variant="contained"
								color="secondary"
								onClick={this.props.clearFilters}
							>
								{t("recherche.clear-filters")}
							</Button>
						</div>
					)}

					<div className="d-none d-sm-block p-xl-4" ref={this.filters}>
						{filters}
					</div>
				</div>
			</>
		);
	}
}

export default withTranslation()(FilterList);
