// Libraries
import React from "react";
import { withTranslation } from "react-i18next";
// Components
import Page from "../Page";
import Media from "./Media";
import Header from "../Header/Header";
// Images
import play1 from "../../resources/albumCd/play.svg";
import play2 from "../../resources/albumCd/play2.png";
// CSS
// import '../../styles/Media.css';
// import '../../styles/MediaAlbumCd.scss';
import $ from "jquery";

class MediaRecueil extends Media {
	constructor(props) {
		super(props);
		// this.audioPlayer = React.createRef();
	}

	getImageSrcByRang(rang) {
		var result = "";
		this.props.app.state.fichiers.forEach(function(element) {
			if (
				element.rang === rang &&
				element.fichier.thumbnail_std &&
				element.couverture
			) {
				result = element.fichier.thumbnail_high.publicLink;
			}
		});

		if (result === "") {
			this.props.app.state.fichiers.forEach(function(element) {
				if (
					element.rang === 1 &&
					element.fichier.thumbnail_std &&
					element.couverture
				) {
					result = element.fichier.thumbnail_high.publicLink;
				}
			});
		}
		return result;
	}

	isContainDownloadFiles() {
		var val = false;
		if (this.props.app.state.fichiers.length > 0) {
			this.props.app.state.fichiers.forEach(function(element) {
				if (!element.couverture || element.couverture == null) {
					val = true;
				}
			});
		}
		return val;
	}

	render() {
		const { t } = this.props;
		return (
			<>
				<Header
					titre={this.props.app.state.media.titre}
					sousTitre={this.props.app.state.media.sousTitre}
					images={"mt-enfants.jpg"}
					// metaTagKeyword={this.props.app.state.metaTagKeyword}
					metaTagKeyword={this.props.app.state.metaTagKeyword}
				/>
				<div className="Media">
					<h3 className="container text-center title">
						{this.props.app.state.media.titre}
						<br />
					</h3>
					<h3 className="container text-center">
						{this.props.app.state.media.sousTitre}
					</h3>

					<div
						className="container-fluid bg-grey-light mt-5"
						style={{ minHeight: "480px" }}
					>
						<div className="container pl-0 pr-0 pb-5">
							<div className="col-lg-12 col-md-12">
								<div className="row">
									<div className="block col-md-4 col-sm-12 mt-3 mb-3">
										<div className="pt-5 mt-2">
											<img
												src={this.getImageSrcByRang(1)}
												className="img-fluid w-100"
												alt=""
											/>
										</div>
									</div>
									<div className="block col-md-8 col-sm-12 mt-3 mb-3">
										<div className="mt-5">
											<div
												dangerouslySetInnerHTML={{
													__html: this.props.app.state.media.contenu
												}}
											/>
											<div style={{ color: "#bbbdc0" }}>
												{this.props.app.state.media.nbrPages > 0 && (
													<>
														nbr pages : {this.props.app.state.media.nbrPages},{" "}
													</>
												)}
												{this.props.app.state.media.dateEdition && (
													<>
														édition : {this.props.app.state.media.dateEdition},{" "}
													</>
												)}
												{this.props.app.state.media.nbrFichiers && (
													<>{this.props.app.state.media.nbrFichiers} chants, </>
												)}
												{this.props.app.state.media.isbn && (
													<>isbn: {this.props.app.state.media.isbn}, </>
												)}
												{this.props.app.state.media.prix > 0 && (
													<>prix: {this.props.app.state.media.prix}€</>
												)}
												{this.props.app.state.media.lienLjc !== "" && (
													<>
														,{" "}
														<a
															href={
																"https://www.librairiejeancalvin.fr/index.php/ljc/Publications/(id)/" +
																this.props.app.state.media.lienLjc
															}
															target="_blank"
															style={{ color: "#bbbdc0" }}
														>
															Commander
														</a>
													</>
												)}
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div className="container-fluid">
						<div className="container home pl-0 pr-0">
							{this.isContainDownloadFiles() && (
								<div className="col-12 pt-3 mb-5">
									<h4 className="title mb-3">Fichier(s) à télécharger</h4>
									<ul className="p-1 p-md-4" style={{ minWidth: "300px" }}>
										{this.props.app.state.fichiers.map(
											fichier =>
												!fichier.couverture && (
													<li key={fichier.fichier.id} className="my-1">
														<a
															style={{ color: "#666" }}
															href={
																fichier.fichier.link && fichier.fichier.link
															}
														>
															{fichier.fichier.extension}
															{fichier.fichier.description &&
																" - " + fichier.fichier.description}
														</a>
													</li>
												)
										)}
									</ul>
								</div>
							)}
						</div>
					</div>
				</div>
			</>
		);
	}
}

export default withTranslation()(MediaRecueil);
