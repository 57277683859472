// Libraries
import React from "react";
import { Button, Input, InputAdornment, IconButton } from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import ClearIcon from "@material-ui/icons/Clear";
import { withTranslation } from "react-i18next";
// Components
import FilterCheckBox from "./FilterCheckBox";

class Filter extends React.Component {
	constructor(props) {
		super(props);
		this.filterList = React.createRef();
		this.state = {
			open:
				this.props.label &&
				this.props.label.trim().toLowerCase() === "types de médias",
			hasScrollBar: false,
			toutVoir:
				this.props.label &&
				this.props.label.trim().toLowerCase() === "types de médias",
			search: "",
			filterItems: []
		};
	}

	handleToggleFilter = () => {
		this.setState(
			prevState => ({ open: !prevState.open }),
			() => {
				if (
					this.filterList.current.clientHeight !==
						this.filterList.current.scrollHeight ||
					!this.state.open
				) {
					this.setState({ hasScrollBar: this.state.open });
				}
				if (!this.state.open) {
					this.setState({
						toutVoir:
							this.props.label &&
							this.props.label.trim().toLowerCase() === "types de médias"
					});
				}
			}
		);
	};

	handleSeeAll = () => {
		this.setState(prevState => ({ toutVoir: !prevState.toutVoir }));
	};

	handleChange = event => {
		var val = event.target.value;
		this.setState({
			search: val,
			filterItems: this.props.filterItems.filter(elem =>
				elem.label.toLowerCase().includes(val.toLowerCase())
			)
		});
	};

	handleClickResetField = () => {
		this.setState({ search: "", filterItems: this.props.filterItems });
	};

	onFilterItemChange = value => {
		this.props.onFilterItemChange({ type: this.props.type, item: value });
	};

	componentDidUpdate(prevProps) {
		if (this.props.filterItems !== prevProps.filterItems) {
			this.setState({ filterItems: this.props.filterItems });
		}
	}

	componentDidMount() {
		this.setState({ filterItems: this.props.filterItems });
	}

	render() {
		const { t } = this.props;
		return (
			<div className="filter m-2 mb-3">
				<h5
					className={
						"p-2 mb-0 " + (this.state.open ? "filter-open" : "filter-close")
					}
					onClick={this.handleToggleFilter}
				>
					{this.props.label}
					<div className="float-right">
						{this.state.open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
					</div>
				</h5>
				<div
					className={!this.state.toutVoir ? "filter-items" : undefined}
					ref={this.filterList}
				>
					{this.state.hasScrollBar && (
						<Input
							placeholder={t("menu.rechercher")}
							inputProps={{
								"aria-label": t("menu.rechercher")
							}}
							fullWidth
							className="px-2 mb-2"
							onChange={this.handleChange}
							value={this.state.search}
							endAdornment={
								this.state.search.length > 0 && (
									<InputAdornment position="end">
										<IconButton onClick={this.handleClickResetField}>
											<ClearIcon />
										</IconButton>
									</InputAdornment>
								)
							}
						/>
					)}
					{this.props.valueType === "checkboxList" && (
						<FilterCheckBox
							filterItems={this.state.filterItems}
							onFilterItemChange={this.onFilterItemChange}
							cssClass="px-2"
							rootClass={
								this.state.open
									? "filter-items-checkbox-show"
									: "filter-items-hide"
							}
							titleCase={this.props.type !== "auteur"}
						/>
					)}
					{/* TODO other filter type like dateRange ... */}
				</div>
				{this.state.hasScrollBar && (
					<Button color="#0ff" fullWidth onClick={this.handleSeeAll}>
						{this.state.toutVoir
							? t("recherche.see-less")
							: t("recherche.see-all")}
					</Button>
				)}
			</div>
		);
	}
}

export default withTranslation()(Filter);
