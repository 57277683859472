import React from "react";
import "./Sondage.scss";
import Modal from "react-bootstrap/Modal";
import { api } from "@mitim/react-mitim";
import { ContenuSondage } from "./ContenuSondage";
import Page from "../Page";

export class Sondage extends Page {
	state = {
		messageModal: "",
		messageModal2: "",
		showModal: false,
		showModal2: false,
		suivantClicked: false,
		elements: {},
		membreChorale: true,
		membreOrc: true,
		nom: "",
		prenom: "",
		token: ""
	};

	componentDidMount() {
		window.scrollTo(0, 0);
		const defaultHeaders = {
			Accept: "application/json",
			"Content-Type": "application/json",
			Authorization: "Basic cHVibGljOjk4SE85SE9JVUdPSTk4SA=="
		};

		fetch(
			`${api.timediaUrl}/public/authByToken/${this.props.match.params.token}`,
			{
				method: "GET",
				headers: defaultHeaders
			}
		)
			.then(response => {
				if (response.status !== 200) {
					console.log("Error: " + response.status);
					return;
				}
				response.json().then(data => {
					console.log(data.data);
					this.setState({
						nom: data.data.lastname || "",
						prenom: data.data.firstname || "",
						token: data.data.token || ""
					});
				});
			})
			.catch(err => {
				console.log(err);
			});
	}

	handleSubmitFormulaire = event => {
		event.preventDefault();
		window.scrollTo(0, 0);
		const data = this.state.elements;
		var message = {};
		message = JSON.stringify(data);

		const defaultHeaders = {
			Accept: "application/json",
			"Content-Type": "application/json",
			Authorization: "Basic " + this.state.token
		};

		fetch(`${api.timediaUrl}/sondages/validerSondage/choraleMaiJuin2022`, {
			method: "POST",
			body: message,
			headers: defaultHeaders
		})
			.then(response => {
				if (response.status !== 200) {
					console.log("Error: " + response.status);
					return;
				}
				response.json().then(data => {
					this.setState({
						messageModal: (
							<>
								Vos informations ont bien été prises en compte
								<br />
								Merci
							</>
						),
						showModal: true
					});
				});
			})
			.catch(err => {
				console.log(err);
			});
	};

	render() {
		const BoiteDialogue = () => (
			<Modal
				show={this.state.showModal}
				onHide={() => this.setState({ showModal: false }, () => {})}
			>
				<Modal.Body>
					<button
						type="button"
						className="close"
						data-dismiss="modal"
						aria-label="Close"
						onClick={() =>
							this.setState({ showModal: false }, () =>
								this.state.voteOk
									? this.props.history.push({
											pathname: "/",
											state: { from: "/" }
									  })
									: window.location.reload()
							)
						}
					>
						<span aria-hidden="true">&times;</span>
					</button>
					<h4 className="text-center">{this.state.messageModal}</h4>
				</Modal.Body>
			</Modal>
		);
		return (
			<div className="container-fluid voteAG no-gutters">
				<div className="container">
					<div className="formulaireInformations">
						<ContenuSondage self={this} />
					</div>
					<BoiteDialogue />
				</div>
			</div>
		);
	}
}
