import React from "react";
import AppSearchAPIConnector from "@elastic/search-ui-app-search-connector";
import "./ElasticArticlesRevuesProvider.scss";
import { Link } from "react-router-dom";
import { RESULT_FIELDS, FACETS } from "./config/configuration";

import {
	ErrorBoundary,
	SearchProvider,
	WithSearch
} from "@elastic/react-search-ui";
import "@elastic/react-search-ui-views/lib/styles/styles.css";

import {
	buildAutocompleteQueryConfig,
	getConfig
} from "./config/config-helper";
import ElasticArticlesRevues from "./ElasticArticlesRevues";

const { hostIdentifier, searchKey, endpointBase, engineName } = getConfig();
const connector = new AppSearchAPIConnector({
	searchKey,
	engineName,
	hostIdentifier,
	endpointBase
});

export default class ElasticArticlesRevuesProvider extends React.Component {
	constructor(props) {
		super(props);
	}

	state = {
		filterClass: "d-none",
		query: ""
	};

	toggleFilters = () => {
		this.state.filterClass === "d-none"
			? this.setState({ filterClass: "" })
			: this.setState({ filterClass: "d-none" });
	};

	render() {
		let configElasticSearch = {
			trackUrlState: false,
			initialState: { resultsPerPage: 48 },
			autocompleteQuery: buildAutocompleteQueryConfig(),
			apiConnector: connector,
			//hasA11yNotifications: true,
			alwaysSearchOnInitialLoad: true,
			searchQuery: {
				result_fields: RESULT_FIELDS,
				//search_fields: {},
				//disjunctiveFacets: [""],
				facets: FACETS
				//filters: filters(),
				//query: this.state.query
				//resultsPerPage: 18
			}
		};

		return (
			<div className="">
				<SearchProvider config={configElasticSearch}>
					<WithSearch
						mapContextToProps={({ wasSearched }) => ({ wasSearched })}
					>
						{({ wasSearched }) => {
							return (
								<ErrorBoundary>
									<ElasticArticlesRevues />
								</ErrorBoundary>
							);
						}}
					</WithSearch>
				</SearchProvider>
			</div>
		);
	}
}
