import React from "react";
import Formule from "./Formule";
import Livraison from "./Livraison";
import { FormulaireInformations } from "./FormulaireInformations";
import { Storage, api } from "@mitim/react-mitim";
import "./Abonnement.scss";
import Modal from "react-bootstrap/Modal";
import { cle_stripe_mt_publique } from "../Constants";
import PaiementStripeForm from "./PaiementStripeForm";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { Link } from "react-router-dom";

export class Abonnement extends React.Component {
	state = {
		// formulaireDonSubmited: false,
		messageEntete: "Je m'abonne",
		reloadPage: false,
		elements: {
			civilite: "M.",
			nom: "",
			prenom: "",
			montant: 0,
			montantAbonnement: 0,
			adressePostale: "",
			ville: "",
			pays: "",
			codePostal: "",
			tel: "",
			email: "",
			nombreRevues: 0,
			eglise: 0,
			livraisonDomicile: false,
			recevoirDerniereRevue: true
		},
		choixPaiement: "",
		showModal: false,
		messageModal: "",
		tabFormulaire: 1,
		lockedTab: true,
		abonne: null,
		lastRevue: {},
		revueDejaCommande: false
	};

	componentDidMount() {
		this.getAbonne();
		this.isRevueDejaCommande();
		const tf = sessionStorage.getItem("tabFormulaire");
		// const user = JSON.parse(localStorage.getItem("user"));
		const user = Storage.getUserParsed();

		user &&
			this.setState({
				elements: {
					...this.state.elements,
					["user"]: user.id
				}
			});
		if (tf) {
			parseInt(tf, 10) === 4 &&
				this.setState({
					tabFormulaire: 4
				});
		}
	}

	getAbonne() {
		// const user = JSON.parse(localStorage.getItem("user"));
		const user = Storage.getUserParsed();

		const tokenPublic = "cHVibGljOjk4SE85SE9JVUdPSTk4SA==";
		const defaultHeaders = {
			Accept: "application/json",
			"Content-Type": "application/json",
			Authorization: "Basic " + tokenPublic
		};

		if (user) {
			fetch(`${api.timediaUrl}/public/abonnements/user/${user.id}`, {
				method: "GET",
				headers: defaultHeaders
			})
				.then(response => {
					if (response.status !== 200) {
						console.log("Error: " + response.status);
						return;
					}
					response.json().then(data => {
						const abonne = data.data;
						abonne &&
							this.setState(
								{
									abonne: abonne,
									elements: {
										...this.state.elements,
										["civilite"]: abonne.civilite,
										["livraisonDomicile"]: false,
										["nom"]: abonne.nom,
										["prenom"]: abonne.prenom,
										["dateNaissance"]: abonne.dateNaissance,
										["lieuNaissance"]: abonne.lieuNaissance,
										["adressePostale"]: abonne.adressePostale,
										["ville"]: abonne.ville,
										["pays"]: abonne.pays,
										["codePostal"]: abonne.codePostal,
										["tel"]: abonne.tel,
										["email"]: abonne.email,
										["eglise"]: abonne.eglise ? abonne.eglise.id : null
									}
								},
								() => console.table(this.state.elements)
							);
						// this.props.app.setState({
						// 	elements: { ...this.props.app.state.elements, ["eglise"]: data.data[0].id }
						// }, () => console.table(this.props.app.state.elements))
						console.log(data.data);
					});
				})
				.catch(err => {
					console.log(err);
				});
		}
	}

	isRevueDejaCommande() {
		// const user = JSON.parse(localStorage.getItem("user"));
		const user = Storage.getUserParsed();

		const tokenPublic = "cHVibGljOjk4SE85SE9JVUdPSTk4SA==";
		const defaultHeaders = {
			Accept: "application/json",
			"Content-Type": "application/json",
			Authorization: "Basic " + tokenPublic
		};

		if (user) {
			fetch(`${api.timediaUrl}/public/isRevueDejaCommande/${user.id}`, {
				method: "GET",
				headers: defaultHeaders
			})
				.then(response => {
					if (response.status !== 200) {
						console.log("Error: " + response.status);
						return;
					}
					response.json().then(data => {
						this.setState(
							{
								revueDejaCommande: data.data,
								elements: {
									...this.state.elements,
									["recevoirDerniereRevue"]: !data.data
								}
							},
							() => console.table(this.state.elements)
						);
					});
				})
				.catch(err => {
					console.log(err);
				});
		}
	}

	tabAbonnement() {
		const Tab = props => (
			<li className="nav-item">
				<div
					className={props.active === 1 ? "nav-link active" : "nav-link"}
					style={{ cursor: "pointer" }}
					onClick={() =>
						props.locked
							? null
							: this.setState(
									{ tabFormulaire: props.num },
									sessionStorage.setItem("tabFormulaire", props.num)
							  )
					}
				>
					{props.nom}
				</div>
			</li>
		);
		return (
			<ul className="nav nav-tabs">
				<Tab
					active={this.state.tabFormulaire === 1 ? 1 : 0}
					nom="Formule"
					num={1}
					locked={false}
				/>
				<Tab
					active={this.state.tabFormulaire === 2 ? 1 : 0}
					nom="Informations"
					num={2}
					locked={this.state.lockedTab}
				/>
				<Tab
					active={this.state.tabFormulaire === 3 ? 1 : 0}
					nom="Livraison"
					num={3}
					locked={this.state.lockedTab}
				/>
				<Tab
					active={this.state.tabFormulaire === 4 ? 1 : 0}
					nom="Carte bancaire"
					num={4}
					locked={this.state.lockedTab}
				/>
				{/* <Tab active={this.state.tabFormulaire === 5 ? 1 : 0} nom="Virement" num={5} locked={this.state.lockedTab} />
				<Tab active={this.state.tabFormulaire === 6 ? 1 : 0} nom="Chèque" num={6} locked={this.state.lockedTab} /> */}
			</ul>
		);
	}

	affichageLivraison = () => {
		console.table(this.state.elements);

		if (sessionStorage.getItem("livraisonDomicile") === "true") {
			const nombreRevues = sessionStorage.getItem("nombreRevues");
			const fraisDePorts = nombreRevues * 4;
			return `${fraisDePorts}€ (4€ x ${nombreRevues} revues)`;
		} else {
			return "gratuit";
		}
	};

	contenuFormulaire() {
		switch (this.state.tabFormulaire) {
			case 1:
				return <Formule app={this} />;
			case 2:
				return <FormulaireInformations app={this} />;
			case 3:
				return <Livraison app={this} />;
			case 4:
				return (
					<>
						<div className="" style={{ width: "100%", position: "relative" }}>
							<div
								className=""
								style={{
									position: "absolute",
									left: "50%",
									transform: "translate(-50%,0)",
									top: "40px"
								}}
							>
								<div className="pt-4 pb-4 text-center">
									<h4>
										Abonnement :{" "}
										{sessionStorage.getItem("montantAbonnement") + " €"}
									</h4>
									<h4>
										Frais de ports :{" "}
										{/* {this.state.elements.livraisonDomicile */}
										{/* {sessionStorage.getItem("nombreRevues") === "true"
											? this.affichageLivraison()
											: "gratuit"} */}
										{this.affichageLivraison()}
									</h4>
									<h4>
										Montant à payer : {sessionStorage.getItem("montantAPayer")}{" "}
										€
									</h4>
								</div>
								<div className="paiementCarte" style={{}}>
									<Elements
										// stripe={loadStripe("pk_test_on4y898bTfQKFPOOGeJKTXeu00qQoKTPjQ")}
										stripe={loadStripe(cle_stripe_mt_publique)}
									>
										<PaiementStripeForm self={this} />
										{/* <CardSetupForm /> */}
									</Elements>
								</div>
							</div>
							<p
								className="text-center"
								style={{
									top: "200px",
									position: "absolute",
									left: "50%",
									transform: "translate(-50%,0)"
								}}
							>
								<div className=""></div>
							</p>
						</div>
					</>
				);

			default:
				return <></>;
		}
	}

	render() {
		const BoiteDialogue = () => (
			<Modal
				show={this.state.showModal}
				onHide={() =>
					this.setState({ showModal: false }, () =>
						this.props.reloadPage ? null : window.location.reload()
					)
				}
			>
				<Modal.Body>
					<button
						type="button"
						class="close"
						data-dismiss="modal"
						aria-label="Close"
						onClick={() =>
							this.setState({ showModal: false }, () =>
								this.props.reloadPage ? null : window.location.reload()
							)
						}
					>
						<span aria-hidden="true">&times;</span>
					</button>
					<h4 className="text-center">{this.state.messageModal}</h4>
				</Modal.Body>
			</Modal>
		);
		return (
			<>
				<div className="pt-3 pb-3 pl-5">
					{this.state.abonne ? (
						<>
							<h4>
								Nom de l'abonné : {this.state.abonne.prenom}{" "}
								{this.state.abonne.nom}
							</h4>
							<h4>
								Votre crédit est de : {this.state.abonne.nombreRevues} revues
							</h4>
							<div className="pt-5">
								<h6>
									Pour augmenter votre nombre de revues, vous pouvez choisir la
									formule qui vous convient ci-dessous
								</h6>
								<p>
									En cas de problème technique merci de nous joindre via la page{" "}
									<Link className="lien" to="/contact">
										contact
									</Link>{" "}
									ou au 07.70.10.83.91
								</p>
								<h6>
									Si vous retombez sur la page d'accueil lors de la sélection
									d'une formule, veuillez vous déconnecter, puis vous
									reconnecter.
								</h6>
							</div>
						</>
					) : (
						<></>
					)}
				</div>
				<div className="abonnement pb-5">
					<BoiteDialogue />
					<div className="card">
						<div className="card-header">{this.state.messageEntete}</div>
						<div className="card-body">
							{this.tabAbonnement()}
							<div className="pt-4 pb-4" style={{ minHeight: "400px" }}>
								{this.contenuFormulaire()}
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}
