import React from "react";
import { withTranslation } from "react-i18next";
import Page from "../Page";
import Search from "../Search";
import { ModuleCaroussel } from "@mitim/react-mitim";
import { Z_BLOCK } from "zlib";

import { ModuleEncartListMt, tools, api } from "@mitim/react-mitim";
import Pub from "../Pub/Pub";

class ParolePartageeBaseDonnee extends Page {
	constructor(props) {
		super(props);
	}

	state = {
		hover: false
	};

	toggleHover() {
		this.setState({ hover: !this.state.hover });
	}

	render() {
		const { t } = this.props;

		return (
			<>
				<Search
					location={this.props.location}
					rootPath={this.props.match.path}
					defaultCategory="toutParolePartagee"
					categories={[
						// "toutCantique",
						"ouvrage",
						// "exhortation",
						// "conférence",
						// "témoignage",
						"revue"
					]}
				/>
			</>
		);
	}
}

export default withTranslation()(ParolePartageeBaseDonnee);
