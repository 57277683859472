import React from "react";

// ###############################################################################################################################################
// ###############################################################################################################################################
// ###############################################################################################################################################

export const FICHIER_TELECHARGER_MT = [
	{
		nom: "Rapports du commissaire aux comptes",
		lien:
			"https://www.dropbox.com/scl/fi/tox59quncclbl34hm6tun/6b425ca8dd45cb191c484985b124abde1d8e1615.pdf?rlkey=qfagose57vel9etkvez266qrq&raw=1"
	},
	{
		nom: "PV d’Assemblée générale ordinaire du 25 novembre 2023",
		lien:
			"https://www.dropbox.com/scl/fi/bog8696dj9j7u3rbyetjc/d5dbec279421f2d784d1dcba601b300938053f65.pdf?rlkey=dk6a890wbq9xx674ssi70n8j5&raw=1"
	}
];
export const FICHIER_TELECHARGER_TAJ = [
	{
		nom: "Rapports du commissaire aux comptes",
		lien:
			"https://www.dropbox.com/scl/fi/1z1rko0r5il5oyl88mf89/9e733f52dd25c39a222c32542aa1a0584adac5d6.pdf?rlkey=v7i9ebu7mf25wyq0dysm0znrk&raw=1"
	},
	{
		nom: "Précédent PV d’AGOA",
		lien:
			"https://www.dropbox.com/scl/fi/u5kdtdmffvoydh0xlsb60/05f01146e6d2cf0d9d4aba6a13898a7148e743ba.pdf?rlkey=cbkqgbbayc2f1g3zqiviktbrp&raw=1"
	}
];
export const FichiersATelecharger = props => (
	<div className="pt-3">
		<ul className="liste">
			{props.liste.map(elem => (
				<li key={elem.lien}>
					<a href={elem.lien} target="_blank" className="lien">
						{elem.nom}
					</a>
				</li>
			))}
		</ul>
	</div>
);

export const MEMBRES_MT_2022 = [
	{
		nom: "AMAN",
		prenom: "Richard",
		profession: "Médecin",
		adresse: "31 route du Col de l’Izoard, 05100 BRIANCON",
		dateFinMandat: "20/12/2023"
	},
	{
		nom: "BERESSNEVITCH",
		prenom: "Michel",
		profession: "Retraité",
		adresse: "Rue Jean PELERIN - 54425 PULNOY",
		dateFinMandat: "20/12/2023"
	},
	{
		nom: "BRANCATO",
		prenom: "Calogero",
		profession: "Educateur spécialisé",
		adresse: "Rue de l’Europe, 296142 LEFERNES (Belgique)",
		dateFinMandat: "20/12/2023"
	},
	{
		nom: "BOPP",
		prenom: "Hans Jurg",
		profession: "Médecin",
		adresse: "283 chemin de Laucire 30140 TORNAC",
		dateFinMandat: "20/12/2023"
	},
	{
		nom: "CARRA",
		prenom: "Julien",
		profession: "Ingénieur",
		adresse: "Route de Marchissy 31261 Le VAUD (Suisse)",
		dateFinMandat: "20/12/2023"
	},
	{
		nom: "CHAMPAULT",
		prenom: "Samuel",
		profession: "Comptable",
		adresse: "47 avenue du mondial de Rugby 34070 MONTPELLIER",
		dateFinMandat: "20/12/2023"
	},
	{
		nom: "CZERMAK",
		prenom: "Bernard",
		profession: "Retraité",
		adresse: "15 route champ du Feu 67130 WALDERSBACH",
		dateFinMandat: "20/12/2023"
	},
	{
		nom: "DROUILLY",
		prenom: "Benjamin",
		profession: "Pasteur",
		adresse: "121 rue du général de Gaulle 78740 VAUX SUR SEINE",
		dateFinMandat: "20/12/2023"
	},
	{
		nom: "DUFEY",
		prenom: "Pierre Alain",
		profession: "Retraité",
		adresse: "Impasse du Vanil, 21726 FARVAGNY LE GRAND (Suisse)",
		dateFinMandat: "20/12/2023"
	},
	{
		nom: "DYE",
		prenom: "Jean Louis",
		profession: "Pasteur",
		adresse: "6595 chemin des arêtes 38590 SAINT MICHEL DE SAINT GEOIRS",
		dateFinMandat: "20/12/2023"
	},
	{
		nom: "FEHLMANN",
		prenom: "Andréas",
		profession: "Gérant de Société",
		adresse: "Grand Rue CH-1261 LE VAUD",
		dateFinMandat: "20/12/2023"
	},
	{
		nom: "FEHLMANN",
		prenom: "Matthias",
		profession: "Secrétaire",
		adresse: "1654 chemin de Recoulin 30140 ANDUZE",
		dateFinMandat: "31/12/2024"
	},
	{
		nom: "GERSCHHEIMER",
		prenom: "Michaël",
		profession: "Médecin",
		adresse: "2430 route de Saint Jean du Gard 30140 ANDUZE",
		dateFinMandat: "20/12/2023"
	},
	{
		nom: "GERVAIS",
		prenom: "Jean David",
		profession: "Pasteur",
		adresse: "17 rue Jacques Hoton 1200 BRUXELLES (Belgique)",
		dateFinMandat: "20/12/2023"
	},
	{
		nom: "GUTSCHER",
		prenom: "David",
		profession: "Pasteur",
		adresse: "25 rue des deux ponts 49300 CHOLET",
		dateFinMandat: "01/06/2022"
	},
	{
		nom: "HAUSER",
		prenom: "Marc",
		profession: "Pasteur",
		adresse: "Mas Elim 30140 GENERARGUES",
		dateFinMandat: "01/06/2022"
	},
	{
		nom: "HEGNAUER",
		prenom: "Ulrich",
		profession: "Menuisier",
		adresse: "Mas Pélissou 30140 TORNAC",
		dateFinMandat: "01/06/2022"
	},
	{
		nom: "ISSARTE",
		prenom: "Daniel",
		profession: "Retraité",
		adresse: "Mas Pélissou 30140 TORNAC",
		dateFinMandat: "01/06/2022"
	},
	{
		nom: "KREMER",
		prenom: "Pierre Yves",
		profession: "VRP",
		adresse: "26 rue de Lorraine 57220 VELVING",
		dateFinMandat: "01/06/2022"
	},
	{
		nom: "LOIRET",
		prenom: "Daniel",
		profession: "Retraité",
		adresse: "La Houssais 44130 BLAIN",
		dateFinMandat: "01/06/2022"
	},
	{
		nom: "LOIRET",
		prenom: "Jérémie",
		profession: "Coordinateur",
		adresse: "283 chemin de Laucire 30140 TORNAC",
		dateFinMandat: "01/06/2022"
	},
	{
		nom: "LOIRET",
		prenom: "Jean Luc",
		profession: "Electricien",
		adresse: "Les Triailles 30140 GENERARGUES",
		dateFinMandat: "31/12/2024"
	},
	{
		nom: "MEIRLAN",
		prenom: "Jean Luc",
		profession: "Ingénieur",
		adresse: "17 rue Jacques Hoton 1200 BRUXELLES (Belgique)",
		dateFinMandat: "31/12/2024"
	},
	{
		nom: "MORLET",
		prenom: "Gilles",
		profession: "Médecin",
		adresse: "La Perrotière 73170 SAINT JEAN DE CHEVELU",
		dateFinMandat: "01/06/2022"
	},
	{
		nom: "RENNETEAU",
		prenom: "Elie",
		profession: "Pasteur",
		adresse: "Mas Elim 30140 GENERARGUES",
		dateFinMandat: "01/06/2022"
	},
	{
		nom: "RICARD",
		prenom: "Patrick",
		profession: "DRH",
		adresse: "190 rue Neuve 60190 MOYVILLERS",
		dateFinMandat: "31/12/2024"
	},
	{
		nom: "TRUMPFF",
		prenom: "Martin",
		profession: "Pasteur",
		adresse: "309 route de Gaujac – Appt 23 30140 BOISSET ET GAUJAC",
		dateFinMandat: "31/12/2024"
	},
	{
		nom: "TYNEVEZ",
		prenom: "Gérard",
		profession: "Retraité",
		adresse: "Le Riffray 35150 CHANTELOUP",
		dateFinMandat: "31/12/2024"
	},
	{
		nom: "WARNON",
		prenom: "Luc",
		profession: "Retraité",
		adresse: "115 rue du Mas de Balan 30000 Nïmes",
		dateFinMandat: "31/12/2024"
	},
	{
		nom: "WEHREY",
		prenom: "Jean Martin",
		profession: "Retraité",
		adresse: "9 rue Bolzen 67120 KOLBSHEIM",
		dateFinMandat: "31/12/2024"
	},
	{
		nom: "ZEHR",
		prenom: "Samuel",
		profession: "Agriculteur",
		adresse: "Ferme Sarrelfing 57400 SARREBOURG",
		dateFinMandat: "31/12/2024"
	}
];

export const TableauMembresMT2022 = () => (
	<div className="table-responsive">
		<table className="table table-striped">
			<thead>
				<tr>
					<th scope="col">Nom</th>
					<th scope="col">Prénom</th>
					<th scope="col">Profession</th>
					<th scope="col">Adresse</th>
					<th scope="col">Date fin de mandat</th>
				</tr>
			</thead>
			<tbody>
				{MEMBRES_MT_2022.map(ligne => (
					<tr key={ligne.nom + ligne.prenom}>
						<td>{ligne.nom}</td>
						<td>{ligne.prenom}</td>
						<td>{ligne.profession}</td>
						<td>{ligne.adresse}</td>
						<td>{ligne.dateFinMandat}</td>
					</tr>
				))}
			</tbody>
		</table>
	</div>
);

export const TableauSalaries = () => (
	<div className="table-responsive">
		<table className="table table-striped">
			<thead>
				<tr>
					<th scope="col">Nom prénom</th>
					<th scope="col">MT</th>
					<th scope="col">Type</th>
					<th scope="col">Lieu de vie</th>
				</tr>
			</thead>
			<tbody>
				{LISTE_SALARIES.map(ligne => (
					<tr key={ligne.nom}>
						<td>{ligne.nom}</td>
						<td>{ligne.mt}</td>
						<td>{ligne.type}</td>
						<td>{ligne.lieuDeVie}</td>
					</tr>
				))}
			</tbody>
		</table>
	</div>
);

export const LISTE_SALARIES = [
	{
		nom: "BERESSNEVITCH Hugo",
		mt: "50%",
		type: "Diacre",
		lieuDeVie: "Générargues (30)"
	},
	{
		nom: "BERNARD François",
		mt: "100%",
		type: "Pasteur",
		lieuDeVie: "Kolbsheim (67)"
	},
	{
		nom: "CZERMAK Pierre-Philippe",
		mt: "43%",
		type: "Diacre",
		lieuDeVie: "Vaux-sur-Seine (78)"
	},
	{
		nom: "DROUILLY Samuel",
		mt: "50%",
		type: "Diacre",
		lieuDeVie: "Massillargues-Atuech (30)"
	},
	{
		nom: "DYE Jean-Louis",
		mt: "100%",
		type: "Pasteur",
		lieuDeVie: "Saint-Michel-de-Saint-Geoirs (38)"
	},
	{
		nom: "FEHLMANN Matthias",
		mt: "20%",
		type: "Diacre",
		lieuDeVie: "Anduze (30)"
	},
	{
		nom: "GUTSCHER David",
		mt: "100%",
		type: "Pasteur",
		lieuDeVie: "Cholet (49)"
	},
	{
		nom: "HAUSER Marc",
		mt: "100%",
		type: "Pasteur",
		lieuDeVie: "Générargues (30)"
	},
	{
		nom: "JAUSSAUD Guilhem",
		mt: "100%",
		type: "Pasteur",
		lieuDeVie: "Anduze (30)"
	},
	{
		nom: "LANGLADE Maxime",
		mt: "100%",
		type: "Pasteur",
		lieuDeVie: "St-Julien-les-Metz (57)"
	},
	{
		nom: "LOIRET Jean-Luc",
		mt: "50%",
		type: "Diacre",
		lieuDeVie: "Générargues (30)"
	},
	{
		nom: "LOIRET Jérémie",
		mt: "50%",
		type: "Pasteur",
		lieuDeVie: "Tornac (30)"
	},
	{
		nom: "LOPES DA-COSTA Manuel",
		mt: "40%",
		type: "Diacre",
		lieuDeVie: "Tornac (30)"
	},
	{
		nom: "LUCAS Franck",
		mt: "100%",
		type: "Pasteur",
		lieuDeVie: "Prades-le-Lèz (34)"
	},
	{
		nom: "LUCAS Stéphan",
		mt: "40%",
		type: "Diacre",
		lieuDeVie: "Anduze (30)"
	},
	{
		nom: "NOLOT Jonathan",
		mt: "50%",
		type: "Diacre",
		lieuDeVie: "Tornac (30)"
	},
	{
		nom: "NOUGUIER Gabriel",
		mt: "50%",
		type: "Diacre",
		lieuDeVie: "Générargues (30)"
	},
	{
		nom: "PARK Sung Huyng",
		mt: "100%",
		type: "Pasteur",
		lieuDeVie: "Alès (30)"
	},
	{
		nom: "PETER Jean-Yves",
		mt: "50%",
		type: "Pasteur",
		lieuDeVie: "Le Vigan (30)"
	},
	{
		nom: "PICA-MONTEZO Rui",
		mt: "50%",
		type: "Diacre",
		lieuDeVie: "Bagard (30)"
	},
	{
		nom: "RENNETEAU Elie",
		mt: "100%",
		type: "Pasteur",
		lieuDeVie: "Générargues (30)"
	},
	{
		nom: "SCHERTZ Jonathan",
		mt: "40%",
		type: "Diacre",
		lieuDeVie: "Anduze (30)"
	},
	{
		nom: "TRUMPFF Martin",
		mt: "60%",
		type: "Pasteur",
		lieuDeVie: "Boisset-Gaujac (30)"
	},
	{
		nom: "TYNEVEZ Daniel",
		mt: "50%",
		type: "Pasteur",
		lieuDeVie: "Chanteloup (35)"
	},
	{
		nom: "ZEHR Stéphane",
		mt: "100%",
		type: "Pasteur",
		lieuDeVie: "Générargues (30)"
	}
];

export const TableauPouvoirBanque = () => (
	<div className="table-responsive">
		<table className="table table-striped">
			<thead>
				<tr>
					<th scope="col">Banque</th>
					<th scope="col">Nom prénom</th>
				</tr>
			</thead>
			<tbody>
				{POUVOIR_BANQUE.map(ligne => (
					<tr key={ligne.banque}>
						<td>{ligne.banque}</td>
						<td>
							<ul>
								{ligne.nom.map(nom => (
									<li key={nom}>{nom}</li>
								))}
							</ul>
						</td>
					</tr>
				))}
			</tbody>
		</table>
	</div>
);

export const POUVOIR_BANQUE = [
	{
		banque: "CIC Mission",
		nom: [
			"FEHLMANN Matthias",
			"TYNEVEZ Daniel",
			"DROUILLY Benjamin",
			"TRUMPFF Martin"
		]
	},
	{
		banque: "BP Cholet",
		nom: ["GUTSCHER David"]
	},
	{
		banque: "Crédit Mutuel Nantes",
		nom: ["PALLIER Luc", "LOIRET Timothée"]
	},
	{
		banque: "Crédit agricole St Etienne de Saint Geoirs ",
		nom: ["FREGONESE Eric", "DYE Jean-Louis"]
	},
	{
		banque: "Crédit agricole Rennes",
		nom: ["LOIRET Cecile", "CORGNE Nathan"]
	},
	{
		banque: "BP Nancy",
		nom: ["ROY Nathan", "ROY Alain"]
	}
];
