import React, { Component } from "react";
import "./AGVote.scss";
import { render } from "react-dom";
import Modal from "react-bootstrap/Modal";
import {
	ModuleCaroussel,
	Icones,
	BlockImgNlt,
	ModuleEncartListMt,
	ModuleCard,
	tools,
	api,
	auth
} from "@mitim/react-mitim";

export class VoteRadioBouton extends Component {
	state = {
		// pour: true,
		pour: this.props.defaultPour,
		contre: false,
		abstention: false
	};

	componentDidMount() {}

	updateVote = val => {
		this.props.self.setState(
			{
				elements: {
					...this.props.self.state.elements,
					[this.props.numVote]: val
				}
			},
			() => console.table(this.props.self.state.elements)
		);
	};

	handleChange = event => {
		console.log(event);
		let target = event.target;
		let value = target.value;
		let id = target.id;
		console.log(event.target.checked);
		console.log(value);
		console.log(id);
		if (id === "pour" + this.props.numVote) {
			this.setState(
				{
					pour: true,
					contre: false,
					abstention: false
				},
				this.updateVote("Pour")
			);
		}
		if (id === "contre" + this.props.numVote) {
			this.setState(
				{
					pour: false,
					contre: true,
					abstention: false
				},
				this.updateVote("Contre")
			);
		}
		if (id === "abstention" + this.props.numVote) {
			this.setState(
				{
					pour: false,
					contre: false,
					abstention: true
				},
				this.updateVote("Abstention")
			);
		}
	};

	render() {
		return (
			<>
				<div className="voteTitre">{this.props.titre}</div>
				<div className="text-center">
					{!this.props.self.state.voteOk ? (
						<>
							<div className="form-check form-check-inline">
								<input
									className="form-check-input"
									type="radio"
									name={"inlineRadioOptions" + this.props.numVote}
									onChange={this.handleChange}
									id={"pour" + this.props.numVote}
									checked={this.state.pour}
								/>
								<label className="form-check-label">Pour</label>
							</div>
							<div className="form-check form-check-inline">
								<input
									className="form-check-input"
									type="radio"
									name={"inlineRadioOptions" + this.props.numVote}
									onChange={this.handleChange}
									id={"contre" + this.props.numVote}
									checked={this.state.contre}
								/>
								<label className="form-check-label">Contre</label>
							</div>
							{!this.props.hiddenAbstention && (
								<div className="form-check form-check-inline">
									<input
										className="form-check-input"
										type="radio"
										name={"inlineRadioOptions" + this.props.numVote}
										onChange={this.handleChange}
										id={"abstention" + this.props.numVote}
										checked={this.state.abstention}
									/>
									<label className="form-check-label">Abstention</label>
								</div>
							)}
						</>
					) : this.props.self.state.voteCloture ? (
						<div>Les votes sont clôturés !</div>
					) : (
						<div>Vous avez déjà voté !</div>
					)}
				</div>
			</>
		);
	}
}
