import React, { Component } from "react";
import "./MonCompte.scss";
import { render } from "react-dom";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import {
	ModuleCaroussel,
	Icones,
	BlockImgNlt,
	ModuleEncartListMt,
	ModuleCard,
	tools,
	api,
	auth,
	Storage
} from "@mitim/react-mitim";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { AddPicture } from "./AddPicture";

export class MonCompte extends Component {
	state = {
		showModalAddPicture: false,
		messageModal: "Vos informations ont été mises à jour avec succès",
		editMode: false,
		texteBouton: "Modifier",
		showModalAddPicture: false,
		disableButtonValidation: false,
		elements: {
			nom: "",
			prenom: "",
			telMobile: "",
			email: "",
			dateNaissance: "",
			email2: "",
			email3: "",
			lieuNaissance: ""
		},
		alerteChangePassword: false,
		alertClass: ""
	};

	componentDidMount() {
		// const user = Storage.getUserParsed();
		// !this.state.abonne &&
		// this.setState({
		// 	elements: {
		// 		...this.state.elements,
		// 		["nom"]: user.lastname,
		// 		["user"]: user.id,
		// 		["prenom"]: user.firstname,
		// 		["email"]: user.email,
		// 		["email2"]: user.email2 || "",
		// 		["email3"]: user.email3 || "",
		// 		["telMobile"]: user.telMobile || "",
		// 		["lieuNaissance"]: user.lieuNaissance || "",
		// 		["dateNaissance"]: user.dateNaissance || ""
		// 	}
		// });

		this.getUserByToken((userJson, user) => {
			console.table(user);

			!this.state.abonne &&
				this.setState({
					elements: {
						...this.state.elements,
						["nom"]: user.lastname,
						["user"]: user.id,
						["prenom"]: user.firstname,
						["email"]: user.email,
						["email2"]: user.email2 || "",
						["email3"]: user.email3 || "",
						["telMobile"]: user.telMobile || "",
						["lieuNaissance"]: user.lieuNaissance || "",
						["dateNaissance"]: user.dateNaissance || ""
					}
				});
		});
	}

	getUserByToken(callback) {
		var user = localStorage.getItem("user");
		user = JSON.parse(user);

		fetch(`${api.timediaUrl}/public/getUser`, {
			method: "GET",
			headers: {
				Authorization: "Basic " + user.token
			}
		})
			.then(response => {
				if (response.status !== 200) {
					console.log("Error: " + response.status);
					return;
				}
				response.json().then(data => {
					const userJson = JSON.stringify(data.data);

					var authorities = new Array();
					data.data.authorities.forEach(function(authority) {
						authorities.push(authority.name);
					});
					Storage.setAuthoritiesJson(JSON.stringify(authorities));
					Storage.setUserJson(userJson);
					callback(userJson, data.data);
				});
			})
			.catch(err => {
				console.log(err);
			});
	}

	updateUser(callback) {
		const data = JSON.stringify({
			firstname: this.state.elements.prenom,
			lastname: this.state.elements.nom,
			email: this.state.elements.email,
			email2: this.state.elements.email2,
			email3: this.state.elements.email3,
			telMobile: this.state.elements.telMobile,
			dateNaissance: this.state.elements.dateNaissance,
			lieuNaissance: this.state.elements.lieuNaissance
		});

		console.log(data);
		var user = localStorage.getItem("user");
		user = JSON.parse(user);
		fetch(`${api.timediaUrl}/public/updateUser/${this.state.elements.user}`, {
			method: "PUT",
			body: data,
			headers: {
				Authorization: "Basic " + user.token,
				"Content-Type": "Application/Json"
			}
		})
			.then(response => {
				if (response.status !== 200) {
					console.log("Error: " + response.status);
					return;
				}
				response.json().then(data => {
					const userJson = JSON.stringify(data.data);
					if (data.success) {
						this.getUserByToken((userJson, user) => {
							console.table(user);
							!this.state.abonne &&
								this.setState({
									elements: {
										...this.state.elements,
										["nom"]: user.lastname,
										["user"]: user.id,
										["prenom"]: user.firstname,
										["email"]: user.email,
										["email2"]: user.email2 || "",
										["email3"]: user.email3 || "",
										["telMobile"]: user.telMobile || "",
										["lieuNaissance"]: user.lieuNaissance || "",
										["dateNaissance"]: user.dateNaissance || ""
									},
									editMode: false,
									texteBouton: "Modifier",
									disableButtonValidation: false,
									showModal: true,
									alertMessage: ""
								});
						});
					} else {
						this.setState({
							messageModal: data.errorMessage,
							showModal: true,
							disableButtonValidation: false
						});
					}

					//callback(userJson, data.data);
				});
			})
			.catch(err => {
				console.log(err);
			});
	}

	handleChangeText = name => event => {
		if (this.state.editMode) {
			this.setState(
				{
					elements: {
						...this.state.elements,
						[name]: event.target.value
					}
				},
				() => console.table(this.state.elements)
			);
			console.log("Valeur de : " + name + " -> " + event.target.value);
		}
	};

	inputText(label, name, type, nbrCol, required) {
		return (
			<div className={"form-group col-md-" + nbrCol}>
				<label htmlFor="" className="col-form-label ">
					{label}
				</label>
				<input
					style={{ backgroundColor: "#f6f6f6", color: "#000" }}
					type={type}
					value={this.state.elements[name]}
					onChange={this.handleChangeText(name)}
					className="form-control"
					id={"Id" + name}
					placeholder={label + (required ? "*" : "")}
					name={name + "Name"}
					disabled={!this.state.editMode}
					required={required}
				/>
			</div>
		);
	}

	inputNumber(label, name, type, nbrCol) {
		return (
			<div className={"form-group col-md-" + nbrCol}>
				<label htmlFor="" className="col-form-label ">
					{label}
				</label>
				<div className="input-group input-group-lg">
					<input
						type={type}
						value={this.state.elements[name]}
						onChange={this.handleChangeText(name)}
						className="form-control text-center montantDonInput"
						id={"Id" + name}
						name={name + "Name"}
						required
					/>
					<span className="input-group-addon euro"> €</span>
				</div>
			</div>
		);
	}

	handleChange = event => {
		console.log(event);
		let target = event.target;
		let value = target.value;
		let id = target.id;
		console.log(event.target.checked);
		console.log(value);
		console.log(id);
		if (id === "civilite" && value === "M.") {
			this.setState({
				avecEnfants: false,
				conjoint: false,
				mmeReferente: false,
				mlle: false,
				elements: { ...this.state.elements, ["civilite"]: value }
			});
		}
		if (id === "civilite" && value === "Mme") {
			this.setState({
				avecEnfants: false,
				conjoint: false,
				mmeReferente: true,
				mlle: false,
				elements: { ...this.state.elements, ["civilite"]: value }
			});
		}
		if (id === "civilite" && value === "Mlle") {
			this.setState({
				avecEnfants: false,
				conjoint: false,
				mmeReferente: true,
				mlle: true,
				elements: { ...this.state.elements, ["civilite"]: value }
			});
		}
	};

	handleSubmitFormulaire = event => {
		event.preventDefault();
		this.setState({ disableButtonValidation: true });
		this.updateUser();

		// auth.userHaveAuthorities(["ROLE_CONNECTED"])
		// 	? this.setState({ tabFormulaire: 3 }, () => {
		// 			sessionStorage.setItem("tabFormulaire", 3);
		// 			console.log(this.state.elements);
		// 	  })
		// 	: this.props.history.push({
		// 			pathname: "/login",
		// 			state: { from: "/revue" }
		// 	  });
	};

	changerMotDePasse = () => {
		var user = localStorage.getItem("user");
		user = JSON.parse(user);

		auth.sendAuthLinkByMail(user.email, values => {
			if (values.success) {
				this.setState({
					alerteChangePassword: true,
					disableButton: true,
					alertClass: "alert-success",
					alertMessage:
						"Un lien de réinitialisation de votre mot de passe vous a été envoyé par e-mail"
				});
			} else {
				this.setState({
					alerteChangePassword: true,
					alertClass: "alert-danger",
					alertMessage:
						"Cette adresse mail n'a pas de compte sur ce site, veuillez creer un nouveau compte ou changer l'adresse mail"
				});
			}
			console.log(values);
		});
	};
	editMode = () => {
		if (!this.state.editMode) {
			this.setState({
				editMode: true,
				texteBouton: "Abandonner"
			});
		} else {
			this.getUserByToken((userJson, user) => {
				console.table(user);

				!this.state.abonne &&
					this.setState({
						elements: {
							...this.state.elements,
							["nom"]: user.lastname,
							["user"]: user.id,
							["prenom"]: user.firstname,
							["email"]: user.email,
							["email2"]: user.email2 || "",
							["email3"]: user.email3 || "",
							["telMobile"]: user.telMobile || "",
							["lieuNaissance"]: user.lieuNaissance || "",
							["dateNaissance"]: user.dateNaissance || ""
						},
						editMode: false,
						texteBouton: "Modifier"
					});
			});
		}
	};

	avatar = () => {
		return (
			<div className="avatar" style={{}}>
				<img
					onClick={() => {
						this.setState({ showModalAddPicture: true });
					}}
					style={{ height: 150, cursor: "pointer" }}
					className="ml-3 rounded-circle"
					src="https://avatars.githubusercontent.com/u/12435040?v=4"
				/>
			</div>
		);
	};

	render() {
		const BoiteDialogue = () => (
			<Modal
				show={this.state.showModal}
				onHide={() =>
					this.setState({
						showModal: false,
						messageModal: "Vos informations ont été mises à jour avec succès"
					})
				}
			>
				<Modal.Body>
					<button
						type="button"
						class="close"
						data-dismiss="modal"
						aria-label="Close"
						onClick={() =>
							this.setState({
								showModal: false,
								messageModal:
									"Vos informations ont été mises à jour avec succès"
							})
						}
					>
						<span aria-hidden="true">&times;</span>
					</button>
					{/* <h4 className="text-center">Veuillez remplir tous les champs !</h4> */}
					<p>{this.state.messageModal}</p>
				</Modal.Body>
			</Modal>
		);

		const BoiteDialogueAddPicture = () => (
			<Modal
				show={this.state.showModalAddPicture}
				onHide={() =>
					this.setState({ showModalAddPicture: false }, () =>
						this.props.reloadPage ? null : window.location.reload()
					)
				}
			>
				<Modal.Body>
					<button
						type="button"
						class="close"
						data-dismiss="modal"
						aria-label="Close"
						onClick={() =>
							this.setState({ showModalAddPicture: false }, () =>
								this.props.reloadPage ? null : window.location.reload()
							)
						}
					>
						<span aria-hidden="true">&times;</span>
					</button>
					<div>
						<AddPicture />
					</div>
				</Modal.Body>
			</Modal>
		);

		return (
			<div className="formulaireInformations">
				{/* <div className="center text-center pt-3 pb-3">
					<div className="text-center">Photo de profil</div>
					{this.avatar()}
				</div> */}
				<div className="text-right">
					{!this.state.alerteChangePassword && (
						<button
							class="btn btn-primary text-right"
							onClick={this.changerMotDePasse}
						>
							Changer de mot de passe
						</button>
					)}
					<button
						class="btn btn-primary text-right ml-2"
						onClick={this.editMode}
					>
						{this.state.texteBouton}
					</button>
				</div>
				{this.state.alerteChangePassword && (
					<div
						class={"mt-3 pt-3 pb-3 alert " + this.state.alertClass}
						role="alert"
					>
						{this.state.alertMessage}
					</div>
				)}
				<form onSubmit={this.handleSubmitFormulaire} method="PUT">
					<div className="form-row">
						{this.inputText("Nom", "nom", "text", "6", true)}
						{this.inputText("Prénom", "prenom", "text", "6", true)}
					</div>
					<div className="form-row">
						{this.inputText(
							"Date de naissance",
							"dateNaissance",
							"date",
							"6",
							true
						)}
						{this.inputText(
							"Lieu de naissance",
							"lieuNaissance",
							"text",
							"6",
							true
						)}
					</div>
					<div className="form-row">
						{this.inputText("Email", "email", "email", "6", true)}
						{this.inputText("Email 2", "email2", "email", "6", false)}
					</div>

					<div className="form-row">
						{this.inputText("Email 3", "email3", "email", "6", false)}
						{this.inputText("Tel mobile", "telMobile", "number", "6", true)}
					</div>
					<div className="text-right pt-2">
						<button
							className="btn btn-primary center-block pl-5 pr-5"
							hidden={!this.state.editMode}
							type="submit"
							disabled={this.state.disableButtonValidation}
						>
							{this.state.disableButtonValidation && (
								<span
									class="spinner-border spinner-border-sm mr-2"
									role="status"
									aria-hidden="true"
								></span>
							)}
							Sauvegarder
						</button>
					</div>
				</form>

				<BoiteDialogue />
				{/* <BoiteDialogueAddPicture /> */}
			</div>
		);
	}
}
