// Libraries
import React from "react";
import { NavLink } from "react-router-dom";
import SvgIcon from "@material-ui/core/SvgIcon";
import SearchIcon from "@material-ui/icons/Search";
import { InputAdornment, Input, IconButton } from "@material-ui/core";
import { titleCase } from "../../utils/functions";
import { withTranslation } from "react-i18next";

class NavBar extends React.Component {
	constructor(props) {
		super(props);
		this.searchText = React.createRef();
		this.state = {
			localString: ""
		};
	}

	componentDidUpdate(prevProps) {
		if (this.props.queryString !== prevProps.queryString) {
			this.setState({ localString: this.props.queryString });
		}
	}

	handleKeyPress = event => {
		if (event.key === "Enter") {
			this.props.handleSearchQueryString(event.target.value);
		}
	};

	handleChange = event => {
		this.setState({ localString: event.target.value });
	};

	chercher = () => {
		this.props.handleSearchQueryString(this.searchText.current.value);
	};

	boutonsGrilleLigne() {
		return (
			<>
				<div className="col-auto mx-auto ml-md-auto mr-md-3">
					<SvgIcon
						viewBox="0 0 6240 6240"
						onClick={() => this.props.handleChangeTypeView(true)}
						shapeRendering="crispEdges"
						className="cursor-pointer"
					>
						<g
							id="layer101"
							fill={this.props.renderList ? "#b01125" : "#777"}
							stroke="none"
						>
							<path d="M0 5390 l0 -850 3120 0 3120 0 0 850 0 850 -3120 0 -3120 0 0 -850z" />
							<path d="M0 3110 l0 -850 3120 0 3120 0 0 850 0 850 -3120 0 -3120 0 0 -850z" />
							<path d="M0 850 l0 -850 3120 0 3120 0 0 850 0 850 -3120 0 -3120 0 0 -850z" />
						</g>
					</SvgIcon>
					<SvgIcon
						viewBox="0 0 6220 6300"
						onClick={() => this.props.handleChangeTypeView(false)}
						shapeRendering="crispEdges"
						className="cursor-pointer ml-2"
					>
						<g
							id="layer101"
							fill={this.props.renderList ? "#777" : "#b01125"}
							stroke="none"
						>
							<path d="M0 5435 l0 -865 845 0 845 0 0 865 0 865 -845 0 -845 0 0 -865z" />
							<path d="M2280 5435 l0 -865 845 0 845 0 0 865 0 865 -845 0 -845 0 0 -865z" />
							<path d="M4530 5435 l0 -865 845 0 845 0 0 865 0 865 -845 0 -845 0 0 -865z" />
							<path d="M0 3165 l0 -865 845 0 845 0 0 865 0 865 -845 0 -845 0 0 -865z" />
							<path d="M2280 3165 l0 -865 845 0 845 0 0 865 0 865 -845 0 -845 0 0 -865z" />
							<path d="M4530 3165 l0 -865 845 0 845 0 0 865 0 865 -845 0 -845 0 0 -865z" />
							<path d="M0 865 l0 -865 845 0 845 0 0 865 0 865 -845 0 -845 0 0 -865z" />
							<path d="M2260 865 l0 -865 845 0 845 0 0 865 0 865 -845 0 -845 0 0 -865z" />
							<path d="M4530 865 l0 -865 845 0 845 0 0 865 0 865 -845 0 -845 0 0 -865z" />
						</g>
					</SvgIcon>
				</div>
			</>
		);
	}

	render() {
		const { t } = this.props;
		const categories = this.props.categories.map(cat => (
			<div className="col-auto category pl-5" key={cat}>
				<NavLink
					exact
					to={this.props.rootPath + (cat.startsWith("tout") ? "" : "/" + cat)}
					onClick={() => this.props.onCategoryChange(cat)}
				>
					{cat.startsWith("tout") ? "Tout" : titleCase(cat + "s")}
				</NavLink>
			</div>
		));
		return (
			<div className="row align-items-center categories-navbar">
				<div className={this.props.classes.filter + " mt-3 my-md-3"}>
					{/* <Input
						className="ml-4"
						placeholder={t('menu.rechercher')}
						inputProps={{
							'aria-label': t('menu.rechercher'),
							ref: this.searchText
						}}
						fullWidth
						onKeyPress={this.handleKeyPress}
						onChange={this.handleChange}
						value={this.state.localString || ''}
						endAdornment={
							<InputAdornment position="end">
								<IconButton
									aria-label={t('menu.rechercher')}
									onClick={this.chercher}
								>
									<SearchIcon />
								</IconButton>
							</InputAdornment>
						}
					/> */}
				</div>
				<div className={this.props.classes.result}>
					<div className="row categories my-3">
						{categories}
						{/* {this.boutonsGrilleLigne()} */}
					</div>
				</div>
			</div>
		);
	}
}

export default withTranslation()(NavBar);
