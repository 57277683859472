import React from "react";
import "./ExpeditionRevue.scss";
import { tools, api } from "@mitim/react-mitim";
import Page from "../Page";

export class ExpeditionRevue extends Page {
	state = {
		idRevue: this.props.match.params.idRevue,
		code: this.props.match.params.code,
		successExpedition: false
	};

	componentDidMount() {
		this.expeditionRevue();
	}

	expeditionRevue() {
		const tokenPublic = "cHVibGljOjk4SE85SE9JVUdPSTk4SA==";
		const defaultHeaders = {
			Accept: "application/json",
			"Content-Type": "application/json",
			Authorization: "Basic " + tokenPublic
		};

		// fetch(`${api}/timedia/public/expeditionRevue/${this.state.code}/${this.state.idRevue}`, {
		fetch(
			// `http://127.0.0.1:8084/timedia/public/expeditionRevue/${this.state.code}/${this.state.idRevue}`,
			`${api.timediaUrl}/public/expeditionRevue/${this.state.code}/${this.state.idRevue}`,
			{
				method: "GET",
				headers: defaultHeaders
			}
		)
			.then(response => {
				if (response.status !== 200) {
					console.log("Error: " + response.status);
					return;
				}
				response.json().then(data => {
					this.setState({
						successExpedition: data
					});
				});
			})
			.catch(err => {
				console.log(err);
			});
	}

	render() {
		const { t } = this.props;

		return (
			<>
				<div className="container pt-5 pb-5">
					<h2 className="text-center pt-4 pb-4">Expédition revue</h2>
					<h2 className="text-center">
						status : {this.state.successExpedition ? "OK" : "Problème"}
					</h2>
				</div>
			</>
		);
	}
}
