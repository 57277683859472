import React from "react";
import "./BarreRecherche.scss";
import { Link } from "react-router-dom";
import { SearchBox } from "@elastic/react-search-ui";

export class BarreRecherche extends React.Component {
	element() {
		return;
	}

	state = {
		value: ""
	};

	handleChangeText = value => {
		console.log(value.nativeEvent.data);
		this.setState({
			value: this.state.value + value.nativeEvent.data
		});
	};

	render() {
		return (
			<div className="barre-recherche">
				<SearchBox
					// onSelectAutocomplete={(suggestion, config, defaultHandler) => {
					//     window.location.href = "/articles?q=" + suggestion.suggestion;
					//     defaultHandler(suggestion);
					// }}
					// onSubmit={(searchTerm) => {
					//     window.location.href = "/articles?q=" + searchTerm;
					// }}
					inputView={({ getAutocomplete, getInputProps, getButtonProps }) => (
						<>
							<div className="sui-search-box__wrapper">
								<input
									className="input-text"
									{...getInputProps({
										placeholder: "Rechercher",
										className: "input-text"
									})}
								/>
								{getAutocomplete()}
							</div>
							<input
								{...getButtonProps({
									value: "Rechercher"
								})}
							/>
						</>
					)}
					autocompleteSuggestions={true}
				/>
			</div>
		);
	}
}
