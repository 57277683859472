import React from "react";
import "./Livraison.scss";
import { api } from "@mitim/react-mitim";
import { withRouter } from "react-router-dom";

export class Livraison extends React.Component {
	state = {
		showEglise: true,
		eglises: []
	};

	componentDidMount() {
		this.loadEglises();
		!this.props.app.state.elements.eglise &&
			this.props.app.setState(
				{
					elements: {
						...this.props.app.state.elements,
						["livraisonDomicile"]: false,
						["eglise"]: 34
					}
				}
				// () => console.table(this.props.app.state.elements)
			);
	}

	loadEglises() {
		const tokenPublic = "cHVibGljOjk4SE85SE9JVUdPSTk4SA==";
		const defaultHeaders = {
			Accept: "application/json",
			"Content-Type": "application/json",
			Authorization: "Basic " + tokenPublic
		};

		fetch(`${api.timediaUrl}/public/eglises`, {
			method: "GET",
			headers: defaultHeaders
		})
			.then(response => {
				if (response.status !== 200) {
					console.log("Error: " + response.status);
					return;
				}
				response.json().then(data => {
					this.setState({
						eglises: data.data
					});
					// this.props.app.setState(
					// 	{
					// 		elements: {
					// 			...this.props.app.state.elements,
					// 			["eglise"]: data.data[0].id
					// 		}
					// 	},
					// 	() => console.table(this.props.app.state.elements)
					// );
					// console.log(data.data);
				});
			})
			.catch(err => {
				console.log(err);
			});
	}

	handleSuivant = () => {
		const data = this.props.app.state.elements;
		var message = {};
		console.table(data);
		message = JSON.stringify(data);
		console.log(message);
		const tokenPublic = "cHVibGljOjk4SE85SE9JVUdPSTk4SA==";
		const defaultHeaders = {
			Accept: "application/json",
			"Content-Type": "application/json",
			Authorization: "Basic " + tokenPublic
		};

		fetch(`${api.timediaUrl}/public/abonnements`, {
			method: "POST",
			body: message,
			headers: defaultHeaders
		})
			.then(response => {
				if (response.status !== 200) {
					console.log("Error: " + response.status);
					this.props.app.setState(
						{
							messageModal:
								"Probleme avec le serveur, veuillez réessayer plus tard ou nous contacter via la page contact.",
							showModal: true
						},
						() => sessionStorage.setItem("tabFormulaire", 1)
					);
					return;
				}
				response.json().then(data => {
					console.log(data);
					sessionStorage.setItem("idTransaction", data.data.id);
					sessionStorage.setItem("tabFormulaire", 4);
					sessionStorage.setItem("montantAPayer", data.data.montant);
					sessionStorage.setItem("nombreRevues", data.data.nombreRevues);
					sessionStorage.setItem(
						"livraisonDomicile",
						data.data.livraisonDomicile
					);
					sessionStorage.setItem(
						"montantAbonnement",
						data.data.montantAbonnement
					);
					this.props.app.setState({ tabFormulaire: 4 }, () => {
						// console.table(this.props.app.state.elements);
					});
				});
			})
			.catch(err => {
				console.log(err);
				this.props.app.setState(
					{
						messageModal:
							"Le serveur est indisponible, veuillez réessayer plus tard",
						showModal: true
					},
					() => sessionStorage.setItem("tabFormulaire", 1)
				);
			});
	};

	handleChange = event => {
		console.log(event);
		let target = event.target;
		let value = target.value;
		let id = target.id;
		console.log(event.target.checked);
		console.log(value);
		console.log(id);
		if (
			id === "choixLivraison" &&
			value === "Livraison dans une église de la Mission (gratuit)"
		) {
			this.setState({ showEglise: true });
			this.props.app.setState(
				{
					elements: {
						...this.props.app.state.elements,
						["livraisonDomicile"]: false,
						["eglise"]: this.state.eglises[0].id
					}
				},
				() => console.table(this.props.app.state.elements)
			);
		}
		if (
			id === "choixLivraison" &&
			value === "Livraison à domicile (4€/revue)"
		) {
			this.setState({ showEglise: false });
			this.props.app.setState(
				{
					elements: {
						...this.props.app.state.elements,
						["livraisonDomicile"]: true,
						["eglise"]: null
					}
				}
				// () => console.table(this.props.app.state.elements)
			);
		}
		if (id === "eglise") {
			this.props.app.setState(
				{
					elements: {
						...this.props.app.state.elements,
						["livraisonDomicile"]: false,
						["eglise"]: parseInt(value, 10)
					}
				}
				// () => console.table(this.props.app.state.elements)
			);
		}
	};

	render() {
		return (
			<>
				<div className="livraison">
					<p className="text-center">
						Pour des raisons de coûts, nous préferons quand cela est possible,
						vous livrer dans l'une des églises de la Mission Timothée.
					</p>
					<form>
						<div className="form-row">
							<div className="form-group col-md-6">
								<label htmlFor="choixLivraison" className="col-form-label">
									Type de livraison
								</label>
								<select
									style={{ backgroundColor: "#f6f6f6" }}
									onChange={this.handleChange}
									className="form-control"
									name="choixLivraison"
									id="choixLivraison"
								>
									<option>
										Livraison dans une église de la Mission (gratuit)
									</option>
									<option>Livraison à domicile (4€/revue)</option>
								</select>
							</div>

							{this.state.showEglise && (
								<div className="form-group col-md-6">
									<label htmlFor="eglise" className="col-form-label">
										Sélectionnez votre église
									</label>
									<select
										value={this.props.app.state.elements.eglise}
										style={{ backgroundColor: "#f6f6f6" }}
										onChange={this.handleChange}
										className="form-control"
										name="eglise"
										id="eglise"
									>
										{this.state.eglises
											.sort((a, b) => a.nom.localeCompare(b.nom))
											.map(elem => (
												<option key={elem.id} value={elem.id}>
													{elem.nom} ({elem.codePostal})
												</option>
											))}
									</select>
								</div>
							)}
						</div>
						<div className="text-right pt-2">
							<button
								type="button"
								className="btn btn-primary center-block pl-5 pr-5"
								onClick={() => this.handleSuivant()}
							>
								Suivant
							</button>
						</div>
					</form>
				</div>
			</>
		);
	}
}

export default withRouter(Livraison);
