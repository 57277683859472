import React from "react";
import Page from "../Page";
import { tools, ModuleCardActu, ModuleCard } from "@mitim/react-mitim";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { ThemeProvider } from "@material-ui/core";
import { getKeyValuePublique } from "../Constants";

export class DiffusionDirectCoursFormation extends Page {
	constructor(props) {
		super(props);
		this.handleShow = this.handleShow.bind(this);
		this.handleClose = this.handleClose.bind(this);
	}

	state = {
		annonceReuDirect: "",
		show: false,
		acces: true,
		lienAudio: "",
		lienYoutube: false,
		fichiersATelecharger: []
	};

	componentDidMount() {
		super.componentDidMount();
		getKeyValuePublique("diffusion-direct-cours-formation", val => {
			this.setState({
				lienYoutube: val.lienYoutube
			});
		});
	}

	handleClose() {
		this.setState({ show: false });
	}

	handleShow() {
		this.setState({ show: true });
	}

	render() {
		return (
			<>
				{this.state.acces && (
					<>
						<div className="container reunionsDirect">
							<div className="mt-4 mb-5">
								<h2 className="text-center mb-5"></h2>
								{tools.titreSousTitre(
									"Diffusion en direct",
									"Cours de formation"
								)}

								{/* <p className="pt-3 pb-3">
									<h4 className="pb-3">
										Liste des fichiers de présentation en pdf à télécharger :
									</h4>
									<ul className="liste">
										{this.state.fichiersATelecharger.map(fichier => (
											<>
												<li key={fichier.nom}>
													<a
														target="_blank"
														className="lien"
														href={fichier.lien}
													>
														{fichier.nom}
													</a>
												</li>
											</>
										))}
									</ul>
								</p> */}
								<div className="pb-5">
									<div className="video-container" style={{ top: "24px" }}>
										<div className="contenu-page">
											<div className="container pt-5 pb-5">
												<center>
													{/* <iframe
														width={560}
														height={315}
														src={this.state.lienYoutube}
														frameBorder={0}
														allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
														allowFullScreen
													/> */}
													<iframe
														width="560"
														height="315"
														src="https://www.youtube.com/embed/videoseries?list=PLvqXsLJhAiXGWmgkamoTq0nR9KhLFC9Ts"
														frameborder="0"
														allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
														allowfullscreen="allowfullscreen"
													></iframe>
												</center>
												<br />
											</div>
										</div>
									</div>
								</div>
								{/* <div className="text-center pt-3 pb-5 mt-5">
									<h4 className="pt-5 pb-3 text-center">
										Si la vidéo ne fonctionne pas, vous pouvez suivre l'audio
										ci-dessous
									</h4>
									<audio
										controls="controls"
										controlsList="nodownload"
										preload="auto"
										src={this.state.lienAudio}
									/>
								</div> */}
							</div>
						</div>
						<Modal show={this.state.show} onHide={this.handleClose}>
							<Modal.Body>
								<button
									type="button"
									class="close"
									data-dismiss="modal"
									aria-label="Close"
									onClick={this.handleClose}
								>
									<span aria-hidden="true">&times;</span>
								</button>
								<h3 className="text-center">{this.state.annonceReuDirect}</h3>
							</Modal.Body>
						</Modal>
					</>
				)}
			</>
		);
	}
}
