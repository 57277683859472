import React from "react";
import Page from "../Page";
import "./DownloadFiles.scss";
import {
	ModuleEncartMtCustom,
	ModuleEncartListMt,
	ModuleCard,
	tools,
	api,
	Icones
} from "@mitim/react-mitim";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import download from "../../resources/icons/download.svg";
import sound from "../../resources/icons/sound.gif";

export class DownloadFiles extends Page {
	constructor(props) {
		super(props);
		this.audioPlayer = React.createRef();
	}
	componentDidUpdate(prevProps) {
		if (this.props.match.params.id !== prevProps.match.params.id) {
			this.loadMedia();
		}
	}

	state = {
		data: {},
		media: {},
		auteurs: [],
		fichiers: [],
		livreBibles: [],
		mediaMedias: [],
		videoYoutubes: [],
		cantique: {},
		cantiqueListen: "",
		id: "0",
		indexLecture: "0",
		classementMediaMedia: "0",
		fetchReceive: false,
		audioListen: "",
		play: false,
		couleurPlayAll: "#" + this.props.match.params.couleur
	};

	componentDidMount() {
		super.componentDidMount();
		this.loadMedia();
	}

	loadMedia() {
		api.getMedia("2167?order=id", this, () => {
			let audioFichier = this.state.fichiers.filter(
				fichier =>
					fichier.fichier &&
					fichier.fichier.fichierContentType.fichierType.id === 4
			);
			if (audioFichier.length >= 1) {
				this.setState({
					audioListen: audioFichier[0].fichier.sharedLink,
					id: audioFichier[0].fichier.id,
					rangMediaMedia: audioFichier[0].rang
				});
			}
			// }
		});
	}

	handleClick = (assMediaFichier, index) => {
		if (assMediaFichier.fichier) {
			console.log("this is:", assMediaFichier.fichier.sharedLink);
			console.log("this is:", assMediaFichier.fichier.id);
			this.setState(
				{
					audioListen: assMediaFichier.fichier.sharedLink,
					id: assMediaFichier.fichier.id,
					indexLecture: index,
					play: true
				},
				() => {
					this.audioPlayer.current.type = "audio/mp3";
					this.audioPlayer.current.play();
				}
			);
		}
	};

	playAll = () => {
		for (const [index, value] of this.state.fichiers
			.filter(fichier => fichier.fichier)
			.entries()) {
			this.setState(
				{
					audioListen: value.fichier.sharedLink,
					id: value.fichier.id,
					indexLecture: 0,
					play: true
				},
				() => {
					this.audioPlayer.current.type = "audio/mp3";
					this.audioPlayer.current.play();
				}
			);
			break;
		}
	};

	handleEnded = () => {
		for (const [index, value] of this.state.fichiers
			.filter(fichier => fichier.fichier)
			.entries()) {
			if (this.state.indexLecture < index) {
				this.setState(
					{
						audioListen: value.fichier.sharedLink,
						id: value.fichier.id,
						indexLecture: index,
						play: true
					},
					() => {
						this.audioPlayer.current.type = "audio/mp3";
						this.audioPlayer.current.play();
					}
				);
				break;
			}
		}
	};

	handlePlaying = () => {
		this.setState({ play: true });
	};

	audio() {
		return (
			<>
				<audio
					style={{}}
					src={this.state.audioListen}
					preload="true"
					ref={this.audioPlayer}
					id="audio1"
					controls="controls"
					controlsList="nodownload"
					onEnded={this.handleEnded}
					type="audio/mpeg"
					onPlaying={this.handlePlaying}
				>
					Your browser does not support HTML5 Audio!
				</audio>
			</>
		);
	}

	classOfCantique(assMediaFichier) {
		var classValue = "";
		if (assMediaFichier.fichier) {
			if (assMediaFichier.fichier.id === this.state.id && this.state.play) {
				classValue = "ml-5 my-3 enLecture";
			} else {
				classValue = "ml-5 my-3";
			}
		} else {
			classValue = "ml-5 my-3";
		}
		return classValue;
	}

	render() {
		const TelechargementComplet = props => (
			<div className="telechargementComplet">
				<a href={props.assMediaFichier.fichier.sharedLink} target="_blank">
					<div className="select pl-2 pr-2">
						<div
							className="pt-2 text-black ft-alegreya-sans-light"
							style={{ fontSize: "1.1em" }}
						>
							{props.assMediaFichier.fichier.nom || ""}.
							{props.assMediaFichier.fichier.extension} -{" "}
							{(props.assMediaFichier.fichier.sizeFile / 1000000).toFixed(2)}Mo
							<img style={{ float: "right" }} src={download} width={18} />
						</div>
					</div>
				</a>
				<hr />
			</div>
		);

		const { t } = this.props;

		return (
			<>
				<div className="container-fluid" style={{ minHeight: "480px" }}>
					<div className="container home pl-0 pr-0">
						<div className="col-lg-12 col-md-12 pl-0 pr-0 pb-5">
							<div className="container downloadFiles">
								<h5
									style={{ display: "block" }}
									className="ft-lato-regular text-noir text-center pt-5 text-uppercase pb-5"
								>
									<strong>Fichiers à télécharger</strong>
								</h5>
								{this.state.fichiers.map((assMediaFichier, index) => (
									<TelechargementComplet assMediaFichier={assMediaFichier} />
								))}
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}
