import React from "react";
import Page from "../Page";
import "./PourquoiSecte.scss";
import { tools, api } from "@mitim/react-mitim";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import download from "../../resources/icons/download.svg";

export class PourquoiSecte extends Page {
	state = {
		show: false,
		popupValue: ""
	};

	handleClose() {
		this.setState({ show: false });
	}

	handleShow() {
		this.setState({ show: true });
	}

	grandEcran = () => <div className="d-none d-lg-block"></div>;

	render() {
		const { t } = this.props;

		const Titre2 = props => (
			<div className="pt-3 pb-1">
				<h2 style={{ backgroundColor: props.couleur }}>{props.texte}</h2>
			</div>
		);
		const Titre3 = props => (
			<h3 className="pt-2" style={{ color: props.couleur }}>
				{props.texte}
			</h3>
		);
		const TelechargementComplet = props => (
			<div className="telechargementComplet">
				<a href={props.lien} target="_blank">
					<div className="select pl-2 pr-2">
						<div
							className="pt-2 text-black ft-alegreya-sans-light"
							style={{ fontSize: "1.1em" }}
						>
							Version PDF
							<img style={{ float: "right" }} src={download} width={18} />
						</div>
					</div>
				</a>
				<hr />
			</div>
		);
		return (
			<>
				<div className="container pourquoiSecte pb-5 pt-5">
					<h1 className="">
						Pourquoi la Mission Timothée
						<br /> est mentionnée en tant que secte sur Internet ?{" "}
					</h1>

					<p className="p1">
						Depuis plusieurs années, le travail d’accueil de la Mission Timothée
						est régulièrement sali sur Internet. Nos détracteurs, pour la
						plupart d’anciens sympathisants contrariés, utilisent souvent pour
						donner plus de poids à leur calomnie un rapport officieux rendu
						public en 1995 – et partiellement déjugé par ses commanditaires
						depuis.
					</p>
					<p className="p1">
						Nous n’avons tout d’abord pas souhaité entrer dans ce débat et
						apporter de l’importance par là à des accusations gratuites et
						outrancières, préférant contacter directement les autorités
						compétentes pour faire valoir nos arguments. Les résultats de ces
						démarches nous semblent aujourd’hui de nature à éclairer l’ensemble
						de la situation, c’est pourquoi nous souhaitons les rendre publics.
					</p>
					<Titre2
						couleur={tools.JAUNE_CLAIR}
						texte="A l’origine, un rapport officieux sur le phénomène sectaire"
					/>

					<p className="p1">
						En 1995, sous le coup de l’émotion consécutive au drame de l’Ordre
						du Temple solaire
						<sup>
							<a href="#notes" target="_self">
								[1]
							</a>
						</sup>
						, une commission d’enquête parlementaire est mise en place pour
						établir un rapport sur le phénomène sectaire. Une liste de 172
						mouvements sectaires y est alors annexée. Cette liste n’est en
						réalité qu’un document de travail des Renseignements Généraux aux
						contours flous, fruit de dénonciations, de signalements, ou
						simplement de rumeurs relayées lors de divorces difficiles par
						exemple.
					</p>

					<Titre2
						couleur={tools.VERT}
						texte="Un rapport discrédité par ses erreurs"
					/>
					<p className="p1">
						De fait, le recensement établi par cette liste est arbitraire : les
						informations ne sont pas issues d’études reposant sur des visites ou
						de témoignages vérifiés, encore moins d’analyses des enseignements
						et comportements de telle ou telle communauté. Il était donc
						prévisible qu’au fil des années cette liste s’avère{" "}
						<a
							href="http://www.lepoint.fr/actualites-societe/2007-01-17/la-fin-des-listes-noires/920/0/19641"
							target="_blank"
						>
							contenir de nombreuses erreurs
						</a>
						. Dès 2005 le Premier Ministre Jean-Pierre Raffarin la considère
						comme de « moins en moins pertinente » et demande à ses services
						(dans une{" "}
						<a
							href="http://www.legifrance.gouv.fr/affichTexte.do?cidTexte=JORFTEXT000000809117"
							target="_blank"
						>
							circulaire du 27 mai 2005
						</a>
						) de ne plus l’utiliser mais de privilégier une collaboration avec
						la Mission Interministérielle de Vigilance et de Lutte contre les
						Dérives sectaires (MIVILUDE) constituée en 2002. M. Raffarin
						souligne alors que l’objectif n’était pas de stigmatiser des
						mouvements mais de lutter contre des faits et actes constitutifs de
						dérives sectaires et répréhensibles par la loi.
					</p>
					<Titre2
						couleur={tools.BLEU_FONCE}
						texte="Une utilisation idéologique d’une liste sans valeur juridique"
					/>
					<p className="p1">
						La même année la justice confirme que cette liste n’a aucune «
						valeur juridique »
						<sup>
							<a href="#notes" target="_self">
								[2]
							</a>
						</sup>
						. Entre temps, elle a été utilisée de façon généralisée par les
						administrations et différents organismes privés avec de graves
						conséquences pour ceux qui y sont mentionnés à tort, notamment parce
						qu’elle a laissé des traces indélébiles sur Internet. Il est
						communément admis aujourd’hui que l’établissement de cette liste
						avait profondément été influencé par des a priori idéologiques. En
						2006, lors d’une{" "}
						<a
							href="https://www.youtube.com/watch?v=Mg2-xqFqYgA"
							target="_blank"
						>
							audition devant la Commission Parlementaire sur les dérives
							sectaires
						</a>
						, Didier Leschi, chef du Bureau des Cultes au Ministère de
						l’Intérieur, a affirmé à plusieurs reprises que les allégations à
						l’origine du rapport « n’avaient pas de réalité visible sur le
						terrain ».
						<sup>
							<a href="#notes" target="_self">
								[3]
							</a>
						</sup>
					</p>
					<p className="p1">
						La France a d’ailleurs été épinglée sur ce sujet là par l’ONU dans
						son{" "}
						<a
							href="http://www.coordiap.com/Document/ONU_060308_Rapport_su_3FF.pdf"
							target="_blank"
						>
							rapport de mars 2006
						</a>
						. L’ONU y avance en effet que « des améliorations s’imposent pour
						faire en sorte que le droit à la liberté de religion ou de
						conviction de tous les individus soit garanti et pour éviter la
						stigmatisation des membres de certains groupes religieux ou
						communautés, y compris les groupes dont les membres n’ont jamais
						commis de délit selon la loi française. »
						<sup>
							<a href="#notes" target="_self">
								[4]
							</a>
						</sup>
					</p>
					<Titre2 couleur={tools.ROSE} texte="La réalité des faits" />
					<p className="p1">
						La MIVILUDE nous a confirmé à plusieurs reprises
						<sup>
							<a href="#notes" target="_self">
								[5]
							</a>
						</sup>{" "}
						qu’aucun comportement à dérive sectaire n’avait été signalé en ce
						qui concerne la Mission Timothée. Fait important, notre association
						avait déjà disparu de la liste lors du{" "}
						<a
							href="http://www.assemblee-nationale.fr/dossiers/sectes/sommaire.asp"
							target="_blank"
						>
							rapport parlementaire de 1999
						</a>
						.
					</p>
					<p className="p1">
						Enfin, la Mission Timothée a reçu en 2007 l’agrément du Bureau des
						Cultes du Ministère de l’Intérieur
						<sup>
							<a href="#notes" target="_self">
								[6]
							</a>
						</sup>{" "}
						pour son volet cultuel tandis que la même année elle était reconnue
						d’intérêt général
						<sup>
							<a href="#notes" target="_self">
								[7]
							</a>
						</sup>{" "}
						pour son activité sociale.
					</p>

					<div className="pt-5"></div>
					<a name="notes" className=""></a>
					<ul>
						<li>
							<a href="#n1" target="_self">
								[1]
							</a>{" "}
							30 septembre 1994, au Québec, 5 morts ; 5 octobre 1994, en Suisse,
							25 personnes mortes dans le Valais et 23 dans le canton de
							Fribourg
						</li>
						<li>
							<a href="#n2" target="_self">
								[2]
							</a>{" "}
							Tribunal Administratif de Caen, 18 avril 2007
						</li>
						<li>
							<a href="#n3" target="_self">
								[3]
							</a>{" "}
							Audition du 17/10/2006 devant la Commission Parlementaire sur les
							dérives sectaires, domaine de l’enfance
						</li>
						<li>
							<a href="#n4" target="_self">
								[4]
							</a>{" "}
							Rapport de Mars 2006, Asma Jahangir, rapporteuse spéciale des
							Droits de l’Homme de l’ONU.
						</li>
						<li>
							<a href="#n5" target="_self">
								[5]
							</a>{" "}
							et notamment 2 fois par des courriers datés du 1/08/2003 et du
							22/12/2005
						</li>
						<li>
							<a href="#n6" target="_self">
								[6]
							</a>{" "}
							Statuts présentés au Bureau des Cultes et validés. Confirmation de
							l’Agrément par courrier du 29 Octobre 2007 ; Direction des
							Services Fiscaux du Gard.
						</li>
						<li>
							<a href="#n7" target="_self">
								[7]
							</a>{" "}
							Courrier de Jean-François COPE, Ministre du Budget, 3 avril 2007
						</li>
					</ul>

					<TelechargementComplet lien="https://storage.timothee.fr/ftp/media/doc/PourquoiLaMissionTimotheeEstMentionneeEnTantQueSecteSurInternet.pdf" />
				</div>
			</>
		);
	}
}
