import React from "react";
import twitter from "../../resources/icons/twitter.svg";
import facebook from "../../resources/icons/facebook.svg";
import ljc from "../../resources/icons/ljc.svg";
import "./Pub.scss";
import { Icones, tools } from "@mitim/react-mitim";

export default class Pub extends React.Component {
	state = {
		couleurFacebook: "#fff",
		couleurTwitter: "#fff"
	};

	render() {
		const titre1 = "LIBRAIRIE JEAN CALVIN";
		const titre2 = "LIBRAIRIE PROTESTANTE";
		return (
			<>
				<div className="container-fluid no-gutters">
					<div style={{ display: "block", position: "relative" }}>
						<div
							className="mt-pub-banner"
							style={{
								backgroundImage: `url(${tools.lienPhotos}/librairie.jpg)`
							}}
						>
							<div className="row" style={{ zIndex: "2" }}>
								<div className="col-md-12">
									<div className="text-center">
										<img src={ljc} width={200} className="pt-5" />

										{/* Grand écran */}
										<div className="d-none d-xl-block">
											<h1
												className="text-white ft-ubuntu"
												style={{ fontSize: "3em" }}
											>
												{titre1}
											</h1>
											<div
												className="text-white ft-ubuntu-light"
												style={{ fontSize: "2em" }}
											>
												{titre2}
											</div>
										</div>

										{/* Mobile */}
										<div className="d-block d-xl-none">
											<h1
												className="text-white ft-ubuntu"
												style={{ fontSize: "2em" }}
											>
												{titre1}
											</h1>
											<div
												className="text-white ft-ubuntu-light"
												style={{ fontSize: "1em" }}
											>
												{titre2}
											</div>
										</div>

										<a
											href="https://twitter.com/libjeancalvin"
											target="_blank"
											onMouseEnter={() => {
												this.setState({ couleurTwitter: tools.ORANGE });
											}}
											onMouseLeave={() => {
												this.setState({ couleurTwitter: "#fff" });
											}}
										>
											<Icones.twitter
												width={50}
												couleur={this.state.couleurTwitter}
											/>
										</a>
										<span className="ml-2"></span>
										<a
											href="https://fr-fr.facebook.com/LJCPARIS"
											target="_blank"
											onMouseEnter={() => {
												this.setState({ couleurFacebook: tools.ORANGE });
											}}
											onMouseLeave={() => {
												this.setState({ couleurFacebook: "#fff" });
											}}
										>
											<Icones.facebook
												width={50}
												couleur={this.state.couleurFacebook}
											/>
										</a>
										<div className="pt-5">
											<a
												href={"https://www.librairiejeancalvin.fr"}
												target="_blank"
												style={{
													textDecoration: "none",
													fontWeight: "bold"
												}}
											>
												<span className="text-center mt-5 ft-ubuntu text-uppercase mt-pub-banner-button">
													VISITEZ LE SITE
												</span>
											</a>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}
