// Libraries
import React from "react";
import { withRouter } from "react-router-dom";
import $ from "jquery";
// Components
import FilterList from "./FilterList";
import ResultList from "./ResultList";
import NavBar from "./NavBar";
import Pagination from "./Pagination";
import { parseUrl, cleanFilterSearch } from "./utils";

/**
 * TODO
 * - (pour plus tard) tri croissant / décroissant par titre / date de publication / ... ?
 * - (pour plus tard) afficher le nombre de résultat par filterItem (> 0)
 * - Remplacer les icônes de téléchargement / audio / video / ... (grid et line view) (par celle d'Elisa)
 * - Afficher un media selon son mediaType (afficher un cantique avec les fichiers ou un album avec toutes les pistes ...)
 * - Commenter
 */

class MediaSearch extends React.Component {
	state = {
		filterSearch: { filters: [] },
		data: [],
		quantityResult: 0,
		renderList: false
	};

	handleChangeTypeView = value => {
		this.setState({ renderList: value });
	};

	parseUrl = stringFilter => {
		let category = this.getCategory();
		let parseFilter = parseUrl(stringFilter);
		parseFilter.category = category;
		return parseFilter;
	};

	getCategory = () => {
		let category = this.props.location.pathname
			.replace(this.props.rootPath, "")
			.replace("/", "");
		if (category === "" || !this.props.categories.includes(category)) {
			category = this.props.defaultCategory;
		}
		return category;
	};

	updateStateFilterAndSearch = (updatedFilterSearch, shouldGoToTop) => {
		this.setState({ filterSearch: updatedFilterSearch });
		const { cleanedFilterSearch, urlFilterSearch } = cleanFilterSearch(
			updatedFilterSearch
		);

		if (Object.keys(urlFilterSearch).length !== 0) {
			this.props.history.push(
				"?q=" + encodeURI(JSON.stringify(urlFilterSearch))
			);
		} else {
			this.props.history.push(this.props.location.pathname);
		}
		this.props.search(cleanedFilterSearch, this);
		$("html, body").animate(
			{
				scrollTop: shouldGoToTop ? 0 : 530
			},
			"slow"
		);
	};

	onFilterItemChange = value => {
		let category = this.getCategory();
		let updatedFilterSearch = this.state.filterSearch;
		updatedFilterSearch.pageNumber = 1;
		updatedFilterSearch.category = category;
		for (let indice in updatedFilterSearch.filters) {
			let filter = updatedFilterSearch.filters[indice];
			if (filter.type === value.type) {
				for (let itemIndice in filter.filterItems) {
					let filterItem = filter.filterItems[itemIndice];
					if (filterItem.id === value.item.id) {
						filterItem.value = value.item.value;
					}
				}
			}
		}
		this.updateStateFilterAndSearch(updatedFilterSearch);
	};

	onQueryStringChange = value => {
		// let category = this.getCategory();
		let updatedFilterSearch = this.state.filterSearch;
		updatedFilterSearch.pageNumber = 1;
		// updatedFilterSearch.category = category;
		updatedFilterSearch.queryString = value;
		this.updateStateFilterAndSearch(updatedFilterSearch);
	};

	onChangePage = pageNumber => {
		// let category = this.getCategory();
		let updatedFilterSearch = this.state.filterSearch;
		// updatedFilterSearch.category = category;
		updatedFilterSearch.pageNumber = pageNumber;
		this.updateStateFilterAndSearch(updatedFilterSearch);
	};

	onCategoryChange = category => {
		let updatedFilterSearch = this.state.filterSearch;
		updatedFilterSearch.pageNumber = 1;
		updatedFilterSearch.category = category;
		updatedFilterSearch.filters = [];
		updatedFilterSearch.queryString = "";
		this.updateStateFilterAndSearch(updatedFilterSearch);
	};

	clearFilters = () => {
		let updatedFilterSearch = this.state.filterSearch;
		updatedFilterSearch.pageNumber = 1;
		updatedFilterSearch.pageSize = 30;
		for (let indice in updatedFilterSearch.filters) {
			let filter = updatedFilterSearch.filters[indice];
			for (let itemIndice in filter.filterItems) {
				delete filter.filterItems[itemIndice].value;
			}
		}
		this.updateStateFilterAndSearch(updatedFilterSearch);
	};

	update = () => {
		const params = new URLSearchParams(this.props.location.search);
		const jsonFilter = params.get("q");
		let filterSearch;
		if (jsonFilter) {
			filterSearch = this.parseUrl(jsonFilter);
		} else {
			filterSearch = { category: this.getCategory(), pageSize: 30 };
		}
		if (!filterSearch.pageSize) {
			filterSearch.pageSize = 30;
		}
		if (!filterSearch.filters) {
			filterSearch.filters = [];
		}
		this.updateStateFilterAndSearch(filterSearch, true);
	};

	componentDidMount() {
		this.update();
	}

	componentDidUpdate() {
		if (this.getCategory() !== this.state.filterSearch.category) {
			this.update();
		}
	}

	// static getDerivedStateFromProps(nextProps, prevState) {
	// 	const params = new URLSearchParams(this.props.location.search);
	// 	const jsonFilter = params.get('q');
	// 	let filterSearch;
	// 	if (jsonFilter) {
	// 		filterSearch = this.parseUrl(jsonFilter);
	// 	} else {
	// 		filterSearch = { category: this.getCategory(), pageSize: 30 };
	// 	}
	// 	if (!filterSearch.pageSize) {
	// 		filterSearch.pageSize = 30;
	// 	}
	// 	if (!filterSearch.filters) {
	// 		filterSearch.filters = [];
	// 	}
	// 	this.updateStateFilterAndSearch(filterSearch);
	//    return null;
	//  }

	render() {
		let classes = {
			filter: "col-12 col-md-4 col-lg-3 col-xl-3 col-xxl-2",
			result: "col-12 col-md-8 col-lg-9 col-xl-9 col-xxl-10",
			pagination: "pt-5"
		};
		let filters = [...this.state.filterSearch.filters].sort((a, b) => {
			if (a.label && b.label) {
				let aLabel = a.label.trim().toLowerCase();
				let bLabel = b.label.trim().toLowerCase();
				if (aLabel === "types de médias") {
					return -1;
				} else if (bLabel === "types de médias") {
					return 1;
				} else {
					return aLabel.localeCompare(bLabel, "fr", {
						sensitivity: "base"
					});
				}
			}
		});
		return (
			<div className="container-fluid">
				<NavBar
					handleChangeTypeView={this.handleChangeTypeView}
					renderList={this.state.renderList}
					handleSearchQueryString={this.onQueryStringChange}
					queryString={this.state.filterSearch.queryString}
					categories={this.props.categories}
					rootPath={this.props.rootPath}
					onCategoryChange={this.onCategoryChange}
					classes={classes}
				/>
				<div className="row">
					<FilterList
						className={classes.filter}
						onFilterItemChange={this.onFilterItemChange}
						filterList={filters}
						clearFilters={this.clearFilters}
					/>
					<ResultList
						className={classes.result}
						resultsList={this.state.data}
						quantity={this.state.quantityResult}
						renderList={this.state.renderList}
					/>
					<Pagination
						classes={classes}
						onChangePage={this.onChangePage}
						quantity={this.state.quantityResult}
						pageNumber={this.state.filterSearch.pageNumber}
						pageSize={this.state.filterSearch.pageSize}
					/>
				</div>
			</div>
		);
	}
}

export default withRouter(MediaSearch);
