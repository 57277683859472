// Libraries
import React from "react";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { tools, Icones } from "@mitim/react-mitim";
// Components
import Media from "./Media";
import Header from "../Header/Header";
import Page from "../Page";
// Images
// CSS
// import '../../styles/Media.css';
import "./MediaOuvrage.scss";
import * as communs from "./communs";
import "./MediaRevue.scss";

class MediaRevue extends Page {
	constructor(props) {
		super(props);
		this.audioPlayer = React.createRef();
	}

	render() {
		const { t } = this.props;
		const media = this.props.app.state.media;

		const styleBouton = {
			fontSize: "1.1em",
			color: "#333",
			paddingTop: "20px",
			paddingBottom: "100px"
		};
		const classeBouton = "text-center mt-5 ft-lato-light text-uppercase";
		return (
			<>
				<Header
					slide={{
						template: 2,
						image: "parolePartagee.jpg",
						h1: "Parole partagée",
						h2: "LIVRES",
						h3: "Nourrir sa foi et sa reflexion",
						descriptionMeta: ""
					}}
				/>
				<communs.BanniereTitre
					couleur={this.props.couleur}
					texteType="REVUE"
					titre={media.nomRevue.nom + " N° " + media.numRevue}
					sousTitre={media.sousTitre}
				/>

				<div
					className="container-fluid pb-lg-5 no-gutters pt-5 bg-gris media-revue"
					style={{ minHeight: "480px" }}
				>
					<div className="container">
						<div className="row pt-5">
							<div className="col-12 col-md-4">
								<div className="row">
									<div className="col-md-11">
										<img
											src={communs.getCouvertureImageSrcByRang(
												1,
												this.props.app.state.fichiers
											)}
											className="img-fluid d-block"
											alt=""
											style={{ borderRadius: "5px" }}
										/>
										<div className="" style={{}}>
											<p className="pt-3">
												Proposer à la lecture du plus grand nombre des
												exhortations et réflexions propres à édifier et
												fortifier la foi des croyants, voilà en quelques mots
												l’objectif de cette revue. La forme et le niveau de
												lecture des articles varient : témoignage, exhortation,
												histoire, approfondissement d’un sujet théologique,
												d’éthique ou de société... Ainsi, chacun pourra y
												trouver une nourriture en fonction de ses besoins et de
												ses responsabilités. Excellente lecture à tous !
												<br />
												<br />
												{media.nbrPages && (
													<>
														Nbr pages : {media.nbrPages}
														<br />
													</>
												)}
												{media.dateEdition && (
													<>
														Édition : {media.dateEdition}
														<br />
													</>
												)}
											</p>

											{media.lienLjc && (
												<div
													className="text-center p-3 ml-5 mr-5 mt-4 mb-4"
													style={{ border: "1px solid #414042" }}
												>
													<a
														href={
															"https://www.librairiejeancalvin.fr/index.php/ljc/Publications/(id)/" +
															media.lienLjc
														}
														target="_blank"
														className={classeBouton}
														style={styleBouton}
													>
														passer commande
													</a>
												</div>
											)}
										</div>
									</div>
									<div className="col-md-1"></div>
								</div>
							</div>
							<ul>
								{this.props.app.state.mediaMedias.map(media => (
									<li>
										<Link
											to={`/media/${
												media.mediaChild.id
											}/${media.mediaChild.titre.split(" ").join("-")}`}
										>
											<h5>{media.mediaChild.titre}</h5>
										</Link>
									</li>
								))}
							</ul>
							{this.props.app.state.media.contenu && (
								<communs.LireContenu
									contenu={this.props.app.state.media.contenu}
									titre={"DESCRIPTION DE LA REVUE"}
									couleur={this.props.couleur}
								/>
							)}
						</div>
					</div>
				</div>
			</>
		);
	}
}

export default withTranslation()(MediaRevue);
