import React, { useLayoutEffect } from "react";
import {
	MEMBRES_TAJ,
	MEMBRES_MT,
	SIGNATURE,
	DATA_FORMALITES_BANCAIRES,
	SIGNATURE_AG2024
} from "./DATA";

export const TableauSignatureAG2024 = () => (
	<div className="table-responsive">
		<table className="table table-striped">
			<tbody>
				{SIGNATURE_AG2024.map(ligne => (
					<tr key={ligne.nom}>
						<td>
							{ligne.prenom} {ligne.nom}
						</td>
						<td>
							<img src={ligne.signature} alt="" width={80} />
						</td>
					</tr>
				))}
			</tbody>
		</table>
	</div>
);

export const TableauSignature = () => (
	<div className="table-responsive">
		<table className="table table-striped">
			<tbody>
				{SIGNATURE.map(ligne => (
					<tr key={ligne.distinction}>
						<td>{ligne.distinction}</td>
						<td>
							{ligne.prenom} {ligne.nom}
						</td>
						<td>
							<img src={ligne.signature} alt="" width={80} />
						</td>
					</tr>
				))}
			</tbody>
		</table>
	</div>
);

export const Titre2 = props => (
	<div className="pt-5 pb-1">
		<h2 style={{ backgroundColor: props.couleur }}>{props.texte}</h2>
	</div>
);
export const T2 = ({ children, couleur }) => (
	<div className="pt-5 pb-1">
		<h2 style={{ backgroundColor: couleur }}>{children}</h2>
	</div>
);
export const Titre3 = props => (
	<h3 className="pt-2" style={{ color: props.couleur }}>
		{props.texte}
	</h3>
);
export const T3 = ({ children, couleur }) => (
	<h3 className="pt-2 t3" style={{ color: couleur }}>
		{children}
	</h3>
);
export const Titre4 = props => (
	<h4 className="pt-2 titre4" style={{ color: "#414042" }}>
		{props.texte}
	</h4>
);
export const T4 = ({ children, couleur }) => (
	<h4 className="pt-2 titre4" style={{ color: couleur || "#414042" }}>
		{children}
	</h4>
);
export const Titre5 = props => (
	<h5 className="pt-2 titre5" style={{ color: "#414042" }}>
		{props.texte}
	</h5>
);
export const T5 = ({ children, couleur }) => (
	<h5 className="pt-2 titre5" style={{ color: couleur || "#414042" }}>
		{children}
	</h5>
);
export const Titre6 = props => (
	<h5 className="pt-2 titre6 pb-3" style={{ color: "#414042" }}>
		{props.texte}
	</h5>
);
export const T6 = ({ children, couleur }) => (
	<h5 className="pt-2 titre6 pb-3" style={{ color: couleur || "#414042" }}>
		{children}
	</h5>
);

export const P3 = ({ children }) => <p className="paragraph3">{children}</p>;
export const P4 = ({ children }) => <p className="paragraph4">{children}</p>;
export const P5 = ({ children }) => <p className="paragraph5">{children}</p>;
export const Paragraph3 = props => <p className="paragraph3">{props.texte}</p>;
export const Paragraph4 = props => <p className="paragraph4">{props.texte}</p>;
export const Paragraph5 = props => <p className="paragraph5">{props.texte}</p>;

export const Image = props => (
	<div className="text-center pb-3">
		<img className="img-fluid text-center" src={props.src} alt="" />
	</div>
);

export const TableauFormalitesBancaires = () => (
	<div className="table-responsive">
		<table className="table table-striped">
			<thead>
				{/* <tr>
					<th scope="col"></th>
					<th scope="col"></th>
					<th scope="col"></th>
				</tr> */}
			</thead>
			<tbody>
				{DATA_FORMALITES_BANCAIRES.map(ligne => (
					<tr key={ligne.num_1}>
						<td>{ligne.num_1}</td>
						<td>{ligne.num_2}</td>
						<td>
							<ul>
								{ligne.num_3.map(nom => (
									<li>{nom}</li>
								))}
							</ul>
						</td>
					</tr>
				))}
			</tbody>
		</table>
	</div>
);

export const TableauMembresTAJ = () => (
	<div className="table-responsive">
		<table className="table table-striped">
			<thead>
				<tr>
					<th scope="col">Nom</th>
					<th scope="col">Prénom</th>
					<th scope="col">Profession</th>
					<th scope="col">Adresse</th>
				</tr>
			</thead>
			<tbody>
				{MEMBRES_TAJ.map(ligne => (
					<tr key={ligne.nom + ligne.prenom}>
						<td>{ligne.nom}</td>
						<td>{ligne.prenom}</td>
						<td>{ligne.profession}</td>
						<td>{ligne.adresse}</td>
					</tr>
				))}
			</tbody>
		</table>
	</div>
);

export const TableauMembresMT = () => (
	<div className="table-responsive">
		<table className="table table-striped">
			<thead>
				<tr>
					<th scope="col">Nom</th>
					<th scope="col">Prénom</th>
					<th scope="col">Profession</th>
					<th scope="col">Adresse</th>
				</tr>
			</thead>
			<tbody>
				{MEMBRES_MT.map(ligne => (
					<tr key={ligne.nom + ligne.prenom}>
						<td>{ligne.nom}</td>
						<td>{ligne.prenom}</td>
						<td>{ligne.profession}</td>
						<td>{ligne.adresse}</td>
					</tr>
				))}
			</tbody>
		</table>
	</div>
);
