import React, { Component } from "react";
import "./Sondage.scss";
import { render } from "react-dom";
import Modal from "react-bootstrap/Modal";
import {
	ModuleCaroussel,
	Icones,
	BlockImgNlt,
	ModuleEncartListMt,
	ModuleCard,
	tools,
	api,
	auth
} from "@mitim/react-mitim";

export class SondageRadioBouton extends Component {
	state = {
		// pour: true,
		oui: this.props.defaultOui,
		non: false
	};

	componentDidMount() {}

	updateVote = val => {
		this.props.self.setState(
			{
				elements: {
					...this.props.self.state.elements,
					[this.props.nom]: val
				}
			},
			() => console.table(this.props.self.state.elements)
		);
	};

	handleChange = event => {
		console.log(event);
		let target = event.target;
		let value = target.value;
		let id = target.id;
		console.log(event.target.checked);
		console.log(value);
		console.log(id);
		if (id === "oui" + this.props.nom) {
			this.setState(
				{
					oui: true,
					non: false
				},
				this.updateVote("oui")
			);
		}
		if (id === "non" + this.props.nom) {
			this.setState(
				{
					oui: false,
					non: true
				},
				this.updateVote("non")
			);
		}
	};

	render() {
		return (
			<>
				<div className="voteTitre mt-5">{this.props.titre}</div>
				<div className="text-center">
					<>
						<div className="form-check form-check-inline">
							<input
								className="form-check-input"
								type="radio"
								name={"inlineRadioOptions" + this.props.nom}
								onChange={this.handleChange}
								id={"oui" + this.props.nom}
								checked={this.state.pour}
								required={true}
							/>
							<label className="form-check-label">Oui</label>
						</div>
						<div className="form-check form-check-inline">
							<input
								className="form-check-input"
								type="radio"
								name={"inlineRadioOptions" + this.props.nom}
								onChange={this.handleChange}
								id={"non" + this.props.nom}
								checked={this.state.contre}
								required={true}
							/>
							<label className="form-check-label">Non</label>
						</div>
					</>
				</div>
			</>
		);
	}
}
