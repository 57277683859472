export const MAP = {
	name: "france",
	areas: [
		{
			name: "Perpignan",
			country: "FR",
			shape: "circle",
			coords: [517, 754, 5],
			preFillColor: "#009d96",
			lien: "www.eglise-perpignan.timothee.fr",
			cp: "66"
		},
		{
			name: "Narbonne",
			country: "FR",
			shape: "circle",
			coords: [521, 716, 5],
			preFillColor: "#009d96",
			lineWidth: 2,
			lien: "www.eglise-narbonne.timothee.fr",
			cp: "11"
		},
		// {
		// 	name: "Toulouse",
		// 	country: "FR",
		// 	shape: "circle",
		// 	coords: [415, 674, 5],
		// 	preFillColor: "#009d96",
		// 	lineWidth: 2,
		// 	lien: "www.eglise-toulouse.timothee.fr",
		// 	cp: "31"
		// },
		{
			name: "Toulouse",
			country: "FR",
			shape: "circle",
			coords: [415, 674, 5],
			preFillColor: "#009d96",
			lineWidth: 2,
			lien: "",
			cp: "31",
			popup: true,
			popupValue:
				"<h4>GROUPE DE MAISON DE TOULOUSE</h4><p>92 bis route de Cintegabelle<br/>31190 - AUTERIVE<br/>Contacter Yves Justament : 06 20 51 88 87 - yves.justamante@live.fr</p>"
		},
		{
			name: "Montpellier",
			country: "FR",
			shape: "circle",
			coords: [568, 680, 5],
			preFillColor: "#009d96",
			lineWidth: 2,
			lien: "www.eglise-montpellier.timothee.fr",
			cp: "34"
		},
		{
			name: "Marseille",
			country: "FR",
			shape: "circle",
			coords: [657, 700, 5],
			preFillColor: "#009d96",
			lineWidth: 2,
			lien: "www.eglise-marseille.timothee.fr",
			cp: "13"
		},
		{
			name: "Nîmes",
			country: "FR",
			shape: "circle",
			coords: [596, 659, 5],
			preFillColor: "#009d96",
			lineWidth: 2,
			lien: "www.eglise-nimes.timothee.fr",
			cp: "30"
		},
		{
			name: "Anduze",
			country: "FR",
			shape: "circle",
			coords: [574, 646, 5],
			preFillColor: "#009d96",
			lineWidth: 2,
			lien: "www.timotheeaccueiljeunesse.fr/centre-accueil-anduze",
			cp: "30"
		},
		{
			name: "Alès",
			country: "FR",
			shape: "circle",
			coords: [589, 636, 5],
			preFillColor: "#009d96",
			lineWidth: 2,
			lien: "www.eglise-ales.timothee.fr",
			cp: "30"
		},
		{
			name: "Nice",
			country: "FR",
			shape: "circle",
			coords: [765, 662, 5],
			preFillColor: "#009d96",
			lineWidth: 2,
			lien: "www.eglise-nice.timothee.fr",
			cp: "06"
		},
		{
			name: "Briançon",
			country: "FR",
			shape: "circle",
			coords: [728, 568, 5],
			preFillColor: "#009d96",
			lineWidth: 2,
			lien: "www.eglise-briancon.timothee.fr",
			cp: "05"
		},
		{
			name: "St Michel",
			country: "FR",
			shape: "circle",
			coords: [657, 531, 5],
			preFillColor: "#009d96",
			lineWidth: 2,
			lien: "www.eglise-st-michel.timothee.fr",
			cp: "38"
		},
		{
			name: "Say",
			country: "FR",
			shape: "circle",
			coords: [527, 506, 5],
			preFillColor: "#009d96",
			lineWidth: 2,
			lien: "www.eglise-say.timothee.fr",
			cp: "63"
		},
		{
			name: "Limoges",
			country: "FR",
			shape: "circle",
			coords: [417, 493, 5],
			preFillColor: "#009d96",
			lineWidth: 2,
			lien: "www.eglise-limoges.timothee.fr",
			cp: "87"
		},
		{
			name: "Hauteville",
			country: "FR",
			shape: "circle",
			coords: [649, 478, 5],
			preFillColor: "#009d96",
			lineWidth: 2,
			//lien: "www.eglise-hauteville.timothee.fr",
			cp: "01",
			popup: true,
			popupValue:
				"<h4>GROUPE BIBLIQUE DE HAUTEVILLE</h4><p>Adresse : 142 chemin de la couche, 01230 EVOSGES<br/>Contacter Christophe PESENTI : pesenti.christophe@gmail.com</p>"
		},
		{
			name: "Le Vaud",
			country: "CH",
			shape: "circle",
			coords: [696, 439, 5],
			preFillColor: "#009d96",
			lineWidth: 2,
			lien: "www.eglise-le-vaud.timothee.fr",
			cp: "CH-1261"
		},
		{
			name: "Farvagny",
			country: "CH",
			shape: "circle",
			coords: [746, 423, 5],
			preFillColor: "#009d96",
			lineWidth: 2,
			lien: "www.eglise-protestante-fribourg.ch",
			cp: "CH-1726"
		},
		{
			name: "Besançon",
			country: "FR",
			shape: "circle",
			coords: [684, 384, 5],
			preFillColor: "#009d96",
			lineWidth: 2,
			lien: "www.eglise-besancon.timothee.fr",
			cp: "25"
		},
		{
			name: "Cholet",
			country: "FR",
			shape: "circle",
			coords: [304, 392, 5],
			preFillColor: "#009d96",
			lineWidth: 2,
			lien: "www.eglise-cholet.timothee.fr",
			cp: "49"
		},
		{
			name: "Nantes",
			country: "FR",
			shape: "circle",
			coords: [261, 373, 5],
			preFillColor: "#009d96",
			lineWidth: 2,
			lien: "www.eglise-nantes.timothee.fr",
			cp: "44"
		},
		// {
		// 	name: "Angers Ecouflant",
		// 	country: "FR",
		// 	shape: "circle",
		// 	coords: [327, 354, 5],
		// 	preFillColor: "#009d96",
		// 	lineWidth: 2,
		// 	lien: "www.eglise-angers.timothee.fr",
		// 	cp: "49"
		// },
		{
			name: "Fouday",
			country: "FR",
			shape: "circle",
			coords: [746, 286, 5],
			preFillColor: "#009d96",
			lineWidth: 2,
			lien: "www.timotheeaccueiljeunesse.fr/centre-accueil-fouday",
			cp: "67"
		},
		{
			name: "Strasbourg",
			country: "FR",
			shape: "circle",
			coords: [776, 263, 5],
			preFillColor: "#009d96",
			lineWidth: 2,
			lien: "www.eglise-strasbourg.timothee.fr",
			cp: "67"
		},
		{
			name: "Sarrebourg",
			country: "FR",
			shape: "circle",
			coords: [735, 254, 5],
			preFillColor: "#009d96",
			lineWidth: 2,
			lien: "www.eglise-sarrebourg.timothee.fr",
			cp: "57"
		},
		{
			name: "Nancy",
			country: "FR",
			shape: "circle",
			coords: [689, 257, 5],
			preFillColor: "#009d96",
			lineWidth: 2,
			lien: "www.eglise-nancy.timothee.fr",
			cp: "54"
		},
		{
			name: "Metz",
			country: "FR",
			shape: "circle",
			coords: [689, 233, 5],
			preFillColor: "#009d96",
			lineWidth: 2,
			lien: "www.eglise-metz.timothee.fr",
			cp: "57"
		},
		{
			name: "Velving",
			country: "FR",
			shape: "circle",
			coords: [708, 227, 5],
			preFillColor: "#009d96",
			lineWidth: 2,
			lien: "www.eglise-velving.timothee.fr",
			cp: "57"
		},
		{
			name: "Bruxelles",
			country: "BE",
			shape: "circle",
			coords: [591, 92, 5],
			preFillColor: "#009d96",
			lineWidth: 2,
			lien: "www.eglise-bruxelles.timothee.fr",
			cp: "B-1200"
		},
		{
			name: "Lobbes",
			country: "BE",
			shape: "circle",
			coords: [587, 131, 5],
			preFillColor: "#009d96",
			lineWidth: 2,
			lien: "www.eglise-lobbes.timothee.fr",
			cp: "B-6142"
		},

		{
			name: "La Neuville-en-Hez",
			country: "FR",
			shape: "circle",
			coords: [485, 195, 5],
			preFillColor: "#009d96",
			lineWidth: 2,
			lien: "www.eglise-la-neuville-en-hez.timothee.fr",
			cp: "60"
		},

		// {
		// 	name: "Sermaise",
		// 	country: "FR",
		// 	shape: "circle",
		// 	coords: [469, 275, 5],
		// 	preFillColor: "#009d96",
		// 	lineWidth: 2,
		// 	lien: "www.eglise-sermaise.timothee.fr",
		// 	cp: "91"
		// },
		{
			name: "Paris",
			country: "FR",
			shape: "circle",
			coords: [488, 252, 5],
			preFillColor: "#009d96",
			lineWidth: 2,
			lien: "",
			cp: "75",
			popup: true,
			popupValue:
				"<h4>GROUPE BIBLIQUE DE PARIS</h4><p>Rassemblement les dimanches après-midi tous les 15 jours à 15h.<br/>Adresse : Rue de Clichy, Paris 9<br/>Contacter Patrick Ricard : pa.ricard@gmail.com</p>"
		},
		{
			name: "Vaux sur Seine",
			country: "FR",
			shape: "circle",
			coords: [465, 235, 5],
			preFillColor: "#009d96",
			lineWidth: 2,
			lien: "www.eglise-vaux-sur-seine.timothee.fr",
			cp: "78"
		},
		{
			name: "Rennes",
			country: "FR",
			shape: "circle",
			coords: [268, 300, 5],
			preFillColor: "#009d96",
			lineWidth: 2,
			lien: "www.eglise-rennes.timothee.fr",
			cp: "35"
		},
		{
			name: "Alençon",
			country: "FR",
			shape: "circle",
			coords: [361, 280, 5],
			preFillColor: "#009d96",
			lineWidth: 2,
			lien: "www.eglise-alencon.timothee.fr",
			cp: "61"
		},
		{
			name: "Thann",
			country: "FR",
			shape: "circle",
			coords: [743, 333, 5],
			preFillColor: "#009d96",
			lineWidth: 2,
			lien: "www.eglise-thann.timothee.fr",
			cp: "68"
		},
		// {
		// 	name: "Evreux",
		// 	country: "FR",
		// 	shape: "circle",
		// 	coords: [416, 234, 5],
		// 	preFillColor: "#009d96",
		// 	lineWidth: 2,
		// 	lien: "www.eglise-evreux.timothee.fr",
		// 	cp: "27"
		// },
		{
			name: "Boisset",
			country: "FR",
			shape: "circle",
			coords: [585, 647, 5],
			preFillColor: "#009d96",
			lineWidth: 2,
			lien: "www.eglise-boisset.timothee.fr",
			cp: "30"
		},
		{
			name: "Tornac",
			country: "FR",
			shape: "circle",
			coords: [575, 660, 5],
			preFillColor: "#009d96",
			lineWidth: 2,
			lien: "",
			cp: "30",
			lien: "www.eglise-tornac.timothee.fr"
			// popup: true,
			// popupValue:
			// 	"<h4>ÉGLISE DE PELISSOU</h4><p>Adresse : Mas Pelissou 30140 TORNAC</p>"
		},
		{
			name: "Générargues",
			country: "FR",
			shape: "circle",
			coords: [572, 636, 5],
			preFillColor: "#009d96",
			lineWidth: 2,
			lien: "www.eglise-generargues.timothee.fr",
			cp: "30"
		}
	]
};

export const MAP_BULGARIE = {
	name: "bulgarie",
	areas: [
		{
			name: "Montana",
			shape: "circle",
			coords: [46, 49, 5],
			preFillColor: "#009d96",
			lien: "www.timotheeaccueiljeunesse.fr/centre-accueil-bulgarie"
		}
	]
};

export const MAP_GUINEE = {
	name: "guinee",
	areas: [
		{
			name: "Conakry",
			shape: "circle",
			coords: [56, 126, 5],
			preFillColor: "#009d96",
			lien: "www.timotheeaccueiljeunesse.fr/orphelinat"
		}
	]
};

export const MAP_MADA = {
	name: "mada",
	areas: [
		{
			name: "Tananarive",
			shape: "circle",
			coords: [80, 98, 5],
			preFillColor: "#009d96",
			lien: "www.timotheeaccueiljeunesse.fr/centre-accueil-madagascar"
		}
	]
};
