import React from "react";

export const SIGNATURE_AG2024 = [
	{
		// distinction: "Le Coordinateur",
		nom: "LOIRET",
		prenom: "Jérémie",
		signature:
			"https://www.dropbox.com/s/vd2xvc6or6uoo6f/ae851991e82f3f5e9abbdf9a026846d9d87c580f.jpg?raw=1"
	},
	{
		// distinction: "Le Président",
		nom: "Jaussaud ",
		prenom: "Guilhem",
		signature:
			"https://www.dropbox.com/scl/fi/in9t2ks5okzergwu9bj3s/13c4302c71404ad123503c2eb4d89c672061437a.png?rlkey=vcylrory96ie1qg8vwtf6jphq&raw=1"
	},
	{
		// distinction: "Le Président",
		nom: "Bernard",
		prenom: "François",
		signature:
			"https://www.dropbox.com/scl/fi/sdrq494ikb5ishlz54ljl/37e67b43c8abc131166232b5d808137c3cdea35e.png?rlkey=7z0ny4kkh46ehi3k8f8lb962l&raw=1"
	},
	{
		// distinction: "Le Secrétaire de séance",
		nom: "GUTSCHER",
		prenom: "David",
		signature:
			"https://www.dropbox.com/s/mh0s4xg93sqpygf/e8f953ba9dfa29f9afafa881e176012701426a46.png?raw=1"
	}
];

export const SIGNATURE = [
	{
		distinction: "Président de séance",
		nom: "TYNEVEZ",
		prenom: "Daniel",
		signature:
			"https://www.dropbox.com/scl/fi/4xfouv9sf36xs6sht2br0/019acef647ca0cecf8c5487ca696e7224e9ec7eb.jpg?rlkey=45p0638jtm5znk5ty55m55z1y&raw=1"
	},
	{
		distinction: "Secrétaire de séance",
		nom: "GERVAIS",
		prenom: "Jean David",
		signature:
			"https://www.dropbox.com/scl/fi/c1zdtyusg9r811lvcdr1c/d1ef3c06490be0554f025848d92fc280313b15a5.jpg?rlkey=eb1mxqqlqbqoxhbe1glj0u4d7&raw=1"
	},
	{
		distinction: "Trésorier",
		nom: "DROUILLY",
		prenom: "Benjamin",
		signature:
			"https://www.dropbox.com/scl/fi/wsl86y7vjs7imru24n7vk/76d47db207ade32f4acecf720916978a1c9e224c.png?rlkey=pmwl7oaqrmkg82pxbvlhmz7vx&raw=1"
	}
];

export const MEMBRES_MT = [
	{
		nom: "AMAN",
		prenom: "Richard",
		profession: "Médecin",
		adresse: "31 route du Col de l’Izoard, 05100 BRIANCON"
	},
	{
		nom: "BERESSNEVITCH",
		prenom: "Michel",
		profession: "Retraité",
		adresse: "Rue Jean PELERIN - 54425 PULNOY"
	},
	{
		nom: "BRANCATO",
		prenom: "Calogero",
		profession: "Educateur spécialisé",
		adresse: "Rue de l’Europe, 296142 LEFERNES (Belgique)"
	},
	{
		nom: "BOPP",
		prenom: "Hans Jurg",
		profession: "Médecin",
		adresse: "283 chemin de Laucire 30140 TORNAC"
	},
	{
		nom: "CARRA",
		prenom: "Julien",
		profession: "Ingénieur",
		adresse: "Route de Marchissy 31261 Le VAUD (Suisse)"
	},
	{
		nom: "CHAMPAULT",
		prenom: "Samuel",
		profession: "Comptable",
		adresse: "47 avenue du mondial de Rugby 34070 MONTPELLIER"
	},
	{
		nom: "CZERMAK",
		prenom: "Bernard",
		profession: "Retraité",
		adresse: "15 route champ du Feu 67130 WALDERSBACH"
	},
	{
		nom: "DROUILLY",
		prenom: "Benjamin",
		profession: "Pasteur",
		adresse: "121 rue du général de Gaulle 78740 VAUX SUR SEINE"
	},
	{
		nom: "DUFEY",
		prenom: "Pierre Alain",
		profession: "Retraité",
		adresse: "Impasse du Vanil, 21726 FARVAGNY LE GRAND (Suisse)"
	},
	{
		nom: "DYE",
		prenom: "Jean Louis",
		profession: "Pasteur",
		adresse: "6595 chemin des arêtes 38590 SAINT MICHEL DE SAINT GEOIRS"
	},
	{
		nom: "FEHLMANN",
		prenom: "Andréas",
		profession: "Gérant de Société",
		adresse: "Grand Rue CH-1261 LE VAUD"
	},
	{
		nom: "FEHLMANN",
		prenom: "Matthias",
		profession: "Secrétaire",
		adresse: "1654 chemin de Recoulin 30140 ANDUZE "
	},
	{
		nom: "GERSCHHEIMER",
		prenom: "Michaël",
		profession: "Médecin",
		adresse: "2430 route de Saint Jean du Gard 30140 ANDUZE"
	},
	{
		nom: "GERVAIS",
		prenom: "Jean David",
		profession: "Pasteur",
		adresse: "17 rue Jacques Hoton 1200 BRUXELLES (Belgique)"
	},
	{
		nom: "GUTSCHER",
		prenom: "David",
		profession: "Pasteur",
		adresse: "25 rue des deux ponts 49300 CHOLET"
	},
	{
		nom: "HAUSER",
		prenom: "Marc",
		profession: "Pasteur",
		adresse: "Mas Elim 30140 GENERARGUES"
	},
	{
		nom: "HEGNAUER",
		prenom: "Ulrich",
		profession: "Menuisier",
		adresse: "Mas Pélissou 30140 TORNAC"
	},
	{
		nom: "ISSARTE",
		prenom: "Daniel",
		profession: "Retraité",
		adresse: "Mas Pélissou 30140 TORNAC"
	},
	{
		nom: "KREMER",
		prenom: "Pierre Yves",
		profession: "VRP",
		adresse: "26 rue de Lorraine 57220 VELVING"
	},
	{
		nom: "LOIRET",
		prenom: "Daniel",
		profession: "Retraité",
		adresse: "La Houssais 44130 BLAIN"
	},
	{
		nom: "LOIRET",
		prenom: "Jérémie",
		profession: "Coordinateur",
		adresse: "283 chemin de Laucire 30140 TORNAC"
	},
	{
		nom: "LOIRET",
		prenom: "Jean Luc",
		profession: "Electricien",
		adresse: "Les Triailles 30140 GENERARGUES"
	},
	{
		nom: "MEIRLAN",
		prenom: "Jean Luc",
		profession: "Ingénieur",
		adresse: "17 rue Jacques Hoton 1200 BRUXELLES (Belgique)"
	},
	{
		nom: "MORLET",
		prenom: "Gilles",
		profession: "Médecin",
		adresse: "La Perrotière 73170 SAINT JEAN DE CHEVELU"
	},
	{
		nom: "RENNETEAU",
		prenom: "Elie",
		profession: "Pasteur",
		adresse: "Mas Elim 30140 GENERARGUES"
	},
	{
		nom: "RICARD",
		prenom: "Patrick",
		profession: "DRH",
		adresse: "190 rue Neuve 60190 MOYVILLERS"
	},
	{
		nom: "TRUMPFF",
		prenom: "Martin",
		profession: "Pasteur",
		adresse: "309 route de Gaujac – Appt 23 30140 BOISSET ET GAUJAC"
	},
	{
		nom: "TYNEVEZ",
		prenom: "Gérard",
		profession: "Retraité",
		adresse: "Le Riffray 35150 CHANTELOUP"
	},
	{
		nom: "WARNON",
		prenom: "Luc",
		profession: "Retraité",
		adresse: "115 rue du Mas de Balan 30000 Nïmes"
	},
	{
		nom: "WEHREY",
		prenom: "Jean Martin",
		profession: "Retraité",
		adresse: "9 rue Bolzen 67120 KOLBSHEIM"
	},
	{
		nom: "ZEHR",
		prenom: "Samuel",
		profession: "Agriculteur",
		adresse: "Ferme Sarrelfing 57400 SARREBOURG"
	}
];

// ###############################################################################################################################################
// ###############################################################################################################################################
// ###############################################################################################################################################

export const MEMBRES_TAJ = [
	{
		nom: "AMBERG",
		prenom: "Arnaud",
		profession: "Intendant",
		adresse: "62 rue principale 67130 FOUDAY"
	},
	{
		nom: "BERNARD",
		prenom: "François",
		profession: "Pasteur",
		adresse: "9 rue Bolzen 67120 KOLBSHEIM"
	},
	{
		nom: "COATANHAY",
		prenom: "Eliane",
		profession: "Mère au Foyer",
		adresse: "446 les hauts de Labahou 30140 ANDUZE"
	},
	{
		nom: "COLIN",
		prenom: "Agathe",
		profession: "Mère au foyer",
		adresse: "7 rue du Luxembourg 30140 ANDUZE"
	},
	{
		nom: "CHAMPAULT",
		prenom: "Samuel",
		profession: "Comptable",
		adresse: "47 avenue du mondial de Rugby 34070 MONTPELLIER"
	},
	{
		nom: "DROUILLY",
		prenom: "Jérémie",
		profession: "Intendant",
		adresse: "15 chemin les hauts de Labahou 30140 ANDUZE"
	},
	{
		nom: "DYE",
		prenom: "Sophie",
		profession: "Professeur d’anglais",
		adresse: "6595 chemin des arêtes 38590 SAINT MICHEL DE SAINT GEOIRS"
	},
	{
		nom: "FEHLMANN",
		prenom: "Matthias",
		profession: "Secrétaire",
		adresse: "1645 chemine de Recoulin 30140 ANDUZE"
	},
	{
		nom: "FEHLMANN",
		prenom: "Andréas",
		profession: "Gérant de Société",
		adresse: "Grand Rue CH-1261 LE VAUD"
	},
	{
		nom: "GERSCHHEIMER",
		prenom: "Ernest",
		profession: "Retraité",
		adresse: "Le Viala Le Château 30140 GENERARGUES"
	},
	{
		nom: "GASSER",
		prenom: "Joël",
		profession: "Enseignant",
		adresse: "17 impasse des Palettes 63740 GELLES"
	},
	{
		nom: "GUTSCHER",
		prenom: "Audrey",
		profession: "Mère au foyer",
		adresse: "25 rue des deux ponts 49300 CHOLET"
	},
	{
		nom: "GUTSCHER",
		prenom: "David",
		profession: "Pasteur",
		adresse: "25 rue des deux ponts 49300 CHOLET"
	},
	{
		nom: "ISSARTE",
		prenom: "David",
		profession: "Exploitant de Camping",
		adresse: "1105 Chemin de Recoulin 30140 ANDUZE"
	},
	{
		nom: "ISSARTE",
		prenom: "Daniel",
		profession: "Retraité",
		adresse: "Mas Pélissou 30140 TORNAC"
	},
	{
		nom: "JAUSSAUD",
		prenom: "Guilhem",
		profession: "Pasteur",
		adresse: "1645 chemine de Recoulin 30140 ANDUZE"
	},
	{
		nom: "LOIRET",
		prenom: "Jérémie",
		profession: "Coordinateur",
		adresse: "Mas Pélissou 30140 TORNAC"
	},
	{
		nom: "LOIRET",
		prenom: "Priscille",
		profession: "Mère au foyer",
		adresse: "283 chemin de Laucire 30140 TORNAC"
	},
	{
		nom: "LOCONTE",
		prenom: "Michaël",
		profession: "Technicien hospitalier",
		adresse: "1 rue du Grand Curtil 38080 FOUR"
	},
	{
		nom: "MEBARKI",
		prenom: "Lise Marie",
		profession: "Mère au foyer",
		adresse: "446 les hauts de Labahou 30140 ANDUZE"
	},
	{
		nom: "MEBARKI",
		prenom: "Mehdi",
		profession: "VRP",
		adresse: "446 les hauts de Labahou 30140 ANDUZE"
	},
	{
		nom: "N’ZUE",
		prenom: "Pawlow",
		profession: "VRP",
		adresse: "Mas Pélissou 30140 TORNAC"
	},
	{
		nom: "OZIL",
		prenom: "Stéphane",
		profession: "Dirigeant de Société",
		adresse: "Chemin du mas paulet 30140 ANDUZE"
	},
	{
		nom: "PRADAL",
		prenom: "Guy",
		profession: "Infirmier",
		adresse: "16 rue Cuvier 25000 BESANCON"
	},
	{
		nom: "RENAUD",
		prenom: "Marianne",
		profession: "Enseignante",
		adresse: "344 chemin de l’Olivier 30140 ANDUZE"
	},
	{
		nom: "RICARD",
		prenom: "Michèle",
		profession: "Enseignante",
		adresse: "190 rue Neuve 60190 MOYVILLERS"
	},
	{
		nom: "RICARD",
		prenom: "Luc",
		profession: "Médecin",
		adresse: "150 impasses rouviérettes 30140 BAGARD"
	},
	{
		nom: "SAGOLS",
		prenom: "Emmanuel",
		profession: "Intendant",
		adresse: "131 rue Ferrari 13005 MARSEILLES"
	},
	{
		nom: "SCHERTZ",
		prenom: "Jonathan",
		profession: "Plombier",
		adresse: "1645 chemine de Recoulin 30140 ANDUZE"
	},
	{
		nom: "TYNEVEZ",
		prenom: "Céline",
		profession: "Intendante",
		adresse: "Le Riffray 35150 CHANTELOUP"
	},
	{
		nom: "TYNEVEZ",
		prenom: "Daniel",
		profession: "Pasteur",
		adresse: "Le Riffray 35150 CHANTELOUP"
	},
	{
		nom: "ZEHR",
		prenom: "Joseph",
		profession: "Médecin",
		adresse: "Aspères 30140 TORNAC"
	},
	{
		nom: "ZEHR",
		prenom: "Stéphane",
		profession: "Pasteur",
		adresse: "446 les Hauts de Labahou 30140 ANDUZE"
	}
];

export const DATA_FORMALITES_BANCAIRES = [
	{
		num_1: "C.I.C Timothee",
		num_2: "17296001",
		num_3: [
			"FEHLMANN Matthias",
			"LOIRET Jérémie",
			"GUTSCHER David",
			"TRUMPFF Martin",
			"DROUILLY Jérémie"
		]
	},
	{
		num_1: "Crédit Agricole Anduze",
		num_2: "4521650001",
		num_3: [
			"LOIRET Jérémie",
			"DROUILLY Jérémie",
			"FEHLMANN Matthias",
			"GUTSCHER David",
			"TRUMPFF Martin (accès)"
		]
	},
	{
		num_1: "BP RENNES Mandataire Internet",
		num_2: "0195288R030",
		num_3: ["LOIRET Cécile", "CORGNE Nathan"]
	},
	{
		num_1: "LA BANQUE POSTALE FOUDAY",
		num_2: "0851476N030",
		num_3: ["AMBERG Arnaud"]
	},
	{
		num_1: "LA BANQUE POSTALE METZ - Mandataire Internet",
		num_2: "0444160L030",
		num_3: [
			"SCIBETTA Vincent",
			"GUTSCHER David",
			"FEHLMANN Matthias",
			"DESBRUS Alexandre (accès)"
		]
	},
	{
		num_1: "Crédit Agricole Maison Say",
		num_2: "",
		num_3: ["KREMER  Madeline"]
	},
	{
		num_1: "Crédit Agricole Colonies Say",
		num_2: "",
		num_3: ["KREMER  Madeline "]
	},
	{
		num_1: "Crédit Agricole Camprieu",
		num_2: "4521650002",
		num_3: [
			"GUTSCHER David",
			"LOIRET Jeremie LOIRET",
			"DROUILLY Jérémie",
			"DESBRUS  Alexandre (accès)"
		]
	}
];
