import React from "react";
import { VoteRadioBouton } from "../AGVote/VoteRadioBouton";
import { EnteteTaj, EnteteMT } from "../AGVote/AGVote";
import { tools } from "@mitim/react-mitim";
import {
	FichiersATelecharger,
	FICHIER_TELECHARGER_MT,
	TableauSalaries,
	TableauPouvoirBanque
} from "./DATA";
import {
	Titre2,
	Titre3,
	TableauSignature,
	Paragraph4,
	Paragraph3,
	T2,
	T3,
	T4,
	T5,
	T6,
	P3,
	P4,
	P5,
	Titre4,
	Image,
	Titre6
} from "../AGVote/Fonctions";
import { TableauMembresMT2022 } from "./DATA";

export const ContenuMt = props => (
	<>
		<div className="voteAg pt-5 mb-5">
			<br />
			<EnteteMT />
			<h1 className="">
				BULLETIN DE VOTE PAR CORRESPONDANCE
				<br />
				ASSEMBLEE GENERAL ORDINAIRE 2024
				<br />
				(PORTANT SUR L’EXERCICE 2023)
			</h1>
			<p className="indent">
				Le 3 juin 2024, le bureau de l’association Mission Timothée a lancé une
				consultation écrite aux adresses courriels indiquées par les membres
				conformément à l’article 9 de ses statuts. Les membres peuvent voter ou
				émettre des remarques, réserves et avis via leur espace personnalisé sur
				le site www.mission-timothee.fr. Le bureau indique que cette
				consultation prendra fin le 22 juin 2024, les membres n’ayant pas voté
				seront réputés absents. Une fois la procédure de vote close, le bureau
				enverra aux membres une copie du procès-verbal d’assemblée générale
				tenue par écrit.
			</p>
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* // PREALABLE */}
			<T2 couleur={tools.JAUNE_CLAIR}>
				DELIBERATION PREALABLE – COMPOSITION DU BUREAU
			</T2>
			<p>
				Le conseil d’administration propose à l’assemblée la composition
				suivante du bureau de séance :
				<ul>
					<li>Daniel TYNEVEZ, préside la séance en sa qualité de président</li>
					<li>Benjamin DROUILLY, trésorier, présente les comptes</li>
					<li>
						Jean-David GERVAIS, secrétaire de l’association, assure la fonction
						de secrétaire de séance
					</li>
				</ul>
			</p>
			<VoteRadioBouton
				numVote="mtAgPre"
				self={props.self}
				defaultPour={true}
				titre="VOTE d’approbation de la composition du bureau"
			/>
			<p>
				Sont nommés Scrutateurs et acceptent cette fonction : Jonathan NOLOT et
				Jérémie DROUILLY.
			</p>
			<p>
				La Société AUDIT ASSOCIES MEDITERANEE dont le siège social est situé 125
				rue du professeur Antonin Balmes MONTPELLIER (34070) représentée par
				Monsieur Jean-Paul LACOMBE, commissaire aux comptes de l'association, a
				été régulièrement convoquée.
			</p>
			<p>
				Conformément à ce qui a été indiqué dans la convocation, l’ordre du jour
				suivant sera évoqué :
				<ul>
					<li>Validation du précédent procès-verbal.</li>
					<li>Rapports d’activités</li>
					<li>Rapports financiers et approbation des comptes annuels</li>
					<li>Affectation du résultat</li>
					<li>Approbation du budget d’investissement et de fonctionnement</li>
					<li>
						Renouvellement du tiers des membres de l’Organe collégial
						d’administration
					</li>
					<li>Pouvoirs en vue des formalités juridiques et bancaires.</li>
					<li>Listes des pièces jointes</li>
				</ul>
			</p>
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* // 1 */}
			<Titre2
				couleur={tools.VERT}
				texte="1. PREMIÈRE DELIBERATION - APPROBATION DU PROCES-VERBAL DU 25 NOVEMBRE 2023"
			/>
			<p>
				Chacun a reçu le procès-verbal de l’Assemblée générale tenue le 25
				novembre 2023 portant sur l’année 2022. Plusieurs exemplaires sont
				également consultables.
			</p>
			<VoteRadioBouton
				numVote="mtAg1"
				self={props.self}
				defaultPour={true}
				titre="VOTE D’APPROBATION DU PRECEDENT PV d’ASSEMBLEE GENERALE ORDINAIRE"
			/>
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* // 2 */}
			<T2 couleur={tools.BLEU_FONCE}>
				2. DEUXIÈME DELIBERATION - RAPPORT MORAL ET D’ACTIVITE
			</T2>
			<p>
				Le bureau de l’association fait état des différentes activités de
				l’association sur l’année écoulée. Ce rapport souhaite être le plus
				complet possible afin que chacun des membres puissent avoir un avis
				éclairé et suffisamment concret de nos activités mais il ne saurait être
				totalement exhaustif. Nous invitons donc chacun des membres à avoir la
				liberté d’interroger les organes de direction de l’association (conseil
				d’administration, bureau) qui répondront avec diligence aux demandes de
				chacun.
			</p>
			<T3 couleur={tools.BLEU_FONCE}>2.1. COURS DE FORMATION MISSIONNAIRE</T3>
			<P3>
				Lors de la rentrée de septembre 2023, 19 étudiants ont rejoints la
				formation (contre 26 l’année précédente), majoritairement en
				décentralisée.
			</P3>
			<P3>
				Au total, pour l’année 2023-2024, nous avons une promotion de 69
				étudiants :
				<ul>
					<li>1 étudiante suit la formation courte en 1 an sur la colline</li>
					<li>
						28 étudiants suivent la formation courte (dont 9 sur place à Anduze)
					</li>
					<li>22 suivent la décentralisée sur 6 ans</li>
					<li>18 suivent la centralisée</li>
				</ul>
			</P3>
			<P3>
				En août 2023, 6 étudiants sont arrivés au terme de leur formation et ont
				poursuivi leur engagement dans nos différentes assemblées ainsi que dans
				les domaines divers de l’œuvre. Il s’agit de Lise ROUANET, Cédric
				DURINGER, Sarah LANGLADE, Paul et Elodie DE MAESTRI, Quentin SCHMITZ. Au
				terme de l’année scolaire, 2 étudiantes, Yvanna AYRINHAC et Joukje VAN
				DER SCHOOR, ont interrompu leur formation pour donner suite à des
				évolutions dans leur situation familiale. Depuis, elles se consacrent
				notamment aux activités des Ateliers de Pélissou et de Team étud’.
			</P3>
			<P3>
				Comme chaque année, 4 semaines de cours intensifs sont organisées (en
				septembre, décembre, mars et juin). Le programme s’articule autour d’une
				ou plusieurs sessions portant sur l’histoire des Missions, l’étude de
				l’Islam, du lien entre la science et la foi autour des questions de la
				Création, une introduction à l’Ancien testament, des cours de doctrine
				(doctrine de l’Église, doctrine de Christ, du péché et de la rédemption)
				et des études bibliques thématiques (sur la louange notamment).
			</P3>
			<T3 couleur={tools.BLEU_FONCE}>2.2. COMITE DE REDACTION</T3>
			<P3>
				Le comité de rédaction s’est tenu le 13 mars 2023. Les travaux ont porté
				sur le choix des textes pour la revue LRFT n°28. Ont été abordés
				plusieurs sujets en relation : la question du genre et de l’évolution
				des mentalités/discours sur ce sujet ; 3 articles autour de cette
				question ont paru dans le n°28 : sur le transgenre (éthique et
				théologique), sur la complémentarité créationnelle homme-femme (étude
				biblique) et sur la dysphorie de genre (médical). D’autres sujets ont
				également été abordés : la cure d’âme et la délivrance, en rapport avec
				le cours de Daniel Issarte ainsi que la question de l’évangélisation.
			</P3>
			<P3>
				La revue LRFT est habituellement tirée à 800 exemplaires. Il y a un peu
				moins de 450 abonnés, le reste est vendu au numéro (de grosses
				disparités sont à relever suivant les thématiques).
			</P3>
			<P3>
				Les éditions de la Mission Timothée n’ont pas publié de nouveauté en
				2023. Du côté de Calvin éditions, 2023 a été marquée par la sortie de
				l’Histoire de la Mission Timothée en mai (820 exemplaires vendus).
				Plusieurs recensions sont à signaler telles que Regards protestants
				(Sébastien Fath), Libre Sens, Souvenance anabaptiste.
			</P3>
			<T3 couleur={tools.BLEU_FONCE}>2.3. TRAVAIL MISSIONNAIRE A L’ETRANGER</T3>
			<P3>
				En préambule, nous rappelons que beaucoup d’informations sont données
				régulièrement à travers la Lettre de Nouvelles « Le Lien des
				collaborateurs ». Dans ce compte rendu, seuls certains aspects sont mis
				en avant, de façon différente selon les années et les circonstances.
			</P3>
			<T4 couleur={tools.BLEU_FONCE}>2.3.1. Travail pastoral en Bulgarie</T4>
			<P4>
				Au quotidien nous sommes confrontés à des situations difficiles où nous
				voyons les ravages du péché, de la violence, de la misère et de
				l’alcool. Mais nous y voyons d’autant plus le privilège que nous avons
				d’être auprès des malheureux. Lors du dernier lien, je v ous partageais
				nos préoccupations concernant Christian. En décembre il a voulu
				reprendre contact. Il a exprimé son désarroi de retomber dans ses vices,
				sa solitude et de perdre toute énergie. Il a accepté d’être aidé mais il
				reste très fragile. Merci de prier pour lui. Joël poursuit le travaille
				à Berkovitza. Chaque mercredi un petit groupe se rassemble. Joël
				aimerait trouver davantage de temps pour les visites personnelles. Mais
				nous sommes bien chargés par les demandes et les besoins. Nous espérons
				que le Seigneur envoie davantage d’ouvriers dans sa moisson.
			</P4>
			<T4 couleur={tools.BLEU_FONCE}>
				2.3.2. Travail pastoral à Tananarive – Madagascar
			</T4>
			<P4>
				La plupart des Malgaches sont introvertis et l’éducation ne permet pas
				(surtout aux jeunes) de s’exprimer dans leurs difficultés et leurs
				frustrations. Nous avons constaté aussi que des pasteurs n’ont pas de
				vis-à-vis pour partager leur fardeau ou tout simplement du mal à
				s’ouvrir à leur collaborateur et semble plus à l’aise à le faire au CEP.
				Le CEP aussi est sollicité pour la vie de famille (couple, relation...)
				et la difficulté de l’éducation parentale désirant une orientation
				biblique. Une rencontre aussi est donnée à des femmes âgées et seules
				(une trentaine) d’une association, une fois dans l’année (dernier mois
				de janvier).
			</P4>
			<P4>
				Pour l’écoute, nous avons 4 personnes par mois en moyenne dont des
				couples, des parents en détresse et des jeunes dans la tourmente.
				L’objectif de ce travail est de faire connaître et découvrir l’amour de
				Dieu et de Son Fils Jésus afin d’emmener un grand nombre au Salut pour
				une foi profonde et affermie. Nous avons 15 jeunes hébergés tous les
				mardis, vendredi et samedi pour nos réunions du soir ; 60 enfants en
				moyenne pour la réunion d’enfants suivi de repas avec l’accompagnement
				de 10 monitrices (une formation de moniteur d’enfants avec atelier
				pratique a été initiée) ; 30 Jeunes en moyenne pour la réunion de jeunes
				dont 4 grandes séances d’évangélisation dans l’année ; 3 interventions
				ont été réalisées suite à l’invitation de trois églises et d’association
				chrétienne. Il faut noter également un cours d’initiation à la guitare
				pour renforcer la louange. Une quarantaine de fidèles se réunissent à
				l’église le dimanche matin suivi d’un repas le midi ; 4 personnes ont
				été baptisées après leur conversion.
			</P4>
			<T4 couleur={tools.BLEU_FONCE}>
				2.3.3. Travail pastoral à Antsirabé – Madagascar
			</T4>
			<P4>
				Chaque dimanche, nous sommes une petite cinquantaine de personnes, dont
				la majorité est régulière, ce qui stabilise un peu notre groupe.
				Quelques jeunes couples s’attachent à nous mais ont encore besoin
				d’enseignement car il leur manque de la connaissance de la Parole et de
				la vie chrétienne.
			</P4>
			<P4>
				Le club d’enfants du dimanche après-midi (6-13 ans) a bien du succès,
				nous dépassons la barre des 100 enfants. Notre préoccupation est que la
				Parole puisse déjà être semée dans les cœurs. Nous continuons nos
				groupes de jeunes (14-24 ans) du samedi, prions pour que les jeunes se
				convertissent, et ne pas venir par habitude ou pour l’activité sportive
				seulement. Les deux jeunes garçons qui se sont convertis cet été au camp
				sont fidèles.
			</P4>
			<T4 couleur={tools.BLEU_FONCE}>2.3.4. Travail pastoral Guinée Conakry</T4>
			<P4>
				<i>
					« De David. Mon âme, bénis l’Éternel ! Que tout ce qui est en moi
					bénisse son saint nom. Mon âme, bénis l’Éternel, Et n’oublie aucun de
					ses bienfaits ! C’est lui qui pardonne toutes tes iniquités, Qui
					guérit toutes tes maladies ; C’est lui qui délivre ta vie de la fosse,
					Qui te couronne de bonté et de miséricorde ; C’est lui qui rassasie de
					biens ta vieillesse, Qui te fait rajeunir comme l’aigle. »
				</i>
				Psaume 103. En s’appuyant sur cette parole, les enfants du Centre
				témoignent des bienfaits de Dieu. L’Église Ésaie 58 grandit. Au début de
				cette année nous avons commencé à tenir le culte dominical à Louma, un
				village situé à 6 km du Centre. Nous vous demandons de prier pour cette
				Église en implantation.
			</P4>
			<T3 couleur={tools.BLEU_FONCE}>2.4. CHANTS/COMITE MUSIQUE</T3>
			<T4 couleur={tools.BLEU_FONCE}>2.4.1 Organisation</T4>
			<P4>
				<ul>
					<li>
						Modification des membres du Comité Musique afin d’intégrer des
						collaborateurs qui travaillent effectivement dans ce domaine. Le
						Comité Musique actuel est donc formé de : Elie Renneteau, Jérémie
						Loiret, Jonathan Nolot, Jérémie Corgne, Franck Lucas, Yannick
						Schertz, Etienne Scibetta, Jean-François Issarte.
					</li>
					<li>
						Création d’une adresse avecdescrisdejoie@gmail.com pour faciliter
						les échanges avec les chefs de chœurs et les compositeurs : Etienne
						Scibetta en a la charge.
					</li>
					<li>
						Création d’un Comité chants d’enfants pour alléger le travail du
						Comité Musique et développer ce domaine en expansion…
					</li>
				</ul>
			</P4>
			<T4 couleur={tools.BLEU_FONCE}>2.4.2 Partitions</T4>
			<P4>
				<ul>
					<li>
						Genèse du projet de révision des harmonies vocales du recueil :
						Jérémie Corgne et Elie Renneteau sont chargés de ce travail à long
						terme.
					</li>
					<li>
						Poursuite du travail de révision des harmonies instrumentales : Elie
						Renneteau pilote ce travail en sollicitant plusieurs musiciens
						(notamment Jean-Yves Peter).
					</li>
					<li>
						Comme chaque année, choix des chants à intégrer au recueil assemblée
						(B 2022) sélection des chants à essayer pendant l’année (B2023).
					</li>
					<li>Intégration de notre recueil au logiciel VideoPsalm</li>
					<li>
						Travail d’harmonisation, d’orchestration pour les multiples chœurs,
						pour les événements et nécessairement pour les nouvelles
						compositions : Elie Renneteau
					</li>
				</ul>
			</P4>
			<T4 couleur={tools.BLEU_FONCE}>2.4.3. Albums</T4>
			<P4>
				<ul>
					<li>
						Décision de solliciter les chorales régionales de l’Est et de
						l’Ouest pour soulager la chorale du Sud et pour les intégrer au
						travail d’enregistrement.
					</li>
					<li>
						Choix des chants pour les albums Avec des Cris de Joie n° 21 et Ce
						que nos pères chantaient n° 3, répétitions de chœurs et instruments,
						et enregistrements :
						<ul>
							<li>Chorale de l’Est : 17/06/2023</li>
							<li>Chœur « des 20 » : 10/11/2023</li>
							<li>Chorale de l’Ouest : 18/11/2023</li>
							<li>Chœur mixte : 12/11/2023</li>
							<li>Chorale du Sud : 02/12/2023</li>
						</ul>
					</li>
					<li>Sortie d’un album live pour Noël 2023 (environ 30 chants)</li>
					<li>
						Quelques chiffres concernant l’application :
						<ul>
							<li>Albums ACDJ 20 sur l’application : 574 achats</li>
						</ul>
					</li>
				</ul>
			</P4>
			<T4 couleur={tools.BLEU_FONCE}>2.4.4. Événements</T4>
			<P4>
				<ul>
					<li>
						Convention Chrétienne des Cévennes : Chorale et orchestre du Sud
						sollicités
					</li>
					<li>Chorales de camp : au Riffray, à Fouday et à Anduze</li>
				</ul>
			</P4>
			<Image src="https://www.dropbox.com/scl/fi/scrrvylhixniszyoosha8/4b514e2efd244d4dfe1f41b8e9319f30a2b317e6.png?rlkey=3qp386np7jxia7k6lu8m0ttlm&raw=1" />
			<T3 couleur={tools.BLEU_FONCE}>
				2.5. RAPPORT MORAL DU PRESIDENT – DANIEL TYNEVEZ
			</T3>
			<P3>
				L’année 2023 a été marquée par le décès tragique de Samuel Champault,
				puis l’arrêt de Jérémie Loiret pour épuisement. De tels chocs acculent à
				des remises en question que le rythme quotidien ne permet pas de mener
				jusqu’au bout : « Au jour du malheur, réfléchis... »
			</P3>
			<P3>
				Il est apparu avec évidence ce que beaucoup ressentaient déjà par
				moment, que l’organisation centralisée (nationale) de notre association
				– source de bénédiction pendant longtemps – est maintenant devenue trop
				lourde. La charge repose sur une poignée de collaborateurs écrasés (au
				niveau spirituel comme administratif), cela peut tendre des pièges,
				faire goulot d’étranglement, faire illusion en conduisant à une
				passivité de l’ensemble ; cela peut éloigner du sens de l’œuvre
				(l’amour) et des membres des assemblées.
			</P3>
			<P3>
				La mission, ce n’est pas une organisation mais une maison spirituelle,
				un lien fraternel, une vision qu’on a reçue, une parole qui nous a tous
				convaincu et un message d’amour qu’on doit recevoir et pratiquer là où
				on se trouve.
			</P3>
			<P3>
				Il ne s’agit pas de nier l’évidence d’une croissance et d’une
				bénédiction parmi nous. Nous ne savons d’ailleurs pas exactement comment
				orienter les choses concrètement ni à quel rythme... Mais ces coups
				d’arrêts – et les passages de l’Écriture qui nous interpellent – nous
				poussent à rechercher davantage de simplicité, à mieux répartir la
				charge, à inciter les frères à se parler et chercher ensemble les
				orientations nécessaires. Nous sommes dans la foi que des ministères
				seront alors confirmés dans l’œuvre et que la joie de servir (dont nous
				étions assez dépourvus ces derniers temps) sera renouvelée.
			</P3>
			<P3>
				<i>
					« Que la tendresse du Seigneur, notre Dieu, soit sur nous ! Affermis
					pour nous l’ouvrage de nos mains, Oui, affermis l’ouvrage de nos
					mains. »
				</i>
				(Ps. 90)
			</P3>
			<P3>
				Nous remercions chacun pour son soutien permettant de continuer l’œuvre
				que le Seigneur nous a confié.
			</P3>
			<VoteRadioBouton
				numVote="mtAg2"
				self={props.self}
				defaultPour={true}
				titre="VOTE D’APPROBATION DU RAPPORT MORAL DE L’ANNEE 2023"
			/>
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* // 3 */}
			<T2 couleur={tools.ROSE}>
				3. TROISIÈME DELIBERATION – PREÉSENTATION DES COMPTES ANNUELS 2023
			</T2>
			<p>
				Le trésorier de séance présente les comptes annuels et le bilan 2023.
				Ces documents ainsi que le rapport de gestion sont annexés à ce
				procès-verbal.
			</p>
			<T3 couleur={tools.ROSE}>3.1. BILAN</T3>
			<P3>
				Le bilan est une photographie de l’entreprise à un moment donné. Aussi
				pour l’année 2023 le bilan se ventile de la façon suivante :
			</P3>
			<Image src="https://www.dropbox.com/scl/fi/czvp5id5rrmcw7lg492lc/8a0293f89100ed39c6c2627128b5b185c065d73f.png?rlkey=vrsctwy02n7on5ec1da70e0bp&raw=1" />
			<P3>
				Nota Bene : Les fonds associatifs sont constitués d’apports,
				affectations ou excédents définitivement acquis à cet organisme.
			</P3>
			<T3 couleur={tools.ROSE}>3.2. COMPTE DE RESULTAT</T3>
			<Image src="https://www.dropbox.com/scl/fi/j40av9784jwkr04sgxr2e/4c7694cd7114835d8d091a59d841c613a30ff022.png?rlkey=28k7dxau67vlm173vqp6ytp2h&raw=1" />
			<P3>
				En 2023, les ressources globales de l’association s’élèvent à 549 295€.
				C’est le niveau le plus bas de ces 5 dernières années. Les dons
				réguliers représentent à eux seuls 401 703€ et même s’ils ont fortement
				augmenté (+41% par rapport à 2022), cette progression n’a pas suffi pour
				compenser la perte de dons exceptionnels, ou offrandes. Il s’agit des
				dons reçus des fidèles, en plus de leurs dons réguliers, et qui sont
				d’un montant élevé. Entre 2019 et 2022, les offrandes étaient en moyenne
				de 200 000€ par an. Sur l’année 2023, elles représentent 77 200€. Les
				revenus 2023 sont nettement en dessous des charges courantes de
				l’association, qui s’élèvent à 867 081€ au total.
			</P3>
			<P3>
				L’insuffisance des dons aurait pu causer de graves difficultés pour
				honorer les échéances du quotidien. Toutefois, l’association bénéficie
				depuis plusieurs années de niveaux de trésorerie particulièrement
				élevés, grâce aux offrandes et autres dons exceptionnels reçus depuis
				2020.
			</P3>
			<P3>
				Le trésorier de l’association remercie l’ensemble des membres pour leur
				engagement, le bénévolat est évalué à 657 407 sur l’année écoulée ce qui
				est remarquable.
			</P3>
			<P3>
				Pour finir, sur l’année 2023, le résultat est déficitaire pour 321 136
				euros.
			</P3>
			<T4 couleur={tools.ROSE}>3.2.1. Point sur la trésorerie</T4>
			<Image src="https://www.dropbox.com/scl/fi/htj4a12peihes2xxr8web/0c73cc39cc6cb85d484e3d7a796b17297df49d72.png?rlkey=bjcm5xdbehx0tmbhvaxn4wyhk&raw=1" />
			<P4>
				Sur le premier trimestre 2024, la hausse des dons réguliers et le
				ralentissement des investissements se confirment. Il y a suffisamment
				pour pourvoir aux besoins, même si la trésorerie n’est pas large comme
				auparavant. Cela dépend des dons, surtout des dons exceptionnels, sur
				lesquels il n’y a pas la possibilité d’intervenir.
			</P4>
			<VoteRadioBouton
				numVote="mtAg3"
				self={props.self}
				defaultPour={true}
				titre="VOTE D’APPROBATION DES COMPTES 2023 & QUITUS DONER AU CONSEIL D’ADMINISTRATION DE L’ASSOCIATION"
			/>
			<p>
				Les rapports du commissaire aux comptes sont ici communiqués, les
				comptes ont été validés sans réserve par la société audit associés
				méditerranée, société par actions simplifiée immatriculée sous le SIREN
				790233340 sis 67 route de Beziers, Saint Jean de Vedas (34430)
				représentée par Monsieur Sylain Raynal commissaire aux comptes dument
				habilité.
			</p>
			<p>
				Pour rappel, l’objet de sa mission légal, tel qu’elle est définie aux
				articles L 820-1 à L 824-16 du Code de commerce, il a la charge
				d’apporter un contrôle s’assurant que l’ensemble des éléments qui
				rentrent dans les comptes de l’association soient conformes à l’objet
				social. L’exercice de sa profession consiste en l’exercice de missions
				de contrôle légales et d’autres missions qui lui sont confiées par la
				loi ou le règlement. Sa mission s’effectue en toute transparence et
				indépendance juridique et financière. Il a aussi la responsabilité
				d’exprimer une opinion sur les comptes : pour cet exercice il s’agit
				d’une certification sans réserve.
			</p>
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* // 4 */}
			<T2 couleur={tools.VIOLET}>
				4. QUATRIÈME DELIBERATION – AFFECTATION DU DEFICIT
			</T2>
			<p>
				L'assemblée générale décide d'affecter le déficit de l'exercice clos le
				31 décembre 2023 comme suit : 321 136 € aux fonds associatifs sans droit
				de reprise.
			</p>
			<p>
				Pour indication, les fonds associatifs sans droit de reprise font
				référence aux ressources financières d’une association qui ne peuvent
				pas être récupérées par les membres une fois qu’ils ont été affectés à
				la réalisation de l’objet social.
			</p>
			<VoteRadioBouton
				numVote="mtAg4"
				self={props.self}
				defaultPour={true}
				titre="VOTE D’APPROBATION DE L’AFFECTATION DU DEFICIT "
			/>
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* // 5 */}
			<Titre2
				couleur={tools.ORANGE_CLAIR}
				texte="5. CINQUIEME DELIBERATION – BUDGET 2024"
			/>
			<p>
				Le trésorier présente le projet de budget 2024 établi par le conseil
				d’administration. Il s’agit d’un budget prévisionnel équilibré d’un
				montant de qui se répartit ainsi :
			</p>
			<Image src="https://www.dropbox.com/scl/fi/vt59is1noh102u873v9pf/f3e6d43a855c5b4bc160b9c704fbd78a131ddbf5.png?rlkey=099ggnpln6qcv27j8mo9klxok&raw=1" />
			<VoteRadioBouton
				numVote="mtAg5"
				self={props.self}
				defaultPour={true}
				titre="VOTE D’APPROBATION DU BUDGET 2024"
			/>
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* // 6 */}
			<T2 couleur={tools.BLEU_TURQUOISE}>
				6. SIXIEME DELIBERATION – RENOUVELLEMENT DU MANDAT DU COMMISSAIRE AUX
				COMPTES
			</T2>
			<p>
				Pour les six années à venir, le bureau de l’association propose de
				renouveler le mandat de commissariat aux comptes de la société AUDIT
				ASSOCIES MEDITERANNEE pour un mandat de six exercices. Pour rappelle aux
				membres, la certification des comptes par un commissaire aux comptes est
				obligatoire pour les associations percevant plus de 153 000 euros de
				dons par ans d’après l’article L 612-1 du Code de commerce. Son audit
				garantie aux membres que les fonds donnés sont utilisés conformément à
				l’objet associatif, cela garantie d’une gestion financière saine et
				transparente.
			</p>
			<VoteRadioBouton
				numVote="mtAg6"
				self={props.self}
				defaultPour={true}
				// hiddenAbstention={true}
				titre="VOTE D’APPROBATION SANS RESERVE"
			/>
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* // 7 */}
			<T2 couleur={tools.ROSE}>7. SEPTIEME DELIBERATION - FOCUS SOCIAL</T2>
			<p>
				Pour rappel, la listes des salariés de notre association est la suivante
				:
			</p>
			<TableauSalaries />
			<p>
				Le conseil d’administration propose d’embaucher à temps plein Monsieur
				Franck Lucas en qualité de pasteur pour l’Église de Montpellier et plus
				généralement pour la région du sud-ouest. Il était déjà embauché à
				mi-temps dans notre association mais avec un mi-temps à la COCEBAL
				(société exploitant la librairie Calvin), sa situation personnelle le
				contraignant à de nombreux déplacements quotidiens et les besoins
				grandissants.
			</p>
			<VoteRadioBouton
				numVote="mtAg7"
				self={props.self}
				defaultPour={true}
				titre="VOTE D’APPROBATION DE l’EMBAUCHE DE FRANCK LUCAS "
			/>
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* // 8 */}
			<T2 couleur={tools.BLEU_TURQUOISE}>
				8. HUITIEME DELIBERATION - DESIGNATION DES MEMBRES DU CONSEIL
				D’ADMINISTRATION
			</T2>
			<p>
				Le président de séance indique que le mandat des administrateurs actuels
				à une durée de 3 ans mais un tiers seulement des administrateurs est
				renouvelé chaque année (article 10.2 des statuts,).
			</p>
			<p>
				Conformément aux statuts, le conseil d'administration est l’organe de
				direction de l’association. Il représente l’association auprès des
				pouvoirs publics, il signe tout acte et représente l’association en
				justice. Il est chargé de la correspondance et rédige les procès-verbaux
				des assemblées générales ordinaires et extraordinaires et des séances du
				conseil.
			</p>
			<p>
				Les administrateurs dont le mandat arrive à échéance ont été questionné
				par le bureau sur leur souhait ou non de renouvellement, la plupart des
				administrateurs concernés souhaitent se représenter
			</p>
			<div className="table-responsive">
				<table className="table table-striped">
					<thead>
						<tr>
							<th scope="col">Nom Prénom</th>
							<th scope="col">Profession </th>
							<th scope="col"></th>
						</tr>
					</thead>
					<tbody>
						<tr key="mtMembreLoiretJeanLuc">
							<td>LOIRET Jean-Luc</td>
							<td>Électricien</td>
							<td>
								<VoteRadioBouton
									numVote="mtMembreLoiretJeanLuc"
									self={props.self}
									defaultPour={true}
									titre=""
								/>
							</td>
						</tr>
						<tr key="mtMembreMeirlanJeanLuc">
							<td>MEIRLAN Jean-Luc</td>
							<td>Agent administratif</td>
							<td>
								<VoteRadioBouton
									numVote="mtMembreMeirlanJeanLuc"
									self={props.self}
									defaultPour={true}
									titre=""
								/>
							</td>
						</tr>
						<tr key="mtMembreRicardPatrick">
							<td>Ricard Patrick</td>
							<td>Retraité</td>
							<td>
								<VoteRadioBouton
									numVote="mtMembreRicardPatrick"
									self={props.self}
									defaultPour={true}
									titre=""
								/>
							</td>
						</tr>
						<tr key="mtMembreTynevezGerard">
							<td>Tynevez Gérard</td>
							<td>Retraité</td>
							<td>
								<VoteRadioBouton
									numVote="mtMembreTynevezGerard"
									self={props.self}
									defaultPour={true}
									titre=""
								/>
							</td>
						</tr>
						<tr key="mtMembreWarnonLuc">
							<td>Warnon Luc</td>
							<td>Retraité</td>
							<td>
								<VoteRadioBouton
									numVote="mtMembreWarnonLuc"
									self={props.self}
									defaultPour={true}
									titre=""
								/>
							</td>
						</tr>
						<tr key="mtMembreWehreyJeanMartin">
							<td>Wehrey Jean-Martin</td>
							<td>Retraité</td>
							<td>
								<VoteRadioBouton
									numVote="mtMembreWehreyJeanMartin"
									self={props.self}
									defaultPour={true}
									titre=""
								/>
							</td>
						</tr>
						<tr key="mtMembreZehrSamuel">
							<td>Zehr Samuel</td>
							<td>Retraité</td>
							<td>
								<VoteRadioBouton
									numVote="mtMembreZehrSamuel"
									self={props.self}
									defaultPour={true}
									titre=""
								/>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
			{/* <VoteRadioBouton
				numVote="mtAg8"
				self={props.self}
				defaultPour={true}
				titre="Vote de la Résolution – Approbation des pouvoirs accordés pour accomplir les formalités bancaires"
			/> */}
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* // 9 */}
			<T2 couleur={tools.ORANGE}>
				9. NEUVIÈME DELIBERATION - POUVOIR POUR ACCOMPLIR LES FORMALITES
				BANCAIRES
			</T2>
			<p>
				Le conseil d’administration propose la liste suivante désignant des
				personnes habilitées pour représenter l’association auprès des
				institutions bancaires et de crédit. Ce pouvoir vaut procuration pour
				une année soit jusqu’à la prochaine assemblée générale ordinaire de
				l’association, il est ici précisé que ce pouvoir est limité aux actes
				d’administration et de conservation et non les actes de disposition qui
				seront soumis à l’approbation du conseil d’administration. Les
				engagements dépassant une somme supérieure à quinze mille euros devront
				être validé par le conseil d’administration.
			</p>
			<TableauPouvoirBanque />
			<VoteRadioBouton
				numVote="mtAg9"
				self={props.self}
				defaultPour={true}
				titre="VOTE D’APPROBATION DES POUVOIRS BANCAIRES"
			/>
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* // 10 */}
			<T2 couleur={tools.VIOLET}>
				10. DIXIÈMME DELIBERATION - POUVOIR POUR ACCOMPLIR LES FORMALITES ET
				REPRESENTER L’ASSOCIATION
			</T2>
			<p>
				Le bureau propose à l’assemblée de donner tous pouvoirs à Monsieur David
				GUTSCHER, Monsieur Benjamin DROUILLY et Monsieur Jean David GERVAIS, et
				plus généralement au porteur d’une copie ou d’un extrait du
				Procès-verbal de la présente assemblée pour accomplir les formalités
				nécessaires et représenter l’association vis à vis des tiers.
			</p>
			<p>
				Il est ici précisé que ce pouvoir est limité aux actes d’administration
				et de conservation et non les actes de disposition qui seront soumis à
				l’approbation du conseil d’administration. Les engagements dépassant une
				somme supérieure à quinze mille euros devront être validé par le conseil
				d’administration
			</p>
			<VoteRadioBouton
				numVote="mtAg10"
				self={props.self}
				defaultPour={true}
				titre="VOTE D’APPROBATION DU POUVOIR"
			/>
			<p className="pt-5 pb-5">Merci pour votre participation !</p>
			<p className="pt-5 pb-5 text-center">
				<b>
					Fait à Anduze,
					<br /> Le 3 juin 2024
				</b>
			</p>
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			<p className="pt-5"></p>
			<TableauSignature />
			<h5 className="pt-5">Liste des annexes Mission Timothée</h5>
			<FichiersATelecharger liste={FICHIER_TELECHARGER_MT} />
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
		</div>

		<div className="pt-5 pb-5">
			<div className="row">
				<div className="col-md-5 text-center pb-3">
					<button
						onClick={event => {
							event.preventDefault();
							props.self.setState(
								{
									menu: 1
								},
								() => window.scrollTo(0, 450)
							);
						}}
						className="btn btn-primary center-block pl-5 pr-5"
					>
						Précédent
					</button>
				</div>
				<div className="col-md-2"></div>
				<div className="col-md-5 text-center">
					{!props.self.state.voteOk && (
						<button
							onClick={event => {
								event.preventDefault();
								props.self.handleSubmitFormulaire();
							}}
							className="btn btn-primary center-block pl-5 pr-5"
						>
							Valider mon vote
						</button>
					)}
				</div>
			</div>
		</div>
	</>
);
