import React from "react";

const ljcDatas = {};
export const LjcContext = React.createContext(ljcDatas);

export const getNomDecode = str => {
	if (str != null) {
		const lastIndex = str.lastIndexOf("-");
		let before = str.slice(0, lastIndex);
		before = before.replaceAll("-", " ");
		before = before.replaceAll("_", "-");
		return before;
	} else {
		return null;
	}
};

export const getIdNiveau = str => {
	if (str != null) {
		const lastIndex = str.lastIndexOf("-");
		const after = str.slice(lastIndex + 1);
		return after;
	} else {
		return null;
	}
};

export const encodageUrl = str => {
	// encodage
	str = str.replaceAll("-", "_");
	str = str.replaceAll(" ", "-");
	return str;
};
