// Libraries
import React from "react";
import { withTranslation } from "react-i18next";
// Components
import Page from "../Page";
import MediaAlbumCd from "./MediaAlbumCd";
import MediaRecueil from "./MediaRecueil";
import MediaOuvrage from "./MediaOuvrage";
import MediaRevue from "./MediaRevue";
import MediaExhortation from "./MediaExhortation";
import Actualites from "../Actualites/Actualites";
import { api, tools } from "@mitim/react-mitim";
import { MediaArticleRevue } from "./MediaArticleRevue";

const getMedia = (mediaId, self, callback) => {
	fetch(`${api.timediaUrl}/public/mediaFull/${mediaId}`, {
		...api.defaultParams,
		method: "GET"
	}).then(response => {
		if (response.status !== 200) {
			console.error("Error: " + response.status);
			return;
		}
		response.json().then(json => {
			self.setState(
				{
					media: json.data.media,
					auteurs: json.data.auteurs,
					fichiers: json.data.fichiers,
					livreBibles: json.data.livreBibles,
					mediaMedias: json.data.mediaMedias,
					videoYoutubes: json.data.videoYoutubes,
					notes: json.data.notes,
					fetchReceive: true
				},
				callback
			);
		});
	});
};

class MediaFactory extends Page {
	componentDidUpdate(prevProps) {
		if (this.props.match.params.id !== prevProps.match.params.id) {
			this.loadMedia();
		}
	}

	state = {
		data: {},
		media: {},
		auteurs: [],
		fichiers: [],
		livreBibles: [],
		mediaMedias: [],
		videoYoutubes: [],
		notes: [],
		cantique: {},
		cantiqueListen: "",
		id: "0",
		rangMediaMedia: "0",
		classementMediaMedia: "0",
		fetchReceive: false
	};

	componentDidMount() {
		super.componentDidMount();
		this.loadMedia();
	}

	loadMedia() {
		getMedia(this.props.match.params.id, this, () => {
			if (this.state.media.mediaType.id === 7) {
			}
			if (
				//this.state.media.mediaType.id === 2 || // cantique
				this.state.media.mediaType.id === 16 || // Découvrir l'évangile
				this.state.media.mediaType.id === 17 || // Enseignement
				this.state.media.mediaType.id === 1 // Exhortation
			) {
				this.setState({
					cantique: {
						cds: this.state.mediaMedias.filter(
							m => m.media && m.media.mediaType.id === 8
						),
						recueils: this.state.mediaMedias.filter(
							m =>
								m.media &&
								(m.media.mediaType.id === 10 || m.media.mediaType.id === 11)
						) // TODO change to real ID
					}
				});
				let audioFichier = this.state.fichiers.filter(
					fichier =>
						fichier.fichier &&
						fichier.fichier.fichierContentType.fichierType.id === 4
				);
				if (audioFichier.length >= 1) {
					this.setState({
						cantiqueListen: audioFichier[0].fichier.sharedLink,
						id: audioFichier[0].fichier.id,
						rangMediaMedia: audioFichier[0].rang
					});
				}
			}
			if (this.state.media.mediaType.id === tools.EXHORTATIONTION) {
				let audioFichier = this.state.fichiers.filter(
					fichier =>
						fichier.fichier &&
						fichier.fichier.fichierContentType.fichierType.id === 4
				);
				if (audioFichier.length >= 1) {
					this.setState({
						cantiqueListen: audioFichier[0].fichier.sharedLink,
						id: audioFichier[0].fichier.id,
						rangMediaMedia: audioFichier[0].rang
					});
				}
			}
		});
	}

	render() {
		const { t } = this.props;
		return (
			<>
				{this.state.fetchReceive && (
					<>
						{/* {this.state.media.mediaType.id === 2 && (
							<MediaCantique idMedia={this.props.match.params.id} app={this} />
						)} */}
						{/* {this.state.media.mediaType.id === 8 && (
							<MediaAlbumCd idMedia={this.props.match.params.id} app={this} />
						)} */}

						{this.state.media.mediaType.id === tools.OUVRAGE && ( // ouvrage
							<MediaOuvrage
								idMedia={this.props.match.params.id}
								app={this}
								couleur={tools.COULEUR_LIVRE}
							/>
						)}
						{this.state.media.mediaType.id === tools.REVUE && ( // revue
							<MediaRevue
								idMedia={this.props.match.params.id}
								app={this}
								couleur={tools.ORCOULEUR_REVUE}
							/>
						)}
						{this.state.media.mediaType.id === tools.EXHORTATION && ( // exhortation
							<MediaExhortation
								idMedia={this.props.match.params.id}
								app={this}
								couleur={tools.COULEUR_EXHORTATION}
							/>
						)}
						{this.state.media.mediaType.id === 16 && ( // exhortation
							<MediaExhortation
								idMedia={this.props.match.params.id}
								app={this}
								couleur={tools.COULEUR_EXHORTATION}
							/>
						)}
						{this.state.media.mediaType.id === 7 && ( // article de revue
							<MediaArticleRevue
								idMedia={this.props.match.params.id}
								app={this}
								couleur={tools.COULEUR_EXHORTATION}
							/>
						)}
						{this.state.media.mediaType.id === 17 && ( // exhortation
							<MediaExhortation
								idMedia={this.props.match.params.id}
								app={this}
								couleur={tools.COULEUR_EXHORTATION}
							/>
						)}
						{this.state.media.mediaType.id === tools.TEMOIGNAGE && ( // exhortation
							<MediaExhortation
								idMedia={this.props.match.params.id}
								app={this}
								couleur={tools.COULEUR_TEMOIGNAGE}
							/>
						)}
						{this.state.media.mediaType.id === tools.ACTUALITES && ( // actualites
							<Actualites idMedia={this.props.match.params.id} app={this} />
						)}
					</>
				)}
			</>
		);
	}
}

export default withTranslation()(MediaFactory);
