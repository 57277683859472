import React from "react";

export const SIGNATURE = [
	{
		distinction: "Le Président",
		nom: "ISSARTE",
		prenom: "Daniel",
		signature:
			"https://www.dropbox.com/s/kcjxegiobfkxpne/444ffcfc268b6eabe82f171f90e69f7906e29160.jpg?raw=1"
	},
	{
		distinction: "Le Coordinateur",
		nom: "LOIRET",
		prenom: "Jérémie",
		signature:
			"https://www.dropbox.com/s/vd2xvc6or6uoo6f/ae851991e82f3f5e9abbdf9a026846d9d87c580f.jpg?raw=1"
	},
	{
		distinction: "Le Secrétaire de séance",
		nom: "GUTSCHER",
		prenom: "David",
		signature:
			"https://www.dropbox.com/s/mh0s4xg93sqpygf/e8f953ba9dfa29f9afafa881e176012701426a46.png?raw=1"
	}
];

export const MEMBRES_MT = [
	{
		nom: "AMAN",
		prenom: "Richard",
		profession: "Médecin",
		adresse: "31 route du Col de l’Izoard, 05100 BRIANCON"
	},
	{
		nom: "BERESSNEVITCH",
		prenom: "Michel",
		profession: "Retraité",
		adresse: "Rue Jean PELERIN - 54425 PULNOY"
	},
	{
		nom: "BRANCATO",
		prenom: "Calogero",
		profession: "Educateur spécialisé",
		adresse: "Rue de l’Europe, 296142 LEFERNES (Belgique)"
	},
	{
		nom: "BOPP",
		prenom: "Hans Jurg",
		profession: "Médecin",
		adresse: "283 chemin de Laucire 30140 TORNAC"
	},
	{
		nom: "CARRA",
		prenom: "Julien",
		profession: "Ingénieur",
		adresse: "Route de Marchissy 31261 Le VAUD (Suisse)"
	},
	{
		nom: "CHAMPAULT",
		prenom: "Samuel",
		profession: "Comptable",
		adresse: "47 avenue du mondial de Rugby 34070 MONTPELLIER"
	},
	{
		nom: "CZERMAK",
		prenom: "Bernard",
		profession: "Retraité",
		adresse: "15 route champ du Feu 67130 WALDERSBACH"
	},
	{
		nom: "DROUILLY",
		prenom: "Benjamin",
		profession: "Pasteur",
		adresse: "121 rue du général de Gaulle 78740 VAUX SUR SEINE"
	},
	{
		nom: "DUFEY",
		prenom: "Pierre Alain",
		profession: "Retraité",
		adresse: "Impasse du Vanil, 21726 FARVAGNY LE GRAND (Suisse)"
	},
	{
		nom: "DYE",
		prenom: "Jean Louis",
		profession: "Pasteur",
		adresse: "6595 chemin des arêtes 38590 SAINT MICHEL DE SAINT GEOIRS"
	},
	{
		nom: "FEHLMANN",
		prenom: "Andréas",
		profession: "Gérant de Société",
		adresse: "Grand Rue CH-1261 LE VAUD"
	},
	{
		nom: "FEHLMANN",
		prenom: "Matthias",
		profession: "Secrétaire",
		adresse: "1654 chemin de Recoulin 30140 ANDUZE "
	},
	{
		nom: "GERSCHHEIMER",
		prenom: "Michaël",
		profession: "Médecin",
		adresse: "2430 route de Saint Jean du Gard 30140 ANDUZE"
	},
	{
		nom: "GERVAIS",
		prenom: "Jean David",
		profession: "Pasteur",
		adresse: "17 rue Jacques Hoton 1200 BRUXELLES (Belgique)"
	},
	{
		nom: "GUTSCHER",
		prenom: "David",
		profession: "Pasteur",
		adresse: "25 rue des deux ponts 49300 CHOLET"
	},
	{
		nom: "HAUSER",
		prenom: "Marc",
		profession: "Pasteur",
		adresse: "Mas Elim 30140 GENERARGUES"
	},
	{
		nom: "HEGNAUER",
		prenom: "Ulrich",
		profession: "Menuisier",
		adresse: "Mas Pélissou 30140 TORNAC"
	},
	{
		nom: "ISSARTE",
		prenom: "Daniel",
		profession: "Retraité",
		adresse: "Mas Pélissou 30140 TORNAC"
	},
	{
		nom: "KREMER",
		prenom: "Pierre Yves",
		profession: "VRP",
		adresse: "26 rue de Lorraine 57220 VELVING"
	},
	{
		nom: "LOIRET",
		prenom: "Daniel",
		profession: "Retraité",
		adresse: "La Houssais 44130 BLAIN"
	},
	{
		nom: "LOIRET",
		prenom: "Jérémie",
		profession: "Coordinateur",
		adresse: "283 chemin de Laucire 30140 TORNAC"
	},
	{
		nom: "LOIRET",
		prenom: "Jean Luc",
		profession: "Electricien",
		adresse: "Les Triailles 30140 GENERARGUES"
	},
	{
		nom: "MEIRLAN",
		prenom: "Jean Luc",
		profession: "Ingénieur",
		adresse: "17 rue Jacques Hoton 1200 BRUXELLES (Belgique)"
	},
	{
		nom: "MORLET",
		prenom: "Gilles",
		profession: "Médecin",
		adresse: "La Perrotière 73170 SAINT JEAN DE CHEVELU"
	},
	{
		nom: "RENNETEAU",
		prenom: "Elie",
		profession: "Pasteur",
		adresse: "Mas Elim 30140 GENERARGUES"
	},
	{
		nom: "RICARD",
		prenom: "Patrick",
		profession: "DRH",
		adresse: "190 rue Neuve 60190 MOYVILLERS"
	},
	{
		nom: "TRUMPFF",
		prenom: "Martin",
		profession: "Pasteur",
		adresse: "309 route de Gaujac – Appt 23 30140 BOISSET ET GAUJAC"
	},
	{
		nom: "TYNEVEZ",
		prenom: "Gérard",
		profession: "Retraité",
		adresse: "Le Riffray 35150 CHANTELOUP"
	},
	{
		nom: "WARNON",
		prenom: "Luc",
		profession: "Retraité",
		adresse: "115 rue du Mas de Balan 30000 Nïmes"
	},
	{
		nom: "WEHREY",
		prenom: "Jean Martin",
		profession: "Retraité",
		adresse: "9 rue Bolzen 67120 KOLBSHEIM"
	},
	{
		nom: "ZEHR",
		prenom: "Samuel",
		profession: "Agriculteur",
		adresse: "Ferme Sarrelfing 57400 SARREBOURG"
	}
];

// ###############################################################################################################################################
// ###############################################################################################################################################
// ###############################################################################################################################################

export const MEMBRES_TAJ = [
	{
		nom: "AMBERG",
		prenom: "Arnaud",
		profession: "Intendant",
		adresse: "62 rue principale 67130 FOUDAY"
	},
	{
		nom: "BERNARD",
		prenom: "François",
		profession: "Pasteur",
		adresse: "9 rue Bolzen 67120 KOLBSHEIM"
	},
	{
		nom: "COATANHAY",
		prenom: "Eliane",
		profession: "Mère au Foyer",
		adresse: "446 les hauts de Labahou 30140 ANDUZE"
	},
	{
		nom: "COLIN",
		prenom: "Agathe",
		profession: "Mère au foyer",
		adresse: "7 rue du Luxembourg 30140 ANDUZE"
	},
	{
		nom: "CHAMPAULT",
		prenom: "Samuel",
		profession: "Comptable",
		adresse: "47 avenue du mondial de Rugby 34070 MONTPELLIER"
	},
	{
		nom: "DROUILLY",
		prenom: "Jérémie",
		profession: "Intendant",
		adresse: "15 chemin les hauts de Labahou 30140 ANDUZE"
	},
	{
		nom: "DYE",
		prenom: "Sophie",
		profession: "Professeur d’anglais",
		adresse: "6595 chemin des arêtes 38590 SAINT MICHEL DE SAINT GEOIRS"
	},
	{
		nom: "FEHLMANN",
		prenom: "Matthias",
		profession: "Secrétaire",
		adresse: "1645 chemine de Recoulin 30140 ANDUZE"
	},
	{
		nom: "FEHLMANN",
		prenom: "Andréas",
		profession: "Gérant de Société",
		adresse: "Grand Rue CH-1261 LE VAUD"
	},
	{
		nom: "GERSCHHEIMER",
		prenom: "Ernest",
		profession: "Retraité",
		adresse: "Le Viala Le Château 30140 GENERARGUES"
	},
	{
		nom: "GASSER",
		prenom: "Joël",
		profession: "Enseignant",
		adresse: "17 impasse des Palettes 63740 GELLES"
	},
	{
		nom: "GUTSCHER",
		prenom: "Audrey",
		profession: "Mère au foyer",
		adresse: "25 rue des deux ponts 49300 CHOLET"
	},
	{
		nom: "GUTSCHER",
		prenom: "David",
		profession: "Pasteur",
		adresse: "25 rue des deux ponts 49300 CHOLET"
	},
	{
		nom: "ISSARTE",
		prenom: "David",
		profession: "Exploitant de Camping",
		adresse: "1105 Chemin de Recoulin 30140 ANDUZE"
	},
	{
		nom: "ISSARTE",
		prenom: "Daniel",
		profession: "Retraité",
		adresse: "Mas Pélissou 30140 TORNAC"
	},
	{
		nom: "JAUSSAUD",
		prenom: "Guilhem",
		profession: "Pasteur",
		adresse: "1645 chemine de Recoulin 30140 ANDUZE"
	},
	{
		nom: "LOIRET",
		prenom: "Jérémie",
		profession: "Coordinateur",
		adresse: "Mas Pélissou 30140 TORNAC"
	},
	{
		nom: "LOIRET",
		prenom: "Priscille",
		profession: "Mère au foyer",
		adresse: "283 chemin de Laucire 30140 TORNAC"
	},
	{
		nom: "LOCONTE",
		prenom: "Michaël",
		profession: "Technicien hospitalier",
		adresse: "1 rue du Grand Curtil 38080 FOUR"
	},
	{
		nom: "MEBARKI",
		prenom: "Lise Marie",
		profession: "Mère au foyer",
		adresse: "446 les hauts de Labahou 30140 ANDUZE"
	},
	{
		nom: "MEBARKI",
		prenom: "Mehdi",
		profession: "VRP",
		adresse: "446 les hauts de Labahou 30140 ANDUZE"
	},
	{
		nom: "N’ZUE",
		prenom: "Pawlow",
		profession: "VRP",
		adresse: "Mas Pélissou 30140 TORNAC"
	},
	{
		nom: "OZIL",
		prenom: "Stéphane",
		profession: "Dirigeant de Société",
		adresse: "Chemin du mas paulet 30140 ANDUZE"
	},
	{
		nom: "PRADAL",
		prenom: "Guy",
		profession: "Infirmier",
		adresse: "16 rue Cuvier 25000 BESANCON"
	},
	{
		nom: "RENAUD",
		prenom: "Marianne",
		profession: "Enseignante",
		adresse: "344 chemin de l’Olivier 30140 ANDUZE"
	},
	{
		nom: "RICARD",
		prenom: "Michèle",
		profession: "Enseignante",
		adresse: "190 rue Neuve 60190 MOYVILLERS"
	},
	{
		nom: "RICARD",
		prenom: "Luc",
		profession: "Médecin",
		adresse: "150 impasses rouviérettes 30140 BAGARD"
	},
	{
		nom: "SAGOLS",
		prenom: "Emmanuel",
		profession: "Intendant",
		adresse: "131 rue Ferrari 13005 MARSEILLES"
	},
	{
		nom: "SCHERTZ",
		prenom: "Jonathan",
		profession: "Plombier",
		adresse: "1645 chemine de Recoulin 30140 ANDUZE"
	},
	{
		nom: "TYNEVEZ",
		prenom: "Céline",
		profession: "Intendante",
		adresse: "Le Riffray 35150 CHANTELOUP"
	},
	{
		nom: "TYNEVEZ",
		prenom: "Daniel",
		profession: "Pasteur",
		adresse: "Le Riffray 35150 CHANTELOUP"
	},
	{
		nom: "ZEHR",
		prenom: "Joseph",
		profession: "Médecin",
		adresse: "Aspères 30140 TORNAC"
	},
	{
		nom: "ZEHR",
		prenom: "Stéphane",
		profession: "Pasteur",
		adresse: "446 les Hauts de Labahou 30140 ANDUZE"
	}
];

// ###############################################################################################################################################
// ###############################################################################################################################################
// ###############################################################################################################################################

export const TABLEAU_OUVRAGES = [
	{
		titre: "DE L'EXIL A L'ESPERANCE ",
		parution: "2018",
		totalVentesTirage: "612 / 1500",
		ventes2018: "578"
	},
	{
		titre: "CHAIR ET L'ESPRIT NOUVELLE EDITION (LA)",
		parution: "2018",
		totalVentesTirage: "101 / 150",
		ventes2018: "47"
	},
	{
		titre: "DANGERS DE L'HOMEOPATHIE SUIVI DE : LA SOPHROLOGIE",
		parution: "2018",
		totalVentesTirage: "389 / 2000",
		ventes2018: "336"
	},
	{
		titre: "MES YEUX LE VERRONT ET NON CEUX D'UN AUTRE",
		parution: "2017",
		totalVentesTirage: "456 / 800",
		ventes2018: "42"
	},
	{
		titre: "PARLE SEIGNEUR TON SERVITEUR ECOUTE",
		parution: "2017",
		totalVentesTirage: "1006 / 1500",
		ventes2018: "500"
	},
	{
		titre: "COMMENTAIRE D'ESAIE - VOLUME 2 /",
		parution: "2017",
		totalVentesTirage: "419 / 800",
		ventes2018: "140"
	},
	{
		titre: "SALUT NE VIENT PAS D'HITLER (LE)",
		parution: "2016",
		totalVentesTirage: "1235 / 1500",
		ventes2018: "248"
	},
	{
		titre: "PUIS ILS POSERENT LEUR SAC...",
		parution: "2015",
		totalVentesTirage: "654 / 2500",
		ventes2018: "37"
	},
	{
		titre: "MES PENSEES NE SONT PAS VOS PENSEES",
		parution: "2015",
		totalVentesTirage: "Epuisé (tiré à 600ex)",
		ventes2018: "30"
	},
	{
		titre: "CARNET DE ROUTE",
		parution: "2014",
		totalVentesTirage: "479 / 600",
		ventes2018: "23"
	},
	{
		titre: "ERRANCES - VOLUME 2",
		parution: "2014",
		totalVentesTirage: "1431 / 2500",
		ventes2018: "125"
	},
	{
		titre: "COMMENTAIRE D'ESAIE - VOLUME 1 /",
		parution: "2014",
		totalVentesTirage: "927 / 1300",
		ventes2018: "75"
	},
	{
		titre: "SAGESSE DES PROVERBES AU QUOTIDIEN (LA)",
		parution: "2013",
		totalVentesTirage: "2234 / 3000",
		ventes2018: "126"
	},
	{
		titre: "ERRANCES - VOLUME 1",
		parution: "2012",
		totalVentesTirage: "1321 / 1500",
		ventes2018: "108"
	},
	{
		titre: "VOYAGE AU BOUT DE MA NUIT",
		parution: "",
		totalVentesTirage: "641 / 1500",
		ventes2018: "83"
	},
	{
		titre: "FEMME AU SEIN DE LA CREATION ET SA PLACE DANS L'EGLISE",
		parution: "",
		totalVentesTirage: "épuisé",
		ventes2018: ""
	}
];

// ###############################################################################################################################################
// ###############################################################################################################################################
// ###############################################################################################################################################

export const FICHIER_TELECHARGER_MT = [
	{
		nom: "Rapport de gestion ",
		lien:
			"https://www.dropbox.com/s/4tl824mce68rlde/131e1b1212fc56bc765f92c68fd88a2b7755aa75.pdf?raw=1"
	},
	{
		nom: "Rapports du commissaire aux comptes ",
		lien:
			"https://www.dropbox.com/s/rlc06433u5rqbyh/d935ae115a097a568982fc90dc2658b5612449a7.pdf?raw=1"
	},
	{
		nom: "Budget prévisionnel",
		lien:
			"https://www.dropbox.com/s/jcyw6lapzvd9ylq/a9fe68352c2528a482f55060abc08963b28498d1.pdf?raw=1"
	},
	{
		nom: "PV de l'année 2020",
		lien:
			"https://www.dropbox.com/s/n2cdhkienwra74i/dcdb73341084086d5a5827052d94df43e0515442.pdf?raw=1"
	}
];
export const FICHIER_TELECHARGER_TAJ = [
	{
		nom: "Bilan",
		lien:
			"https://www.dropbox.com/s/ifzhgwdzh5g0h2n/36c851642cc4735766a571c22c9e593da46c9256.pdf?raw=1"
	},
	{
		nom: "Rapports du commissaire aux comptes",
		lien:
			"https://www.dropbox.com/s/0yo1hc20pwx4a6c/c12a43f3d842a61e858770c91ae4d0f31e80cd00.pdf?raw=1"
	},
	{
		nom: "Budget prévisionnel détaillé",
		lien:
			"https://www.dropbox.com/s/f2yt27nsl82clou/585eccd1fd11f155f3f785f6ac6720456bd9f54d.pdf?raw=1"
	},
	{
		nom: "PV de l'année 2020",
		lien:
			"https://www.dropbox.com/s/759p6v643lb11fy/6ff521ea67c615942fcc929b758a18bc92ff4657.pdf?raw=1"
	}
];
export const FichiersATelecharger = props => (
	<div className="pt-3">
		<ul className="liste">
			{props.liste.map(elem => (
				<li key={elem.lien}>
					<a href={elem.lien} target="_blank" className="lien">
						{elem.nom}
					</a>
				</li>
			))}
		</ul>
	</div>
);
