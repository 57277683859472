import React from "react";
import { MonCompte } from "./MonCompte";

import { Storage, api } from "@mitim/react-mitim";
import "./Utilisateur.scss";
import Modal from "react-bootstrap/Modal";

export class Utilisateur extends React.Component {
	state = {
		// formulaireDonSubmited: false,
		messageEntete: "Gérer votre compte",
		reloadPage: false,
		elements: {
			civilite: "M.",
			nom: "",
			prenom: "",
			montant: 0,
			montantAbonnement: 0,
			adressePostale: "",
			ville: "",
			pays: "",
			codePostal: "",
			tel: "",
			email: "",
			nombreRevues: 0,
			eglise: 0,
			livraisonDomicile: false,
			recevoirDerniereRevue: true
		},
		showModal: false,
		messageModal: "",
		tabFormulaire: 1
	};

	componentDidMount() {
		//this.getAbonne();
		this.isRevueDejaCommande();
		const tf = sessionStorage.getItem("tabFormulaire");
		const user = Storage.getUserParsed();

		user &&
			this.setState({
				elements: {
					...this.state.elements,
					["user"]: user.id
				}
			});
		if (tf) {
			parseInt(tf, 10) === 4 &&
				this.setState({
					tabFormulaire: 4
				});
		}
	}

	isRevueDejaCommande() {
		const user = Storage.getUserParsed();

		const tokenPublic = "cHVibGljOjk4SE85SE9JVUdPSTk4SA==";
		const defaultHeaders = {
			Accept: "application/json",
			"Content-Type": "application/json",
			Authorization: "Basic " + tokenPublic
		};

		if (user) {
			fetch(`${api.timediaUrl}/public/isRevueDejaCommande/${user.id}`, {
				method: "GET",
				headers: defaultHeaders
			})
				.then(response => {
					if (response.status !== 200) {
						console.log("Error: " + response.status);
						return;
					}
					response.json().then(data => {
						this.setState(
							{
								revueDejaCommande: data.data,
								elements: {
									...this.state.elements,
									["recevoirDerniereRevue"]: !data.data
								}
							},
							() => console.table(this.state.elements)
						);
					});
				})
				.catch(err => {
					console.log(err);
				});
		}
	}

	tabAbonnement() {
		const Tab = props => (
			<li className="nav-item">
				<div
					className={props.active === 1 ? "nav-link active" : "nav-link"}
					style={{ cursor: "pointer" }}
					onClick={() =>
						props.locked
							? null
							: this.setState(
									{ tabFormulaire: props.num },
									sessionStorage.setItem("tabFormulaire", props.num)
							  )
					}
				>
					{props.nom}
				</div>
			</li>
		);
		return (
			<ul className="nav nav-tabs">
				<Tab
					active={this.state.tabFormulaire === 1 ? 1 : 0}
					nom="Infomations personnelles"
					num={1}
					locked={false}
				/>
				{/* <Tab
					active={this.state.tabFormulaire === 2 ? 1 : 0}
					nom="Informations abonnement revue"
					num={2}
					locked={false}
				/>
				<Tab
					active={this.state.tabFormulaire === 3 ? 1 : 0}
					nom="Achats albums"
					num={3}
					locked={false}
				/>
				<Tab
					active={this.state.tabFormulaire === 4 ? 1 : 0}
					nom="Carte bancaire"
					num={4}
					locked={false}
				/> */}
			</ul>
		);
	}

	affichageLivraison = () => {
		console.table(this.state.elements);

		if (sessionStorage.getItem("livraisonDomicile") === "true") {
			const nombreRevues = sessionStorage.getItem("nombreRevues");
			const fraisDePorts = nombreRevues * 4;
			return `${fraisDePorts}€ (4€ x ${nombreRevues} revues)`;
		} else {
			return "gratuit";
		}
	};

	contenuFormulaire() {
		switch (this.state.tabFormulaire) {
			case 1:
				// return <></>;
				return <MonCompte app={this} />;
			case 2:
				return <></>;
			case 3:
				return <></>;
			case 4:
				return <></>;
			default:
				return <></>;
		}
	}

	render() {
		const BoiteDialogue = () => (
			<Modal
				show={this.state.showModal}
				onHide={() =>
					this.setState({ showModal: false }, () =>
						this.props.reloadPage ? null : window.location.reload()
					)
				}
			>
				<Modal.Body>
					<button
						type="button"
						class="close"
						data-dismiss="modal"
						aria-label="Close"
						onClick={() =>
							this.setState({ showModal: false }, () =>
								this.props.reloadPage ? null : window.location.reload()
							)
						}
					>
						<span aria-hidden="true">&times;</span>
					</button>
					<h4 className="text-center">{this.state.messageModal}</h4>
				</Modal.Body>
			</Modal>
		);
		return (
			<>
				<div className="utilisateur pb-5 pt-5">
					<BoiteDialogue />
					<div className="card">
						<div className="card-header">{this.state.messageEntete}</div>
						<div className="card-body">
							{this.tabAbonnement()}
							<div className="pt-4 pb-4" style={{ minHeight: "400px" }}>
								{this.contenuFormulaire()}
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}
