import React, { useState } from "react";
import { Link } from "react-router-dom";
import isExternal from "is-url-external";
import {
	ModuleEncartMtCustom,
	ModuleEncartListMt,
	ModuleCard,
	tools,
	api,
	Icones
} from "@mitim/react-mitim";
import { Bouton } from "./Bouton";
import { composeInitialProps } from "react-i18next";

const Titre = props => (
	<>
		<div className="pt-5">
			<Bouton
				texte="ÉCOUTER LE LIVRE"
				lien={props.lien}
				couleur={props.couleurBouton}
			/>
		</div>
	</>
);

export const BlocLectureLivre = props => {
	const [couleurSoundcloud, setCouleurSoundcloud] = useState(0);
	const [couleurItunes, setCouleurItunes] = useState(1);
	return (
		<>
			<div className="container">
				{tools.titreSousTitre(props.titre, props.sousTitre)}
				<p className="text-center pb-5">
					{props.description}
					{isExternal(props.lienLivre) ? (
						<a
							href={props.lienLivre || ""}
							target="_blank"
							// style={{ textDecoration: 'none' }}
							className="lien"
						>
							{" " + props.nomLienLivre}
						</a>
					) : (
						<Link className="lien" to={props.lienLivre}>
							{" " + props.nomLienLivre}
						</Link>
					)}
					.
				</p>
			</div>
			<div
				className="container-fluid pb-lg-5 no-gutters"
				style={{ minHeight: "480px", backgroundColor: "#e2e3e4" }}
			>
				<div style={{ display: "block", position: "relative" }}>
					<div
						className="mt-BlockLecteurAudio-banner"
						style={{
							backgroundImage: `url(${props.imageBackground})`,
							filter: "brightness(100%)",
							backgroundColor: "#000"
						}}
					>
						<div className="row" style={{ zIndex: "2" }}>
							<div className="col-md-12">
								<div className="text-center">
									<br />
									<br />
									{/* Grand écran */}
									<div className="d-none d-xl-block">
										<div className="pt-5">
											<Titre
												lien={props.lien}
												couleurBouton={props.couleurBouton}
											/>
										</div>
									</div>

									{/* Mobile */}
									<div className="d-block d-xl-none">
										<div className="pt-5">
											<Titre
												lien={props.lien}
												couleurBouton={props.couleurBouton}
											/>
										</div>
									</div>

									<div className="pt-5 pb-2"></div>

									{props.lienSoundcloud && (
										<a
											href={props.lienSoundcloud}
											target="_blank"
											onMouseEnter={() =>
												setCouleurSoundcloud(tools.VERT_FONCE)
											}
											onMouseLeave={() => setCouleurSoundcloud("#fff")}
										>
											<Icones.soundCloud
												width={50}
												couleur={couleurSoundcloud}
											/>
										</a>
									)}
									<span className="ml-2"></span>

									{props.lienItunes && (
										<a
											href={props.lienItunes}
											target="_blank"
											onMouseEnter={() => setCouleurItunes(tools.ORANGE_CLAIR)}
											onMouseLeave={() => setCouleurItunes("#fff")}
										>
											<Icones.music width={50} couleur={couleurItunes} />
										</a>
									)}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};
