import React from "react";
import Page from "../Page";
import { withTranslation } from "react-i18next";
import "./Actualites.scss";
import { tools, api, ModuleCardActu, ModuleCard } from "@mitim/react-mitim";
import Header from "../Header/Header";
import { getKeyValuePublique } from "../Constants";
import { getGetActusPage } from "../Home/Home";

const BLEU_FONCE = "#7cb4d7";
const BLEU_TURQUOISE = "#009d96";
const ORANGE = "#e46644";
const JAUNE = "#e3af56";

class Actualites extends Page {
	constructor(props) {
		super(props);
	}

	state = {
		elementsActualites: [],
		actus: []
	};

	componentDidMount() {
		super.componentDidMount();
		getKeyValuePublique("site-mission-actualites", val => {
			this.setState({
				elementsActualites: val
			});
		});
		getGetActusPage(1984, this, () => {});
	}

	render() {
		const { t } = this.props;
		return (
			<>
				<div
					className="container-fluid pb-lg-5 no-gutters pt-3"
					style={{ minHeight: "480px", backgroundColor: "#d1d3d4" }}
				>
					<div className="container">
						{tools.titreSousTitre("ACTUALITÉS", "")}
						{/* <ModuleCard elements={this.state.elementsActualites} /> */}
						<ModuleCard elements={this.state.actus} />
					</div>
				</div>
			</>
		);
	}
}

export default withTranslation()(Actualites);
