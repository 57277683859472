import React from "react";
import { withTranslation } from "react-i18next";
import { tools, api } from "@mitim/react-mitim";
import mtIconeCarteFrance from "../../resources/icons/carteFrance.svg";
import ImageMapper from "react-image-mapper";
import { Redirect } from "react-router-dom";
import Page from "../Page";

const EncartAngleDroits = props => (
	<div className="col-xl-6 col-lg-6">
		<div className="bg-white mt-3">
			<div
				style={{ backgroundColor: props.data.couleur }}
				className="p-3 text-center text-white titreEncart"
			>
				{props.data.titre}
			</div>
			<div className="p-5">{props.data.contenu}</div>
		</div>
	</div>
);

const LienLi = props => (
	<li>
		<a className="text-noir" target="_blank" href={props.url}>
			{props.contenu}
		</a>
	</li>
);

const TitreBloc = props => <h5 className="text-noir pt-4">{props.val}</h5>;

class EnseignementEnfants extends Page {
	render() {
		const data = this.props.data;
		const { t } = this.props;

		return (
			<>
				<div className="container">
					{tools.titreSousTitre(
						"ENSEIGNEMENT DES ENFANTS",
						"Une aide pour les moniteurs"
					)}

					<p>
						Bien chers amis !<br />
						Voilà la suite de l’enseignement pour les enfants en 1re année.
						Quelques modifications apparaissent suite à diverses suggestions :
					</p>
					<ul className="liste pt-4 pb-4">
						<li>
							Un cadre a été ajouté sur la page du dessin afin d’y inscrire le
							verset que vous choisissez.
						</li>
						<li>
							Nous avons ajouté de nombreuses références bibliques pour vous
							guider. Nous avons supprimé « contexte historique » pour ne
							laisser que « contexte » qui est plus juste.
						</li>
						<li>
							La fiche moniteur est là comme une « caisse à outils » pour vous
							aider dans votre préparation de réunion et non comme un produit
							prêt à consommer. La préparation d’une réunion implique un travail
							personnel, du temps passé à méditer et à prier. Ceci est vrai pour
							n’importe quelle réunion.
						</li>
						<li>
							Dans la rubrique « éléments pouvant être abordés », nous avons mis
							en premier le sujet principal, le reste est là pour enrichir le
							contenu de votre réunion.
						</li>
					</ul>
					<p>
						Certains sont un peu perdu dans le découpage AT et NT et la
						succession des leçons. Afin de vous éclairer, nous vous enverrons le
						programme des 4 ans qui balaie l’ensemble de la bible.
					</p>
					<h5 className="text-noir pt-4">Remarques :</h5>
					<p>
						Dans beaucoup d’églises, quand un livre du NT est terminé, on
						commence un livre dans l’ancien. Beaucoup de plans de lecture de la
						bible alternent des textes de l’ancien et du nouveau testament. Même
						les livres de la bible ne sont pas tous dans l’ordre chronologique.
						Pour ma part pendant des années j’ai lu 2 chapitres dans l’AT et 2
						dans le NT chaque jour et cela ne pose pas de problème. J’écris cela
						afin de répondre aux inquiétudes de certains. L’important est que le
						moniteur replace bien la leçon dans son contexte historique.
					</p>
					<p>
						Nous sommes bien conscients que ce programme est en construction et
						nécessite de nombreuses améliorations. C’est pour cela que nous
						comptons sur vos remarques constructives. Pour toutes questions ou
						suggestions, vous pouvez me joindre par mail (guy.pradal@free.fr) je
						centralise les demandes et coordonne le travail. Bien
						fraternellement,
						<br />
						<br />
						L’équipe « Tim-école du dimanche »
					</p>
					<p className="pb-5">
						Vous trouverez ci-dessous le nouveau programme pour l’enseignement
						des enfants de 6 à 11 ans, ainsi que des conseils de préparation.
					</p>
				</div>
				<div
					className="container-fluid bg-gris1 no-gutters"
					style={{ minHeight: "80px" }}
				>
					<div className="container">
						<div className="row pt-5 pb-5">
							<EncartAngleDroits
								data={{
									couleur: "#b11f27",
									titre: "3 À 4 ANS",
									contenu: (
										<>
											<TitreBloc val="Cahier 1" />
											<ul className="liste rouge">
												<LienLi
													contenu="Récits de l’Évangile (Fiches moniteur)"
													url="https://www.dropbox.com/s/tw201h3llg18iuq/050cfee382a45024120d1f01dffe5da50f7c2a30.pdf?raw=1"
												/>
												<LienLi
													contenu="Récits de l’Évangile (Cahier)"
													url="https://www.dropbox.com/s/thk3cx2m1c7bggn/0a485538c392c70872a404618c27d342c08f62aa.pdf?raw=1"
												/>
											</ul>
											<TitreBloc val="Cahier 2" />
											<ul className="liste rouge">
												<LienLi
													contenu="Récits de l’Ancien Testament (Fiches moniteur)"
													url="https://www.dropbox.com/s/t29ms7jvvtphvxq/44eaafc757dba6394ab5a10eb09dbbde427ceb06.pdf?raw=1"
												/>
												<LienLi
													contenu="Récits de l’Ancien Testament (Cahier)"
													url="https://www.dropbox.com/s/insssi09y0eyiwl/e16cb231b950639f01b8a4715247102b347a8af2.pdf?raw=1"
												/>
											</ul>
											{/* <ul className="liste rouge">
												<LienLi contenu="Images coloriées" url="https://" />
											</ul> */}
											<p>
												<br />
												<br />
												<br />
												<br />
												<br />
												<br />
												<br />
											</p>
										</>
									)
								}}
							/>
							<EncartAngleDroits
								data={{
									couleur: "#e46644",
									titre: "5 À 6 ANS",
									contenu: (
										<>
											<TitreBloc val="Cahier 1 (Genèse - Exode)" />
											<ul className="liste orange">
												<LienLi
													contenu="De la Création à l’Exode (Fiches moniteur)"
													url="https://www.dropbox.com/s/o2c8vwzva7xcqym/20eabbb6da44dc2a1d35d2c8f1810c685ba1623d.pdf?raw=1"
												/>
												<LienLi
													contenu="De la Création à l’Exode (Cahier)"
													url="https://www.dropbox.com/s/o2c8vwzva7xcqym/20eabbb6da44dc2a1d35d2c8f1810c685ba1623d.pdf?raw=1"
												/>
											</ul>

											<TitreBloc val="Cahier 2 ( Sinaï - David)" />
											<ul className="liste orange">
												<LienLi
													contenu="Du Sinaï à David (Fiches moniteur)"
													url="https://www.dropbox.com/s/4ffvm0r8o5nbb0j/996ec8ca856a47f0543c153f23ec97a9efe7da46.pdf?raw=1"
												/>
												<LienLi
													contenu="Du Sinaï à David (Cahier)"
													url="https://www.dropbox.com/s/90hq0qat2ovk06e/7a123c05baf87fcc6c455f28a3cf56e533ae0e20.pdf?raw=1"
												/>
											</ul>
											<TitreBloc val="Cahier 3 (Salomon - retour de déportation)" />
											<ul className="liste orange">
												<LienLi
													contenu="De Salomon au retour de la déportation (Cahier)"
													url="https://www.dropbox.com/s/mcicuxyqm7hy1hd/de5e728922bff1c1b1f5bd11ec324921366fef0f.pdf?raw=1"
												/>
											</ul>
											<TitreBloc val="Cahier 4 (Les évangiles)" />
											<ul className="liste orange">
												<LienLi
													contenu="Les Évangiles (Cahier)"
													url="https://www.dropbox.com/s/nrxrc6vvtxc748x/a5123b3671a8fcc9009579ef359548d39a48f2af.pdf?raw=1"
												/>
											</ul>
										</>
									)
								}}
							/>
							<EncartAngleDroits
								data={{
									couleur: "#e3af56",
									titre: "7 À 10 ANS",
									contenu: (
										<>
											<TitreBloc val="" />
											<ul className="liste jaune">
												<LienLi
													contenu="Cahier année 1"
													url="https://www.dropbox.com/s/rkiai1y8lq5ph5z/bc85cf3bcc46b0fb049566da311e3720594f1a15.pdf?raw=1"
												/>
												<LienLi
													contenu="Cahier année 2"
													url="https://www.dropbox.com/s/6fzp0dmod2dxexv/7efe7ecbeeb5bd7df246cd849dc986d192e94983.pdf?raw=1"
												/>
												<LienLi
													contenu="Cahier année 3"
													url="https://www.dropbox.com/s/c6f8yfmq46414aq/096d4fb0f2e1e56ddfd8f874e7f8aa49c3aed99f.pdf?raw=1"
												/>
												<LienLi
													contenu="Cahier année 4"
													url="https://www.dropbox.com/s/smdy4il07n2saxo/e96d9110857008c8561c5387545c7decac8a98e4.pdf?raw=1"
												/>
												<LienLi
													contenu="Frise chronologique"
													url="https://www.dropbox.com/s/44cmojanzhscmqt/5efd0c891c0a6d3980dbd5452b854887f49debff.pdf?raw=1"
												/>
												<LienLi
													contenu="Dessins coloriés"
													url="https://www.dropbox.com/s/efg3acz0946xmlq/0eaa69bd1b64c399a774ed04f45f373d3176aecb.zip?raw=1"
												/>
											</ul>
											<br />
											<br />
											<br />
											<br />
										</>
									)
								}}
							/>
							<EncartAngleDroits
								data={{
									couleur: "#7cb4d7",
									titre: "AUTRES",
									contenu: (
										<>
											<TitreBloc val="Introduction école du dimanche" />
											<ul className="liste bleu">
												<LienLi
													contenu="Conseils de préparation"
													url="https://www.dropbox.com/s/8fqnnty37hq24wr/652fc664e3d4f4992964eb1a5a872806f1554d31.pdf?raw=1"
												/>
											</ul>
											<TitreBloc val="Documentation" />
											<ul className="liste bleu">
												<LienLi
													contenu="Images"
													url="https://www.dropbox.com/s/mixfnyz5lgx07p8/ef4bc1e50a85833b0acf8e3954ec3e64d3eecb2d.zip?raw=1"
												/>
												<LienLi
													contenu="Bibliographie"
													url="https://www.dropbox.com/s/cu7fl3sdd4dhyrj/ca8ac83df1855b75c6787b4fd1d9eb8d70918b0d.pdf?raw=1"
												/>
												<LienLi
													contenu="Polices"
													url="https://www.dropbox.com/s/n2a8ania95mlqq8/c7ca14e825b2405ad098364f5285846042dcd61a.zip?raw=1"
												/>
												<LienLi
													contenu="Divers fichiers"
													url="https://www.dropbox.com/s/ccv1ytd9eejz4vp/e458c8a0f7a55b02981132c065726ea0c32e65b0.zip?raw=1"
												/>
												<LienLi
													contenu="Cartes et illustrations"
													url="https://www.dropbox.com/s/td51fckbuo4f46f/568c859869c8cfe1a128ec1a97ee5d0fc05bfa75.pdf?raw=1"
												/>
											</ul>
										</>
									)
								}}
							/>
						</div>
					</div>
				</div>
			</>
		);
	}
}
export default withTranslation()(EnseignementEnfants);
