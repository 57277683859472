import React from "react";
import Page from "../Page";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import {
	ModuleEncartMtCustom,
	ModuleEncartListMt,
	ModuleCard,
	tools,
	api
} from "@mitim/react-mitim";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { ThemeProvider } from "@material-ui/core";
import BlockLecteurAudio from "../BlockLecteurAudio/BlockLecteurAudio";
import { element } from "prop-types";

class MediatationQuotidienne extends Page {
	constructor(props) {
		super(props);
		this.handleShow = this.handleShow.bind(this);
		this.handleClose = this.handleClose.bind(this);
	}

	state = {
		parleTonServiteurEcoute: {
			date: "",
			titre: "",
			lien: ""
		},
		sagesseProverbes: {
			date: "",
			titre: "",
			lien: ""
		}
	};

	componentDidMount() {
		super.componentDidMount();
		api.getParleTonServiteurEcoute(this, () => {});
		api.getSagesseProverbes(this, () => {});
	}

	handleClose() {
		this.setState({ show: false });
	}

	handleShow() {
		this.setState({ show: true });
	}

	render() {
		const { t } = this.props;

		return (
			<>
				<div className="container">
					{tools.titreSousTitre(
						"Méditation quotidienne",
						"La sagesse des proverbes au quotidien"
					)}
					<p className="text-center pb-5">
						Écouter une méditation quotidienne tirée du livre.{" "}
						<Link
							className="lien"
							to="/media/137/La-Sagesse-des-Proverbes-au-Quotidien"
						>
							"La sagesse des proverbes au quotidien"
						</Link>
						.
					</p>
				</div>
				<div
					className="container-fluid pb-lg-5 no-gutters"
					style={{ minHeight: "480px", backgroundColor: "#e2e3e4" }}
				>
					<BlockLecteurAudio
						date={this.state.sagesseProverbes.date}
						titre={this.state.sagesseProverbes.titre}
						lien={this.state.sagesseProverbes.lien}
						imgBackground="mer.jpg"
						// lienSoundCloud="https://soundcloud.com/mission-timothee-nehemie"
						// lienItunes="https://podcasts.apple.com/fr/podcast/commentaire-du-livre-la-bible-n%C3%A9h%C3%A9mie-par-la-mission/id1503923000"
					/>
				</div>

				<div className="container">
					{tools.titreSousTitre(
						"MÉDITATION QUOTIDIENNE",
						"Parle Seigneur, ton serviteur écoute"
					)}
					<p className="text-center pb-5">
						Écouter une méditation quotidienne tirée du livre{" "}
						<Link
							className="lien"
							to="/media/153/Parle-Seigneur-ton-serviteur-écoute"
						>
							Parle Seigneur, ton serviteur écoute
						</Link>
						.
					</p>
				</div>
				<div
					className="container-fluid pb-lg-5 no-gutters"
					style={{ minHeight: "480px", backgroundColor: "#e2e3e4" }}
				>
					{/* parleSeigneurTonServiteurEcouteLecteur.jpg */}
					<BlockLecteurAudio
						date={this.state.parleTonServiteurEcoute.date}
						titre={this.state.parleTonServiteurEcoute.titre}
						lien={this.state.parleTonServiteurEcoute.lien}
						imgBackground="bibleLecteur.jpg"
						lienSoundCloud="https://soundcloud.com/mission-timothee"
						lienItunes="https://podcasts.apple.com/us/podcast/parle-seigneur-ton-serviteur-%C3%A9coute/id1332789588"
					/>
				</div>
			</>
		);
	}
}

export default withTranslation()(MediatationQuotidienne);
