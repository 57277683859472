import React from "react";
import "./ElasticArticlesRevues.scss";

import {
	Facet,
	Results,
	PagingInfo,
	ResultsPerPage,
	Paging,
	Sorting
} from "@elastic/react-search-ui";

import { MultiCheckboxFacet } from "@elastic/react-search-ui-views";
import "@elastic/react-search-ui-views/lib/styles/styles.css";
import { Button } from "@material-ui/core";
import { LjcContext } from "./fonctions";
import { buildSortOptionsFromConfig } from "./config/config-helper";
import { ElementArticle } from "./ElementArticle";
import { BarreRecherche } from "./BarreRecherche";

const CustomResultView = ({ result }) => {
	return <ElementArticle result={result} />;
};

const CustomResultsView = ({ children }) => {
	return <div className="row pl-md-2 pt-2">{children}</div>;
};

export default class ElasticArticlesRevues extends React.Component {
	static contextType = LjcContext;

	constructor(props) {
		super(props);
	}

	state = {
		filterClass: "d-none",
		categories: []
	};

	componentDidMount() {
		if (sessionStorage.getItem("categoriesLoaded") == "true") {
			this.setState({
				categories: JSON.parse(sessionStorage.getItem("categories"))
			});
		}
	}

	toggleFilters = () => {
		this.state.filterClass === "d-none"
			? this.setState({ filterClass: "" })
			: this.setState({ filterClass: "d-none" });
	};

	handleChange = FieldValue => {
		console.log(FieldValue);
	};

	render() {
		let classes = {
			filter: "col-12 col-md-4 col-lg-3 col-xl-3 col-xxl-2",
			result: "col-12 col-md-8 col-lg-9 col-xl-9 col-xxl-10",
			pagination: "pt-5"
		};

		return (
			<div className="" style={{}}>
				<div className="header-recherche d-flex justify-content-between ">
					{this.props.query && (
						<>
							<span
								className="terme-wrapper"
								onClick={() => {
									window.location.href = "/articles";
								}}
							>
								Recherche :{" "}
								<span className="terme-val">
									{this.props.query}
									<span className="icone">
										<svg
											width={20}
											version="1.1"
											x="0px"
											y="0px"
											viewBox="0 0 1000 1000"
										>
											<g>
												<path
													style={{ fill: "#fff" }}
													d="M500,990C229.8,990,10,770.2,10,500S229.8,10,500,10s490,219.8,490,490S770.2,990,500,990z M500,80.3C268.6,80.3,80.3,268.6,80.3,500c0,231.4,188.3,419.7,419.7,419.7c231.4,0,419.7-188.3,419.7-419.7C919.7,268.6,731.4,80.3,500,80.3z M549.3,501.5l151.3-149.7c13.8-13.6,13.9-35.7,0.3-49.5c-13.6-13.8-35.8-13.9-49.5-0.3L499.9,451.9L350.6,302.2c-13.7-13.7-35.8-13.8-49.5-0.1c-13.7,13.6-13.7,35.8-0.1,49.5l149,149.5L299.8,649.8c-13.8,13.6-13.9,35.7-0.3,49.5c6.9,6.9,15.9,10.4,24.9,10.4c8.9,0,17.8-3.4,24.6-10.1l150.5-148.8l151.7,152.2c6.8,6.9,15.8,10.3,24.8,10.3c9,0,17.9-3.4,24.7-10.2c13.7-13.7,13.7-35.8,0.1-49.5L549.3,501.5z"
												/>
											</g>
										</svg>
									</span>
								</span>
							</span>
						</>
					)}
					<span></span>
				</div>
				<div className="row">
					<div className="col-12 d-md-none p-3 filter-list">
						<div className="m-2 mb-3">
							<Button
								variant="contained"
								color="secondary"
								fullWidth
								onClick={this.toggleFilters}
							>
								Filtres
							</Button>
						</div>
					</div>
					<div
						className={
							this.state.filterClass +
							" d-md-block col-md-4 col-lg-3 col-xl-3 col-xxl-2 filter-list"
						}
					>
						<div style={{ backgroundColor: "" }} className="p-3">
							<div className="pt-2"></div>
							<Sorting
								label={"Trier par"}
								sortOptions={buildSortOptionsFromConfig()}
							/>

							<Facet
								field="revue"
								label="Revue"
								view={MultiCheckboxFacet}
								filterType="any"
								isFilterable
								searchPlaceholder="Revue..."
							/>
							<Facet
								field="auteurs"
								label="Auteurs"
								view={MultiCheckboxFacet}
								filterType="any"
								isFilterable
								searchPlaceholder="Auteur..."
							/>
							<Facet
								field="theme"
								label="Thèmes"
								view={MultiCheckboxFacet}
								filterType="any"
								isFilterable
								searchPlaceholder="Thème..."
							/>
							<Facet
								field="date_parution"
								label="Date de parution"
								view={MultiCheckboxFacet}
								filterType="any"
								isFilterable
								searchPlaceholder="Date de parution..."
							/>
						</div>
					</div>
					<div
						className={classes.result + " pt-3"}
						style={{ backgroundColor: "#fff" }}
					>
						<div className="row pl-3 pr-5">
							<div className="col-12">
								<BarreRecherche />

								<span style={{ display: "inline" }}>
									<PagingInfo
										view={({ start, end, totalResults }) => (
											<div className="paging-info">
												<strong>
													{start} - {end} sur {totalResults} résultats
												</strong>
											</div>
										)}
									/>
								</span>
								<span className="result-par-page">
									{
										<ResultsPerPage
											label="Afficher"
											options={[18, 35, 50, 100]}
											inline="false"
										/>
									}
								</span>
							</div>

							<Results view={CustomResultsView} resultView={CustomResultView} />
							<div className="col-12">
								<div
									style={{
										position: "absolute",
										left: "50%",
										translate: "-50%"
									}}
								>
									<div className="" style={{ width: "320px" }}>
										<Paging />
									</div>
								</div>
							</div>

							<p>
								<br />
								<br />
								<br />
								<br />
								<br />
							</p>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
