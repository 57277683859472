import React from "react";
import { withTranslation } from "react-i18next";
import "./ConfessionFoi.scss";
import { tools, api } from "@mitim/react-mitim";
import mtIconeCarteFrance from "../../resources/icons/carteFrance.svg";
import ImageMapper from "react-image-mapper";
import { Redirect } from "react-router-dom";
import { ModuleBlocGaucheDroite2 } from "@mitim/react-mitim";
import Page from "../Page";
import download from "../../resources/icons/download.svg";

class ConfessionFoi extends Page {
	render() {
		const data = this.props.data;
		const { t } = this.props;

		const Titre2 = props => (
			<div className="pt-3 pb-1">
				<h2 style={{ backgroundColor: props.couleur }}>{props.texte}</h2>
			</div>
		);
		const Titre3 = props => (
			<h3 className="pt-2" style={{ color: props.couleur }}>
				{props.texte}
			</h3>
		);

		const TelechargementComplet = props => (
			<div className="telechargementComplet">
				<a href={props.lien} target="_blank">
					<div className="select pl-2 pr-2">
						<div
							className="pt-2 text-black ft-alegreya-sans-light"
							style={{ fontSize: "1.1em" }}
						>
							Version complète
							<img style={{ float: "right" }} src={download} width={18} />
						</div>
					</div>
				</a>
				<hr />
			</div>
		);

		return (
			<>
				<div className="container confessionFoi">
					<h1 className="">CONFESSION DE FOI</h1>

					<Titre2
						couleur={tools.JAUNE_CLAIR}
						texte="LA BIBLE, ÉCRITURE SAINTE"
					/>
					<p className="p1">
						Elle est Parole de Dieu, inspirée par le Saint-Esprit et infaillible
						quant au dessein de Dieu. Il en découle qu’aucune tradition humaine,
						expérience ou nouvelle révélation ne puisse lui être opposée. Les
						Saintes Écritures sont notre seul critère en ce qui concerne le
						discernement de la vérité.
					</p>

					<Titre2 couleur={tools.VERT} texte="LA LOI" />
					<p className="p1">
						Jésus-Christ n’est pas venu pour abolir la loi, mais pour
						l’accomplir en satisfaisant ses exigences par une vie pure et sa
						mort expiatoire. Jésus en a considérablement renforcé la valeur
						contraignante.
						<br />
						La loi morale, inscrite dans la conscience des hommes, les retient
						de s’abandonner complètement au mal. Elle révèle à l’homme son
						péché, son incapacité à vouloir et à pratiquer le bien. Elle est le
						pédagogue qui conduit à Christ.
						<br />
						Le croyant régénéré n’est plus sous la loi de la condamnation mais
						sous celle du Christ. L’Esprit de Christ soumet la volonté de
						l’homme et le rend capable de faire librement et avec zèle ce que
						requiert la volonté de Dieu révélée dans sa loi.
					</p>

					<Titre2
						couleur={tools.BLEU_FONCE}
						texte="INTERPRÉTATION DES TEXTES DE LA BIBLE"
					/>
					<p>
						L’Écriture est elle-même la règle infaillible de l’interprétation.
						<br />
						La révélation intérieure de l’Esprit de Dieu est nécessaire à une
						compréhension à salut de la Parole divine.
					</p>

					<Titre2 couleur={tools.ROSE} texte="DIEU" />
					<p>
						Dieu est un, il est esprit, il est éternel. Il est omniscient,
						omniprésent, omnipotent et souverain. Il est saint, fidèle, juste,
						compatissant et amour. Il est créateur de toutes choses, visibles
						comme invisibles, y compris des anges. Dieu gouverne et conduit
						toutes choses. Il est seul sage. Dieu possède en lui-même et par
						lui-même toute gloire, et toute créature lui doit adoration, service
						et obéissance.
						<br />
						Dans l’unité divine, il y a trois personnes égales, mais distinctes,
						d’une seule et même substance, puissance et éternité : Dieu le Père,
						Dieu le Fils et Dieu le Saint-Esprit.
					</p>
					<ul>
						<li>
							Le Père est la cause première, le principe, l’origine et la fin de
							toutes choses. Le Fils et le Saint-Esprit lui sont soumis. Il est
							le Père des croyants et nos prières s’adressent à lui.
						</li>
						<li>
							Le Fils est la parole et la sagesse éternelles de Dieu. Il est
							éternellement engendré du Père et non créé. Conçu par le
							Saint-Esprit, né de la Vierge Marie, il est parfait quant à la
							divinité et parfait quant à l’humanité ; il est vraiment Dieu et
							vraiment homme, sans confusion, sans transformation, sans
							division, sans séparation des natures. Seul médiateur entre Dieu
							et les hommes, il est mort et ressuscité pour notre salut. Il
							siège comme Seigneur à la droite du Père. Il est le chef de
							l’Église qui est son corps. Il revient pour l’enlèvement de
							l’Église, le jugement final et le rétablissement du Royaume de
							Dieu.
						</li>
						<li>
							Le Saint-Esprit est la force et la puissance efficace de Dieu.
							Troisième personne de la trinité, il inspire la Parole [82], nous
							révèle le Fils, convainc de péché, opère le salut dans l’homme,
							habite chaque croyant pour en faire le temple de Dieu, nous
							conduit dans toute la vérité. Il est le consolateur, donne la
							force au témoignage, dispense les dons dans l’Église et accomplit
							des miracles.
						</li>
					</ul>

					<Titre2 couleur={tools.VIOLET} texte="L'HOMME" />
					<p>
						L’homme a été créé pour célébrer la gloire de Dieu. Dieu l’a créé
						conforme à Son image, sans la moindre tache. Par sa faute, l’homme
						est déchu de la grâce dont il jouissait. Ainsi séparé de Dieu, sa
						nature est désormais entièrement corrompue. De ce fait, il est
						devenu incapable de trouver Dieu par son intelligence ou sa volonté
						qui sont totalement prisonnières du péché, bien qu’il subsiste
						encore dans l’homme la pensée de l’éternité et un certain
						discernement du bien et du mal.
					</p>

					<Titre2 couleur={tools.ORANGE_CLAIR} texte="L'ŒUVRE DU SALUT" />
					<Titre3 couleur={tools.ORANGE_CLAIR} texte="L'ŒUVRE OBJECTIVE" />
					<p>
						Malgré cette corruption et cette condamnation générales, Dieu, dans
						sa grâce, a voulu faire alliance avec l’homme déchu. Par ses seules
						bontés et sa miséricorde, Dieu a voulu conduire à la gloire beaucoup
						de fils.
						<br />
						Dans sa volonté éternelle et immuable, il nous a élus avant la
						fondation du monde en Jésus-Christ, et cela sans considération de
						nos œuvres. Il nous a prédestinés dans son amour à être, par
						Jésus-Christ, ses enfants d’adoption pour célébrer la gloire de sa
						grâce. En lui, tout ce qui était nécessaire à notre salut nous a été
						offert et communiqué. Il en est la source, le moyen et le but.
						<br />
						Dieu a envoyé son Fils pour satisfaire sa justice ; sa mort nous
						assure une totale réparation et nous acquitte de tous nos péchés. Il
						est mort une fois pour toutes ; son sacrifice expiatoire, son sang
						sont nécessaires et pleinement suffisants pour nous acquérir la
						rédemption, le pardon et la purification de tous nos péchés.
						<br />
						Tout péché mérite la mort ; il n’en y a aucun qui ne puisse être
						pardonné, hormis le péché contre le Saint-Esprit.
						<br />
						Jésus-Christ nous a rachetés de la malédiction de la Loi, étant
						devenu malédiction pour nous. Il a effacé l’acte rédigé contre nous
						et les ordonnances qui nous condamnaient.
					</p>

					<Titre3 couleur={tools.ORANGE_CLAIR} texte="L'ŒUVRE SUBJECTIVE" />
					<p>
						Ceux que Dieu a prédestinés, il les a aussi appelés par sa Parole et
						fait, par son Esprit, naître en eux la foi et la repentance. Dans sa
						tristesse qui vient de Dieu, la haine de son péché Le pécheur,
						conscient d’avoir offensé Dieu, confesse et dénonce son péché, se
						repent de ses transgressions et demande le pardon. Il place sa
						confiance dans le Dieu Sauveur, s’approprie la vie de Christ.
						<br />
						Par la foi le croyant accepte Christ, se repose en lui et reçoit le
						privilège d’être couvert par Sa justice. Ce don merveilleux ne
						dépend pas de ses œuvres. Le Père le voit juste de par son union
						avec son Fils. Elle a pour fondement la résurrection de Jésus
						d’entre les morts.
						<br />
						Lorsque le pécheur se repent, se convertit (se détourne du monde et
						du péché) et met sa confiance en Jésus-Christ, il naît de nouveau.
						La Parole vivante de Dieu le régénère. C’est ce que l’Écriture
						appelle le « baptême du Saint-Esprit ». Il est alors une nouvelle
						création, Christ habite en lui, il devient ainsi le temple de Dieu.
						<br />
						Dès notre nouvelle naissance, nous recevons l’Esprit d’adoption par
						lequel nous disons « Père », car ceux qui sont conduits par l’Esprit
						de Dieu sont fils de Dieu. L’Esprit lui-même rend témoignage à notre
						esprit que nous sommes enfants de Dieu.
						<br />
						Le Fils, premier-né, devient notre frère ; il nous ouvre un libre
						accès dans le sanctuaire du Père. Nous sommes, désormais,
						cohéritiers avec Jésus-Christ et scellés pour le jour de la
						rédemption.
						<br />
						Notre communion est avec le Père et avec son Fils Jésus-Christ, car
						celui qui croit au nom du Fils de Dieu a la vie éternelle.
						Jésus-Christ est le Dieu véritable et la vie éternelle.
					</p>

					<Titre2
						couleur={tools.BLEU_TURQUOISE}
						texte="QU'EST-CE-QUE L'ÉGLISE ?"
					/>
					<p>
						L’Église est la communauté de tous les croyants dont les noms sont
						inscrits dans les cieux. Ils forment une race élue, un peuple que
						Dieu s’est acquis, une nation sainte. Ils sont appelés hors du monde
						par Dieu pour se consacrer à lui et exercer un sacerdoce royal.
						<br />
						On devient membre de l’Église par la régénération du Saint-Esprit,
						fruit de l’union à Jésus-Christ dans sa mort et dans sa
						résurrection. La communion de tous ses membres est avec le Père et
						avec son Fils Jésus-Christ.
						<br />
						L’Église est le corps de Christ : le Seigneur en est la tête et les
						croyants en sont les membres unis et vivifiés par lui. Elle est
						l’épouse, objet de son amour il s’unira à elle lors de son retour.
						Elle est le saint temple de Dieu, dont le Christ est la pierre
						angulaire et les enfants de Dieu les pierres vivantes qui s’édifient
						sur le fondement des apôtres et des prophètes.
					</p>

					<Titre3 couleur={tools.BLEU_TURQUOISE} texte="LE CULTE" />
					<p>
						Dieu, Souverain, Seigneur de toutes choses, bon et bienfaisant,
						demande à être cru, aimé, craint, loué, invoqué et servi par les
						hommes de tout leur cœur, de toute leur âme et de toute leur force.
						C’est dans ce but que l’Église se rassemble pour célébrer le culte.
						L’adoration est le fruit de lèvres qui confessent le nom du
						Seigneur.
						<br />
						Dieu lui-même a ordonné et fixé par sa propre volonté la façon de
						lui rendre un culte, indépendamment de toute imagination et désir
						humains, de toute suggestion de Satan, de toute représentation que
						ce soit. Ce culte doit Lui être rendu en esprit et en vérité, dans
						la liberté et la soumission aux Écritures. Il n’est pas attaché à un
						lieu ou à un édifice particulier.
					</p>

					<Titre3
						couleur={tools.BLEU_TURQUOISE}
						texte="LES MINISTÈRES ET LES DONS"
					/>
					<p>
						Les ministères s’exercent dans l’Église. Ils sont donnés par Dieu
						pour l’instruction, l’édification, le perfectionnement de l’Église
						dans l’unité et pour son accroissement, afin qu’elle parvienne à la
						stature parfaite de Christ.
						<br />
						Chaque membre reçoit, pour l’exercice des ministères, un ou
						plusieurs dons de l’Esprit. Dieu distribue les dons à qui il veut,
						comme il le veut et quand il le veut, mais tout croyant est appelé à
						y aspirer.
						<br />
						Tous les ministères ne se trouvent pas nécessairement dans chaque
						église, certains dépassant le cadre local. Chaque don s’exerce dans
						l’ordre, l’humilité et la soumission mutuelle au sein de l’église et
						doit être éprouvé et reconnu par elle.
					</p>

					<Titre3
						couleur={tools.BLEU_TURQUOISE}
						texte="LE BAPTÊME ET LA SAINTE-CÈNE"
					/>
					<p>
						Le baptême et la Sainte-Cène ont été institués par Jésus-Christ. Ils
						sont un ordre du Seigneur, un témoignage extérieur nécessaire de
						notre union à Christ, dans sa mort et sa résurrection, et de notre
						appartenance à son corps qui est l’Église. Bien que ces signes ne
						soient pas le véhicule de la grâce, ils ne sont pas dépourvus de
						valeur. Si nous les pratiquons dans la foi, Dieu nous accorde
						réellement, par son Saint-Esprit, les bénédictions promises qui y
						sont associées.
					</p>

					<Titre2
						couleur={tools.ROSE}
						texte="LE SORT DE L'HOMME APRÈS LA MORT"
					/>
					<p>
						À cause du péché, il est réservé aux hommes de mourir une seule
						fois, après quoi vient le jugement. Le corps retourne à la
						poussière. L'âme, qui connaît une existence sans fin, retourne à
						Dieu, soit pour la vie, soit pour la mort.
						<br />
						Contrairement à l’impie, le croyant qui meurt dans le Seigneur
						triomphe de la mort. Il ne soupire pas après la mort mais aspire à
						la vie éternelle. Plein de confiance, il préfère quitter ce corps et
						demeurer auprès du Seigneur.
						<br />
						Le croyant régénéré rejoint Jésus dans le paradis. Accompagné de ses
						œuvres, il connaît le bonheur et le repos, en attendant
						l’immortalité, l’adoption et la rédemption du corps.
					</p>

					<Titre3 couleur={tools.ORANGE} texte="LA FIN DES TEMPS" />
					<p>
						La parole prophétique nous instruit, de plusieurs manières, sur les
						temps à venir. Elle concerne principalement le retour de notre
						Seigneur Jésus-Christ. Elle nous exhorte à y prêter une vive
						attention et à nous garder de toute interprétation particulière.
					</p>

					<Titre3
						couleur={tools.ORANGE}
						texte="LE RETOUR DE CHRIST OU LA PAROUSIE"
					/>
					<p>
						L’Église, épouse de Christ, attend avec une ferme espérance la
						parousie. Elle prie et agit pour hâter Sa venue. Christ revient
						délivrer les siens, juger le monde pécheur et établir Son règne
						éternel de justice et de paix.
						<br />
						Nul ne connaît le jour, ni l’heure, car il viendra comme un voleur
						dans la nuit. Mais des signes précurseurs nous sont donnés, afin de
						nous inciter à veiller et à lever nos têtes car la délivrance est
						proche.
					</p>

					<Titre3
						couleur={tools.ORANGE}
						texte="ENLÈVEMENT DE L’ÉGLISE ET JUGEMENT DERNIER"
					/>
					<p>
						Jésus-Christ a vaincu Satan à la croix. Christ règne désormais dans
						le ciel, jusqu’à ce qu’il ait mis tous ses ennemis sous ses pieds.
						Au moment fixé par Dieu, en un clin d’œil, à un signal donné, le
						Christ descendra du ciel pour enlever l’Église.
						<br />
						Ensuite, la terre et le ciel disparaîtront et les morts impies
						ressusciteront pour être jugés d’après leurs œuvres. La mort et le
						séjour des morts, ainsi que le diable, seront jetés dans l’étang de
						feu et de soufre.
					</p>

					<Titre3 couleur={tools.ORANGE} texte="LE CIEL" />
					<p>
						Un nouveau ciel et une nouvelle terre apparaîtront ainsi que la
						nouvelle Jérusalem, épouse et tabernacle de Dieu. Toutes choses
						seront devenues nouvelles. Dieu habitera avec les siens et les
						consolera. Le Seigneur, Dieu Tout-Puissant, sera leur temple ainsi
						que l’Agneau. La gloire de Dieu les éclairera et l’Agneau sera leur
						flambeau. Ses serviteurs, le front marqué de son nom, verront sa
						face, ils le serviront et régneront aux siècles des siècles.
					</p>

					<Titre2 couleur={tools.VERT_FONCE} texte="EN CONCLUSION" />
					<p>
						Si nous savons ces choses nous sommes heureux, pourvu que nous les
						pratiquions (Jean 13 verset 17).
					</p>

					<TelechargementComplet
						lien={`${tools.lienStorage}/MissionTimothee/data/confession de foi MT.pdf`}
					/>
				</div>
			</>
		);
	}
}
export default withTranslation()(ConfessionFoi);
