import React from "react";
import { VoteRadioBouton } from "../AGVote/VoteRadioBouton";
import { EnteteTaj, EnteteMT } from "../AGVote/AGVote";
import { tools } from "@mitim/react-mitim";
import { FichiersATelecharger, FICHIER_TELECHARGER_TAJ } from "./DATA";

import {
	Titre2,
	Titre3,
	Titre4,
	Titre5,
	Paragraph4,
	Paragraph5,
	Image,
	TableauMembresTAJ,
	TableauSignature
} from "../AGVote/Fonctions";

export const ContenuTaj = props => (
	<>
		<div className="voteAg pt-5 mb-5">
			<br />
			<EnteteTaj />
			<h1 className="">BULLETIN DE VOTE PAR CORRESPONDANCE</h1>
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* // PREALABLE */}
			<Titre2
				couleur={tools.JAUNE_CLAIR}
				texte="DELIBERATION PREALABLE – COMPOSITION DU BUREAU DE SEANCE"
			/>
			<p>
				<b>
					Le Comité propose à l’assemblée la composition suivante de son Bureau
				</b>
			</p>
			<ul>
				<li>
					Daniel ISSARTE, préside la séance en sa qualité de président de
					l'association
				</li>
				<li>
					David GUTSCHER membre de l’association, assure le secrétariat de la
					séance
				</li>
				<li>
					Jérémie LOIRET membre du conseil d’administration assure la fonction
					de coordinateur
				</li>
			</ul>
			<p>
				La fonction des membres du bureau prend fin avec lors du renouvellement
				du mandat des membres du conseil d’administration concernés. Une
				nouvelle élection sera alors proposée aux membres.
			</p>
			<VoteRadioBouton
				numVote="tajAgPre"
				self={props.self}
				defaultPour={true}
				titre="VOTE d’approbation de la composition du bureau ainsi proposée"
			/>
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* // 1 */}
			<Titre2
				couleur={tools.VERT}
				texte="I. PREMIÈRE DELIBERATION - APPROBATION DU PROCES-VERBAL DE L’ASSEMBLEE GENERALE DE 2020"
			/>
			<p style={{ textIndent: "40px" }}>
				Chacun a reçu copie du procès-verbal de l’Assemblée générale tenue
				l’année précédente le 1er juin 2020 portant sur l’activité de l’année
				2019.
			</p>
			<VoteRadioBouton
				numVote="tajAg1"
				self={props.self}
				defaultPour={true}
				titre="Vote d’approbation du procès-verbal d’Assemblée générale de l’année 2020"
			/>
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* // 2 */}
			<Titre2
				couleur={tools.BLEU_FONCE}
				texte="II. DEUXIÈME DELIBERATION - RAPPORT MORAL ET D’ACTIVITE"
			/>
			<p>
				La présentation suivante permet aux membres d’avoir une idée globale des
				activités de l’association sur l’année écoulée.
			</p>
			<p>
				Toutefois, le bureau précise que cette présentation n’est pas
				exhaustive, elle a seulement pour but de brosser les grands traits qui
				ont caractérisés l’action de l’association sur l’année 2020.
			</p>
			<Titre3
				couleur={tools.BLEU_FONCE}
				texte="A. Soutien de l’œuvre à l’étranger"
			/>
			<Titre4 texte="1. Bulgarie – rapport de Damien Dubois" />
			<Paragraph4
				texte={
					<>
						Au début de l’année 2020, la maison d’accueil à Slivovic a été bien
						remplie. Pendant cinq mois nous avions quatre à cinq jeunes hommes
						en permanence. Deux d’entre-eux étaient des repris de justice et les
						trois autres des jeunes ayant fréquenté des orphelinats.
						Malheureusement, l’absence de collaborateur sur place nous a
						cruellement manqué. Avec Joël, nous nous y rendions trois à quatre
						fois par semaine. Mais le reste du temps nos jeunes étaient livrés à
						eux-mêmes. Avec leurs passés difficiles et les mauvaises habitudes,
						la situation est devenue ingérable. En mai, nous avons donc décidé
						de fermer la maison d’accueil. Seul un jeune, Pepi, qui n’avait pas
						d’autre recours est resté jusqu’en septembre.
					</>
				}
			/>
			<Paragraph4
				texte={
					<>
						Cette année 2020 a donc été particulièrement difficile concernant le
						travail dans la maison d’accueil. Ces difficultés nous conduisent à
						réfléchir quant aux orientations prises. En l’absence de
						collaborateur sur place, l’accueil perd tout son sens. Même si nos
						passages étaient fréquents et que Markus a continué à s’investir,
						malgré la crise COVID, rien ne remplace un couple sur place.
					</>
				}
			/>
			<Paragraph4
				texte={
					<>
						Nous avons tout de même continué à faire des passages réguliers à
						Slivovic avec des jeunes dans le besoin. Mais ces visites ne
						dépassent pas la journée.
					</>
				}
			/>
			<Titre4 texte="2. Madagascar" />
			<Titre5 texte="a. Antsirabe" />
			<Paragraph5
				texte={
					<>
						Nous avons tout de même continué à faire des passages réguliers à
						Slivovic avec des jeunes dans le besoin. Mais ces visites ne
						dépassent pas la journée.
					</>
				}
			/>
			<Titre5 texte="b. Le CEP – Mamy Raharison" />
			<Paragraph5
				texte={
					<>
						L’accueil s’est principalement focalisé sur des hébergements de
						jeunes adultes qui ont décroché les études par manque de moyen ou
						pour cause de rupture sociale. Issus de famille en difficultés ils
						sont pour la plupart membres de groupe de jeune du CEP qui en font
						une demande d'accueil. En principe, la durée de leur formation est
						variable mais elle est au minimum de 1 ans et au maximum de 3ans.
						L’idée étant de continuer l’accueil jusqu’à l’obtention d’un
						travail.
					</>
				}
			/>
			<Paragraph5
				texte={
					<b>
						Le centre dispose de 10 lits de disponibles (4 filles dans la maison
						principale et 6 garçons dans l'annexe). Sur l’année 2020
					</b>
				}
			/>
			<ul style={{ paddingLeft: "120px" }}>
				<li>3 jeunes ont été insérés professionnellement</li>
				<li>
					Une jeune fille s’est marié et rejoint son mari (un autre jeune du
					CEP) à Antsirabe en travaillant avec Ergo santé
				</li>
				<li>
					Un couple est devenu collaborateur sur place en occupant un chalet
				</li>
				<li>
					Un jeune qui a terminé son école théologique habite au cep pour aider
					à l’encadrement
				</li>
				<li>
					Une femme veuve avec 5 enfants sont accueillis et occupent une autre
					maison
				</li>
			</ul>
			<Paragraph5
				texte={
					<>
						<b>
							Quelques chiffres relatant les repas servis dans l'année en
							moyenne
						</b>
					</>
				}
			/>
			<ul style={{ paddingLeft: "120px" }}>
				<li>3360 pour les enfants + moniteurs (déjeuner)</li>
				<li>4320 pour les accueillis (diner et déjeuner pour certains)</li>
				<li>960 pour les réunions de dimanche (déjeuner)</li>
				<li>936 pour les 3 collaborateurs (déjeuner)</li>
			</ul>
			<Titre3 couleur={tools.BLEU_FONCE} texte="B. Guinée – centre Esaïe 58" />
			<p style={{ textIndent: "40px" }}>
				Pour rappel, l’association Timothée accueil et jeunesse soutient
				l’association Aide aux enfants de Guinée N°5677/MATD/CAB/SERPROMA/2017.
				BP 3796 sis en République de Guinée Conakry par une convention de
				collaboration missionnaire. Cette association a pour objet d’accueillir,
				d’héberger des enfants orphelins et leur donnant le nécessaire pour
				vivre et pour être éduqué. Le responsable du centre Esaïe 58, Daniel
				Tonlo souligne quelques points saillants sur l’année écoulée.
			</p>
			<ul>
				<li>
					<b>L’agrandissement des dortoirs : </b>le travail d’agrandissement des
					dortoirs est toujours encours. En 2020 les diacres étaient venus pour
					le finaliser. Mais le retard du container qui devrait venir avec le
					matériel de travail et la covid 19 a retardé le travail.
				</li>
				<li>
					<b>La construction mur du Centre Ésaïe 58 : </b>La construction du mur
					a bien avancé. Aujourd’hui il reste à placer les deux portails et les
					barbelés sur le mur. Les travaux ont retardé à cause du décès de la
					femme du maître maçon qui a la charge de construire le mur. Il a
					repris les travaux après le deuil. Dans quelques jours les travaux de
					construction du mur prendront fin.
				</li>
				<li>
					<b>Construction du préau : </b>Les travaux de construction du préau
					ont commencé en 2020. À cause des grandes pluies, le plan dudit préau
					a été réaménagé. Aujourd’hui nous sommes sur les travaux de finition.
					Le préau sert à plusieurs activités du Centre : réfectoire, lieu de
					repos etc
				</li>
				<li>
					<b>Construction de cinq salles de classes : </b>En 2020 nous avons
					lancé la construction de 5 classes. Les murs sont soulevés, la toiture
					est faite. Il reste les travaux de finition : Peinture, dallasse … et
					l’équipement des salles de classes.
				</li>
				<li>
					<b>Agriculture : </b>Afin de réduire certains frais de fonctionnement
					une activité agricole se développe au centre. En 2020 nous avons
					cultivé 3 hectares du riz. Nous avons récolté deux tonnes et demie du
					riz. Nous avons besoin un soutien technique pour aménager les trois
					hectares de bas-fond. Ceci nous aidera à améliorer le rendement de la
					récolte.
				</li>
			</ul>
			<Titre3 couleur={tools.BLEU_FONCE} texte="C. Librairie Jean Calvin" />
			<p style={{ textIndent: "40px" }}>
				Pour rappel, le réseau de librairie Jean Calvin est une marque codétenue
				avec la société Comptoir cevenol d’emballages sis 480 avenue olivier de
				Serres 30100 Ales, SIREN 302567052 RCS Nîmes mettant en avant des
				ouvrages de littératures d’histoire, de théologie et de culture
				protestante au sens large. Ce travail est une manière de mettre en
				application l’objet associatif de notre association qui a pour but de
				promouvoir la culture protestante.
			</p>
			<p>
				Cela étant rappelé, l’année 2020 a été marquée par le Covid et la
				fermeture des magasins à deux reprises du 16 mars au 10 mai et du 30
				octobre au 27 novembre. Lors du premier confinement, la solidarité du
				lectorat de la Mission Timothée s’est particulièrement sentie, notamment
				par des achats en ligne. Plusieurs moyens, plus symboliques
				qu’efficaces, ont été mis en œuvre pour maintenir une activité.
			</p>
			<p>
				Nous avons profité du confinement pour mettre en place Calvin éditions
				et travailler sur le premier volume de la collection « Réforme et Réveil
				», un recueil de lettres de Calvin intitulé Au milieu de tant
				d’épreuves.
			</p>
			<p>
				Le cycle de conférence a été annulé, ainsi que les stands prévus, dont
				l’Assemblée au Désert. Nous avons fait une conférence Zoom avec Patrick
				Cabanel à propos des Mémoires d’André Trocmé parues chez Labor et Fides.
			</p>
			<p>
				Du 13 au 25 juillet, nous avons eu en stage Mohammed Alouane, libraire à
				la librairie chrétienne d’Amiens.
			</p>
			<p>
				Les stands des marchés des Cévennes l’été ont été assurés, et ont été
				plutôt bien fréquentés.
			</p>
			<p>
				Deux bénévoles se sont engagés au service de Calvin diffusion, à Paris
				et dans le Grand Est. La diffusion a amplement servi à soutenir le
				travail de librairie.
			</p>
			<p>
				Majid Kamali a rejoint l’équipe d’Alès. Il s’occupe des commandes des
				stations, des envois et seconde Stéphane pour les achats.
			</p>
			<Titre3
				couleur={tools.BLEU_FONCE}
				texte="D. Maison Bernard, centre d’accueil de Timothée Accueil Jeunesse à Fouday"
			/>
			<p style={{ textIndent: "40px" }}>
				Le Fonds de dotation maison Bernard est propriétaire de la maison de
				Fouday depuis le 24 mars 2017 pour donner suite à une donation effectuée
				par Monsieur et Madame Bernard et Annie Czermack. Ce fonds a pour but de
				valoriser son patrimoine tout en soutenant notre association par des
				mises à disposition de locaux et des dons réguliers. Concrètement une
				maison d’accueil est gérée dans ce lieu par un couple missionnaire
				Arnaud et Elodie Amberg.
			</p>
			<p>
				Malgré le contexte particulier du COVID, la maison a continué
				d’accueillir durant toute l’année, et ce même pendant les périodes de
				confinement ce qui est réjouissant.
			</p>
			<ul>
				<li>
					Accueil « long-séjour » de plusieurs personnes : Eddy LOTTO (13
					semaines), Eloïse ARLOT étudiante à Team Etud’ (deux séjours de 17 et
					7 semaines), et Serge CHERNYAVSKIY étudiant réfugié (11 semaines).
				</li>
				<li>
					Accueil ponctuel de personnes en détresse (dépression, maladie ou
					besoin de repos) : séjours généralement hebdomadaires avec la
					possibilité d’être renouvelable.{" "}
				</li>
				<li>
					Accueil ponctuel de familles ou de jeunes des assemblées de la Mission
					Timothée.
				</li>
				<li>
					Accueil ponctuel d’amis de la Mission Timothée voulant participer aux
					travaux de la maison.
				</li>
			</ul>
			<p>
				L’accueil comprend généralement les repas et les nuits en dortoirs ainsi
				que des méditations bibliques régulières le soir après le repas. Pour
				l’accueil quotidien, il comprend également de l’aide administrative, le
				linge et le ménage des dortoirs.
			</p>
			<p>
				Des camps ont tout de même pu être réalisés avec une capacité d’accueil
				réduite liée à la pandémie.
			</p>
			<ul>
				<li>
					Deux camps de familles pendant l’été : 15 personnes reçues dans la
					maison pour chaque camp
				</li>
				<li>
					Deux camps de jeunes à l’extérieur pendant l’été : 30 personnes pour
					chaque camp
				</li>
				<li>
					Organisation de WE de jeunes t (2 par an avec 25 ados), de WE
					d’étudiants (2 WE par an avec 35 étudiants
				</li>
				<li>
					Organisation de camp d’entraide pendant les vacances scolaires d’hiver
					et de la Toussaint : 2 semaines par an avec environ 25 adultes
				</li>
			</ul>
			<p>
				Par ailleurs des travaux d’entretien et d’aménagement extérieurs :
				tonte, débroussaillage, coupe de bois, jardin ont été effectués.
			</p>
			<Titre3
				couleur={tools.BLEU_FONCE}
				texte="E. Maison d’accueil « Le phare » – Marseille, Emmanuel Sagols"
			/>
			<p style={{ textIndent: "40px" }}>
				Nous avons eu au cours de l’année quelques nouvelles d’étudiants des
				années passées et deux anciens pensionnaires du Phare viennent assez
				souvent nous voir. Durant l’année écoulée nous avions au maximum 12
				étudiants.
			</p>
			<p>
				Nous avons aussi eu cette année le passage pendant 3 semaines de nos
				diacres qui sont venus pour les travaux de la terrasse et des deux toits
				de la maisonnette. Cela a permis de faire connaitre notre association
				auprès de nos voisins à cette occasion. Hugo Pesenti nous aide toujours
				au poste de cuisinier et Malvina Rehmet aide bénévolement après ses
				heures de travail.
			</p>
			<Titre3
				couleur={tools.BLEU_FONCE}
				texte="F. Travail de jeunesse – Guy Pradal"
			/>
			<p style={{ textIndent: "40px" }}>
				La pandémie nous a obligée à annuler, à contre cœur, les camps et
				colonies de vacances qui étaient prévues. Afin de ne pas laisser les
				enfants et adolescents sans rencontres, des journées ou petits séjours
				ont été organisés au niveau régional.
			</p>
			<ul>
				<li>
					Deux mini séjours ont rassemblé une vingtaine de jeunes à FOUDAY pour
					4 jours
				</li>
				<li>
					Deux weekends de jeunes se sont déroulés au Riffray ainsi qu’un
					weekend pour une quarantaine d’adolescents
				</li>
				<li>
					Les responsables jeunesse de Nantes ont organisé un weekend pour les
					enfants
				</li>
				<li>
					Les responsables jeunesse de Saint Michel ont reçu une trentaine
					d’adolescents et de jeunes pour un weekend
				</li>
			</ul>
			<p>
				Le bilan de ces séjours, certes très courts, est positif car cela a
				permis des contacts personnels plus approfondis. Autant les participants
				que les encadrants ont apprécié ce fonctionnement en petit groupe et
				l’ambiance familiale. Malheureusement se sont très peu d’enfants et
				d’adolescents qui ont pu bénéficier d’un séjour, à notre regret très
				court.
			</p>
			<p>
				La fréquentation du site « Timothée Kid’s » a fortement augmenté depuis
				le premier confinement avec près de 40% de connections depuis
				l’étranger.
			</p>
			<Titre3
				couleur={tools.BLEU_FONCE}
				texte="G. Maison d’accueil à Anduze – Guilhem Jaussaud"
			/>
			<Titre4 texte="1. L’accueil" />
			<Paragraph4 texte="Fidèle à sa vocation d’accueil, la maison à Anduze a reçu tout au long de l’année 2020 de nombreux hôtes. La plupart nous ont rejoint à cause de difficultés matérielles et morales, pour sevrage de la drogue ou de l’alcool ; d’autres pour un séjour d’édification. Le weekend, de manière générale, nous avons continué de recevoir des étudiants du second degré, de Nîmes et Montpellier." />
			<Paragraph4 texte="Toutefois, notre activité durant 2020 a été fortement marquée par la pandémie de la Covid 19. En concertation avec les médecins et infirmiers membres de l’association, nous avons cherché à poursuivre notre vocation, tout en appliquant les consignes sanitaires de mises. " />
			<Paragraph4 texte="Cela a impliqué notamment ; au quotidien, de limiter au minimum nécessaire la présence de visiteurs aux repas et aux réunions, les interactions avec les associations filles, les entrées sorties des pensionnaires ; et l’annulation de plusieurs séjours de camp bibliques durant les vacances scolaires. Dans un premier temps, au pic de l’épidémie, nous avons repoussé à des dates ultérieures toutes les nouvelles demandes d’accueil qui nous parvenaient, pour respecter le plus possible le cadre du confinement. Par la suite, nous avons accédé à de nombreuses demandes qui nous ont semblé plus pressantes. Les séjours réalisés dans ces périodes se sont, pour la plupart, avérés fructueux, ce qui nous a beaucoup encouragé. Durant l’été, nous avons mis en place une formule de camp plus légère permettant de recevoir du monde, en nombre limité, sur des séjours plus courts mais plus réguliers. " />
			<Titre4 texte="2. Chantier d’agrandissement de la maison " />
			<Paragraph4 texte="La période de relatif isolement que nous avons connu durant les confinements successifs nous a permis non seulement de nous concentrer sur le cœur de notre travail d’accueil auprès des personnes en difficulté mais aussi d’avancer de manière conséquente les travaux d’agrandissement. " />
			<Paragraph4 texte="Le terrassement de l'aile 2 a commencé la première semaine du mois de mars et les travaux de gros œuvre se sont enchaînés jusqu'à la fin du mois de juin.  Au passage, la coursive le long des sanitaires - nurserie - studio candidats a été refaite et le mur dans le hall des sanitaires renforcé." />
			<Paragraph4 texte="En septembre une dalle est ajoutée au niveau ancien bassin entre les piliers de la librairie. En parallèle les diacres ont travaillé de septembre à fin décembre pour le chauffage et surtout pour l’installation de la centrale de l’air conditionnée. En novembre 2020 les menuiseries étaient posées. Ce qui fait qu'en fin d'année le bâtiment était hors d'eau hors d'air. " />
			<Paragraph4 texte="Les jardiniers, aidé par une équipe de conséquente bénévoles, ont travaillé pendant plusieurs mois sur l’aménagement des allés devant les motels A et C ainsi que sur l’aire de jeux. Ces travaux ont contribué à un embellissement considérable de la « colline » et en même temps l’accès à la salle de réunion depuis le parking situé devant le motel C4 a été rendu plus facile par des plans inclinés qui remplacent les escaliers. " />
			<Titre3
				couleur={tools.BLEU_FONCE}
				texte="H. Compte rendu du coordinateur – Jérémie Loiret"
			/>
			<p>
				Comme chaque année nous nous sommes rendus à Conakry pour soutenir le
				travail d’accueil au centre Esaïe 58. L’occasion de faire quelques
				séances de formation pour les collaborateurs du centre.
			</p>
			<p>
				Une réunion du comité a pu sceller une fusion entre le travail des
				comptoirs Esaïe 58 et l’association AEG (Aide aux enfants de Guinée).
				Samuel Telliano pasteur en ville s’occupe de ce comptoir qui permet de
				fournir en médicaments, habits et nourriture les plus démunis. Certains
				enfants de son réseau auraient besoin d’aide à la scolarisation. Nous
				avions suggéré cette fusion pour encourager à une vraie collaboration en
				vue de déterminer ensemble les priorités.
			</p>
			<VoteRadioBouton
				numVote="tajAg2"
				self={props.self}
				defaultPour={true}
				titre="Vote d’approbation sans réserve du rapport morale et d’activité de l’année 2020 "
			/>
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* // 3 */}
			<Titre2
				couleur={tools.ROSE}
				texte="III. TROISIÈME DELIBERATION – SOUTIEN DU TRAVAIL A L’ETRANGER"
			/>
			<p>
				Le trésorier de séance présente un tableau récapitulatif chiffré de
				l’ensemble du soutien de l’association au travail à l’étranger sur les
				six dernières années
			</p>
			<p>Pour rappel, voici les principaux lieux de notre engagement :</p>
			<ul>
				<li>
					A Madagascar : le travail d’accueil concernait jusqu’à présent
					uniquement le CEP à Antanarivo (voir rapport de Mamy supra) mais il
					tend à se développer
				</li>
				<li>
					En Bulgarie : l’activité d’accueil de marginaux, drogués ou de
					personnes en réinsertion sociale s’effectue à Montana et à Slivovic
					(voir rapport d’activité de Damien Dubois supra)
				</li>
				<li>
					En guinée : nous soutenons le travail de l’orphelinat et, plus
					globalement du centre Esaïe 58 qui reçoit, héberge et donne un
					enseignement à des jeunes sans familles et/ou défavorisé (voir rapport
					de Daniel Tonlo)
				</li>
			</ul>
			<Image src="https://www.dropbox.com/s/1uey4ak71l58s12/6bae631c3b0c30240d9c0593c5c45603a3a8c1a2.png?raw=1" />
			<p>
				Le trésorier présente sommairement la nature de ces dépenses il souligne
				également que sur l’ensemble de nos activités il s’agit d’un travail qui
				ne mobilise pas beaucoup notre budget global et qui augmente peu.
				L’orientation de ce travail et l’engagement financer que cela représente
				est soumise au vote des membres.
			</p>
			<VoteRadioBouton
				numVote="tajAg3"
				self={props.self}
				defaultPour={true}
				titre="Vote d’approbation sans réserve des dépenses engagées pour soutenir le travail en Bulgarie, Madagascar et en Guinée"
			/>
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* // 4 */}
			<Titre2
				couleur={tools.VIOLET}
				texte="IV. QUATRIEME DELIBERATION - APPROBATION DU SOUTIEN A L’ONG SIS A MADAGASCAR"
			/>
			<p>
				Durant l’année écoulée des formalités ont été effectuées pour faire une
				demande de siège à Madagascar pour permettre le développement d’un
				travail d’accueil et de soutien au plus défavorisé dans un centre nommé
				Tsarafaritra sis à Antsirabe (voir supra et le rapport d’activité de
				Jean David Bopp).
			</p>
			<p>
				Vis-à-vis des autorités locales ce travail nous oblige à remettre
				annuellement des rapports d’activités au département, d’honorer les
				exigences des ministères techniques résumées dans un tableau qui sera
				joint à l’accord de siège.
			</p>
			<p>
				Cela nous engage aussi financièrement et moralement en tant
				qu’association. Le conseil d’administration propose d’allouer un budget
				de quinze milles (15.000) euros par an à ce travail en contrepartie
				d’une traçabilité comptable permettant de vérifier que ces sommes sont
				bien utilisées dans un usage conforme à notre objet associatif. Il
				s’agit d’une somme dite plafond qui pourra être réévaluée par
				l’assemblée générale en fonction de l’évolution des travaux.
			</p>
			<VoteRadioBouton
				numVote="tajAg4"
				self={props.self}
				defaultPour={true}
				titre="Vote d’approbation sans réserve de ce soutien financier à l’ONG Timothée accueil en création soutenant le travail du centre Tsarafaritra sis à ANTANANARIVO"
			/>
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* // 5 */}
			<Titre2
				couleur={tools.ORANGE_CLAIR}
				texte="V. CINQUIEME DELIBERATION - APPROBATION DES COMPTES ANNUELS 2020"
			/>
			<p>
				Le trésorier communique les comptes annuels tels qu’établis le 31
				décembre 2020. Un commentaire de gestion est également communiqué. Ces
				documents sont communiqués en pièce jointe aux membres.
			</p>
			<p>
				Le comptable, Samuel Champault ajoute quelques remarques afin de
				permettre à l’ensemble des membres d’avoir quelques points de repères
				sur les différents éléments comptables qui sont jointes aux présentes.
			</p>
			<p className="text-center">
				<b>Remarques sur le compte de résultat</b>
			</p>
			<div className="text-center">
				<img
					className="img-fluid text-center"
					src="https://www.dropbox.com/s/s54p8vj79wtvdlv/b730c40a9536d4be8834b25013f7b388266524be.png?raw=1"
					alt=""
				/>
			</div>
			<p>
				Il est intéressant de voir que malgré la pandémie les ressources ont
				globalement peu diminué. Logiquement la fraction correspondant aux «
				participations aux frais » a diminué puisque très peu de camps ont pu
				être maintenu. Il faut donc souligner qu’il y a une générosité qui s’est
				maintenue durant cette année.
			</p>
			<p>
				Si, dans l’ensemble, nous observons que beaucoup de gros dons viennent
				en fin d’année nous pouvons souligner que la situation fait que
				plusieurs donateurs nous soutiennent désormais à distance par des
				virements bancaires qui sont de plus en plus réguliers. Sans changer
				fondamentalement nos ressources, cela permet d’avoir une facilité de
				gestion appréciable.
			</p>
			<Image src="https://www.dropbox.com/s/x38lpp3ih181ox5/ab7ee0ef3bea8388d7697f85b749be2db4757f06.png?raw=1" />
			<p>
				L’évolution des charges de l’association sur les trois dernières années
				nous indique plusieurs choses. Tout d’abord l’activité se développe
				considérablement, les collaborateurs s’ajoutent et cela s’observe sur
				l’augmentation progressive de la masse salariale. Ensuite, si les
				ressources ont quasiment été à l’équilibre sur l’année précédente les
				frais d’accueil ont, quant à eux, sensiblement diminués (- 105 446
				euros) sur l’année écoulée. Cela s’explique également par le fait que
				peu de camps ont été tenus durant l’année.
				<br />
				Sur l’année <b>un excédent de 187 755 euros</b> s’est dégagé.
			</p>
			<p className="text-center">
				<b>Remarque sur le Bilan</b>
			</p>
			<Image src="https://www.dropbox.com/s/yo63zm4o546iomk/222babec2ef13d3dfe5de0c71247d6b36d655fee.png?raw=1" />
			<p>
				Le bilan est un document comptable qui nous donne une « photo » de notre
				patrimoine acquis depuis le début de l’œuvre associative. Cela nous
				permet d’observer que des investissements conséquents sont fait sur nos
				lieux de vies. Cette année, l’investissement est évalué à 450 000 euros.
				Cela s’explique notamment par les frais engagés pour l’extension de la
				maison d’accueil sis à Anduze.
			</p>
			<Image src="https://www.dropbox.com/s/akulay9ndgp6eu0/f9d26ed4deee09ccbe06970f22bc27000eb40846.png?raw=1" />
			<p>
				L’ensemble du coût de revient de l’extension, estimé au 31 octobre 2021
				s’élève à <b>994 000 euros</b>. Ce cout de revient est global, il
				s’étale principalement sur trois années (2019-2020-2021).
			</p>
			<p>
				Le coût de la main d’œuvre ne comptabilise{" "}
				<b>que les salariés de l’association</b> et non l’ensemble des bénévoles
				qui ont très nombreux pour aider aux travaux.
			</p>
			<p>
				Comme évoqué précédemment, la situation sanitaire a permis d’effectuer
				ces travaux au bon moment étant entendu qu’une inflation considérable
				des prix des matériaux s’est observée au milieu de l’année 2021. Il y a
				là quelque chose d’assez inespéré car plusieurs obstacles auraient pu
				ralentir l’avancée des travaux notamment des routes bloquées longtemps
				empêchant l’approvisionnement.
			</p>
			<VoteRadioBouton
				numVote="tajAg5"
				self={props.self}
				defaultPour={true}
				titre="Vote d’approbation sans réserve des comptes et du rapport de gestion de l’année 2020 en donnant quitus aux membres du conseil d'administration de l’association de l'exécution de leur mandat au titre de l'exercice écoulé"
			/>
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* // 6 */}
			<Titre2
				couleur={tools.BLEU_TURQUOISE}
				texte="VI. SIXIEME DELIBERATION – AFFECTATION DE L’EXCEDENT"
			/>
			<p>
				Le bureau propose à l'assemblée générale d'affecter l'excédent de
				l'exercice clos le 31 décembre 2020 comme suit : <b>187 755 €</b> aux
				fonds associatifs sans droit de reprise.
			</p>
			<p>
				Le secrétaire de séance souligne qu’un tel excédent est exceptionnel et
				souligne une générosité particulière.
			</p>
			<VoteRadioBouton
				numVote="tajAg6"
				self={props.self}
				defaultPour={true}
				titre="Vote d’approbation sans réserve de l’affectation de l’excédent de trésorerie sur l’année écoulée"
			/>
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* // 7 */}
			<Titre2
				couleur={tools.ROSE}
				texte="VII. SEPTIEME DELIBERATION – APPROBATION DU BUDGET 2021"
			/>
			<Titre3 couleur={tools.ROSE} texte="Budget des dépenses 2021" />
			<Image src="https://www.dropbox.com/s/ji67eigf66yxera/9b3044af0eb36e60e22708395549fe1ea65a4fb8.png?raw=1" />
			<p>
				Quelques remarques : sur dix ans le budget a augmenté de 299 535euros.
				L’association a besoin en moyenne de 3985 euros par jours pour ne pas
				connaitre de difficulté de trésorerie.
				<br />
				En ce qui concerne le budget prévisionnel des produits il s’agit
				principalement de participation aux frais et de libéralités. Ils
				représentent 91% des produits tandis que les « produits d’exploitation »
				et les « produits divers » ne sont budgétisés uniquement pour 121 000
				euros et 19 200 euros. L’association vit donc des dons.
			</p>
			<Titre3 couleur={tools.ROSE} texte="Budget d’investissement 20201" />
			<Image src="https://www.dropbox.com/s/w2v6pc62g916jsc/5f8c92de09c287fc9f409d6abfbb39da2bb8065b.png?raw=1" />
			<Image src="https://www.dropbox.com/s/ehopjx9mvi5v9o0/c13b78eb768c8972b1300f627983d9e36ca1551a.png?raw=1" />
			<p>
				Le bureau propose à l'assemblée générale un projet de budget 2021. Il
				s’agit d’un budget prévisionnel équilibré fixé à 1 590 466 euros, il
				comprend les achats non stockés, les services extérieurs, les charges de
				personnel, les impôts et taxes, les charges diverses et quelques
				dotations aux amortissements etc. Ce budget prévisionnel comprenant les
				dépenses et les investissements est soumis à l’approbation des membres.
			</p>
			<p>
				Sur l’ensemble des éléments comptables sus évoqués, les rapports du
				commissaire aux comptes Monsieur Jean Paul Lacombe sont joints aux
				présentes.
			</p>
			<p>
				Pour rappel, l’objet de sa mission légale qui est d’apporter un regard
				différent pour s’assurer que l’ensemble des éléments qui rentrent dans
				les comptes de l’association soient conformes à l’objet social. Il
				précise que sa mission s’effectue en toute transparence et indépendance
				juridique et financière. Il a aussi la responsabilité d’exprimer une
				opinion sur les comptes : pour cet exercice il s’agit d’une
				certification sans réserve.
			</p>
			<VoteRadioBouton
				numVote="tajAg7"
				self={props.self}
				defaultPour={true}
				titre="Vote d’approbation sans réserve du budget prévisionnel de l’association tel que présenté"
			/>
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* // 8 */}
			<Titre2
				couleur={tools.ORANGE}
				texte="VIII. HUITIEME DELIBERATION : CONSEIL D’ADMINISTRATION ET BUREAU"
			/>
			<Titre3 couleur={tools.ORANGE} texte="Membres Conseil d’administration" />
			<p>
				L'association est administrée par un conseil d'administration de 10 à 40
				membres élus pour trois ans par l'assemblée générale et choisis en son
				sein. Les membres sortants sont rééligibles. Le conseil d'administration
				se réunit au moins trois fois par an sur convocation du Secrétaire
				ordonnée par le président.
			</p>
			<p>
				Les questions à l'ordre du jour y sont mentionnées. Les décisions sont
				prises à la majorité absolue des membres présents. La présence de la
				moitié des membres plus un est indispensable à la validité des
				déclarations. Le conseil d’administration a les pouvoirs les plus
				étendus pour gérer les affaires de l’association et la représenter à
				l’égard des tiers.
			</p>
			<p className="text-center pt-3">
				<b>
					Au 31 décembre 2020, le conseil d’administration est composé comme
					suit
				</b>
			</p>
			<TableauMembresTAJ />
			<p>
				L’ensemble de ces administrateurs sont élus pour une durée de trois ans,
				ils ont été élus à la majorité requise dans les statuts le 20 décembre
				2020 et sont donc encore en exercice jusqu’au 20 décembre 2023.
			</p>
			<Titre3 couleur={tools.ORANGE} texte="Nomination du bureau" />
			<p>
				<b>
					Le conseil d'administration élit en son sein un Bureau comprenant un
					président, un secrétaire et un trésorier.
				</b>
			</p>
			<ul>
				<li>
					Le président représente l'association auprès des pouvoirs publics,
					ordonnance les dépenses, signe valablement tout acte et représente
					l'association en justice.
				</li>
				<li>
					Le secrétaire est chargé de tout ce qui concerne la correspondance. Il
					rédige les procès-verbaux tant des assemblées générales que des
					conseils d'administration. C'est également lui qui tient le registre
					spécial.
				</li>
				<li>
					Le trésorier perçoit les recettes, paye les dépenses et fait tous les
					versements, virements et retraits sur les comptes de l'association. Un
					autre membre du conseil d’administration peut recevoir délégation du
					conseil pour accomplir tout ou partie des mêmes opérations sous sa
					seule signature.
				</li>
			</ul>
			<p>
				<b>
					Sur proposition du conseil d’administration il est proposé que le
					bureau de l’association soit désigné de la façon suivante pour une
					durée de 2 ans
				</b>
			</p>
			<ul>
				<li>
					Monsieur Daniel Issarte, demeurant mas de Pelissou (30) Tornac en tant
					que Président
				</li>
				<li>
					Monsieur David Gutscher, demeurant rue des deux ponts Cholet (49) en
					tant que secrétaire.{" "}
				</li>
				<li>
					Samuel Champault, demeurant avenue du mondial de rugby Montpellier
					(34) en tant que trésorier.
				</li>
			</ul>
			<VoteRadioBouton
				numVote="tajAg8"
				self={props.self}
				defaultPour={true}
				titre="VOTE de la Résolution – approbation de la désignation du bureau"
			/>
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* // 9 */}
			<Titre2
				couleur={tools.VERT_FONCE}
				texte="IX. NEUVIEME DELIBERATION : DELEGATION DE POUVOIR"
			/>
			<p>
				Le conseil d’administration, ayant les pouvoirs les plus étendus pour
				gérer les affaires de l’association propose de déléguer limitativement
				la trésorerie dans un fonctionnement par « caisse locale » dans nos
				lieux d’accueil. Cette délégation concernera principalement les lieux
				suivants
			</p>
			<ul>
				<li>Maison d’accueil - 1654 chemin de recoulin à Anduze (30)</li>
				<li>Château du Riffray sis à Chanteloup (35) </li>
				<li>
					Maison à Say - 9 impasse des palettes lieu-dit « Say Sud » à Gelles
					(63)
				</li>
				<li>Maison d’accueil Bernard sis à Fouday (68)</li>
				<li>Maison à Metz, 6 Rue Jean Burger, Saint-Julien-lès-Metz (57)</li>
			</ul>
			<p>
				La délégation ne sera possible que pour des membres dument proposés par
				le bureau et approuvés en conseil d’administration. Dans ces lieux, les
				trésoriers délégués seront investis des pouvoir les plus étendus pour
				percevoir les recettes, payer les dépenses et faire tous les versement,
				virement et retraits sur les comptes de l'association dans la limite
				d’opération dépassant trente milles (30.000) euros. Si par
				extraordinaire une opération dépassait ce montant, une autorisation
				expresse du bureau de l’association sera requise.
			</p>
			<p>
				Cette délégation n’est pas exclusivement réservée aux trésoriers
				gravitant autour de ces lieux limitativement énuméré, d’autres
				trésoriers peuvent être proposés par le bureau. Ces derniers seront
				investis des pouvoir les plus étendus pour percevoir les recettes, payer
				les dépenses et faire tous les versement, virement et retraits sur les
				comptes de l'association dans la limite d’opérations dépassant dix
				milles (10 000) euros. Si par extraordinaire une opération dépassait ce
				montant, une autorisation expresse du bureau de l’association sera
				requise.
			</p>
			<VoteRadioBouton
				numVote="tajAg9"
				self={props.self}
				defaultPour={true}
				titre="VOTE de la Résolution – approbation de la délégation éventuelles des fonctions de trésorier"
			/>
			{/* %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* 10 */}
			<Titre2
				couleur={tools.JAUNE_CLAIR}
				texte="X. DIXIEME DELIBERATION : POUVOIR D'ACCOMPLIR LES FORMALITES"
			/>
			<p>
				Le bureau propose à l’assemblée de donner tous pouvoirs à Monsieur David
				GUTSCHER et Monsieur Benjamin DROUILLY et, plus généralement au porteur
				d’une copie ou d’un extrait du procès-verbal de la présente assemblée
				pour accomplir les formalités nécessaires.
			</p>
			<VoteRadioBouton
				numVote="tajAg10"
				self={props.self}
				defaultPour={true}
				titre="VOTE de la Résolution - Délégation de pouvoir en vue d'accomplir les formalités"
			/>
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* // CONLUSION */}
			<Titre2 couleur={tools.VERT} texte="CONCLUSION DU PRESIDENT" />
			<p>
				Durant le confinement nous n’avons pu soutenir et conseiller que de loin
				nos collaborateurs en charge de l’accueil dans nos divers centres.
				L’orientation choisie a été de laisser la porte ouverte aux personnes
				vraiment dans le besoin en les accueillant avec vigilance mais avec foi
				car il est une loi supérieure à toutes les lois, celle de la charité et
				de l’hospitalité selon ce que nous enseigne le chapitre 58 du prophète
				Esaïe.
			</p>
			<p>
				Nous avons d’ailleurs été frappé de voir que Luther évoquait une
				protection particulière dans les périodes d’épidémie (la peste en
				l’occurrence) promise à ceux qui secourent le malheureux en s’appuyant
				notamment sur les premiers versets du psaume 41. « Heureux celui qui
				sait avoir souci du misérable, l’Éternel le délivrera au jour du malheur
				» Cette orientation a été confirmée par le fait qu’aucun des nôtres n’a
				été atteint gravement dans nos maisons d’accueil tout en ayant accepté
				de recevoir tous ceux qui frappaient à notre porte.
			</p>
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			<p className="pt-3 pb-3 text-center">
				<b>
					L’ordre du jour étant épuisé, le vote est laissé à la discrétion des
					membres.
				</b>
			</p>
			<TableauSignature />
			<h5 className="pt-5">Liste des annexes Timothée Accueil et Jeunesse</h5>
			<FichiersATelecharger liste={FICHIER_TELECHARGER_TAJ} />
		</div>
		<div className="text-right pt-5 pb-5">
			<button
				onClick={event => {
					event.preventDefault();
					props.self.setState(
						{
							menu: 2
						},
						() => window.scrollTo(0, 450)
					);
					// if (
					// 	props.self.state.elements.tajAge1 !== "" ||
					// 	props.self.state.voteOk
					// ) {
					// 	props.self.setState(
					// 		{
					// 			menu: 2
					// 		},
					// 		() => window.scrollTo(0, 450)
					// 	);
					// } else {
					// 	console.log("pas de validation");
					// 	props.self.setState({
					// 		showModal2: true,
					// 		messageModal2:
					// 			"Vous devez voter l’approbation de la modification statutaire !"
					// 	});
					// }
				}}
				className="btn btn-primary center-block pl-5 pr-5"
				disabled={props.self.state.disabledBoutonSuivant1}
			>
				Suivant
			</button>
		</div>
	</>
);
