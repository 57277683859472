import React from "react";
// import MediaSearch from 'react-media-search-view';
import MediaSearch from "../MediaSearch";
// import { search } from '../../api/api';
import { api } from "@mitim/react-mitim";
import Page from "../Page";

class Search extends Page {
	render() {
		return <MediaSearch {...this.props} search={api.search} />;
	}
}

export default Search;
