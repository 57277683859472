// Librairies
import React from "react";
import { Redirect, Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import Button from "@material-ui/core/Button";
import { auth, Storage } from "@mitim/react-mitim";
import Page from "../Page";
import CustomInput from "../CustomInput";

class Login extends Page {
	state = {
		mail: { value: "", errorMessage: "" },
		password: { value: "", errorMessage: "" },
		redirectToReferrer: false
	};

	handleChange = event => {
		// this.setState({ [event.target.name]: event.target.value });
		this.setState({
			[event.target.name]: {
				value: event.target.value
			}
		});
	};

	handleSubmit = event => {
		event.preventDefault();
		if (!event.target.checkValidity()) {
			// form is invalid! so we do nothing
			return;
		}
		auth.authenticate(this.state.mail, this.state.password, (values, data) => {
			// const values = JSON.stringify(data.data);
			if (values !== "null") {
				var authorities = new Array();
				data.data.authorities.forEach(function(authority) {
					authorities.push(authority.name);
				});
				// localStorage.setItem("authorities", JSON.stringify(authorities));
				Storage.setAuthoritiesJson(JSON.stringify(authorities));
				console.log(values);
				// localStorage.setItem("user", values);
				Storage.setUserJson(values);

				this.setState({ redirectToReferrer: true });
			} else {
				this.setState({
					password: {
						errorMessage: "Le mot de passe ou l'indentifiant est incorrect !",
						value: ""
					}
				});
			}
		});
	};

	handleInvalid = event => {
		this.setState({
			[event.target.name]: {
				errorMessage: event.target.validationMessage,
				value: event.target.value
			}
		});
	};

	render() {
		const { t } = this.props;
		let { from } = this.props.location.state || { from: { pathname: "/" } };

		if (this.state.redirectToReferrer) return <Redirect to={from} />;
		return (
			<div
				className="container login"
				style={{ marginTop: "100px", marginBottom: "120px" }}
			>
				{/* <h3 className="text-center pb-5">
					Vous pouvez utiliser votre adresse e-mail et le mot de passe de
					l'ancien site
					<br />
					En cas de problème n'hésitez pas à nous contacter
				</h3> */}
				<div className="row">
					<div className="col-md-2"></div>
					<div className="col-md-8">
						<form onSubmit={this.handleSubmit} noValidate className="pl-2 pr-2">
							<CustomInput
								required
								id="mail"
								label={t("login.mail")}
								type="email"
								onChange={this.handleChange}
								onInvalid={this.handleInvalid}
								state={this.state.mail}
								dataparse="lowerCase"
								autoComplete="username email"
							/>
							<CustomInput
								required
								id="password"
								label={t("login.password")}
								type="password"
								onChange={this.handleChange}
								onInvalid={this.handleInvalid}
								state={this.state.password}
								autoComplete="current-password"
								center
							/>
							<div className="row justify-content-center mt-5">
								<div className="col-auto">
									<Link className="lien" to="/compte-oublie">
										Informations de compte oubliées ?
									</Link>
								</div>
								<div className="col-auto">
									<Button
										component={Link}
										to="/inscription"
										variant="contained"
										color="primary"
									>
										<span style={{ color: "#fff" }}>
											{t("login.creer-compte")}
										</span>
									</Button>
								</div>
								<div className="col-auto">
									<Button variant="contained" color="secondary" type="submit">
										{t("menu.login")}
									</Button>
								</div>
							</div>
						</form>
					</div>
				</div>
				<div className="col-md-2"></div>
			</div>
		);
	}
}

export default withTranslation()(Login);
