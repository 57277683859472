import React from "react";
import ContactForm from "./ContactForm";
import $ from "jquery";
import ModuleMaisonAccueil from "../ModuleMaisonAccueil/ModuleMaisonAccueil";
import Page from "../Page";

export default class Contact extends Page {
	constructor(props) {
		super(props);
		this.state = { lienMap: "" };
		// $('html,body').scrollTop(0);
		$("html, body").animate(
			{
				scrollTop: 0
			},
			"slow"
		);
	}

	contenu() {
		return (
			<div className="container">
				<div className="">
					<ContactForm />
				</div>
			</div>
		);
	}

	render() {
		return (
			<>
				<ModuleMaisonAccueil
					titre="Nous contacter"
					sousTitre=""
					contact={{
						nom: "Mission Timothée",
						titreAdresse: "Mission Timothée",
						adresse: ["1654 chemin de Recoulin", "30140 ANDUZE"],
						tel: ["04 66 61 73 61"],
						mail: [""],
						url: "",
						lienMap:
							"https://www.google.com/maps/place/Mission+Timoth%C3%A9e/@44.0727088,3.9700965,15z/data=!4m5!3m4!1s0x0:0x524de8ac9dc2899!8m2!3d44.0727088!4d3.9700965"
					}}
					contenu={this.contenu()}
				/>
			</>
		);
	}
}
