import React from "react";
import Page from "../Page";
import { withTranslation } from "react-i18next";
import { ModuleEncartListMt, tools, api } from "@mitim/react-mitim";
import { NavLink, Link } from "react-router-dom";
import { titleCase } from "../../utils/functions";
import download from "../../resources/icons/download.svg";
import { DATA } from "./data";
import { getKeyValuePublique } from "../Constants";

class EspaceCandidatsCours extends Page {
	constructor(props) {
		super(props);
	}

	state = {
		acces: true,
		type: "",
		dataCoursAudios: [],
		dataDevoirsBibliques: [],
		dataDevoirsDogmatique: []
	};

	componentDidMount() {
		super.componentDidMount();
		this.setState({ type: this.props.match.params.type });
		getKeyValuePublique("formation-cours-audios", val => {
			this.setState({
				dataCoursAudios: val
			});
		});
		getKeyValuePublique("formation-devoirs-bibliques", val => {
			this.setState({
				dataDevoirsBibliques: val
			});
		});
		getKeyValuePublique("formation-devoirs-dogmatique", val => {
			this.setState({
				dataDevoirsDogmatique: val
			});
		});
	}

	componentDidUpdate(prevProps) {
		if (this.props.match.params.type !== prevProps.match.params.type) {
			console.log("update");
			this.setState({ type: this.props.match.params.type });
		}
	}

	render() {
		const { t } = this.props;
		let classes = {
			filter: "col-12 col-md-4 col-lg-3 col-xl-3 col-xxl-2",
			result: "col-12 col-md-8 col-lg-9 col-xl-9 col-xxl-10",
			pagination: "pt-5"
		};
		const CATEGORIES = [
			"devoirs bibliques",
			"devoirs dogmatiques",
			"cours audio"
		];
		const categories = CATEGORIES.map(cat => (
			<div className="col-auto category pl-5" key={cat}>
				<NavLink exact to={"/espace-candidats-cours/" + cat}>
					{titleCase(cat)}
				</NavLink>
			</div>
		));

		const Element = props => (
			<div className="col-12 mt-1 result" style={{ backgroundColor: "#fff" }}>
				<div className="row pl-md-3">
					<div className="col-12 mt-1 result telechargementCoursCandidats">
						<a href={props.data.lien} className="lien">
							<div>
								<span
									className="text-white pl-3 pr-3 ft-lato-regular pt-2 pb-1"
									style={{
										fontSize: "0.8em",
										backgroundColor: props.data.couleurTitre
									}}
								>
									{props.data.titre}
								</span>
							</div>
							<div className="telechargementCoursCandidatsSelect pl-2 pr-2">
								<div
									className="pt-2 text-black ft-alegreya-sans-light"
									style={{ fontSize: "1em" }}
								>
									{props.data.contenu}
									<img style={{ float: "right" }} src={download} width={18} />
								</div>
							</div>
						</a>
						<hr />
					</div>
				</div>
			</div>
		);

		const Audios = props => (
			<>
				<h5
					style={{ display: "block" }}
					className="ft-lato-regular text-noir pl-4 pb-4 pt-4"
				>
					ANNÉE {props.annee}
				</h5>
				{this.state.dataCoursAudios.map(elem => (
					<>
						{elem.annee == props.annee && (
							<Element
								key={elem.lien}
								data={{
									lien: elem.lien,
									couleurTitre: tools[elem.couleurTitre],
									titre: elem.titre,
									contenu: <>{elem.contenu}</>
								}}
							/>
						)}
					</>
				))}
			</>
		);

		return (
			<>
				{this.state.acces && (
					<>
						<div className="container-fluid">
							<div className="row align-items-center categories-navbar">
								<div className={classes.filter + " mt-3 my-md-3"}></div>

								<div className={classes.result}>
									<div className="row categories my-3">{categories}</div>
								</div>
							</div>

							<div className="row">
								<div className={classes.filter + " filter-list"}></div>
								<div
									className={classes.result}
									style={{ backgroundColor: "#fff" }}
								>
									<p>
										<br />
									</p>
									<div className="row pl-md-3">
										{this.state.type == "devoirs bibliques" &&
											this.state.dataDevoirsBibliques.map(elem => (
												<>
													<Element
														key={elem.lien}
														data={{
															lien: elem.lien,
															couleurTitre: tools[elem.couleurTitre],
															titre: elem.titre,
															contenu: <>{elem.contenu}</>
														}}
													/>
												</>
											))}
										{this.state.type == "devoirs dogmatiques" &&
											this.state.dataDevoirsDogmatique.map(elem => (
												<>
													<Element
														key={elem.lien}
														data={{
															lien: elem.lien,
															couleurTitre: tools[elem.couleurTitre],
															titre: elem.titre,
															contenu: (
																<div
																	dangerouslySetInnerHTML={{
																		__html: elem.contenu
																	}}
																/>
															)
														}}
													/>
												</>
											))}
										{this.state.type == "cours audio" && (
											<>
												<Audios annee="2020" />
												<Audios annee="2019" />
												<Audios annee="2018" />
											</>
										)}
										<p>
											<br />
											<br />
											<br />
											<br />
											<br />
										</p>
									</div>
								</div>
							</div>
						</div>
					</>
				)}
			</>
		);
	}
}

export default withTranslation()(EspaceCandidatsCours);
