import React from "react";
import Page from "../Page";
import "./Lrft.scss";
import { Abonnement } from "./Abonnement";
import { Link } from "react-router-dom";
import { Bouton } from "../Podcats/Bouton";

export class Lrft extends Page {
	state = {};

	render() {
		// const BlocOption = props => (
		// 	<>
		// 		<div className="col-md-6">
		// 			<div className="blocOption">
		// 				<section className="p-3">
		// 					<h3>{props.titre}</h3>
		// 					<ul className="text-center">
		// 						<li>{props.l1}</li>
		// 						<li>{props.l2}</li>
		// 					</ul>
		// 					<h1 className="text-black pt-3 pb-3 text-center">{props.prix}</h1>
		// 					<div className="text-center">
		// 						<button
		// 							type="button"
		// 							className="btn btn-success text-center"
		// 							onClick={() => this.handleSubmitChoixPaiement("RIB")}
		// 						>
		// 							Commander
		// 						</button>
		// 					</div>
		// 				</section>
		// 			</div>
		// 		</div>
		// 	</>
		// );

		return (
			<>
				<div className="Lrft">
					<div className="container bloc1">
						<h1>Revue</h1>
						<h2>Lumières reçues au fil du temps</h2>
						<p className="pt-5">
							Partager au fur et à mesure les réflexions du moment, telle était
							l’idée à l’origine du lancement de la collection « Lumières reçues
							au fil du temps ». Ayant plus que jamais à coeur de poursuivre
							notre travail d’édition dans la ligne de la Mission Timothée, « ce
							que tu as reçu confie-le à d’autres », il nous a semblé que nous
							pouvions aller plus loin en diffusant un panel de sujets un peu
							plus élargi par le biais de différents articles pouvant
							constituer, au final, une sorte de revue.
						</p>
						<p>
							Cette formule permet de pouvoir bénéficier du travail de plusieurs
							d’entre nous et notamment de ceux qui, par le biais de leurs
							études de théologie ou d’autres matières, ont été conduits à «
							plancher » sur des sujets qui peuvent s’avérer riches en intérêt
							pour l’Eglise. C’est ainsi que nous innovons dans ce cahier numéro
							5 en introduisant, en plus d’une exhortation, d’autres sujets
							traitant aussi d’histoire et de société.
						</p>
						{/* <Link className="lien" to="/revues/revues">
							Voir toutes les revues
						</Link> */}
						<div className="text-center pt-5 pb-5">
							<Bouton
								texte="Voir toutes les revues"
								lien={"/revues/revues"}
								couleur={"rgb(251, 128, 51)"}
							/>
						</div>
					</div>

					<div className="container-fluid blocTarifs no-gutters">
						<div className="container">
							<h2 className="pb-4 pt-4 text-center">Abonnement</h2>

							<Abonnement />
						</div>
					</div>
				</div>
			</>
		);
	}
}
