import React from "react";
import { VoteRadioBouton } from "../AGVote/VoteRadioBouton";
import { EnteteTaj, EnteteMT } from "../AGVote/AGVote";
import { tools } from "@mitim/react-mitim";
import { FichiersATelecharger, FICHIER_TELECHARGER_TAJ } from "./DATA";

import {
	T2,
	T3,
	T4,
	T5,
	T6,
	Titre2,
	Titre3,
	Titre4,
	Titre5,
	Titre6,
	P3,
	P4,
	P5,
	Paragraph3,
	Paragraph4,
	Paragraph5,
	Image,
	TableauMembresTAJ,
	TableauSignature,
	TableauFormalitesBancaires,
	TableauSignatureAG2024
} from "../AGVote/Fonctions";

export const ContenuTaj = props => (
	<>
		<div className="voteAg pt-5 mb-5">
			<br />
			<EnteteTaj />
			<h1 className="">
				BULLETIN DE VOTE PAR CORRESPONDANCE
				<br />
				ASSEMBLEE GENERAL ORDINAIRE 2024
				<br />
				(PORTANT SUR L’EXERCICE 2023)
			</h1>

			<p>
				Le 3 juin 2024, le bureau de l’association Timothée Accueil et Jeunesse
				a lancé une consultation écrite aux adresses courriels indiquées par les
				membres conformément à l’article 9 de ses statuts. Les membres peuvent
				voter ou émettre des remarques, réserves et avis via leur espace
				personnalisé sur le site www.mission-timothee.fr. Le bureau indique que
				cette consultation prendra fin le 22 juin 2024, les membres n’ayant pas
				voté seront réputés absents. Une fois la procédure de vote close, le
				bureau enverra aux membres une copie du procès-verbal d’assemblée
				générale tenue par écrit.
			</p>

			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* // PREALABLE */}
			<T2 couleur={tools.JAUNE_CLAIR}>
				DELIBERATION PREALABLE – COMPOSITION DU BUREAU
			</T2>
			<p>
				<b>
					Le conseil d’administration propose à l’assemblée la composition
					suivante du bureau de séance :
				</b>
			</p>
			<ul>
				<li>
					Jérémie Loiret, préside la séance en sa qualité de président de
					l'association
				</li>
				<li>David GUTSCHER, trésorier présente les comptes.</li>
				<li>
					Guilhem JAUSSAUD, secrétaire de l’association assure la fonction de
					secrétaire de séance.
				</li>
			</ul>
			<VoteRadioBouton
				numVote="tajAgPre"
				self={props.self}
				defaultPour={true}
				titre="VOTE d’approbation de la composition du bureau"
			/>
			<p className="pt-2">
				Sont nommés scrutateurs et acceptent cette fonction : Jonathan NOLOT et
				Jérémie DROUILLY.
				<br />
			</p>
			<p>
				La Société AUDIT ASSOCIES MÉDITERRANÉE dont le siège social est situé
				125 rue du professeur Antonin Balmes MONTPELLIER (34070) représentée par
				Monsieur Sylvain Raynal, commissaire aux comptes de l'association, a été
				régulièrement convoquée.
			</p>
			<p>
				Conformément à ce qui a été indiqué dans la convocation, l’ordre du jour
				suivant sera évoqué :
			</p>
			<ul>
				<li>Validation du précédent procès-verbal.</li>
				<li>Rapports d’activités.</li>
				<li>Rapports financiers et approbation des comptes annuels.</li>
				<li>Affectation du résultat.</li>
				<li>Approbation du budget d’investissement et de fonctionnement</li>
				<li>Pouvoirs en vue des formalités juridiques et bancaires.</li>
			</ul>
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}

			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* // 1 */}
			<T2 couleur={tools.VERT}>
				1. PREMIÈRE DELIBERATION - APPROBATION DU PROCES-VERBAL DE L’ANNEE
				PRECEDENTE
			</T2>
			<P3>
				Chacun a reçu le procès-verbal de l’Assemblée générale tenue le 25
				novembre 2023 portant sur l’année 2022. Plusieurs exemplaires sont
				également consultables.
			</P3>
			<VoteRadioBouton
				numVote="tajAg1"
				self={props.self}
				defaultPour={true}
				titre="VOTE D’APPROBATION DU PRÉCÉDENT PV d’ASSEMBLEE GENERALE ORDINAIRE"
			/>
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}

			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* // 2 */}
			<Titre2
				couleur={tools.BLEU_FONCE}
				texte="2. DEUXIÈME DELIBERATION - RAPPORT MORAL ET D’ACTIVITE"
			/>
			<T3 couleur={tools.BLEU_FONCE}>
				2.1. TRAVAIL MISSIONNAIRE EN BULGARIE – DAMIEN DUBOIS
			</T3>
			<P3>
				Lors de cette année 2023, nous avons accueilli pendant cinq mois une
				famille au sein même des locaux de l’église. Ils vivaient à cinq dans
				une pièce délabrée de 9m². Ensuite nous avons eu la joie d’organiser un
				camp d’enfants et d’adolescents à Slivovic. Nous avons associé à notre
				groupe de jeunes de Montana des enfants extrêmement défavorisés d’un
				village de la région. Pour le camp nous avons reçu l’aide de Daniel de
				Maestri et Sah’avann. Y ont assisté aussi une femme de l’atelier avec
				ses trois enfants ainsi que Christian, un jeune que nous aidons et qui
				essaye de sortir de son alcoolisme. L’ambiance générale était très
				encourageante. Nous aspirons à en organiser un nouveau l’année
				prochaine.
			</P3>
			<P3>
				Depuis fin août Ognian, un homme de 65 ans vit à la maison. Malade de la
				tuberculose, d’une cirrhose et du cœur, les médecins ne lui donnaient
				pas beaucoup de jours. Le connaissant depuis plus de dix ans, à la suite
				du travail en prison, et aussi encouragé par la demande du cardiologue
				nous avons décidé de l’héberger à la maison. Depuis il s’est
				littéralement rétabli. Un autre homme vit aussi chez nous. Il s’appelle
				Miro, il a 24 ans. Nous le connaissons depuis ses six ans. Il était
				alors dans l’orphelinat de Berkovitza que nous visitions régulièrement.
				Ils étaient une soixantaine d’enfants gardés par deux surveillants. Les
				conditions étaient épouvantables. Et pour calmer les plus difficiles,
				ils les ensuquaient avec des neuroleptiques. Miro est ainsi sorti de
				l’orphelinat, complètement dépendant aux médicaments et avec un retard
				au niveau psychique. Ayant très peu de notion du temps, Miro peut venir
				sonner à la porte à 3h de matin, en ayant l’impression qu’il est minuit.
				Nous avons donc décidé d’améliorer notre abri à vélos, où il allait
				souvent se réfugier, pour en faire une petite chambre indépendante. Il
				est heureux d’avoir son petit chez lui. Nous espérons qu’il aura soif de
				l’évangile.
			</P3>
			<P3>
				Nous poursuivons nos tournées hebdomadaires dans les villages de la
				région. Chaque mardi ou mercredi je vais à Slivovic. Le mardi soir nous
				avons une réunion dans un village, Doctor Iosifovo. Là-bas les
				conditions sont rudes, il n’y a ni électricité ni eau courante. Le
				vendredi matin nous avons une réunion à l’atelier de couture (outils de
				réinsertion soutenu par l’association RETIM), et l’après-midi nous nous
				rendons dans deux villages pour des réunions bibliques.
			</P3>
			<P3>
				Toutes les trois semaines, nous réunissons le samedi les jeunes de
				Montana pour des activités de jeunesse avec Lydie-Anne. Tous les
				dimanches après-midi, Marie-Neige et Lydie-Anne continuent un travail de
				longue haleine auprès des enfants Tziganes de Zanogéné. C’est un village
				retiré à flanc de montagne. La plupart des enfants sont méprisés et
				rejetés dans leur école. Marie-Neige et Lydie-Anne en plus de l’école
				biblique, leur font des cours d'alphabétisation et mathématique. Les
				enfants ont en général beaucoup de joie à s’y retrouver. A ce programme
				s’ajoute les nombreux imprévus propres au travail missionnaire.
			</P3>
			<T3 couleur={tools.BLEU_FONCE}>2.2. TRAVAIL MISSIONNAIRE A MADAGASCAR</T3>
			<T4 couleur={tools.BLEU_FONCE}>2.2.1. CEP à Tananarive</T4>

			<T5 couleur={tools.BLEU_FONCE}>a. Petit historique de rappel</T5>

			<P5>
				Daniel et Martine Loiret, missionnaires pour l’association Timothée
				accueil et jeunesse étaient venus à Madagascar avec notre frère de
				l’église de Cholet et de cette visite est créée une amitié forte de
				plusieurs années qui a abouti à une visite fraternelle à Anduze. Cette
				rencontre nous a permis de partager ce que nous avons eu à cœur,
				c’est-à-dire d’accueillir et de venir en aide aux personnes dans le
				besoin. Une petite équipe de responsable est venue en 2010 pour voir
				notre réalité sur place et de là est née l’association Mission Timothée
				madagascar-CEP (Centre d’écoute et de Partage) dont les membres du
				comité sont : Mamy Raharison, Jaona Ranaivoson, Clotilde Ranaivoson,
				Vololona Raharison et Chrystel Raharison.
			</P5>

			<T5 couleur={tools.BLEU_FONCE}>b. Début du travail</T5>
			<P5>
				Nous avons commencé par 4 enfants de la rue que nous avions invité au
				centre et de bouche à oreille d’autres enfants (une centaine)
				s’ajoutaient au petit groupe. Un repas leur a été servi car beaucoup ne
				mangeaient qu’une fois par jour. Entre temps nous avons effectué une
				visite de courtoisie auprès de la mairie qui nous a sollicité pour une
				collaboration auprès du lycée public du quartier. Le maire s’est plaint
				de mauvais comportement des lycéens et de leur difficulté à les gérer.
				Cette opportunité nous a permis de faire de nous occuper de ces
				étudiants au cep sous couvert une autorisation officielle.
			</P5>
			<T5 couleur={tools.BLEU_FONCE}>
				c. Le début de l’accueil et du soutien scolaire
			</T5>
			<P5>
				Beaucoup des jeunes que nous aidions ne pouvaient plus continuer leur
				étude après le bac, la situation précaire de leur famille en était la
				cause. Leur avenir était sombre et incertain. Dans leur désespoir et
				leur désarroi ils nous ont demandé de l’aide. C’était le commencement de
				l’accueil et de l’accompagnement, il est alors orienté vers les jeunes
				moralement, matériellement et spirituellement en difficulté. C’est un
				travail plus ou moins à moyen terme car les jeunes sont hébergés durant
				3 à 5 ans (pour certain) selon leur orientation et leur difficulté
			</P5>
			<P5>
				N’oublions pas que le pays ne connait d’aucune sorte d’aide sociale
				vis-à-vis des personnes en difficulté et que seul le travail subvienne
				au besoin des foyers, d’où l’importance de la formation pour permettre
				au jeune de briser la chaine de la pauvreté dans leur famille éprouvée.
			</P5>
			<T5 couleur={tools.BLEU_FONCE}>
				d. Pourquoi le centre d’écoute et de partage ?
			</T5>
			<P5>
				La plupart des Malgaches sont introvertis et l’éducation ne permet pas
				(surtout aux jeunes) de s’exprimer dans leurs difficultés et leurs
				frustrations. Nous avons constaté aussi que les personnes qui s’en
				occupe n’ont pas de vis-à-vis pour partager leur fardeau ou tout
				simplement du mal à s’ouvrir à leur collaborateur et semble plus à
				l’aise à le faire au CEP. Le CEP aussi est sollicité pour La vie de
				famille (couple, relation.) et la difficulté de l’éducation parentale.
			</P5>
			<T5 couleur={tools.BLEU_FONCE}>
				e. Les activités réalisées en 2023 suivant notre orientation{" "}
			</T5>
			<P5>
				Nous avons hébergé en pension complète ou en demi-pension et en rotation
				,10 jeunes pour une formation, dont les études sont soutenues par Retim
				pour certains et d’autres ponctuellement par des particuliers (Iscam).
			</P5>
			<P5>
				Nous avons 15 jeunes hébergés tous les mardis, vendredi et samedi pour
				nos réunions du soir
				<ul>
					<li>12 personnes ont demandé d’accueil temporaire dont 4 couples.</li>
					<li>4 couples pastorales ont séjourné pour quelques jours.</li>
					<li>2 familles de la mission sont venues</li>
					<li>3 missionnaires locales en visite </li>
					<li>
						60 enfants en moyenne pour la réunion d’enfant suivi de repas avec
						l’accompagnement de 10 monitrices
					</li>
					<li>
						Une formation de moniteur d’enfant avec atelier pratique a été
						initiée.
					</li>
					<li>30 jeunes en moyenne pour la réunion de jeunes.</li>
					<li>3 voyages de visites à Antsirabe. </li>
				</ul>
			</P5>
			<P5>
				Une rencontre annuelle avec les parents des enfants permet à ces
				derniers de savoir ce que nous donnons comme programme mais aussi
				l’occasion d’apporter la parole. A cela s’ajoute une rencontre à
				destination des femmes âgées et seules (une trentaine) une fois dans
				l’année (dernier week-end du mois de janvier).
			</P5>
			<P5>
				Nous avons pour objectif d’apporter l’espérance aux jeunes dans un pays
				parmi les plus pauvres conformément à notre foi chrétienne. Durant
				l’année 2023, trois étudiants ont eu leur diplôme :
				<ul>
					<li>1 sage-femme </li>
					<li>1 comptable</li>
					<li>1 licencié en marketing et commerce </li>
				</ul>
			</P5>

			<T3 couleur={tools.BLEU_FONCE}>2.3. MAISONS D’ACCUEIL </T3>
			<T4 couleur={tools.BLEU_FONCE}>2.3.1. Fouday – Arnaud Amberg</T4>
			<P4>
				L’activité de la maison en 2023 est sensiblement similaire à celle de
				2022 en termes d’accueil.
			</P4>
			<P4>
				Plusieurs personnes ont été accueillies en « séjour long », c’est le cas
				notamment de jeunes hommes en détresse envoyés par le centre d’Anduze
				(6-8 semaines), d’une femme à la rue venant de Mulhouse (2 mois), d’un
				homme venant des Ardennes (3 mois) et d’une jeune femme travaillant en
				maison de retraite (8 mois).
			</P4>

			<P4>
				Nous avons également accueilli ponctuellement des personnes en détresse
				(dépression, maladie, etc.) : ce sont des séjours généralement
				hebdomadaires avec la possibilité d’être renouvelable en fonction des
				besoins. Au fur et à mesure des années, davantage de personnes ou
				familles désireuses de se reposer viennent dans la maison pour profiter
				de cet accueil. Pour donner un chiffre, une vingtaine de personnes ont
				été accueillies pour un total d’environ 160 nuitées.
			</P4>
			<P4>
				La maison est également un bon outil pour organiser des weekends pour
				les jeunes et les étudiants. Ainsi, sur l’année 2023, quatre weekends se
				sont déroulés réunissant entre 30 et 60 participants selon la période.
			</P4>
			<P4>
				Nous organisons aussi des camps biblique, similaires à ceux d’Anduze, de
				manière régulière. Ainsi, deux camps de familles ont eu lieu dans la
				maison pendant les vacances d’avril et celles d’été, ils durent une
				semaine et rassemble entre 70 et 80 campeurs. D’autres camps sont
				organisés plus fréquemment, ce sont les camps d’entraide. Ils ont eu
				lieu pendant les vacances de février et de la Toussaint rassemblant
				entre 40 et 60 participants.
			</P4>
			<T4 couleur={tools.BLEU_FONCE}>2.3.2. Riffray – Joël Loiret</T4>
			<P4>
				Cette année, la maison a organisé deux camps supplémentaires en plus de
				celui du printemps et de l’été ; un camp « hors-saison » au mois de juin
				et un camp de famille à Noël. D’une manière générale ces camps sont très
				remplis, et il nous semble atteindre le maximum de nos capacités. Cela
				nous réjouit, c’est un sujet de reconnaissance. La maison accueille
				aussi une fois par mois des rencontres de jeunes régionales qui
				concernent collégiens et lycéens. Il y a toujours autant de bénévoles
				qui viennent aider au travail, que ce soit pour le ménage, la cuisine,
				l’entretien des bâtiments ou des espaces verts. C’est un véritable
				soutien que nous mesurons d’année en année.
			</P4>
			<P4>
				Nous avons accueilli à demeure une dizaine de jeunes garçons en
				difficulté qui ont exprimé le souhait d’être accompagnés. Ces personnes
				sont soit en étude soit en apprentissage ainsi qu’environ cinq filles
				dans la même situation. A cela s’ajoute les séjours réguliers de
				personnes qui ont besoin d’un temps de repos ou d’être entourées.
			</P4>
			<P4>
				Nous avons aussi organisé un camp de « chantier bois » pendant les
				vacances de la Toussaint. Quelques frères de l’église de Vannes et du
				Sud qui sont des professionnels nous ont bénévolement aidés dans cette
				tâche, nous les en remercions. En ce qui concerne les travaux, nous
				avons réaménagé une pièce du château (l’ancienne cuisine). C’est une
				pièce multifonction qui nous sert de salon, de salle de réunion, mais
				qui est également équipée d’une cuisine et qui peut servir pour
				l’accueil. Il y a eu de nouveau quelques aménagements au niveau de la
				longère afin de permettre des accès PMR.
			</P4>
			<P4>
				Le nombre de repas servis dans la maison en 2023 s’élève à 33358. Ce
				chiffre est en augmentation pour les raisons évoquées au début : plus de
				camp organisés et augmentation du nombre de personnes accueillies cette
				année.
			</P4>
			<T4 couleur={tools.BLEU_FONCE}>2.3.3. Anduze – Guilhem Jaussaud</T4>
			<P4>
				L’année 2023 a été marquée par une diminution relative du nombre de
				pensionnaires. Alors que nous avions servi approximativement 90000 repas
				en 2022, nous en servions 78200 en 2023. Outre le fait que nous avons
				tenu deux camps de moins durant l’année écoulée, cela s’explique aussi
				par le fait qu’en 2022 (principalement de janvier à juin), nous avions
				accueilli beaucoup de monde (notamment des couples, des familles) et
				pour de longues périodes.
			</P4>
			<P4>
				Toutefois, durant l’année 2023, plusieurs séjours, quoique plus courts
				dans la durée, ont été « concluants » dans le sens de notre vocation, et
				ont encouragé l’équipe de la maison. Vers la fin d’année 2022 nous
				étions préoccupés de recevoir de moins en moins de demandes d’accueil
				qui correspondaient réellement à l’orientation de notre maison. Nous
				recevions alors principalement des demandes d’hommes ou de femmes qui
				cherchaient une aide nécessitant un suivi et des soins psychiatriques
				trop importants que nous ne sommes pas en mesure de fournir ; d’autres,
				un accueil très long terme, comme une forme de résidence sociale ce qui
				n’est pas non plus notre optique.
			</P4>
			<P4>
				C’est avec joie que nous avons constaté une évolution importante durant
				l’année 2023. Courant mars, nous avons reçu deux hommes, qui sortaient
				tout droit de la rue et qui souffraient d’une forte addiction à
				l’alcool. Ils se sont sevrés parmi nous et ont tous deux reçu la foi en
				Jésus-Christ. Ils sont depuis en voie de réinsertion et fréquentent nos
				assemblées. Des chrétiens de la ville de Vannes réalisent des maraudes
				et tiennent un point d’accueil d’hebdomadaires. Ce sont eux qui nous ont
				envoyé ces deux hommes d’abord, et plusieurs autres depuis, notre
				travail est très complémentaire.
			</P4>
			<P4>
				Puis, par divers biais, plusieurs autres personnes nous ont contactés :
				certains, comme un point d’étape dans leur itinéraire de routard,
				d’autre, par connaissances interposées, envoyé par un médecin
				addictologue, une assistante sociale, des parents désespérés devant le
				désœuvrement de leur enfant, un pasteur ne sachant plus comment aider
				tel ou tel des membres de son assemblée. La plupart nous ont témoigné de
				la très forte impression qu’ils ont ressenti dès leur arrivée dans la
				maison. L’accord entre le message prêché et la charité manifesté
				concrètement par l’équipe (au travers non seulement de la gratuité du
				séjour, mais aussi de la gentillesse de chacun, du « cadre », du soutien
				moral et médico-social, de la qualité des repas…) porte un fruit qui
				nous réjouit.
			</P4>
			<T4 couleur={tools.BLEU_FONCE}>2.3.4. Marseille – Emmanuel Sagols</T4>
			<P4>
				<i>
					Nota Bene : Pour rappel, le Phare est une association propriétaire
					d’un bien sur Marseille faisant principalement de l’accueil
					d’étudiants mais, depuis la prise en gestion par notre association,
					l’accueil est orienté également à destination d’un public en
					difficulté, mêlant volontairement un public bigarré dans une volonté
					d’inclusivité. Le phare dispose de sept chambres et d’un appartement
					d’accueil mis à disposition de notre association.
				</i>
			</P4>
			<P4>
				Cette année nous avons peu d’étudiants, 8 au total dont 7 garçons et une
				fille. Cet accueil rentre dans la vie du Phare, ce sont des jeunes
				toujours sympathiques et respectueux, certains sont là depuis 3 ans.
			</P4>
			<P4>
				L’hiver nous avons souvent des personnes qui viennent dormir une ou deux
				nuits ou qui viennent pour venir prendre de la nourriture ou demander
				des habits. Toutes ces personnes qui passent le seuil du Phare sont un
				sujet de réjouissance pour nous. Le Phare quand il remplit cette
				vocation brille bien plus et fonctionne dans l’essence même de sa
				vocation qui est d’accueillir celui qui est dans le besoin et en
				demande. Comme les années précédentes nous nous rendons compte à quel
				point l’ambiance et l’attitude des jeunes est différente quand le Phare
				accueille du monde et que le travail ici doit mettre en application la
				charité, les jeunes sont souvent remués.
			</P4>
			<P4>
				On espère aussi beaucoup pour eux par ce témoignage et ces moments vécus
				ensemble. Très souvent cela créé des questionnements et des brèches dans
				leur avis, leur jugement vis-à-vis du faible, de la réalité et de la
				difficulté de la vie etc…
			</P4>
			<P4>
				Nous ne savons pas pourquoi cette année est plus creuse que les
				précédentes mais malgré le stress financier et les préoccupations que
				cela peut générer d’un point de vue financier et comptable, un appel
				d’air semble s’être créé et, depuis août, nous avons beaucoup d’accueil
				autre. Nous avons eu notamment quelques fois des personnes, des familles
				qui ont des proches hospitalisés à l’hôpital de la Conception (à 100m du
				Phare) ou à l’hôpital de la Timone (900m du Phare) envoyées par des
				assistantes sociales qui connaissent le fonctionnement du Phare et son
				cadre et qui nous sollicitent pour des cas particuliers.
			</P4>
			<P4>
				Pour donner un petit chiffre au niveau de l’accueil, le nombre
				d’assiettes servis pour l’année est d’à peu près 12 500.
			</P4>
			<P4>
				En ce qui concerne la petite équipe du Phare :
				<ul>
					<li>
						Lucas, Malvina, Michel Treger ont dû partir, nous sommes
						reconnaissants de l’aide apportée.
					</li>
					<li>
						Julien (qui est embauché par le Phare) et Lydia sont toujours dans
						l’équipe qui étoffe quelques semaines par mois l’effectif ici et qui
						permet aussi pour nous de souffler un peu.
					</li>
					<li>
						Cyrielle et Booba nous soulage également bénévolement en faisant des
						permanences et en venant une fois par semaine pour nous aider à un
						repas du soir.
					</li>
				</ul>
			</P4>
			<T3 couleur={tools.BLEU_FONCE}>
				2.4. RAPPORT D’ACTIVITE DES ACTIVITES DE JEUNESSE
			</T3>
			<T4 couleur={tools.BLEU_FONCE}>2.4.1 Année 2023 en chiffres</T4>
			<P4>
				<b>419 enfants</b> (+48 par rapport à 2022) ont été accueillis par
				environ <b>150 bénévoles</b> :
				<ul>
					<li>
						Cévennes (Camprieu + St André) : 2 colonies, 3 camps d’ados : 241
						enfants
					</li>
					<li>
						Auvergne (Say) 3 colonies, 1 camp d’ados, 1 camp d’hiver : 178
						enfants
					</li>
				</ul>
			</P4>
			<P4>
				En 2023, considérant les difficultés que pouvaient rencontrer certaines
				familles et les disparités d’aides de la CAF, nous avons proposé aux
				parents de participer librement aux séjours de leurs enfants en les
				informant seulement du coût indicatif.
			</P4>
			<P4>
				Nous aurions pu craindre une diminution significative des participations
				mais au contraire nous avons eu une participation supérieure{" "}
				<b>252 € /séjour</b> (contre 232€ pour 2022 avec un tarif fixe).
			</P4>
			<P4>
				C’est grâce à l’engagement de tous les bénévoles que tout ce travail est
				rendu possible. Un grand merci à eux. Notre besoin d’aides et
				d’animateurs se maintient chaque année, nous sommes donc heureux
				d’entendre que des jeunes s’engagent pour passer les diplômes requis.
			</P4>
			<P4>
				Cette année nous avons pour la deuxième année organisé un camp à noël
				dans le village de Saint-André-de-Valborgne. 38 adolescents ont été
				accueillis au centre de vacances « la source ». Ce séjour était très
				positif, nous avons même eu droit à une inspection qui s’est bien
				déroulée.
			</P4>
			<T4 couleur={tools.BLEU_FONCE}>2.4.2. Projets 2024</T4>
			<P4>
				Suite à notre refus de signer le bail pour Camprieu, très défavorable
				pour nous, nous étions sans locaux pour les séjours qui devaient se
				dérouler en Cévennes. Nous avons donc prospecté en vue de trouver un
				centre qui pourrait nous accueillir dans la région.
			</P4>
			<P4>
				Pour le mois d’août nous avons signé une location avec l’association qui
				gère le centre de vacances de Grizac dans le mont Lozère. Nous avons
				également pu négocier une location ponctuelle pour juillet avec
				l’association qui gère Camprieu.
			</P4>
			<P4>
				Cette année nous n’avons pas été en mesure d’assurer un camp de ski à
				Say au mois de février.
			</P4>
			<P4>
				Nous voyons que le nombre d’inscrits continue à augmenter d’année en
				année, nous avons dû fermer les inscriptions assez tôt pour plusieurs
				colonies qui étaient trop remplies. Il faudra peut-être louer un 3ème
				centre l’année prochaine, cela permettrait aussi d’avoir des colos moins
				grandes et plus familiales, mais nécessite de disposer d’une nouvelle
				équipe.
			</P4>
			<T3 couleur={tools.BLEU_FONCE}>
				2.5. RAPPORT MORAL DU PRESIDENT JEREMIE LOIRET
			</T3>
			<P3>
				L’année 2023 a été marquée par le départ subit et tragique de notre
				trésorier Samuel Champaut. Le bouleversement ressenti par tous a été à
				la mesure de nos liens d’amitié et affection profonde avec sa lui et sa
				famille, tissés par des années de collaboration. Son rôle dans le suivi
				comptable et financier était très important, son regard et son
				expérience très précieux. C’est avec beaucoup de peine qu’il a fallu
				pallier à son absence et non sans interrogations quant à notre mode de
				fonctionnement.
			</P3>
			<P3>
				Dans une moindre mesure, mon arrêt maladie a suscité aussi des
				changements notamment avec la décision de ne pas reprendre le
				fonctionnement du tandem avec Daniel Issarte avec qui nous travaillions
				depuis 22 ans. Une équipe a été recomposée pour que la charge soit
				répartie mais cette période de transition a comporté son lot de
				difficultés et de tâtonnements.
			</P3>
			<P3>
				Tous ces évènements n’ont fait qu’accentuer le besoin de réfléchir à la
				répartition de notre travail avec davantage de délégations dans les
				régions. Il existe entre le Riffray pour la région Ouest et Fouday pour
				l’Est, des centres qui sont en mesure de prendre davantage en charge
				leur fonctionnement sans que tout soit centralisé à Anduze notamment
				pour la gestion et certaines décisions. Il faudra pour cela que des
				collaborateurs prennent plus de responsabilités.
			</P3>
			<P3>
				Nous avons dans le même tant eu la joie de voir des amis en région
				prendre à cœur l’accueil dans un cadre plus familial ce qui serait très
				complémentaire car pour certaines personnes, une trop grande
				collectivité est vécue comme oppressante. Plusieurs ont déjà eu
				l’occasion de prendre le relais en accueillant chez eux des jeunes
				envoyées par le centre d’Anduze et cela a été très bénéfique pour eux.
				Nous espérons que cette collaboration s’intensifie car ce n’est pas une
				association qui porte une vocation mais bien les personnes qui la
				composent et qui mettent en pratique cet accueil du prochain dans la
				sphère où elles sont.
			</P3>
			<P3>
				Dans ce même esprit de solidarité nous espérons une meilleure
				répartition des salariés qui sont actuellement majoritairement
				stationnés dans le sud. Plusieurs y sont sensibles, en attendant c’est
				avec joie que nous envoyons dès que nécessaire des équipes pour prêter
				main forte aux régions.
			</P3>
			<P3>
				A l’étranger, le besoin de collaborateurs est également évident. Damien
				et Marie Neige s’investissent beaucoup et reçoivent au sein de leur
				foyer plusieurs malheureux mais ils ne pourront poursuivre longtemps
				encore cet accueil sans soutien. Daniel et Léontine en Guinée fatiguent
				aussi. Il faut communiquer ces besoins et prier pour que des vocations
				naissent parmi la jeune génération.
			</P3>
			<VoteRadioBouton
				numVote="tajAg2"
				self={props.self}
				defaultPour={true}
				titre="VOTE D’APPROBATION DU RAPPORT MORAL DE L’ANNEE 2023 "
			/>
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}

			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* // 3 */}
			<Titre2
				couleur={tools.ROSE}
				texte="3. TROISIÈME DELIBERATION – APPROBATION DES COMPTES ANNUELS 2023"
			/>
			<T3 couleur={tools.ROSE}>3.1. Le compte de résultats</T3>
			<P3>
				Les ressources totales de l’association reculent de 2%, passant de 1 960
				478€ en 2022 à 1 914 675€ en 2023 (-45.800€). Cela s’explique
				principalement par la baisse des dons réguliers (-68.998€). Même si les
				dons exceptionnels et les participations aux frais des colonies ont
				augmenté respectivement de +8% et +23%, cela n’a pas permis de compenser
				la diminution constatée sur les dons réguliers.
			</P3>
			<Image src="https://www.dropbox.com/scl/fi/i78ny9ar6ibc0c4105yqh/25440853f8629765a9fcb0d4741fd69eecd02f8a.png?rlkey=0il3r9ivl1r4hfvryffgwhoar&raw=1" />
			<P3>
				Comme on peut le voir sur le tableau ci-après, les dépenses totales ont
				quant à elles augmenté de 3% (+56 604€).
			</P3>
			<Image src="https://www.dropbox.com/scl/fi/a2escyv6mvxtzj984470d/ad96bdea43fff1c716f59853d0a8f5e3f5e60e97.png?rlkey=0rarlh6tigw088znlgsr2aq11&raw=1" />
			<P3>
				Les principales hausses concernent les locaux, l'alimentation et la
				mobilité. Ces évolutions ne résultent pas d’un accroissement des
				activités, elles sont la conséquence directe de l’inflation générale,
				encore plus forte sur l’année 2023.
			</P3>
			<P3>
				En effet, le niveau d’accueil dans les principaux lieux de vie de
				l’association a légèrement diminué dans l’ensemble, même s’il y a des
				évolutions contrastées selon les lieux d’accueil : plus d’enfants aux
				colonies, plus de repas servis au Riffray et à Fouday, mais baisse
				notable à Anduze.
			</P3>
			<P3>
				Dans le même temps, on a dû faire avec des augmentations de prix variant
				entre 20% et 60% sur un très large panel de produits alimentaires de
				base : œufs (+62%), lait (+50%), farine (53%), steaks hachés (+49%),
				etc. Inévitablement, les denrées alimentaires pour chaque repas servi
				ont en moyenne coûté 12% plus cher qu’en 2022, tant au Riffray qu’à
				Anduze.
			</P3>
			<P3>
				Pour ce qui est des frais de personnel, le départ à la retraite d’un
				salarié à partir de 2023 participe à la diminution de ce poste. Sur
				l’année 2023, un excédent de 4 180 euros se dégage.
			</P3>
			<T3 couleur={tools.ROSE}>3.2. Trésorerie</T3>
			<P3>
				La trésorerie de l’association diminue quelque peu entre 2022 et 2023,
				passant de 217 098€ à 203 018€. Après 4 années pendant lesquelles des
				investissements considérables ont été réalisés et autofinancés,
				notamment l’agrandissement de la maison d’accueil à Anduze, on constate
				en 2023 un réel ralentissement de la tendance. Les investissements
				s’élèvent au total à 187 000€ en 2023, au lieu de 251 000€ en 2022 (à
				noter que les sommes investies en travaux et autres constructions ont
				pratiquement diminué de moitié, passant de 156 000€ à 88 000€).
			</P3>
			<P3>
				Le frein des investissements a donc permis de maintenir une certaine
				stabilité de la trésorerie générale de l’association. Cette tendance au
				ralentissement devrait se poursuivre sur l’année 2024, afin de «
				sécuriser » en quelque sorte les ressources en trésorerie pour le
				financement du quotidien.
			</P3>

			<VoteRadioBouton
				numVote="tajAg3"
				self={props.self}
				defaultPour={true}
				titre="VOTE D’APPROBATION DES COMPTES 2023 & QUITUS DONNÉ AU CONSEIL D’ADMINISTRATION DE L’ASSOCIATION"
			/>
			<P3>
				Les rapports du commissaire aux comptes sont ici communiqués, les
				comptes ont été validés sans réserve par la société audit associés
				méditerranée, société par actions simplifiée immatriculée sous le SIREN
				790233340 sis 67 route de Béziers, Saint Jean de Vedas (34430)
				représentée par Monsieur Sylvain Raynal commissaire aux comptes dûment
				habilité.
			</P3>
			<P3>
				Pour rappel, l’objet de sa mission légale, telle qu’elle est définie aux
				articles L 820-1 à L 824-16 du Code du commerce, il a la charge
				d’apporter un contrôle s’assurant que l’ensemble des éléments qui
				rentrent dans les comptes de l’association soient conformes à l’objet
				social. L’exercice de sa profession consiste en l’exercice de missions
				de contrôle légales et d’autres missions qui lui sont confiées par la
				loi ou le règlement. Sa mission s’effectue en toute transparence et
				indépendance juridique et financière. Il a aussi la responsabilité
				d’exprimer une opinion sur les comptes : pour cet exercice il s’agit
				d’une certification sans réserve.
			</P3>
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}

			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* // 4 */}
			<T2 couleur={tools.VIOLET}>
				4. QUATRIEME DELIBERATION - AFFECTATION DE L’EXCEDENT
			</T2>
			<p>
				L'assemblée générale décide d'affecter l'excédent de l'exercice clos le
				31 décembre 2022 comme suit : 4180 € aux fonds associatifs sans droit de
				reprise.
			</p>

			<VoteRadioBouton
				numVote="tajAg4"
				self={props.self}
				defaultPour={true}
				titre="VOTE D’APPROBATION DE L’AFFECTATION DE L’EXCEDENT"
			/>

			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* // 5 */}
			<Titre2
				couleur={tools.ORANGE_CLAIR}
				texte="5. CINQUIEME DELIBERATION - BUDGET 2024"
			/>
			<p>
				Le trésorier présente le projet de budget 2024 établi par le conseil
				d’administration. Il s’agit d’un budget prévisionnel équilibré d’un
				montant de 1 898 963 € qui se répartit ainsi :
			</p>

			<Image src="https://www.dropbox.com/scl/fi/gg8la9k86580f84wap9da/49181b9d1f845276e3a2668b03ebffd4c6e08fb2.png?rlkey=bzoefz0i3qui4li5mrg3hqozj&raw=1" />

			<VoteRadioBouton
				numVote="tajAg5"
				self={props.self}
				defaultPour={true}
				titre="Vote d’approbation sans réserve du budget prévisionnel de fonctionnement et d’investissement de l’association tel que présenté"
			/>

			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* // 6 */}
			<Titre2
				couleur={tools.BLEU_TURQUOISE}
				texte="6. SIXIEME DELIBERATION – RENOUVELLEMENT DU MANDAT DU COMMISSAIRE AUX COMPTES"
			/>
			<p>
				Pour les six années à venir, le bureau de l’association propose de
				renouveler le mandat de commissariat aux comptes de la société AUDIT
				ASSOCIES MÉDITERRANÉE pour un mandat de six exercices. Pour rappel aux
				membres, la certification des comptes par un commissaire aux comptes est
				obligatoire pour les associations percevant plus de 153 000 euros de
				dons par an d’après l’article L 612-1 du Code de commerce. Son audit
				garantit aux membres que les fonds donnés sont utilisés conformément à
				l’objet associatif, cela garantit une gestion financière saine et
				transparente.
			</p>

			<VoteRadioBouton
				numVote="tajAg6"
				self={props.self}
				defaultPour={true}
				titre="VOTE D’APPROBATION SANS RÉSERVE"
			/>

			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* // 7 */}
			<Titre2
				couleur={tools.ROUGE}
				texte="7. SEPTIEME DELIBERATION – COMPTES ET RAPPORTS DES ASSOCIATIONS PARTENAIRES"
			/>
			<p>
				Les présidents des associations liées par convention ou par des liens de
				collaboration étroits avec notre association communiquent leurs comptes
				annuels. Toutes ces associations travaillent de concert avec notre
				association en poursuivant et complétant notre objet associatif. Au jour
				des présentes, les associations concernées sont :
				<ul>
					<li>
						L’association LES ATELIERS DE PELISSOU, ayant fait l’objet d’une
						déclaration auprès de la sous-préfecture d’Alès, publiée au journal
						officiel le 26 novembre 2016, ayant son siège social 1928 chemin
						d’Aspères, 30140 TORNAC, représentée par Monsieur Pawlow N’ZUE
						président ayant tout pouvoir à cet effet,
					</li>
					<li>
						L’association RETIM, ayant fait l’objet d’une déclaration auprès de
						la sous-préfecture d’Alès au numéro RNA W 301002663 créée le 10 mars
						2010 ayant son siège social 1654 chemin de Recoulin 30140 ANDUZE,
						représentée par Michèle RICARD, présidente, ayant tout pouvoir à cet
						effet,
					</li>
					<li>
						L’association TEAM ETUD’, ayant fait l’objet d’une déclaration
						auprès de la sous- préfecture d’Alès, publiée au journal officiel le
						10 décembre 2011, numéro de parution 20110050. Ayant son siège
						social 1654 Chemin de Recoulin, 30140 ANDUZE, représenté par
						Monsieur Stéphane OZIL, président, ayant tout pouvoir à cet effet,
					</li>
					<li>
						L’association ASSEMBLEE CHRETIENNE BIBLIQUE, immatriculée aux
						annexes du moniteur avec le numéro d’entreprise suivant 430 755 620
						ayant son siège social 17 rue Jacques Hoton, 1200 BRUXELLES
						(Belgique), représentée par Monsieur Joël Labeau, président, ayant
						tout pouvoir à cet effet,
					</li>
					<li>
						L’association FOYER CHRETIEN LE PHARE ayant fait l’objet d’une
						déclaration à la préfecture des Bouches du Rhône avec le numéro
						d’immatriculation W 133014701 créée le 01 janvier 1946 ayant son
						siège social 133 rue Ferrari 13005 Marseille, représentée par Joseph
						Zehr président, ayant tout pouvoir à cet effet.
					</li>
				</ul>
			</p>
			<T3 couleur={tools.ROUGE}>7.1. LES ATELIERS DE PELISSOU</T3>
			<T4 couleur={tools.ROUGE}>
				7.1.1 Activité et accueils sur Pélissou en 2023
			</T4>
			<P4>
				<b>2 personnes accueillies à l’année dans les locaux :</b>
				<ul>
					<li>
						Yanis (formation agricole dans un e MFR fin de 1ère Année, début de
						2ème année)
					</li>
					<li>Jean</li>
				</ul>
			</P4>
			<P4>
				<b>6 personnes accueillies en journée :</b>
				<ul>
					<li>Pawel (1 demi-journées/semaine)</li>
					<li>Andi (1 demi-journée/semaine)</li>
					<li>Caroline (1 journée/2semaines)</li>
					<li>Charles (2 à 3 demi-journées pendant les vacances scolaires)</li>
					<li>Clara (depuis septembre 3 demi-journées par semaine)</li>
					<li>Kimia (3 semaines en août)</li>
				</ul>
			</P4>
			<P4>
				<b>
					5 personnes accueillies pour plusieurs séjours longs dans l’année :
				</b>
				<ul>
					<li>Grégoire (5 séjours de 1 mois)</li>
					<li>Jean-Denis (3 séjours de 2 mois)</li>
					<li>Jean (3 séjours de deux semaines)</li>
					<li>Alice (5 séjours de deux semaines)</li>
					<li>Mathilde (2 séjours de ceux semaines)</li>
				</ul>
			</P4>
			<P4>
				<b>4 personnes accueillies pour des séjours courts :</b>
				<ul>
					<li>Valentin (1 séjour d’une semaine)</li>
					<li>Julien (4 séjours d’une semaine)</li>
					<li>Léo (1 séjour d’une semaine)</li>
					<li>Satya (1 séjour d’une semaine)</li>
					<li>Nicolas (1 séjour de deux semaines)</li>
				</ul>
			</P4>
			<P4>
				<b>
					Descriptif des activités de l’association auprès des personnes
					accueillies à Pelissou :
				</b>
			</P4>
			<P4>
				Les activités auprès des personnes accueillies restent sensiblement les
				mêmes que les années précédentes dans un objectif de soin aux personnes
				:
				<ul>
					<li>
						Activités manuelles à la ferme de Pélissou (nettoyage, nourrissage,
						laiterie, divers)
					</li>
					<li>
						Travaux de bois pour Ergo Santé (Appuis tête, bois de jonction,
						assises)
					</li>
					<li>Confection de jeux en bois pour l’association RETIM</li>
					<li>Couper et décortiquer les noix pour la mission</li>
					<li>
						Entretien des espaces extérieurs (désherbage, ratissage, jardinage)
					</li>
					<li>Nettoyage des voitures (intérieur et extérieur)</li>
					<li>Activités extérieures (sport, balade, visites)</li>
					<li>Activité cuisine (goûters, préparation du repas du soir)</li>
					<li>Accompagnement aux courses et divers rendez-vous</li>
					<li>Accompagnement dans les actes de la vie quotidienne</li>
					<li>
						Accompagnement à la participation dans les tâches de la vie en
						collectivité (entretien de locaux, mise de table, etc…)
					</li>
				</ul>
			</P4>
			<P4>
				<b>En 2023, s’ajoutent aussi :</b>
				<ul>
					<li>
						Activité en cuisine à la mission avec Romuald (Leo accompagnant)
					</li>
					<li>
						Activités dans le local RETIM avec Julien (Simon accompagnant)
					</li>
					<li>
						Activité au garage de la mission avec les garagistes (Simon
						accompagnant)
					</li>
					<li>Activité pain (Hugo accompagnant)</li>
					<li>
						Activité en intérieur (préparation salle de réunion nettoyage, mise
						en place des chaises + cantiques)
					</li>
					<li>Mettre les suppléments dans les cantiques</li>
				</ul>
			</P4>
			<T4 couleur={tools.ROUGE}>7.1.2 Activité sur Cholet en 2023 :</T4>
			<P4>
				L’activité sur Cholet s’effectue autour de plusieurs personnes clé
				intervenant principalement chez les familles ou près de chez elles
			</P4>
			<P4>
				<b>
					Claudine Siegrist, coordinatrice ADP Ouest, orthophoniste à la
					retraite, bénévole :
				</b>
				<ul>
					<li>
						Coordination des prises en charge des enfants et des jeunes en
						difficulté scolaire ou/et en situation de handicap.
					</li>
					<li>Gestion finances, réunions d’équipe et lien avec les ADP Sud.</li>
					<li>
						Rééducations orthophoniques hebdomadaires auprès de 8 enfants des
						églises.
					</li>
					<li>Soutien et conseils aux parents</li>
					<li>Bilans orthophoniques</li>
					<li>
						Contacts avec des professionnels de santé en vue d’aider les
						familles.
					</li>
					<li>
						Essai de mise en place d’aide à la communication, par visio, tous
						les 15 jours, pour une famille de la région parisienne ayant une
						fillette de 6 ans, Kimia, très lourdement handicapée, sans langage.
					</li>
				</ul>
			</P4>
			<P4>
				<b>
					Jean-Emmanuel Baldaccino, salarié ADP en tant qu’AMP à temps plein :
				</b>
				Prises en charge auprès de 5 personnes en situation de handicap et/ou en
				difficulté, et intervenant auprès des familles.
				<ul>
					<li>Maëlys (prise en charge deux fois par semaine)</li>
					<li>
						Louis (prise en charge deux fois par semaine, ou plus selon les
						besoins)
					</li>
					<li>Jean (prise en charge une fois par semaine)</li>
					<li>Nathan (prise en charge une fois par mois)</li>
					<li>Lisa (prise en charge un week-end par mois)</li>
				</ul>
			</P4>
			<P4>
				<b>
					Jean-François Beaujean (bénévole, instituteur à la retraite)
					accompagnant : (soutien scolaire)
				</b>
				<ul>
					<li>Louis, scolarisé en 4e puis 3e </li>
					<li>Ethan</li>
					<li>
						Faustine : enfant partiellement scolarisée en CM2 puis en 6e,
						soutien scolaire une journée par semaine.
					</li>
				</ul>
			</P4>
			<P4>
				<b>
					Véronique Graveleau (enseignante rémunérée par un organisme officiel)
					: plusieurs heures par semaine auprès d’un jeune en risque de
					déscolarisation.{" "}
				</b>
			</P4>
			<P4>
				<b>
					Camille Jaubert (bénévole) : quelques semaines pendant les vacances
					scolaires chez la famille d’accueil D’Angéli auprès des enfants
					accueillis.
				</b>
			</P4>
			<P4>
				<b>
					Virginie Boucly, professeur de SVT rémunéré par un organisme officiel
					: aide et soutien auprès de Louis.
				</b>
			</P4>

			<T4 couleur={tools.ROUGE}>7.1.4 Activités des salariés </T4>
			<P4>
				L’été 2023, Pierre Ayrinhac a été mis à disposition pour aider à
				l’accompagnement de 3 personnes dans un camp d’adolescents par
				l’association “Timothée Accueil et Jeunesse” :
				<ul>
					<li>Clara</li>
					<li>Andi</li>
					<li>Lucas</li>
				</ul>
			</P4>
			<P4>
				Simon Godefroy et Pierre Ayrinhac, interviennent également dans le cadre
				de l’école privée “Team Etud’” pour des séances d’1 heure d’habiletés
				sociales pour certains jeunes (Alexis, Pawel, Andi, classe de 5ème,
				Cealinn). Au total, 5 heures.
			</P4>
			<T4 couleur={tools.ROUGE}>7.1.4 Partenariat</T4>
			<P4>
				Les ADP ont pu accueillir les enfants de l’IMP du Figaret (Mas
				Cavaillac) et leurs éducateurs sur une journée « à la ferme » au
				printemps : petits travaux à la ferme, balade, pique-nique. Ravis de
				cette 1ère journée découverte, toute l’équipe a souhaité organiser sa
				fête de fin d’année sur le site de Pelissou ; en juillet, ce sont donc
				ces 13 enfants, leurs parents, les équipes et la direction générale qui
				est venue faire leur journée « cirque à la ferme » pour clôturer l’année
				scolaire ; un pique-nique commun avec les professionnels des ADP sur
				place a permis de faire connaissance les uns avec les autres. Une belle
				occasion de donner un témoignage sur cette vocation d’accueil à
				Pelissou.
			</P4>
			<T4 couleur={tools.ROUGE}>7.1.5 Projet d’accueil sur le Vigan</T4>
			<P4>
				Les associés de la SCI ont acheté le 6 mars 2023 le terrain juxtaposé au
				terrain de la famille PETER, en vue de réaliser un bâtiment d’accueil,
				qui corresponde à l’objet social de la SCI, à savoir :
				<br />
				« La gestion d’une propriété permettant un accueil de répit, de repos et
				plus largement permettant de faire de l’accueil de personnes en
				situation de difficultés matérielles, sociales et morales ».
				<br />
				Au cours de l’année, en rapport avec les plans présentés, une analyse de
				faisabilité et de coût a été réalisée. Il s’avère que le scenario
				optimiste a révélé une impossibilité financière de réaliser ce bâtiment.
				Une réunion a été organisée le 16 septembre 2023 pour en informer les
				associés. <br />
				Sandrine PETER y a présenté la conclusion d’un travail de réflexion mené
				à plusieurs pour définir clairement le projet d’accueil. Cela
				nécessiterait une maison individuelle occupée par une famille avec des
				chambres dédiées à l’accueil du public sous la forme de l’accueil
				familial, ou des séjours de vacances en famille.
			</P4>
			<P4>
				Elle a présenté également une activité de témoignage via un club
				d’enfants qui sera organisé à très court terme par elle et Ruth
				Nouguier. Il est convenu qu’il faudrait idéalement un couple engagé dans
				une démarche missionnaire pour assurer ce travail. A ce jour, personne
				ne s’est manifesté Il a été pris à l’unanimité la décision de patienter
				au moins une année pour voir si le besoin se faisant réellement
				ressentir de procéder à la construction d’un bâtiment La trésorerie
				disponible a été placée en attendant sur un compte à terme.
			</P4>
			<T4 couleur={tools.ROUGE}>
				7.1.6 Etat d’avancement du projet d’accueillant familial
			</T4>
			<P4>
				Depuis l’AG de novembre, plusieurs personnes se sont rendues à une
				réunion d’information le 6 février 2024 : Pierre, Simon, Anne Marie W et
				Sandrine.
			</P4>
			<P4>Une réflexion sur la faisabilité de ces agréments est en cours.</P4>
			<T4 couleur={tools.ROUGE}>
				7.1.7 Point d’étape sur le dossier de demande d’agrément de Vacances
				Adaptées Organisées (VAO)
			</T4>
			<P4>
				2023 a été une grosse année de mise en forme de dossiers pour obtenir le
				« sésame » à savoir une immatriculation à ATOUT France afin de permettre
				le dépôt du dossier d’agrément auprès de la préfecture : dossier pour
				obtenir la garantie financière de l’UNAT, assurances, puis dossier à
				Atout France… <br />
				<b>
					Ce travail a été récompensé en mars 2024 par l’obtention de cette
					immatriculation.
				</b>
			</P4>

			<T3 couleur={tools.ROUGE}>7.2. RETIM</T3>
			<P3>
				Le but de l’association RETIM est de soutenir financièrement, en lien
				avec l’association TAJ, les œuvres liées à la mission Timothée à
				l’étranger. Celles-ci se répartissent entre la Bulgarie, Madagascar et
				la Guinée.
			</P3>
			<T4 couleur={tools.ROUGE}>7.2.1. ACTIVITE « DORCAS » 2023</T4>
			<P4>
				Le réseau des bénévoles de Retim, que nous appelons « Dorcas » en
				référence à la vie et à l’exemple biblique de Dorcas (Actes 9-39)
				fabrique et vend des produits originaux « faits maison ». En 2023,
				l’activité a encore augmenté, générant un revenu de 10 % supérieur à
				celui de 2022.
			</P4>
			<P4>
				On peut voir également sur nos stands une constante amélioration de la
				qualité des produits et une motivation pour l’aide et le secours aux
				personnes en situation de besoin qui ne faiblit pas parmi les acheteurs.
				Parallèlement, les dons en numéraire ont augmenté cette année ; cette
				augmentation résulte principalement d’un don exceptionnel important de
				la part d’une personne, les autres dons restants constants.
			</P4>
			<T4 couleur={tools.ROUGE}>7.2.2. DEPENSES 2023</T4>
			<P4>
				L’intégralité des revenus de l’activité des bénévoles ainsi que les dons
				permettent de venir en aide aux amis de Bulgarie, de Madagascar et de
				Guinée. Ainsi Retim y assure la plus grande partie des frais de
				fonctionnement : frais d’accueil et achat de tissus pour la Bulgarie,
				frais de fonctionnement du centre Esaïe 58 en Guinée, frais de
				scolarisation en Guinée et à Madagascar. Ces « frais fixes » auxquels
				Retim s’est engagé représentent l’équivalent des revenus produits par
				l’activité « Dorcas ». Pour les investissements (constructions, frais
				exceptionnels) il nous faut donc compter sur les dons en numéraire.
			</P4>
			<P4>
				<b>EN BULGARIE</b>
				<br />
				L’essentiel de l’aide de Retim pour la Bulgarie concerne le soutien aux
				Ateliers Bulgares. L’activité y est en très légère baisse en 2023, ce
				qui est dû au départ d’une couturière à plein temps fin 2022.
				Actuellement l’atelier permet de donner un travail à 6 couturières : 4
				travaillent dans l’atelier et 2 autres à la maison. Les contrats sont
				adaptés à la vie de chacune (en CDI ou à la tâche), ce qui permet
				d’offrir un revenu voire d’apprendre un métier à des personnes en
				situation précaire. <br />
				En 2023 il y a eu des frais de rénovation pour un appartement à Montana
				dans le local de l’église ainsi que pour une dépendance de la maison de
				Slivovic, afin de loger des personnes sans toit. Il y a eu aussi le
				financement d’un camp de jeunes, et comme chaque année des secours pour
				frais médicaux.
			</P4>
			<P4>
				<b>A MADAGASCAR</b>
				<br />
				Actuellement 6 jeunes sont accueillis au CEP et soutenus par Retim pour
				leur scolarité. L’association permet aussi l’accès à des soins médicaux
				quand cela est nécessaire.
			</P4>
			<P4>
				<b>EN GUINÉE</b>
				<br />
				En 2023 il y eu la construction d’un bâtiment supplémentaire accueillant
				l’école et permettant aux enfants de l’âge du collège de continuer à
				étudier sur place ; également la construction d’une 3ème case pour
				l’accueil de personnes de passage, et différents travaux : abri pour le
				groupe électrogène, château d’eau, fosse septique pour les sanitaires
				garçons, travaux de canalisation et création d’un dallot. <br />
				Différents frais ont été pris en charge : un groupe électrogène, une
				nouvelle moto, un purificateur d’eau. <br />
				Retim prend aussi en charge les frais de fonctionnement de l’orphelinat
				(salaires des encadrants et professeurs, nourriture et dépenses
				courantes), ainsi que les frais de scolarité d’orphelins qui sont placés
				dans des familles sur une vaste zone géographique et que Daniel Tolno
				visite régulièrement. Ils font partie de l’association Aide Aux Enfants
				de Guinée (AAEG) créée par Daniel Tolno (35 enfants). <br />
				Le centre Esaïe 58 accueille au quotidien environ 65 enfants et une
				dizaine d’encadrants avec leur famille, et la plupart du temps également
				des hôtes de passage comme des ouvriers venus travailler et qui mangent
				sur place. <br />
				L’inflation importante de ces derniers mois a considérablement augmenté
				ces différents coûts. S’ajoutent à ces différents frais fixes des aides
				ponctuelles pour des situations de détresse ou de besoin médical.
			</P4>
			<Image src="https://www.dropbox.com/scl/fi/ru2jo8szltqj4xaqqy2vb/b33f9d0123d285571e6fa8d18d20b01dfe650f41.png?rlkey=zahb4rnhpq5moytod2gtq9s9m&raw=1" />
			<Image src="https://www.dropbox.com/scl/fi/pmygwxaonoswzxg5507mc/9114ab706703fc5a4ab0fe1d284b77508abf600f.png?rlkey=z9aoxznlaypubrra22syr59d6&raw=1" />

			<T3 couleur={tools.ROUGE}>7.3. TEAM ETUD</T3>
			<T4 couleur={tools.ROUGE}>7.3.1 Rapport moral du président</T4>
			<P4>
				Des changements sont intervenus au cours de l’année écoulée :
				<ul>
					<li>
						Samuel Champault qui était très investi dans l’association nous a
						quitté ce qui a été un choc pour tous
					</li>
					<li>
						Marie-Anne Zighmi a quitté ses fonctions de directrice. Il tient à
						la remercier car c’est elle qui a posé les fondements de
						l’organisation de l’école ainsi que du CFA. Depuis le début, elle a
						assuré la direction avec compétence. C’est Jérémie Corgne qui lui
						succède.
					</li>
					<li>
						Le président informe par ailleurs l’assemblée que l’association
						ayant atteint son rythme de croisière, et aussi pour des raisons
						familiales, il ne se représentera pas pour siéger au conseil
						d’administration et de ce fait, quitte la présidence de
						l’association. C’est Marc Coatanhay qui est pressenti pour le
						remplacer.
					</li>
				</ul>
			</P4>
			<T4 couleur={tools.ROUGE}>7.3.2 Bilan 2022-2023</T4>
			<T5 couleur={tools.ROUGE}>Concernant l’école</T5>
			<P5>
				Effectifs 2022 – 2023 : 32 élèves dont 9 primaires (6 nouveaux), 15
				collégiens et 2 lycéens (4 nouveaux en secondaire) ainsi que 6 apprentis
				(4 nouveaux). Pour encadrer tous ces élèves, ce sont 53 personnes qui
				sont mobilisés, salariés et bénévoles.
			</P5>
			<P5>
				<b>
					Tous les élèves sont passés en classe supérieure ou ont terminé leurs
					études.
				</b>
				<ul>
					<li>
						20 élèves restent à Team Etud : 8 primaires, 8 collégiens, 1 lycéen,
						3 CAP.
					</li>
					<li>
						8 élèves retournent dans le public : 1 primaire, 6 collégiens, 1
						lycéen.
					</li>
					<li>1 élève spécialisé et 2 CAP ont terminé leurs études.</li>
					<li>1 CAP a arrêté sa formation. </li>
				</ul>
				Au niveau des diplômes, il y a eu 100 % de réussite :
				<ul>
					<li>1 CFG</li>
					<li>2 brevets mention bien</li>
					<li>1 baccalauréat mention bien</li>
					<li>2 CAP menuisier</li>
				</ul>
			</P5>
			<P5>
				De nombreuses sorties ont été organisées dont : la Bambouseraie, le
				Planétarium, La Cocardière, Maison Rouge, le musée du Colombier, les
				Carrières des Lumières, le Château des Baux de Provence, le musée
				Haribo, le parc Accrobranche. Par ailleurs, plusieurs événements ont eu
				lieu : le marché de Noël, une soirée pizza, la matinée Portes Ouvertes
				et la fête de l’école.
			</P5>
			<T5 couleur={tools.ROUGE}>Concernant le soutien scolaire </T5>
			<P5>
				Marc Coatanhay indique qu’il a une ligne téléphonique dédiée pour gérer
				le soutien scolaire ; pour autant, il a eu peu de demandes sur l’année
				écoulée. Il a aussi poursuivi ce qui se faisait déjà pendant les camps
				de la Mission, c’est-à-dire recenser les besoins et mettre les jeunes en
				relation avec les bénévoles. Il indique que ce travail se fait aussi
				dans l’Est avec Daniel Vitaux et dans l’Ouest avec José Parra. Certains
				cours de soutien peuvent aussi se faire en visio.
			</P5>
			<T4 couleur={tools.ROUGE}>
				7.3.3 Évolutions et projets de l’année scolaire 2023-2024
			</T4>
			<P4>
				Jérémie Corgne présente les effectifs pour la rentrée 2023. Ce sont 28
				élèves qui sont inscrits cette année : 9 primaires (dont 2 arrivants),
				14 collégiens et 2 lycéens (5 arrivants en secondaire), et 3 apprentis.
				Les effectifs sont donc en baisse cette année. L’équipe encadrante est
				constituée de 56 personnes.
			</P4>
			<P4>
				Quelques nouveautés ont été mises en place en septembre :
				<ul>
					<li>
						Pour pallier le départ de Marie-Anne Zighmi, une équipe Vie Scolaire
						a été constituée.{" "}
					</li>
					<li>
						Les horaires ont été modifiés pour se caler davantage sur les
						horaires pratiqués dans l’Education Nationale. Notamment, il y a
						cours le mercredi matin pour le secondaire et les devoirs surveillés
						ont lieu le vendredi matin au lieu du samedi matin. La pause
						méridienne a été réduite à 1 H 30 au lieu de 2 H.
					</li>
					<li>
						Un carnet de liaison a été mis en place. C’est un nouvel outil pour
						les enseignants.
					</li>
				</ul>
			</P4>
			<P4>
				Par ailleurs, des travaux ont été faits pendant l’été pour faciliter le
				travail. La salle des lycéens a été repensée et la salle de TP a été
				entièrement refaite.
			</P4>

			<T3 couleur={tools.ROUGE}>7.4. FOYER CHRETIEN LE PHARE</T3>

			<P3>
				Cette année nous avons peu d’étudiants, au total 8 dont 7 garçons et une
				fille. Cet accueil rentre dans la vie du Phare, ce sont des jeunes
				toujours sympathiques et respectueux, certains sont là depuis 3 ans. Ils
				assistent juste avant le repas du soir à une courte méditation sur un
				texte biblique, 3 ou 4 jours par semaine en moyenne. Nous ne savons pas
				pourquoi c’est une année creuse, mais malgré le stress financier et les
				préoccupations comptables, un appel d’air semble s’être crée car depuis
				août nous avons d’autres types d’accueil.
			</P3>
			<P3>
				L’équipe de l’année dernière a été reconduite, sauf quelques changements
				: Lucas est parti faire la formation à Anduze, Malvina est au Riffray
				pour quelques mois, donc nous sommes concrètement seuls avec ma femme au
				quotidien. Julien qui est embauché par le Phare et Lydia sont dans
				l’équipe quelques semaines par mois ce qui nous permet de souffler un
				peu. Hugo Pesenti et sa femme nous aident également en cas de besoin et
				se font très disponibles. Cyriel et Booba AÏT nous aident également
				beaucoup.
			</P3>
			<P3>
				Nos jeunes étudiants qui habitent au Phare gardent l’habitude d’amener
				avec eux des collègues de classe pour manger et pour profiter aussi
				parfois des locaux pour les révisions et devoirs. Parfois il arrive que
				nous les logions pour les dépanner certaines nuits lorsqu’ils
				rencontrent des problèmes de transport. C’est toujours aussi agréable
				pour nous et nous permet de rendre un témoignage concret et simple.
			</P3>
			<P3>
				Pour ce travail d'accueil, le Phare dispose cette année de 7 chambres
				d’accueil, ainsi que d’un petit appartement pour l’accueil TAJ (à la
				demande d’églises ou de paroisses, de la Mission Timothée ou par
				l’intermédiaire de voisins ou d’amis). Nous avons eu aussi parfois des
				proches de personnes hospitalisées à l’hôpital de la Conception ou celui
				de la Timone proches du Phare. Ils sont envoyés par des assistantes
				sociales qui nous sollicitent pour des cas particuliers et les églises
				du bassin Marseillais. L’hiver nous avons souvent des personnes qui
				viennent dormir une ou deux nuits, ou qui viennent prendre un repas ou
				demander des vêtements.
			</P3>
			<P3>
				Comme les années précédentes, il m’arrive de faire une petite méditation
				dans les locaux de l’Armée du Salut sur la Cannebière. C’est un moment
				où je rencontre des étudiants étrangers, des gens dans des situations
				compliquées, des marginaux. Les contacts sont intéressants et parfois le
				Phare peut leur venir en aide.
			</P3>
			<P3>
				Toutes ces personnes qui passent le seuil du Phare sont un sujet de
				réjouissance pour nous. Le Phare, quand il remplit cette vocation,
				brille bien plus et fonctionne dans l’essence même de sa vocation qui
				est d’accueillir celui qui est dans le besoin et en demande. Comme les
				années précédentes nous nous rendons compte à quel point l’ambiance et
				l’attitude des étudiants est différente quand le Phare accueille du
				monde et que le travail ici met en application la charité. Ils sont
				souvent remués. Très souvent cela crée des questionnements et des
				brèches dans leur avis, leur jugement vis-à-vis du faible, de la réalité
				et de la difficulté de la vie… <br />
				Au niveau des chiffres, le nombre de repas servis à l’année est
				d’environ 12 500.
			</P3>
			<P3>
				Certains voisins comme André le Ferrailleur, Jacques et Alain viennent
				de plus en plus souvent au Phare. Ils mangent parfois avec nous et sont
				présents aux méditations. Il n’y a pas de gros travaux faits cette
				année, seulement de la rénovation et des travaux d’urgence pour donner
				suite à des casses ou soucis techniques. Nous continuons le comptoir
				librairie du Phare au marché à Castellane Prado toutes les semaines et
				le vendredi tous les jours Ingrid s’y rend avec l’aide de Béthel.
			</P3>

			<VoteRadioBouton
				numVote="tajAg7"
				self={props.self}
				defaultPour={true}
				titre="Vote de la Résolution – Approbation des rapports des associations partenaires"
			/>

			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* // 8 */}
			<Titre2
				couleur={tools.ORANGE}
				texte="8. HUITIEME DELIBERATION : RAPPORT DES FONDS DE DOTATION"
			/>
			<p className="indent">
				Le fonds de dotation est une personne morale de droit privé à but non
				lucratif qui reçoit et gère, en les capitalisant, des biens et droits de
				toute nature qui lui sont apportés à titre gratuit et irrévocable et
				utilise les revenus de la capitalisation en vue de la réalisation d’une
				œuvre ou d’une mission d’intérêt général ou les redistribue pour
				assister une personne morale à but non lucratif dans l’accomplissement
				de ses œuvres et de ses missions d’intérêt général. Concrètement, trois
				fonds de dotation soutiennent le travail de notre association d’une
				façon très concrète :
				<ul>
					<li>
						Le fonds de dotation Philantropic créé en juin 2014 pour un actif
						total de 792 634 €.
					</li>
					<li>
						Le fonds de dotation Maison Bernard créé le 3 février 2017 pour un
						actif de 489 577 €.
					</li>
					<li>
						Le fonds de dotation maison d’accueil créé le 11 aout 2017 pour un
						actif de 3 132 248€.
					</li>
				</ul>
			</p>
			<T3 couleur={tools.ORANGE}>
				8.1. FONDS DE DOTATION MAISON D’ACCUEIL - PRESENTATION ECRITE
			</T3>
			<T4 couleur={tools.ORANGE}>
				8.1.1. Composition historique du patrimoine global
			</T4>
			<P4>
				<ul>
					<li>
						2017 - Parts de SCI RIFFRAY : donation de 85% des parts sociales
						valorisées à 1 140 000, donation des comptes courants associés pour
						271 856. Dans l’ensemble cette SCI gère un bien d’accueil donc par
						nature peu rentable, il s’agit d’un bien atypique ce qui entraîne
						des travaux conséquents pour les années à venir, la bonne nouvelle
						étant que l’emprunt se termine en 2027. Le budget de la SCI est
						globalement stable soit environ 70 k € de revenus par an et un
						emprunt d’environ 44 k €. Le fonds demande à la SCI de renoncer à
						des revenus potentiels (variable selon l’occupation) si cela
						concerne des biens d’accueil. Depuis 4 ans, plusieurs appartements
						d’accueil et dortoirs sont intégrés dans les surfaces occupées par
						l’association TAJ via un commodat. En 2023, un emprunt bancaire a
						été obtenu par la SCI pour financer des travaux de toiture.
					</li>
					<li>2019 – donation de 20% des parts de SCI Manoir de Virecourt.</li>
					<li>2021 - Salle de Nantes : estimée à 210 000</li>
					<li>2022 - Terrain de Labahou : acheté 120 000</li>
					<li>
						2022 - Colline (grevé d’un bail emphytéotique) : donné pour 1 200
						000. Ledit bail est conclu pour une durée de 18 ans et il confère au
						preneur un droit réel sur les constructions. Un loyer de 5000 € est
						versé au fonds pour cela, au terme du bail l’ensemble des
						constructions reviendront au fonds.
					</li>
					<li>
						2023 - Usufruit de Cholet : donné pour 65 000 évalué comptablement à
						52 900 €.
					</li>
				</ul>
			</P4>
			<T4 couleur={tools.ORANGE}>8.1.2. Soutien à l’association </T4>

			<Image src="https://www.dropbox.com/scl/fi/ovxc00mpt9ciw2uuvr7nk/c13d57e5b088ddf77fada179d2500395510af27e.png?rlkey=e8l6vumg9rb5ripf4lvqukevj&raw=1" />
			<P4>
				Sur l’année 2022, le fonds de dotation a donné 129 500 € à l’association
				Timothée accueil et jeunesse. Ci-dessous, un graphique indiquant
				l’évolution de ce soutien au fil des ans.
			</P4>
			<Image src="https://www.dropbox.com/scl/fi/okhlt830ev60gokgivzjj/76a5aa4bc89e8cc45ef5e19f033d0b28cd64500b.png?rlkey=c770b3n64lzez9k3e78nod3ro&raw=1" />
			<P4>
				Dans sa gestion de la SCI Riffray accueil, il a aussi contribué à
				faciliter le travail d’accueil par des mises à disposition à titre
				gratuit évalué à 27444 €.
			</P4>
			<Image src="https://www.dropbox.com/scl/fi/hbqzkkcu0eqekxy9ckg5d/2b14a63c364453606c164e5257d17aceffa9eb27.png?rlkey=ird93ffbzy3r28nt77rzbfcx6&raw=1" />

			<P4>
				Soit un total de <b>156 944 </b>euros soit une hausse de 117 % par
				rapport à l’année précédente. Le fonds a donc soutenu avec beaucoup de
				libéralité notre association.
			</P4>
			<Image src="https://www.dropbox.com/scl/fi/ovxc00mpt9ciw2uuvr7nk/c13d57e5b088ddf77fada179d2500395510af27e.png?rlkey=e8l6vumg9rb5ripf4lvqukevj&raw=1" />

			<P4>
				Ci-dessus, un graphique comparant les mises à disposition à titre
				gratuit aux cours des dernières années comparé aux investissements.
			</P4>
			<T3 couleur={tools.ORANGE}>
				8.2 FONDS DE DOTATION MAISON BERNARD – PRESENTATION ECRITE
			</T3>
			<P3>
				Pour rappel, ce fonds de dotation a été créé pour donner suite à la
				volonté de Monsieur Bernard Czermak et Annie Bernard de donner un bien
				sis à Fouday (Alsace) pour que ce dernier soit au service de l’œuvre de
				l’association Timothée Accueil et Jeunesse. La donation est intervenue
				le 19 octobre 2017 et cette maison sert aujourd’hui de maison d’accueil.
				Pour donner suite à cela, une SCI détenant un bien immobilier sis à
				Virecourt a été donné par l’ensemble de ses associés à deux fonds de
				dotation, celui des maisons d’accueil et le fonds de dotation Maison
				Bernard, associé majoritaire (80%). Ce bien est utilisé pour loger des
				étudiants et développer un travail d’accueil.
			</P3>
			<T4 couleur={tools.ORANGE}>8.2.1. Soutien depuis sa création</T4>
			<Image src="https://www.dropbox.com/scl/fi/hjecg5uj8tcp1ws64fquj/57016624bbf360a4f765b1d77376bcdd7d5fea58.png?rlkey=oe2rv594dij8nuambr3qy6v0u&raw=1" />
			<T4 couleur={tools.ORANGE}>8.2.2. Faits marquants</T4>
			<P4>
				Sur l’année 2022, le secrétaire du fonds indique que d’une façon directe
				le fonds de dotation a vocation à détenir des lieux d’accueil mais qu’il
				soutient également l’œuvre d’intérêt général de l’association Timothée
				Accueil et Jeunesse de la façon suivante : 8370 € des chambres laissées
				en gestion directe à Fouday - 6840 € correspondant au « manque à gagner
				» avec le commodat en faveur de Timothée Accueil et Jeunesse - 2558 € de
				charges payées par le fonds pour l’usage gratuit de l’association au
				titre du commodat – 10 000 € de donation en numéraire. Soit un total de{" "}
				<b>27 768 €</b>.
			</P4>
			<P4>
				<i>
					NB : Ce total ne comprend pas les revenus locatifs qui seront ensuite
					reversés à l’association bénéficiaire en cas d’excédent après avoir
					été utilisé pour la gestion et l’entretien des biens, conformément au
					double objectif du fonds qui est de valoriser son patrimoine pour
					soutenir une association d’intérêt générale.
				</i>
			</P4>
			<T3 couleur={tools.ORANGE}>
				8.3. FONDS DE DOTATION PHILANTROPIC – PRESENTATION ECRITE
			</T3>
			<P3>
				La vie du fonds a été marquée par le décès accidentel de son
				administrateur Samuel CHAMPAULT, très engagé et qui suivait de près les
				différents aspects comptables et financiers auprès de notre trésorier.
				Il participait aussi activement aux relations avec l’association
				d’intérêt général Timothée Accueil & Jeunesse soutenue par le fonds.
				Pour rappel, le fonds de dotation Philantropic détient et gère PSS dans
				la zone de Labahou.
			</P3>
			<Image src="https://www.dropbox.com/scl/fi/nedsfnxtvuk3ltll1kjis/d5918ece7308265ef2fd802fde860c793412a11e.png?rlkey=25vfr5604nap5o2p1d7pncuu5&raw=1" />
			<VoteRadioBouton
				numVote="tajAg8"
				self={props.self}
				defaultPour={true}
				titre="VOTE D’APPROBATION SANS RÉSERVE"
			/>

			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* // 9 */}
			<Titre2
				couleur={tools.VERT_FONCE}
				texte="9. NEUVIEME DELIBERATION - POUVOIR POUR ACCOMPLIR LES FORMALITES BANCAIRES"
			/>
			<p>
				Le bureau propose à l’assemblée de donner tous pouvoirs à Monsieur David
				Gutscher et Monsieur Matthias FEHLMANN et, plus généralement, à toutes
				personnes dûment habilitées par le conseil d’administration pour
				représenter l’association auprès des institutions bancaires et de
				crédit. Le conseil d’administration propose la liste des personnes
				suivantes :
			</p>

			<TableauFormalitesBancaires />

			<p>
				Il est ici précisé que ce pouvoir est limité aux actes d’administration
				et de conservation et non les actes de disposition qui seront soumis à
				l’approbation du conseil d’administration. Les engagements dépassant une
				somme supérieure à quinze mille euros devront être validés par le
				conseil d’administration.
			</p>

			<VoteRadioBouton
				numVote="tajAg9"
				self={props.self}
				defaultPour={true}
				titre="VOTE D’APPROBATION DES POUVOIRS"
			/>
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* // Délibération 10 */}
			<Titre2
				couleur={tools.VERT}
				texte="10. Dixièmme délibération - POUVOIR POUR ACCOMPLIR LES FORMALITES ET REPRESENTER L’ASSOCIATION"
			/>
			<p>
				Le bureau propose à l’assemblée de donner tous pouvoirs à Monsieur David
				GUTSCHER Monsieur Benjamin DROUILLY et Monsieur Guilhem JAUSSAUD, et
				plus généralement au porteur d’une copie ou d’un extrait du
				Procès-verbal de la présente assemblée pour accomplir les formalités
				nécessaires et représenter l’association vis à vis des tiers.
			</p>
			<p>
				Il est ici précisé que ce pouvoir est limité aux actes d’administration
				et de conservation et non les actes de disposition qui seront soumis à
				l’approbation du conseil d’administration.
			</p>
			<p>
				Les engagements dépassant une somme supérieure à quinze mille euros
				devront être validés par le conseil d’administration.
			</p>

			<VoteRadioBouton
				numVote="tajAg10"
				self={props.self}
				defaultPour={true}
				titre="VOTE D’APPROBATION DES POUVOIRS"
			/>
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}

			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
			{/* //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}

			<p className="pt-5"></p>
			<p className="pt-5 pb-5 text-center">
				<b>
					Fait à Anduze, <br />
					Le 3 juin 2024
				</b>
			</p>

			<TableauSignatureAG2024 />
			<h5 className="pt-5">Liste des annexes Timothée Accueil et Jeunesse</h5>
			<FichiersATelecharger liste={FICHIER_TELECHARGER_TAJ} />
		</div>
		<div className="text-right pt-5 pb-5">
			<button
				onClick={event => {
					event.preventDefault();
					props.self.setState(
						{
							menu: 2
						},
						() => window.scrollTo(0, 450)
					);
				}}
				className="btn btn-primary center-block pl-5 pr-5"
				disabled={props.self.state.disabledBoutonSuivant1}
			>
				Suivant
			</button>
		</div>
	</>
);
