import React, { Component } from "react";
import "./AddPicture.scss";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { Hidden } from "@material-ui/core";

export class AddPicture extends Component {
	state = {
		src: null,
		crop: {
			unit: "%",
			width: 50,
			x: 25,
			y: 25,
			aspect: 1
		}
	};

	componentDidMount() {}
	onSelectFile = e => {
		if (e.target.files && e.target.files.length > 0) {
			const reader = new FileReader();
			reader.addEventListener("load", () =>
				this.setState({ src: reader.result })
			);
			reader.readAsDataURL(e.target.files[0]);
		}
	};
	// If you setState the crop in here you should return false.
	onImageLoaded = image => {
		this.imageRef = image;
	};
	onCropComplete = crop => {
		this.makeClientCrop(crop);
	};
	onCropChange = (crop, percentCrop) => {
		// You could also use percentCrop:
		// this.setState({ crop: percentCrop });
		this.setState({ crop });
	};
	makeClientCrop(crop) {
		if (this.imageRef && crop.width && crop.height) {
			const croppedImageUrl = this.getCroppedImg(
				this.imageRef,
				crop,
				"newFile.jpeg"
			);
			this.setState({ croppedImageUrl });
		}
	}
	getCroppedImg(image, crop) {
		const canvas = document.createElement("canvas");
		const scaleX = image.naturalWidth / image.width;
		const scaleY = image.naturalHeight / image.height;
		canvas.width = crop.width;
		canvas.height = crop.height;
		const ctx = canvas.getContext("2d");
		ctx.drawImage(
			image,
			crop.x * scaleX,
			crop.y * scaleY,
			crop.width * scaleX,
			crop.height * scaleY,
			0,
			0,
			crop.width,
			crop.height
		);
		return canvas.toDataURL("image/jpeg");
		// return canvas.toDataURL('image/jpeg', 1.0);
		// return canvas.toDataURL();
	}

	handleUploadFile = mode => {
		//fetch(`${urlApi}/products/uploadImage/${id}`, {
		fetch(`http://127.0.0.1:8080/timedia/public/userUploadPicture`, {
			method: "POST",
			body: this.state.croppedImageUrl
		})
			.then(r => {
				console.log("success", r);
			})
			.catch(err => console.error("Error", err));
	};

	render() {
		const { crop, croppedImageUrl, src } = this.state;
		return (
			<>
				<div className="custom-file">
					<input
						type="file"
						className="custom-file-input"
						id="inputGroupFile01"
						accept="image/*"
						onChange={this.onSelectFile}
					/>
					<label className="custom-file-label" htmlFor="inputGroupFile01">
						Choisir fichier
					</label>

					<div style={{}} className="pt-3 pb-3">
						{src && (
							<ReactCrop
								src={src}
								crop={crop}
								onImageLoaded={this.onImageLoaded}
								onComplete={this.onCropComplete}
								onChange={this.onCropChange}
								circularCrop={true}
							/>
						)}
					</div>
					<div className="pt-3 text-center">
						<button
							onClick={() => this.handleUploadFile(1)}
							type="button"
							class="btn btn-primary mr-2"
						>
							Envoyer et terminer
						</button>
					</div>
				</div>
			</>
		);
	}
}
