export const RESULT_FIELDS = {
	auteurs: {
		raw: {},
		snippet: {
			size: 100,
			fallback: true
		}
	},

	titre: {
		raw: {},
		snippet: {
			size: 100,
			fallback: true
		}
	},

	sous_titre: {
		raw: {},
		snippet: {
			size: 100,
			fallback: true
		}
	},
	id: {
		raw: {},
		snippet: {
			size: 100,
			fallback: true
		}
	},
	theme: {
		raw: {},
		snippet: {
			size: 100,
			fallback: true
		}
	},
	date_parution: {
		raw: {},
		snippet: {
			size: 100,
			fallback: true
		}
	},
	revue: {
		raw: {},
		snippet: {
			size: 100,
			fallback: true
		}
	}
};

//****************************************************************************************************************** */

export const FACETS = {
	auteurs: {
		type: "value",
		size: 900
	},
	theme: {
		type: "value",
		size: 900
	},
	date_parution: {
		type: "value",
		size: 900
	},
	revue: {
		type: "value",
		size: 900
	}
};
