import React from "react";
import Page from "../Page";
import { tools, api } from "@mitim/react-mitim";
import { NavLink, Link } from "react-router-dom";
import { titleCase } from "../../utils/functions";
import { getKeyValuePublique } from "../Constants";

export class DecouvrirLEvangile extends Page {
	constructor(props) {
		super(props);
	}

	state = {
		acces: true,
		type: "",
		dataMessagesAudios: [],
		medias: []
	};

	componentDidMount() {
		super.componentDidMount();
		this.setState({ type: this.props.match.params.type });

		this.load();
	}

	load() {
		getKeyValuePublique("decouvrir-l-evangile-messages-audios", val => {
			this.setState({
				dataMessagesAudios: val
			});
		});

		api.getGetMediasSimple(this.props.match.params.type, this, () => {});
	}

	componentDidUpdate(prevProps) {
		if (this.props.match.params.type !== prevProps.match.params.type) {
			console.log("update");
			this.setState({ type: this.props.match.params.type });
			this.load();
		}
	}

	render() {
		const { t } = this.props;
		let classes = {
			filter: "col-12 col-md-4 col-lg-3 col-xl-3 col-xxl-2",
			result: "col-12 col-md-8 col-lg-9 col-xl-9 col-xxl-10",
			pagination: "pt-5"
		};
		const CATEGORIES = ["messages audios", "thèmes divers"];
		const categories = CATEGORIES.map(cat => (
			<div className="col-auto category pl-5" key={cat}>
				<NavLink exact to={"/decouvrir-l-evangile/" + cat}>
					{titleCase(cat)}
				</NavLink>
			</div>
		));

		const Element = props => (
			<div className="col-12 mt-1 result" style={{ backgroundColor: "#fff" }}>
				<div className="row pl-md-3">
					<div className="col-12 mt-1 result telechargementCoursCandidats">
						<Link to={props.data.lien} className="lien">
							<div>
								<span
									className="text-white pl-3 pr-3 ft-lato-regular pt-2 pb-1 text-uppercase"
									style={{
										fontSize: "0.8em",
										backgroundColor: props.data.couleurTitre
									}}
								>
									{props.data.titre}
								</span>
							</div>
							<div className="telechargementCoursCandidatsSelect pl-2 pr-2">
								<div
									className="pt-2 text-black ft-alegreya-sans-light"
									style={{ fontSize: "1em" }}
								>
									{props.data.contenu}
									{/* <img style={{ float: "right" }} src={download} width={18} /> */}
								</div>
							</div>
						</Link>
						<hr />
					</div>
				</div>
			</div>
		);

		const Audios = props => (
			<>
				<h5
					style={{ display: "block" }}
					className="ft-lato-regular text-noir pl-4 pb-4 pt-4"
				>
					ANNÉE {props.annee}
				</h5>
				{this.state.dataCoursAudios.map(elem => (
					<>
						{elem.annee == props.annee && (
							<Element
								key={elem.lien}
								data={{
									lien: elem.lien,
									couleurTitre: tools[elem.couleurTitre],
									titre: elem.titre,
									contenu: <>{elem.contenu}</>
								}}
							/>
						)}
					</>
				))}
			</>
		);

		const affichageData = datas => {
			return datas.map(elem => (
				<>
					<Element
						key={elem.lien}
						data={{
							lien: elem.lien,
							couleurTitre: tools[elem.couleurTitre],
							titre: elem.titre,
							// contenu: <>{elem.contenu}</>
							contenu: (
								<div
									dangerouslySetInnerHTML={{
										__html: elem.contenu
									}}
								/>
							)
						}}
					/>
				</>
			));
		};

		const ElementMedia = props => (
			<div className="col-12 mt-1 result" style={{ backgroundColor: "#fff" }}>
				<div className="row pl-md-3">
					<div className="col-12 mt-1 result affichageExhortationsSimple">
						<Link
							to={`/media/${props.data.media.id}/${props.data.media.titre}`}
							className="lien"
						>
							<div>
								{props.data.auteurs.length > 0 && (
									<span
										className="text-white pl-3 pr-3 ft-lato-regular pt-2 pb-1"
										style={{
											fontSize: "0.8em",
											backgroundColor: tools.getColorByAuthorId(
												props.data.auteurs[0].auteur.id
											)
										}}
									>
										<>
											{props.data.auteurs[0].auteur.prenom +
												" " +
												props.data.auteurs[0].auteur.nom}
										</>
									</span>
								)}
							</div>
							<div className="affichageExhortationsSimpleSelect pr-2">
								<div
									className="pt-2 text-black ft-alegreya-sans-light pb-2"
									style={{ fontSize: "1em" }}
								>
									{props.data.videoYoutubes.length > 0 && (
										<img
											src={
												"https://i.ytimg.com/vi/" +
												props.data.videoYoutubes[0].videoYoutube.idYoutube +
												"/mqdefault.jpg"
											}
											className="img-fluid pr-3 d-xl-inline d-block"
											alt=""
											style={{ maxWidth: "200px" }}
										/>
									)}
									<span style={{ verticalAlign: "bottom" }}>
										<span className="pb-5">
											{props.data.livreBibles.map(data => (
												<>
													<strong>
														{data.livreBible.nom1 + " " + data.ref}
													</strong>{" "}
													|{" "}
												</>
											))}
											{props.data.media.titre}
										</span>
									</span>
									<span style={{ float: "right" }}>
										{props.data.media.datePublication}
									</span>
								</div>
							</div>
						</Link>
						<hr className="pt-2 pl-2" />
					</div>
				</div>
			</div>
		);

		const AffichageDatas = () => (
			<>
				{this.state.medias &&
					this.state.medias.map(elem => (
						<ElementMedia key={elem.id} data={elem} />
					))}
			</>
		);

		return (
			<>
				{this.state.acces && (
					<>
						<div className="container-fluid">
							<div className="row align-items-center categories-navbar">
								<div className={classes.filter + " mt-3 my-md-3"}></div>

								<div className={classes.result}>
									<div className="row categories my-3">{categories}</div>
								</div>
							</div>

							<div className="row">
								<div className={classes.filter + " filter-list"}></div>
								<div
									className={classes.result}
									style={{ backgroundColor: "#fff" }}
								>
									<p>
										<br />
									</p>
									<div className="row pl-md-3">
										{this.state.type == "messages audios" &&
											affichageData(this.state.dataMessagesAudios)}
										{this.state.type == "témoignages" && <AffichageDatas />}
										{this.state.type == "thèmes divers" && <AffichageDatas />}

										<p>
											<br />
											<br />
											<br />
											<br />
											<br />
										</p>
									</div>
								</div>
							</div>
						</div>
					</>
				)}
			</>
		);
	}
}
