import React from "react";
import { Icones } from "@mitim/react-mitim";

export const BanniereTitre = props => (
	<div className="container pt-5 pb-5">
		<div className="text-center pb-3">
			<span
				style={{ backgroundColor: props.couleur, fontSize: "1em" }}
				className="text-white pl-3 pr-3 pt-1 pb-1 text-center ft-lato-regular text-uppercase"
			>
				<strong>{props.texteType || "LIVRE"}</strong>
			</span>
		</div>
		<h1
			className="contenu-h1 text-uppercase ft-lato-regular"
			style={{ fontSize: "1.6em" }}
		>
			<strong>{props.titre}</strong>
		</h1>
		<h2 className="contenu-h2 pt-3" style={{ fontSize: "1.5em" }}>
			{props.sousTitre}
		</h2>
	</div>
);

export const getCouvertureImageSrcByRang = (rang, fichiers) => {
	var result = "";
	fichiers.forEach(function(element) {
		if (
			element.rang === rang &&
			element.fichier.thumbnail_std &&
			element.couverture
		) {
			result = element.fichier.thumbnail_std.sharedLink;
			// result = element.fichier.thumbnail_std.publicLink;
		}
	});

	if (result === "") {
		fichiers.forEach(function(element) {
			if (
				element.rang === 0 &&
				element.fichier.thumbnail_std &&
				element.couverture
			) {
				result = element.fichier.thumbnail_std.sharedLink;
			}
		});
	}
	return result;
};

export const LireContenu = props => (
	<div className="col-12 col-md-8 bg-white" style={{ borderRadius: "8px" }}>
		<div className="paroles  p-3 p-md-5">
			<div style={{ position: "relative" }}>
				<span style={{ position: "absolute" }}>
					<Icones.lireCircle couleur={props.couleur} />
				</span>
				<h4
					style={{ position: "absolute" }}
					className="d-inline ft-lato-light text-noir"
					style={{ marginLeft: "45px" }}
				>
					{props.titre}
				</h4>
			</div>
			<div
				className="pt-4"
				dangerouslySetInnerHTML={{
					__html: props.contenu
				}}
			/>
		</div>
	</div>
);

export const isContientFichiersAudios = fichiers => {
	let contientAudio = false;
	// this.props.app.state.fichiers.map(fichier => {
	fichiers.map(fichier => {
		if (fichier.fichier.fichierContentType.fichierType.id === 4) {
			contientAudio = true;
		}
	});
	return contientAudio;
};
