import React from "react";
import { MEMBRES_TAJ, MEMBRES_MT, SIGNATURE } from "./DATA";

export const Titre2 = props => (
	<div className="pt-5 pb-1">
		<h2 style={{ backgroundColor: props.couleur }}>{props.texte}</h2>
	</div>
);
export const Titre3 = props => (
	<h3 className="pt-2" style={{ color: props.couleur }}>
		{props.texte}
	</h3>
);
export const Titre4 = props => (
	<h4 className="pt-2 titre4" style={{ color: "#414042" }}>
		{props.texte}
	</h4>
);
export const Titre5 = props => (
	<h5 className="pt-2 titre5" style={{ color: "#414042" }}>
		{props.texte}
	</h5>
);

export const Paragraph4 = props => <p className="paragraph4">{props.texte}</p>;
export const Paragraph5 = props => <p className="paragraph5">{props.texte}</p>;

export const Image = props => (
	<div className="text-center pb-3">
		<img className="img-fluid text-center" src={props.src} alt="" />
	</div>
);
