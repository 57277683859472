import React from "react";
import { withTranslation } from "react-i18next";
import SearchIcon from "@material-ui/icons/Search";
import IconButton from "@material-ui/core/IconButton";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";

class Rechercher extends React.Component {
	state = { searchValue: "" };

	handleSearch = () => {
		console.log("do the search with the value: " + this.state.searchValue);
		// TODO do the search
	};

	handleKeyPress = event => {
		if (event.key === "Enter") {
			this.handleSearch();
		}
	};

	handleInput = e => {
		this.setState({ searchValue: e.target.value });
	};

	render() {
		const { t } = this.props;
		return (
			<div className="col-auto">
				<Input
					placeholder={t("menu.rechercher")}
					inputProps={{
						"aria-label": t("menu.rechercher")
					}}
					onKeyPress={this.handleKeyPress}
					onChange={this.handleInput}
					value={this.state.searchValue}
					startAdornment={
						<InputAdornment position="start">
							<IconButton
								aria-label={t("menu.rechercher")}
								onClick={this.handleSearch}
							>
								<SearchIcon />
							</IconButton>
						</InputAdornment>
					}
				/>
			</div>
		);
	}
}
export default withTranslation()(Rechercher);
