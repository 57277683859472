import React from "react";
import { withTranslation } from "react-i18next";
import Page from "../Page";
import {
	ModuleEncartListMt,
	tools,
	ModuleCaroussel,
	api
} from "@mitim/react-mitim";
import QuiSommesNous from "./QuiSommesNous";
import "./Home.scss";

import mtIconeEncart_1 from "../../resources/icons/mt-icone-encart-1.svg";
import mtIconeEncart_2 from "../../resources/icons/mt-icone-encart-2.svg";
import mtIconeEncart_3 from "../../resources/icons/mt-icone-encart-3.svg";
import mtIconeEncart_4 from "../../resources/icons/mt-icone-encart-4.svg";
import mtIconeEncart_5 from "../../resources/icons/mt-icone-encart-5.svg";
import mtIconeEncart_6 from "../../resources/icons/mt-icone-encart-6.svg";
import { getKeyValuePublique, timediaUrl } from "../Constants";

const ELEMENTS_ENCARTS_1 = [
	{
		titre: "ÉGLISES",
		couleurTitre: tools.ROUGE,
		theme: "rouge",
		sousTitre: "Églises en France et à l’étranger",
		iconSrc: mtIconeEncart_1,
		imgSrc: "mt-encart-1.jpg",
		linkTo: "/eglises",
		textLink: "Voir la rubrique",
		contenu:
			'<ul style="margin-bottom: 0px"><li>Notre confession de foi</li><li>Trouver une assemblée chrétienne</li><li>Mission Timothée en France et à l’étranger</li><ul><br/>'
	},
	{
		titre: "PAROLE PARTAGÉE",
		couleurTitre: tools.ORANGE,
		sousTitre: "Exhortations | Connaissance de Dieu",
		iconSrc: mtIconeEncart_2,
		theme: "rouge",
		imgSrc: "mt-encart-2.jpg",
		linkTo: "/parole-partagee",
		textLink: "Voir la rubrique",
		contenu:
			"Des méditations bibliques et des lectures dans le but de nourrir la foi des croyants et de tous ceux qui cherchent à connaître Dieu.<br/><br/>"
	},
	{
		titre: "CANTIQUES",
		couleurTitre: tools.JAUNE,
		sousTitre: "Avec Des Cris De Joie",
		iconSrc: mtIconeEncart_3,
		theme: "rouge",
		imgSrc: "mt-encart-3.jpg",
		textLink: "Visiter le site",
		linkTo: "https://avecdescrisdejoie.fr/",
		contenu:
			"Les cantiques de la Mission Timothée sont connus pour mettre le texte biblique en musique. Découvrez ici la richesse de ce répertoire.<br/><br/>"
	}
];

const ELEMENTS_ENCARTS_2 = [
	{
		titre: "ACCUEIL & JEUNESSE",
		couleurTitre: tools.VERT,
		sousTitre: "Maisons d’accueil | Camps bibliques",
		iconSrc: mtIconeEncart_4,
		theme: "rouge",
		imgSrc: "mt-encart-4.jpg",
		textLink: "Visiter le site",
		linkTo: "https://www.timotheeaccueiljeunesse.fr/",
		contenu:
			"</br>Dès l’origine, la Mission Timothée accomplit sa vocation par le biais de l’accueil. Plusieurs maisons d’accueil se sont implantées en France et à l’étranger."
	},
	{
		titre: "FORMATION MISSIONNAIRE",
		couleurTitre: tools.BLEU_TURQUOISE,
		sousTitre:
			"Formation biblique au service des églises (diaconat et enseignement)",
		iconSrc: mtIconeEncart_5,
		textLink: "Voir la rubrique",
		theme: "rouge",
		imgSrc: "mt-encart-5.jpg",
		linkTo: "/formation",
		contenu:
			"La formation s’adresse en priorité à des futurs collaborateurs dans l’œuvre missionnaire. Des filières enseignement et diaconat ont été mises en place."
	},
	{
		titre: "ENFANTS",
		couleurTitre: tools.BLEU_FONCE,
		sousTitre: "Timothée Kids | Ressources pour l’école du dimanche",
		iconSrc: mtIconeEncart_6,
		textLink: "Voir la rubrique",
		theme: "rouge",
		imgSrc: "mt-encart-6.jpg",
		linkTo: "/enfants",
		contenu:
			'<ul style="margin-bottom: 0px"><li>Un site dédié aux enfants</li><li>Ressources pédagogiques pour l’ensei&shy;gne&shy;ment biblique des enfants.</li></ul><br/>'
	}
];

export const tokenPublic = "cHVibGljOjk4SE85SE9JVUdPSTk4SA==";
export const defaultHeaders = {
	Accept: "application/json",
	"Content-Type": "application/json",
	Authorization: "Basic " + tokenPublic
};
export const defaultParams = { headers: defaultHeaders };
export const getGetActusCaroussel = (idGroupeActualite, self, callback) => {
	fetch(`${api.timediaUrl}/public/actualitesCaroussel/${idGroupeActualite}`, {
		...defaultParams,
		method: "GET"
	}).then(response => {
		if (response.status !== 200) {
			console.error("Error: " + response.status);
			return;
		}
		response.json().then(json => {
			console.log(json);
			self.setState(
				{
					actus: json.data
				},
				callback
			);
		});
	});
};
export const getGetActusPage = (idGroupeActualite, self, callback) => {
	fetch(`${api.timediaUrl}/public/actualitesPage/${idGroupeActualite}`, {
		...defaultParams,
		method: "GET"
	}).then(response => {
		if (response.status !== 200) {
			console.error("Error: " + response.status);
			return;
		}
		response.json().then(json => {
			console.log(json);
			self.setState(
				{
					actus: json.data
				},
				callback
			);
		});
	});
};

class Home extends Page {
	componentDidMount() {
		// getKeyValuePublique("site-mission-a-la-une", val => {
		// 	this.setState({
		// 		elementsALaUne: val
		// 	});
		// });

		getGetActusCaroussel(1967, this, () => {});
	}

	state = {
		elementsALaUne: [],
		actus: []
	};

	render() {
		const { t } = this.props;

		return (
			<>
				<div
					className="container-fluid pb-lg-5 no-gutters pt-5"
					style={{ minHeight: "480px", backgroundColor: "#e2e3e4" }}
				>
					<div className="container">
						{/* <BlockImgNlt /> */}
						<ModuleEncartListMt data={ELEMENTS_ENCARTS_1} />
					</div>
					<div className="text-center pt-5 d-none d-lg-block pb-5" style={{}}>
						<img
							src={require(`../../resources/icons/verset_2Tim2.svg`)}
							className="img-fluid"
							style={{ width: "40%" }}
						/>
					</div>

					{/* Mode mobile */}
					<div className="text-center pt-5 d-block d-lg-none" style={{}}>
						<img
							src={require(`../../resources/icons/verset_2Tim2.svg`)}
							className="img-fluid"
							style={{ width: "90%" }}
						/>
					</div>
					{/*  */}

					<div className="container home pt-5">
						<ModuleEncartListMt data={ELEMENTS_ENCARTS_2} />
					</div>
				</div>
				<div
					className="container-fluid no-gutters bg-gris8"
					style={{ minHeight: "80px" }}
				>
					<div className="container pb-5 pt-5">
						<h1 className="contenu-h1 text-uppercase">À LA UNE</h1>

						<div className="pt-5">
							<ModuleCaroussel elements={this.state.actus} className="p-5" />
						</div>
					</div>
				</div>

				<div
					className="container-fluid pb-5 no-gutters bg-gris1"
					style={{ minHeight: "80px" }}
				>
					<QuiSommesNous
						data={{
							titre: "ASSOCIATION CULTUELLE",
							sousTitre: "Loi 1905",
							contenu: (
								<>
									<p>
										La Mission Timothée a pour but l’exercice du culte
										protestant. Elle le fait par le biais de rencontres autour
										de la Bible dans différents lieux de culte en France et à
										l’étranger.
									</p>
									<p>
										Convaincue de la richesse du message biblique, la Mission
										Timothée publie des commentaires bibliques, des témoignages,
										ainsi que les recueils et albums de chants « Avec Des Cris
										de Joie ». À travers la rubrique « Parole Partagée », une
										base de données de prédications et de conférences est mise à
										disposition sur internet. La Mission Timothée propose
										également une formation missionnaire avec une filière
										diaconat et une filière enseignement.
									</p>
									<p>
										Les œuvres sociales, fruit de la charité chrétienne, sont
										exercées sous l’égide de l’association Timothée Accueil et
										Jeunesse (loi 1901, reconnue d’intérêt général).
									</p>
								</>
							),
							boutons: [
								{
									nom: "CONFESSION DE FOI",
									lien: "/confession-de-foi"
								},
								{
									nom: "HISTORIQUE",
									lien: "/historique"
								},
								{
									nom: "NOUS SOUTENIR",
									lien: "/soutien"
								}
							]
						}}
					/>
					{/* <br />
					<br />
					<LAccueil /> */}

					{/* <div className="container">
						<ModuleCard elements={ELEMENTS} />
						<ModuleCaroussel elements={ELEMENTS} />
					</div> */}
				</div>
			</>
		);
	}
}

export default withTranslation()(Home);
