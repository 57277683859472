import React from "react";
import { withTranslation } from "react-i18next";
import Page from "../Page";
import { Utilisateur } from "../Utilisateur/Utilisateur";

class MonCompte extends Page {
	constructor(props) {
		super(props);
	}

	render() {
		const { t } = this.props;
		return (
			<div className="container-fluid pb-5">
				<div className="row">
					<div className="col-lg-1 col-xl-2"></div>
					<div className="col-lg-10 col-xl-8">
						<Utilisateur />
					</div>
					<div className="col-lg-1 col-xl-2"></div>
				</div>
			</div>
		);
	}
}

export default withTranslation()(MonCompte);
