import React from "react";
import Page from "../Page";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import {
	ModuleEncartMtCustom,
	ModuleEncartListMt,
	ModuleCard,
	tools,
	api,
	Icones
} from "@mitim/react-mitim";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { ThemeProvider } from "@material-ui/core";
import BlockLecteurAudio from "../BlockLecteurAudio/BlockLecteurAudio";
import { element } from "prop-types";
import sound from "../../resources/icons/sound.gif";
import "./BibleEnUnAnLecture.scss";

class BibleEnUnAnLectureLivre extends Page {
	constructor(props) {
		super(props);
		this.audioPlayer = React.createRef();
	}
	componentDidUpdate(prevProps) {
		if (this.props.match.params.id !== prevProps.match.params.id) {
			this.loadMedia();
		}
	}

	state = {
		data: {},
		media: {},
		auteurs: [],
		fichiers: [],
		livreBibles: [],
		mediaMedias: [],
		videoYoutubes: [],
		cantique: {},
		cantiqueListen: "",
		id: "0",
		indexLecture: "0",
		classementMediaMedia: "0",
		fetchReceive: false,
		audioListen: "",
		play: false,
		couleurPlayAll: "#" + this.props.match.params.couleur
	};

	componentDidMount() {
		super.componentDidMount();
		this.loadMedia();
	}

	loadMedia() {
		api.getMedia(this.props.match.params.id + "?order=nom", this, () => {
			// if (this.state.media.mediaType.id === 2) {

			let audioFichier = this.state.fichiers.filter(
				fichier =>
					fichier.fichier &&
					fichier.fichier.fichierContentType.fichierType.id === 4
			);
			if (audioFichier.length >= 1) {
				this.setState({
					audioListen: audioFichier[0].fichier.sharedLink,
					id: audioFichier[0].fichier.id,
					rangMediaMedia: audioFichier[0].rang
				});
			}
			// }
		});
	}

	handleClick = (assMediaFichier, index) => {
		if (assMediaFichier.fichier) {
			console.log("this is:", assMediaFichier.fichier.sharedLink);
			console.log("this is:", assMediaFichier.fichier.id);
			this.setState(
				{
					audioListen: assMediaFichier.fichier.sharedLink,
					id: assMediaFichier.fichier.id,
					indexLecture: index,
					play: true
				},
				() => {
					this.audioPlayer.current.type = "audio/mp3";
					this.audioPlayer.current.play();
				}
			);
		}
	};

	playAll = () => {
		for (const [index, value] of this.state.fichiers
			.filter(fichier => fichier.fichier)
			.entries()) {
			this.setState(
				{
					audioListen: value.fichier.sharedLink,
					id: value.fichier.id,
					indexLecture: 0,
					play: true
				},
				() => {
					this.audioPlayer.current.type = "audio/mp3";
					this.audioPlayer.current.play();
				}
			);
			break;
		}
	};

	handleEnded = () => {
		for (const [index, value] of this.state.fichiers
			.filter(fichier => fichier.fichier)
			.entries()) {
			if (this.state.indexLecture < index) {
				this.setState(
					{
						audioListen: value.fichier.sharedLink,
						id: value.fichier.id,
						indexLecture: index,
						play: true
					},
					() => {
						this.audioPlayer.current.type = "audio/mp3";
						this.audioPlayer.current.play();
					}
				);
				break;
			}
		}
	};

	handlePlaying = () => {
		this.setState({ play: true });
	};

	audio() {
		return (
			<>
				<audio
					style={{}}
					src={this.state.audioListen}
					preload="true"
					ref={this.audioPlayer}
					id="audio1"
					controls="controls"
					controlsList="nodownload"
					onEnded={this.handleEnded}
					type="audio/mpeg"
					onPlaying={this.handlePlaying}
				>
					Your browser does not support HTML5 Audio!
				</audio>
			</>
		);
	}

	classOfCantique(assMediaFichier) {
		var classValue = "";
		if (assMediaFichier.fichier) {
			if (assMediaFichier.fichier.id === this.state.id && this.state.play) {
				classValue = "ml-5 my-3 enLecture";
			} else {
				classValue = "ml-5 my-3";
			}
		} else {
			classValue = "ml-5 my-3";
		}
		return classValue;
	}

	render() {
		const { t } = this.props;

		return (
			<>
				{/* <div
					className="container-fluid pb-lg-5 no-gutters pt-5 bibleEnUnAn"
					style={{ minHeight: "480px", backgroundColor: "#e2e3e4" }}
				>
					<div className="container">

					</div>
				</div> */}

				<div
					className="container-fluid bibleEnUnAnLecture"
					style={{ minHeight: "480px" }}
				>
					<div className="container home pl-0 pr-0">
						<div className="col-lg-12 col-md-12 pl-0 pr-0">
							<div className="container">
								<h5
									style={{ display: "block" }}
									className="ft-lato-regular text-noir text-center pt-5 text-uppercase"
								>
									<strong>{this.state.media.titre}</strong>
								</h5>
								<div className="row align-self-center" />
								<div className="row pt-5">
									<div className="col-md-6 col-sm-12">
										<div className="row">
											<div className="col-sm-12 pt-3"></div>
											<div className="col-sm-12 pl-0">
												<div className="pb-4" onClick={this.playAll} style={{}}>
													<span
														className="playAll cursor-pointer"
														onMouseEnter={() => {
															this.setState({ couleurPlayAll: tools.ROUGE });
														}}
														onMouseLeave={() => {
															this.setState({
																couleurPlayAll:
																	"#" + this.props.match.params.couleur
															});
														}}
													>
														<Icones.play couleur={this.state.couleurPlayAll} />
													</span>
													<span style={{}}>
														<h6
															className="d-inline pl-3"
															style={{ fontSize: "1.2em" }}
														>
															Écouter le livre en entier
														</h6>
													</span>
												</div>
												{this.audio()}
											</div>
										</div>
									</div>
									<div className="block col-md-6 col-sm-12 mt-3 mb-3">
										<table className="table table-condensed">
											<tbody>
												{this.state.fichiers.map((assMediaFichier, index) => (
													<tr
														key={assMediaFichier.fichier.id}
														className={
															this.classOfCantique(assMediaFichier) +
															" cursor-pointer"
														}
														onClick={this.handleClick.bind(
															this,
															assMediaFichier,
															index
														)}
														style={{}}
													>
														<td className="">
															<figure style={{ position: "relative" }}>
																<span
																	className="play2"
																	style={{
																		position: "absolute",
																		marginTop: "-12px"
																	}}
																>
																	<Icones.play2
																		couleur={
																			"#" + this.props.match.params.couleur
																		}
																	/>
																</span>
																<span
																	className="sound"
																	style={{
																		position: "absolute",
																		marginTop: "-8px"
																	}}
																>
																	<img src={sound} width={30} />
																</span>

																<span
																	style={{
																		color: "#666",
																		backgroundColor:
																			"#" + this.props.match.params.couleur,
																		position: "absolute",
																		marginTop: "-4px",
																		marginLeft: "70px",
																		paddingLeft: "10px",
																		paddingRight: "10px"
																	}}
																	className="text-white ft-lato-regular"
																>
																	CHAPITRE {index + 1}{" "}
																</span>
															</figure>
														</td>
													</tr>
												))}
											</tbody>
										</table>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}

export default withTranslation()(BibleEnUnAnLectureLivre);
