import React from "react";
import "../AGVote/AGVote.scss";
import { tools } from "@mitim/react-mitim";

export const EnteteTaj = () => (
	<>
		<div className="text-center pb-3">
			<img
				src={`${tools.lienPhotos}/taj.png`}
				name="Image 2"
				style={{ maxWidth: "350px" }}
				className="text-center img-fluid"
				alt=""
			/>
		</div>

		<p className="text-center">
			<font color="#000000">
				<font size={4} style={{ fontSize: "14pt" }}>
					<b>
						<font color="#000000">
							L'association Timothée Accueil et Jeunesse
							<br />
							1654 chemin de Recoulin
							<br />
							30140 Anduze
							<br />
							N° RNA : W 301000407
							<br />
							SIREN&nbsp;: 311&nbsp;087&nbsp;803
						</font>
					</b>
				</font>
			</font>
		</p>
	</>
);

export const EnteteMT = () => (
	<>
		<div className="text-center pb-3">
			<img
				src={`${tools.lienPhotos}/mt2.png`}
				name="Image 2"
				style={{ maxWidth: "350px" }}
				className="text-center img-fluid"
			/>
		</div>

		<p className="text-center">
			<font color="#000000">
				<font size={4} style={{ fontSize: "14pt" }}>
					<b>
						<font color="#000000">
							L'association Mission Timothée
							<br />
							1654 chemin de Recoulin
							<br />
							30140 Anduze
							<br />
							N° RNA : W 301000403
							<br />
							SIREN&nbsp;: 493 249&nbsp;957
						</font>
					</b>
				</font>
			</font>
		</p>
	</>
);
