// Libraries
import React from "react";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { tools, Icones } from "@mitim/react-mitim";
// Components
import Media from "./Media";
import Header from "../Header/Header";
import Page from "../Page";
// Images
// CSS
// import '../../styles/Media.css';
import "./MediaOuvrage.scss";
import * as communs from "./communs";

class MediaOuvrage extends Page {
	constructor(props) {
		super(props);
		this.audioPlayer = React.createRef();
	}

	classOfCantique(fichier) {
		var classValue = "";
		if (fichier.fichier) {
			if (fichier.fichier.id === this.props.app.state.id) {
				classValue = "ml-5 my-3 ligne lineSelected";
			} else {
				classValue = "ml-5 my-3 ligne";
			}
		} else {
			classValue = "ml-5 my-3";
		}
		return classValue;
	}

	handleClick = fichier => {
		if (fichier.fichier) {
			console.log("this is:", fichier.fichier.sharedLink);
			console.log("this is:", fichier.fichier.id);
			this.setState(
				{
					cantiqueListen: fichier.fichier.sharedLink,
					id: fichier.fichier.id,
					rangMediaMedia: fichier.rang
				},
				() => {
					this.audioPlayer.current.play();
					this.audioPlayer.current.type = "audio/mp3";
				}
			);
		}
	};

	handleEnded = () => {
		for (const [index, value] of this.props.app.state.fichiers
			.filter(
				fichier =>
					fichier.fichier &&
					fichier.fichier.fichierContentType.fichierType.id === 4
			)
			.entries()) {
			if (this.props.app.state.rangMediaMedia < value.rang) {
				this.setState(
					{
						cantiqueListen: value.fichier.sharedLink,
						id: value.fichier.id,
						rangMediaMedia: value.rang
					},
					() => {
						this.audioPlayer.current.play();
						this.audioPlayer.current.type = "audio/mp3";
					}
				);
				break;
			}
		}
	};

	audio() {
		return (
			<>
				<audio
					className=""
					style={{}}
					src={this.props.app.state.cantiqueListen}
					preload="true"
					ref={this.audioPlayer}
					id="audio1"
					controls="controls"
					controlsList="nodownload"
					onEnded={this.handleEnded}
				>
					Your browser does not support HTML5 Audio!
				</audio>
			</>
		);
	}

	refsBibliques() {
		return (
			<>
				{this.props.app.state.livreBibles.length > 0 && (
					<>
						<h5>Références bibliques</h5>
						<ul>
							{this.props.app.state.livreBibles.map(referenceBiblique => (
								<li key={referenceBiblique.id}>
									{referenceBiblique.livreBible.nomSimple3 +
										referenceBiblique.ref +
										" "}
								</li>
							))}
						</ul>
					</>
				)}
			</>
		);
	}
	arrangements() {
		return (
			<>
				{this.props.app.state.media.arrangements.length > 0 && (
					<>
						<h5>Arrangements</h5>
						<ul>
							{this.props.app.state.media.arrangements.map(arrangement => (
								<li key={arrangement.id}>{arrangement.nom}</li>
							))}
						</ul>
					</>
				)}
			</>
		);
	}
	typeCantiques() {
		return (
			<>
				{this.props.app.state.media.typeCantiques.length > 0 && (
					<>
						<h5>Harmonisations</h5>
						<ul>
							{this.props.app.state.media.typeCantiques.map(arrangement => (
								<li key={arrangement.id}>{arrangement.nom}</li>
							))}
						</ul>
					</>
				)}
			</>
		);
	}
	auteurs() {
		return (
			<>
				{this.props.app.state.auteurs.length > 0 && (
					<>
						<h5>Auteurs / Compositeurs</h5>
						<ul>
							{this.props.app.state.auteurs.map(auteur => (
								<li key={auteur.auteur.id}>
									{auteur.auteur.prenom} {auteur.auteur.nom}
									{auteur.auteur.pays && <> - {auteur.auteur.pays}</>}
									{auteur.auteur.annees && <> - {auteur.auteur.annees}</>}
									{auteur.auteur.origine && <> - {auteur.auteur.origine}</>}
								</li>
							))}
						</ul>
					</>
				)}
			</>
		);
	}

	detailsCantique() {
		return (
			<div className="col-12 wrap-word">
				<h4 className="title white mb-3">Détails</h4>

				<div
					style={{
						// backgroundColor: '#f1f3f4',
						minWidth: "300px"
					}}
					className={"p-4 bg-white"}
				>
					{this.props.app.state.media.origine && (
						<>
							<h5>Origine</h5>
							<ul>
								<li>{this.props.app.state.media.origine.nom}</li>
							</ul>
						</>
					)}
					{this.refsBibliques()}
					{this.arrangements()}
					{this.typeCantiques()}
					{this.auteurs()}
				</div>
			</div>
		);
	}

	isContientFichiersAudios() {
		let contientAudio = false;
		this.props.app.state.fichiers.map(fichier => {
			if (fichier.fichier.fichierContentType.fichierType.id === 4) {
				contientAudio = true;
			}
		});
		return contientAudio;
	}

	render() {
		const { t } = this.props;
		const media = this.props.app.state.media;

		const styleBouton = {
			fontSize: "1.1em",
			color: "#333",
			paddingTop: "20px",
			paddingBottom: "100px"
		};
		const classeBouton = "text-center mt-5 ft-lato-light text-uppercase";
		return (
			<>
				<Header
					slide={{
						template: 2,
						image: "parolePartagee.jpg",
						h1: "Parole partagée",
						h2: "LIVRES",
						h3: "Nourrir sa foi et sa reflexion",
						descriptionMeta: ""
					}}
				/>
				<communs.BanniereTitre
					texteType="LIVRE"
					couleur={this.props.couleur}
					titre={media.titre}
					sousTitre={media.sousTitre}
				/>

				<div
					className="container-fluid pb-lg-5 no-gutters pt-5 bg-gris"
					style={{ minHeight: "480px" }}
				>
					<div className="container">
						<div className="row pt-5">
							<div className="col-12 col-md-4">
								<div className="row">
									<div className="col-md-11">
										<img
											src={communs.getCouvertureImageSrcByRang(
												1,
												this.props.app.state.fichiers
											)}
											className="img-fluid d-block"
											alt=""
											style={{ borderRadius: "5px" }}
										/>
										<div className="" style={{}}>
											<p className="pt-3">
												{media.nbrPages && (
													<>
														Nbr pages : {media.nbrPages}
														<br />
													</>
												)}
												{media.isbn && (
													<>
														Isbn : {media.isbn}
														<br />
													</>
												)}
												{media.dateEdition && (
													<>
														Édition : {media.dateEdition}
														<br />
													</>
												)}
												{media.prix && <>Prix : {media.prix}€</>}
											</p>

											{media.lienLjc && (
												<div
													className="text-center p-3 ml-5 mr-5 mt-4 mb-4"
													style={{ border: "1px solid #414042" }}
												>
													<a
														href={
															"https://www.librairiejeancalvin.fr/index.php/ljc/Publications/(id)/" +
															media.lienLjc
														}
														target="_blank"
														className={classeBouton}
														style={styleBouton}
													>
														passer commande
													</a>
												</div>
											)}
										</div>
									</div>
									<div className="col-md-1"></div>
								</div>
							</div>
							{this.props.app.state.media.contenu && (
								<communs.LireContenu
									contenu={this.props.app.state.media.contenu}
									titre={"DESCRIPTION"}
									couleur={this.props.couleur}
								/>
							)}
						</div>
					</div>
				</div>
			</>
		);
	}
}

export default withTranslation()(MediaOuvrage);
