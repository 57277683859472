import React from "react";

export const getTitre = result => {
	if (result.mediaType.id === 6) {
		return (
			<>
				{result.nomRevue.nom}
				<br />
				Cahier n°{result.numRevue}
			</>
		);
	} else {
		return result.titre;
	}
};
