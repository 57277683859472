import React from "react";
import { SondageRadioBouton } from "./SondageRadioBouton";
import {
	Titre2,
	Titre3,
	TableauMembresMT,
	TableauSignature
} from "./Fonctions";
import { tools } from "@mitim/react-mitim";

export const ContenuSondage = props => (
	<>
		<form onSubmit={props.self.handleSubmitFormulaire} method="POST">
			<div className="voteAg pt-5 mb-5">
				<br />
				<h1 className="text-uppercase">
					présence aux répétitions et enregistrements
					<br />
					de la chorale et orchestre du sud
				</h1>

				<div className="row text-center">
					<div className="col-md-6">
						<label htmlFor="" className="col-form-label ">
							Prénom
						</label>
						<input
							style={{ backgroundColor: "#f6f6f6", color: "#000" }}
							type="text"
							value={props.self.state.prenom}
							className="form-control"
							id={"IdNom"}
							disabled={true}
							required={true}
						/>
					</div>
					<div className="col-md-6">
						<label htmlFor="" className="col-form-label ">
							Nom
						</label>
						<input
							style={{ backgroundColor: "#f6f6f6", color: "#000" }}
							type="text"
							value={props.self.state.nom}
							className="form-control"
							id={"IdNom"}
							disabled={true}
							required={true}
						/>
					</div>
				</div>

				{/* CHORALE */}
				{props.self.state.membreChorale && (
					<>
						<div className="text-center pt-5">
							<Titre2 couleur={tools.BLEU} texte="CHORALE" />
						</div>

						<SondageRadioBouton
							nom="chorale29mai"
							self={props.self}
							defaultOui={false}
							titre={
								<>
									Serez vous présent à la répétition de chorale <br />
									le dimanche 29 mai 2022 ?
								</>
							}
						/>
						<SondageRadioBouton
							nom="chorale12juin"
							self={props.self}
							defaultOui={false}
							titre={
								<>
									Serez vous présent à la répétition de chorale <br />
									le dimanche 12 juin 2022 ?
								</>
							}
						/>
						<SondageRadioBouton
							nom="chorale26juin"
							self={props.self}
							defaultOui={false}
							titre={
								<>
									Serez vous présent à la répétition de chorale <br />
									le dimanche 26 juin 2022 ?
								</>
							}
						/>
						<SondageRadioBouton
							nom="chorale1juillet"
							self={props.self}
							defaultOui={false}
							titre={
								<>
									Serez vous présent à l'enregistrement de chorale <br />
									le vendredi 1 juillet 2022 ?
								</>
							}
						/>
						<SondageRadioBouton
							nom="chorale2juillet"
							self={props.self}
							defaultOui={false}
							titre={
								<>
									Serez vous présent à l'enregistrement de chorale <br />
									le samedi 2 juillet 2022 ?
								</>
							}
						/>
					</>
				)}
				<div className="text-center pt-5">
					<Titre2 couleur={tools.ORANGE} texte="ORCHESTRE" />
				</div>
				{/* ORCHESTRE */}
				{props.self.state.membreOrc && (
					<>
						<SondageRadioBouton
							nom="orc29mai"
							self={props.self}
							defaultOui={false}
							titre={
								<>
									Serez vous présent à la répétition d'orchestre <br />
									le dimanche 29 mai 2022 ?
								</>
							}
						/>
						<SondageRadioBouton
							nom="orc12juin"
							self={props.self}
							defaultOui={false}
							titre={
								<>
									Serez vous présent à la répétition d'orchestre <br />
									le dimanche 12 juin 2022 ?
								</>
							}
						/>
						<SondageRadioBouton
							nom="orc26juin"
							self={props.self}
							defaultOui={false}
							titre={
								<>
									Serez vous présent à la répétition d'orchestre <br />
									le dimanche 26 juin 2022 ?
								</>
							}
						/>
					</>
				)}
			</div>
			<div className="text-right pt-5 pb-5">
				<button
					//onClick={props.self.handleSubmitFormulaire}
					className="btn btn-primary center-block pl-5 pr-5"
					disabled={props.self.state.disabledBoutonSuivant1}
					type="submit"
				>
					Valider
				</button>
			</div>
		</form>
	</>
);
