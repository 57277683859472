// Libraries
import React from "react";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { tools, Icones } from "@mitim/react-mitim";
// Components
import Media from "./Media";
import Header from "../Header/Header";
import Page from "../Page";
// Images
// CSS
// import '../../styles/Media.css';
import "./MediaOuvrage.scss";
import * as communs from "./communs";

class MediaExhortation extends Page {
	constructor(props) {
		super(props);
		this.audioPlayer = React.createRef();
	}

	classOfCantique(fichier) {
		var classValue = "";
		if (fichier.fichier) {
			if (fichier.fichier.id === this.props.app.state.id) {
				classValue = "ml-5 my-3 ligne lineSelected";
			} else {
				classValue = "ml-5 my-3 ligne";
			}
		} else {
			classValue = "ml-5 my-3";
		}
		return classValue;
	}

	handleClick = fichier => {
		if (fichier.fichier) {
			console.log("this is:", fichier.fichier.sharedLink);
			console.log("this is:", fichier.fichier.id);
			this.props.app.setState(
				{
					cantiqueListen: fichier.fichier.sharedLink,
					id: fichier.fichier.id,
					rangMediaMedia: fichier.rang
				},
				() => {
					this.audioPlayer.current.play();
					this.audioPlayer.current.type = "audio/mp3";
				}
			);
		}
	};

	handleEnded = () => {
		for (const [index, value] of this.props.app.state.fichiers
			.filter(
				fichier =>
					fichier.fichier &&
					fichier.fichier.fichierContentType.fichierType.id === 4
			)
			.entries()) {
			if (this.props.app.state.rangMediaMedia < value.rang) {
				this.props.app.setState(
					{
						cantiqueListen: value.fichier.sharedLink,
						id: value.fichier.id,
						rangMediaMedia: value.rang
					},
					() => {
						this.audioPlayer.current.play();
						this.audioPlayer.current.type = "audio/mp3";
					}
				);
				break;
			}
		}
	};

	audio() {
		return (
			<>
				<audio
					className=""
					style={{}}
					src={this.props.app.state.cantiqueListen}
					preload="true"
					ref={this.audioPlayer}
					id="audio1"
					controls="controls"
					// controlsList="nodownload"
					onEnded={this.handleEnded}
				>
					Your browser does not support HTML5 Audio!
				</audio>
			</>
		);
	}

	refsBibliques() {
		return (
			<>
				{this.props.app.state.livreBibles.length > 0 && (
					<>
						<h5>Références bibliques</h5>
						<ul>
							{this.props.app.state.livreBibles.map(referenceBiblique => (
								<li key={referenceBiblique.id}>
									{referenceBiblique.livreBible.nomSimple3 +
										referenceBiblique.ref +
										" "}
								</li>
							))}
						</ul>
					</>
				)}
			</>
		);
	}
	arrangements() {
		return (
			<>
				{this.props.app.state.media.arrangements.length > 0 && (
					<>
						<h5>Arrangements</h5>
						<ul>
							{this.props.app.state.media.arrangements.map(arrangement => (
								<li key={arrangement.id}>{arrangement.nom}</li>
							))}
						</ul>
					</>
				)}
			</>
		);
	}
	typeCantiques() {
		return (
			<>
				{this.props.app.state.media.typeCantiques.length > 0 && (
					<>
						<h5>Harmonisations</h5>
						<ul>
							{this.props.app.state.media.typeCantiques.map(arrangement => (
								<li key={arrangement.id}>{arrangement.nom}</li>
							))}
						</ul>
					</>
				)}
			</>
		);
	}
	auteurs() {
		return (
			<>
				{this.props.app.state.auteurs.length > 0 && (
					<>
						<h5>Auteurs / Compositeurs</h5>
						<ul>
							{this.props.app.state.auteurs.map(auteur => (
								<li key={auteur.auteur.id}>
									{auteur.auteur.prenom} {auteur.auteur.nom}
									{auteur.auteur.pays && <> - {auteur.auteur.pays}</>}
									{auteur.auteur.annees && <> - {auteur.auteur.annees}</>}
									{auteur.auteur.origine && <> - {auteur.auteur.origine}</>}
								</li>
							))}
						</ul>
					</>
				)}
			</>
		);
	}

	detailsCantique() {
		return (
			<div className="col-12 wrap-word">
				<h4 className="title white mb-3">Détails</h4>

				<div
					style={{
						// backgroundColor: '#f1f3f4',
						minWidth: "300px"
					}}
					className={"p-4 bg-white"}
				>
					{this.props.app.state.media.origine && (
						<>
							<h5>Origine</h5>
							<ul>
								<li>{this.props.app.state.media.origine.nom}</li>
							</ul>
						</>
					)}
					{this.refsBibliques()}
					{this.arrangements()}
					{this.typeCantiques()}
					{this.auteurs()}
				</div>
			</div>
		);
	}

	render() {
		const { t } = this.props;
		const media = this.props.app.state.media;

		const styleBouton = {
			fontSize: "1.1em",
			color: "#333",
			paddingTop: "20px",
			paddingBottom: "100px"
		};
		const classeBouton = "text-center mt-5 ft-lato-light text-uppercase";
		return (
			<>
				<Header
					slide={{
						template: 2,
						image: "parolePartagee.jpg",
						h1: "Parole partagée",
						// h2: "LIVRES",
						h3: "Nourrir sa foi et sa reflexion",
						descriptionMeta: ""
					}}
				/>
				{/* <communs.BanniereTitre
					texteType={media.mediaType.nom}
					couleur={this.props.couleur}
					titre={media.titre}
					sousTitre={media.sousTitre}
				/> */}

				<div className="container pt-5 pb-5">
					<div className="text-center pb-3">
						<span
							style={{ backgroundColor: this.props.couleur, fontSize: "1em" }}
							className="text-white pl-3 pr-3 pt-1 pb-1 text-center ft-lato-regular text-uppercase"
						>
							<strong>{media.mediaType.nom || "LIVRE"}</strong>
						</span>
					</div>
					<h1
						className="contenu-h1 text-uppercase ft-lato-regular pt-3"
						style={{ fontSize: "1.6em" }}
					>
						<strong>{media.titre}</strong>
					</h1>
					<h3
						className="text-center pt-4"
						style={{ color: this.props.couleur }}
					>
						{this.props.app.state.livreBibles.map(data => (
							<span key={data.livreBible.nom1 + " " + data.ref}>
								{data.livreBible.nom1 + " " + data.ref}{" "}
							</span>
						))}
					</h3>
					<h4 className="ft-lato-light text-center pt-4">
						{this.props.app.state.auteurs.map(data => (
							<>
								<div key={data.auteur.prenom + " " + data.auteur.nom}>
									{data.auteur.prenom + " " + data.auteur.nom}
									{this.props.app.state.auteurs.length > 1 && ", "}
								</div>
							</>
						))}
					</h4>
					<h2 className="contenu-h2 pt-3" style={{ fontSize: "1.5em" }}>
						{media.sousTitre}
					</h2>
					<p>
						<div
							className="pt-4"
							dangerouslySetInnerHTML={{
								__html: media.contenu
							}}
						/>
					</p>
				</div>

				<div
					className="container-fluid pb-lg-5 no-gutters pt-5 bg-gris"
					style={{ minHeight: "480px" }}
				>
					<div className="container">
						{this.props.app.state.videoYoutubes.length > 0 && (
							<div className="">
								{this.props.app.state.videoYoutubes.map(video => (
									<div key={video.videoYoutube.id} className="pt-md-3 pb-md-3">
										<div style={{ position: "relative" }} className="pb-4">
											<span style={{ position: "absolute" }}>
												<Icones.regarderCircle couleur={this.props.couleur} />
											</span>
											<h4
												style={{ position: "absolute", marginLeft: "45px" }}
												className="d-inline ft-lato-light text-noir"
											>
												REGARDER
											</h4>
										</div>

										<div className="text-center">
											<div class="embed-responsive embed-responsive-16by9">
												<iframe
													class="embed-responsive-item"
													src={
														"https://www.youtube.com/embed/" +
														video.videoYoutube.idYoutube +
														"?rel=0"
													}
													frameBorder={0}
													allowFullScreen
													modestbranding={1}
													controls={0}
													rel={0}
													info={0}
													allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
												/>
											</div>
										</div>
									</div>
								))}
							</div>
						)}

						{communs.isContientFichiersAudios(
							this.props.app.state.fichiers
						) && (
							<div className="row">
								<div className="col-md-4"></div>
								<div className="col-md-4 wrap-word">
									<div style={{ position: "relative" }} className="pb-3">
										<span style={{ position: "absolute" }}>
											<Icones.lireCircle couleur={this.props.couleur} />
										</span>
										<h4
											style={{ position: "absolute" }}
											className="d-inline ft-lato-light text-noir text-uppercase"
											style={{ marginLeft: "45px" }}
										>
											écouter
										</h4>
									</div>

									<div
										style={{
											backgroundColor: "#f1f3f4",
											minWidth: "300px"
										}}
									>
										<div className="text-center mb-3 pt-4">{this.audio()}</div>

										<table
											className="p-3 p-md-3 table table-condensed player"
											style={{ backgroundColor: "#fff" }}
										>
											<tbody>
												{this.props.app.state.fichiers.map(
													fichier =>
														fichier.fichier.fichierContentType.fichierType
															.id === 4 && (
															<tr
																key={fichier.fichier.id}
																className={this.classOfCantique(fichier)}
																onClick={this.handleClick.bind(this, fichier)}
															>
																<td className="player rang">
																	<span
																		style={{ color: "#666" }}
																		className="pl-4"
																	>
																		{" "}
																	</span>
																</td>
																<td>{fichier.fichier.nom}</td>
															</tr>
														)
												)}
											</tbody>
										</table>
									</div>
								</div>
								<div className="col-md-4"></div>
							</div>
						)}

						{media.contenu.length > 61 && (
							<div className="row pt-5">
								<div className="col-12 col-md-4">
									<div className="row">
										<div className="col-md-11">
											<img
												src={communs.getCouvertureImageSrcByRang(
													1,
													this.props.app.state.fichiers
												)}
												className="img-fluid d-block"
												alt=""
												style={{ borderRadius: "5px" }}
											/>
											<div className="" style={{}}>
												<p className="pt-3">
													{media.nbrPages && (
														<>
															Nbr pages : {media.nbrPages}
															<br />
														</>
													)}
													{media.isbn && (
														<>
															Isbn : {media.isbn}
															<br />
														</>
													)}
													{media.dateEdition && (
														<>
															Édition : {media.dateEdition}
															<br />
														</>
													)}
													{media.prix && <>Prix : {media.prix}€</>}
												</p>

												{media.lienLjc && (
													<div
														className="text-center p-3 ml-5 mr-5 mt-4 mb-4"
														style={{ border: "1px solid #414042" }}
													>
														<a
															href={
																"https://www.librairiejeancalvin.fr/index.php/ljc/Publications/(id)/" +
																media.lienLjc
															}
															target="_blank"
															className={classeBouton}
															style={styleBouton}
														>
															passer commande
														</a>
													</div>
												)}
											</div>
										</div>
										<div className="col-md-1"></div>
									</div>
								</div>
								{/* {this.props.app.state.media.contenu && (
									<communs.LireContenu
										contenu={this.props.app.state.media.contenu}
										titre={""}
										couleur={this.props.couleur}
									/>
								)} */}
							</div>
						)}
					</div>
				</div>
			</>
		);
	}
}

export default withTranslation()(MediaExhortation);
