import React from "react";
import Page from "../Page";
import { withTranslation } from "react-i18next";
import "./EspaceCandidats.scss";
import { ModuleEncartListMt, tools, api } from "@mitim/react-mitim";
import { Link } from "react-router-dom";

const ELEMENTS_ENCARTS_1 = [
	{
		titre: "DEVOIRS BIBLIQUES",
		couleurTitre: tools.ROSE,
		lireLaSuiteHidden: true,
		sousTitre: "Devoirs sur l'Ancien et le Nouveau Testament",
		imgSrc: "mt-formation-cours-encart1.jpg",
		textLink: "Accès",
		linkTo: "/espace-candidats-cours/devoirs%20bibliques",
		contenu:
			"- Devoirs pour la formation courte<br/>- Devoirs pour la formation longue<br/>- Devoirs pour la formation biblique"
	},
	{
		titre: "DEVOIRS DE DOGMATIQUE",
		couleurTitre: tools.ORANGE_FONCE,
		lireLaSuiteHidden: true,
		sousTitre: "Questionnaires de lecture sur différents thèmes : ",
		// theme: "rouge",
		textLink: "Accès",
		imgSrc: "mt-formation-cours-encart2.jpg",
		linkTo: "/espace-candidats-cours/devoirs%20dogmatiques",
		contenu:
			"Doctrine de Dieu, Écriture Sainte, Les créatures, Péché et la Rédemption, Christologie,  Ecclésiologie, Saint-Esprit, Eschatologie."
	},
	{
		titre: "SESSION DE COURS",
		couleurTitre: tools.JAUNE_CLAIR,
		lireLaSuiteHidden: true,
		sousTitre:
			"Sessions de cours des trois dernières années par thèmes et année",
		theme: "rouge",
		textLink: "Accès",
		imgSrc: "mt-formation-cours-encart3.jpg",
		linkTo: "/espace-candidats-cours/cours%20audio",
		contenu:
			"Défense  de  la  foi,  Doctrine,  Histoire,  Intro&shy;ductions  à  l'Ancien,  au  Nouveau  Testament  et aux Psaumes, Théologie pastorale."
	}
];

class EspaceCandidats extends Page {
	constructor(props) {
		super(props);
	}

	state = {
		acces: true
	};

	componentDidMount() {
		super.componentDidMount();
	}

	render() {
		const { t } = this.props;
		return (
			<>
				{this.state.acces && (
					<>
						<div
							className="container-fluid pb-lg-5 no-gutters pt-5"
							style={{ minHeight: "480px", backgroundColor: "#e2e3e4" }}
						>
							<div className="container home pt-lg-5">
								<div className="text-center pb-5 pt-5">
									<h2>
										Pour suivre les cours en direct veuillez cliquer{" "}
										<Link to="/direct-cours-formation">ICI</Link>
									</h2>
								</div>

								<ModuleEncartListMt data={ELEMENTS_ENCARTS_1} />
							</div>
						</div>
					</>
				)}
			</>
		);
	}
}

export default withTranslation()(EspaceCandidats);
