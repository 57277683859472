import React, { useState } from "react";
import "./Bouton.scss";
import { Icones, tools } from "@mitim/react-mitim";
import { Link } from "react-router-dom";

export const Bouton = props => {
	const [couleurBouton, setCouleurBouton] = useState("#fff");
	const couleur = props.couleur;
	return (
		<>
			<div>
				<Link
					to={props.lien}
					style={{
						textDecoration: "none",
						fontWeight: "bold",
						color: couleurBouton
					}}
					onMouseEnter={() => {
						setCouleurBouton(couleur);
					}}
					onMouseLeave={() => setCouleurBouton("#fff")}
				>
					<span
						className="text-center mt-5 ft-ubuntu text-uppercase mt-bouton"
						style={{
							backgroundColor: couleur,
							borderColor: couleur,
							border: "1px solid " + couleur
						}}
					>
						{props.texte}
					</span>
				</Link>
			</div>
		</>
	);
};
