import { tools } from "@mitim/react-mitim";

export const ANCIEN_TESTAMENT_1 = [
	{
		nom: "Genèse",
		numLivre: 1,
		couleur: tools.VERT_FONCE,
		idMediaLivre: 1449
	},
	{
		nom: "Exode",
		numLivre: 2,
		couleur: tools.VERT_FONCE,
		idMediaLivre: 1451
	},
	{
		nom: "Lévitique",
		numLivre: 3,
		couleur: tools.VERT_FONCE,
		idMediaLivre: 1452
	},
	{
		nom: "Nombres",
		numLivre: 4,
		couleur: tools.VERT_FONCE,
		idMediaLivre: 1453
	},
	{
		nom: "Deutéronome",
		numLivre: 5,
		couleur: tools.VERT_FONCE,
		idMediaLivre: 1454
	},
	{
		nom: "Josué",
		numLivre: 6,
		couleur: tools.VERT_FONCE,
		idMediaLivre: 1455
	},
	{
		nom: "Juges",
		numLivre: 7,
		couleur: tools.VERT_FONCE,
		idMediaLivre: 1456
	},
	{
		nom: "Ruth",
		numLivre: 8,
		couleur: tools.VERT_FONCE,
		idMediaLivre: 1457
	},
	{
		nom: "1 Samuel",
		numLivre: 9,
		couleur: tools.VERT_FONCE,
		idMediaLivre: 1458
	},
	{
		nom: "2 Samuel",
		numLivre: 10,
		couleur: tools.VERT_FONCE,
		idMediaLivre: 1459
	},
	{
		nom: "1 Rois",
		numLivre: 11,
		couleur: tools.VERT_FONCE,
		idMediaLivre: 1460
	},
	{
		nom: "2 Rois",
		numLivre: 12,
		couleur: tools.VERT_FONCE,
		idMediaLivre: 1461
	},
	{
		nom: "1 Chroniques",
		numLivre: 13,
		couleur: tools.VERT_FONCE,
		idMediaLivre: 1462
	},
	{
		nom: "2 Chroniques",
		numLivre: 14,
		couleur: tools.VERT_FONCE,
		idMediaLivre: 1463
	},
	{
		nom: "Esdras",
		numLivre: 15,
		couleur: tools.ORANGE_CLAIR,
		idMediaLivre: 1464
	},
	{
		nom: "Néhémie",
		numLivre: 16,
		couleur: tools.VERT_FONCE,
		idMediaLivre: 1465
	},
	{
		nom: "Esther",
		numLivre: 17,
		couleur: tools.VERT_FONCE,
		idMediaLivre: 1466
	},
	{
		nom: "Job",
		numLivre: 18,
		couleur: tools.VERT_FONCE,
		idMediaLivre: 1467
	},
	{
		nom: "Psaumes",
		numLivre: 19,
		couleur: tools.VERT_FONCE,
		idMediaLivre: 1468
	},
	{
		nom: "Proverbe",
		numLivre: 20,
		couleur: tools.VERT_FONCE,
		idMediaLivre: 1469
	}
];
export const ANCIEN_TESTAMENT_2 = [
	{
		nom: "Ecclésiaste",
		numLivre: 21,
		couleur: tools.ORANGE_CLAIR,
		idMediaLivre: 1470
	},
	{
		nom: "C. des cantiques",
		numLivre: 22,
		couleur: tools.ORANGE_CLAIR,
		idMediaLivre: 1471
	},
	{
		nom: "Ésaïe",
		numLivre: 23,
		couleur: tools.ORANGE_CLAIR,
		idMediaLivre: 1472
	},
	{
		nom: "Jérémie",
		numLivre: 24,
		couleur: tools.ORANGE_CLAIR,
		idMediaLivre: 1473
	},
	{
		nom: "Lam. de Jérémie",
		numLivre: 25,
		couleur: tools.ORANGE_CLAIR,
		idMediaLivre: 1474
	},
	{
		nom: "Ézéchiel",
		numLivre: 26,
		couleur: tools.ORANGE_CLAIR,
		idMediaLivre: 1475
	},
	{
		nom: "Daniel",
		numLivre: 27,
		couleur: tools.ORANGE_CLAIR,
		idMediaLivre: 1476
	},
	{
		nom: "Osée",
		numLivre: 28,
		couleur: tools.ORANGE_CLAIR,
		idMediaLivre: 1477
	},
	{
		nom: "Joël",
		numLivre: 29,
		couleur: tools.ORANGE_CLAIR,
		idMediaLivre: 1478
	},
	{
		nom: "Amos",
		numLivre: 30,
		couleur: tools.ORANGE_CLAIR,
		idMediaLivre: 1479
	},
	{
		nom: "Abdias",
		numLivre: 31,
		couleur: tools.ORANGE_CLAIR,
		idMediaLivre: 1480
	},
	{
		nom: "Jonas",
		numLivre: 32,
		couleur: tools.ORANGE_CLAIR,
		idMediaLivre: 1481
	},
	{
		nom: "Michée",
		numLivre: 33,
		couleur: tools.ORANGE_CLAIR,
		idMediaLivre: 1482
	},
	{
		nom: "Nahum",
		numLivre: 34,
		couleur: tools.ORANGE_CLAIR,
		idMediaLivre: 1483
	},
	{
		nom: "Habakuk",
		numLivre: 35,
		couleur: tools.ORANGE_CLAIR,
		idMediaLivre: 1484
	},
	{
		nom: "Sophonie",
		numLivre: 36,
		couleur: tools.ORANGE_CLAIR,
		idMediaLivre: 1485
	},
	{
		nom: "Aggée",
		numLivre: 37,
		couleur: tools.ORANGE_CLAIR,
		idMediaLivre: 1486
	},
	{
		nom: "Zacharie",
		numLivre: 38,
		couleur: tools.ORANGE_CLAIR,
		idMediaLivre: 1487
	},
	{
		nom: "Malachie",
		numLivre: 39,
		couleur: tools.ORANGE_CLAIR,
		idMediaLivre: 1488
	}
];
export const NOUVEAU_TESTAMENT_1 = [
	{
		nom: "Matthieu",
		numLivre: 40,
		couleur: tools.BLEU,
		idMediaLivre: 1489
	},
	{
		nom: "Marc",
		numLivre: 41,
		couleur: tools.BLEU,
		idMediaLivre: 1490
	},
	{
		nom: "Luc",
		numLivre: 42,
		couleur: tools.BLEU,
		idMediaLivre: 1491
	},
	{
		nom: "Jean",
		numLivre: 43,
		couleur: tools.BLEU,
		idMediaLivre: 1492
	},
	{
		nom: "Actes",
		numLivre: 44,
		couleur: tools.BLEU,
		idMediaLivre: 1493
	},
	{
		nom: "Romains",
		numLivre: 45,
		couleur: tools.BLEU,
		idMediaLivre: 1494
	},
	{
		nom: "1 Corinthiens",
		numLivre: 46,
		couleur: tools.BLEU,
		idMediaLivre: 1495
	},
	{
		nom: "2 Corinthiens",
		numLivre: 47,
		couleur: tools.BLEU,
		idMediaLivre: 1496
	},
	{
		nom: "Galates",
		numLivre: 48,
		couleur: tools.BLEU,
		idMediaLivre: 1497
	},
	{
		nom: "Éphésiens",
		numLivre: 49,
		couleur: tools.BLEU,
		idMediaLivre: 1498
	},
	{
		nom: "Philippiens",
		numLivre: 50,
		couleur: tools.BLEU,
		idMediaLivre: 1499
	},
	{
		nom: "Colossiens",
		numLivre: 51,
		couleur: tools.BLEU,
		idMediaLivre: 1500
	},
	{
		nom: "1 Thessaloniciens",
		numLivre: 52,
		couleur: tools.BLEU,
		idMediaLivre: 1501
	},
	{
		nom: "2 Thessaloniciens",
		numLivre: 53,
		couleur: tools.BLEU,
		idMediaLivre: 1502
	},
	{
		nom: "1 Timothée",
		numLivre: 54,
		couleur: tools.BLEU,
		idMediaLivre: 1503
	},
	{
		nom: "2 Timothée",
		numLivre: 55,
		couleur: tools.BLEU,
		idMediaLivre: 1504
	},
	{
		nom: "Tite",
		numLivre: 56,
		couleur: tools.BLEU,
		idMediaLivre: 1505
	},
	{
		nom: "Philémon",
		numLivre: 57,
		couleur: tools.BLEU,
		idMediaLivre: 1506
	},
	{
		nom: "Hébreux",
		numLivre: 58,
		couleur: tools.BLEU,
		idMediaLivre: 1507
	},
	{
		nom: "Jacques",
		numLivre: 59,
		couleur: tools.BLEU,
		idMediaLivre: 1508
	}
];

export const NOUVEAU_TESTAMENT_2 = [
	{
		nom: "1 Pierre",
		numLivre: 60,
		couleur: tools.ORANGE_CLAIR,
		idMediaLivre: 1509
	},
	{
		nom: "2 Pierre",
		numLivre: 61,
		couleur: tools.ORANGE_CLAIR,
		idMediaLivre: 1510
	},
	{
		nom: "1 Jean",
		numLivre: 62,
		couleur: tools.ORANGE_CLAIR,
		idMediaLivre: 1511
	},
	{
		nom: "2 Jean",
		numLivre: 63,
		couleur: tools.ORANGE_CLAIR,
		idMediaLivre: 1512
	},
	{
		nom: "3 Jean",
		numLivre: 64,
		couleur: tools.ORANGE_CLAIR,
		idMediaLivre: 1513
	},
	{
		nom: "Jude",
		numLivre: 65,
		couleur: tools.ORANGE_CLAIR,
		idMediaLivre: 1514
	},
	{
		nom: "Apocalypse",
		numLivre: 66,
		couleur: tools.ORANGE_CLAIR,
		idMediaLivre: 1515
	}
];
