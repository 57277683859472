import React from "react";
import { Fab } from "@material-ui/core";
import LastPageIcon from "@material-ui/icons/LastPage";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import { default as PrevPageIcon } from "@material-ui/icons/ChevronLeft";
import { default as NextPageIcon } from "@material-ui/icons/ChevronRight";
import { withTranslation } from "react-i18next";

class Pagination extends React.Component {
	render() {
		const { pageSize, pageNumber, quantity, t } = this.props;
		const params = { className: "m-1 col-auto", size: "small" };
		let lastPage = Math.ceil(quantity / pageSize);
		let pages = [];
		let pageAround = 3;
		var pageBegin = pageNumber - pageAround;
		for (
			var i = pageBegin < 1 ? 1 : pageBegin;
			i <= pageNumber + pageAround && i <= lastPage;
			i++
		) {
			let tmp = i;
			pages.push(
				<Fab
					key={i}
					{...params}
					color={i === pageNumber ? "primary" : "secondary"}
					onClick={() => tmp !== pageNumber && this.props.onChangePage(tmp)}
					className={
						i <= pageNumber + 1 && i >= pageNumber - 1
							? params.className
							: params.className + " d-none d-sm-block"
					}
				>
					{i}
				</Fab>
			);
		}

		params.color = "primary";
		let firstPageDisabled = pageNumber - 1 < 1;
		let lastPageDisabled = pageNumber + 1 > lastPage;
		return (
			<>
				<div
					className={
						"d-none d-sm-block filter-list-continue " +
						this.props.classes.filter
					}
					style={{ backgroundColor: "#fff" }}
				/>
				<div
					className={
						this.props.classes.result + " " + this.props.classes.pagination
					}
					style={{ backgroundColor: "#fff" }}
				>
					<div className="row justify-content-center mb-4">
						<Fab
							{...params}
							title={t("recherche.first-page")}
							disabled={firstPageDisabled}
							onClick={() => this.props.onChangePage(1)}
						>
							<FirstPageIcon />
						</Fab>
						<Fab
							{...params}
							title={t("recherche.prev-page")}
							disabled={firstPageDisabled}
							onClick={() => this.props.onChangePage(pageNumber - 1)}
						>
							<PrevPageIcon />
						</Fab>
						{pages}
						<Fab
							{...params}
							title={t("recherche.next-page")}
							disabled={lastPageDisabled}
							onClick={() => this.props.onChangePage(pageNumber + 1)}
						>
							<NextPageIcon />
						</Fab>
						<Fab
							{...params}
							title={t("recherche.last-page")}
							disabled={lastPageDisabled}
							onClick={() => this.props.onChangePage(lastPage)}
						>
							<LastPageIcon />
						</Fab>
					</div>
				</div>
			</>
		);
	}
}

export default withTranslation()(Pagination);
