// Libraries
import React from "react";
import ResultGridView from "./ResultGridView";
import ResultListView from "./ResultListView";
import { withTranslation, Trans } from "react-i18next";

class ResultList extends React.Component {
	render() {
		let Component = this.props.renderList ? ResultListView : ResultGridView;
		const results = this.props.resultsList.map(result => (
			<Component key={result.id} result={result} />
		));
		let count = this.props.quantity;
		return (
			<div className={this.props.className} style={{ backgroundColor: "#eee" }}>
				<div className="result-number">
					<Trans i18nKey="recherche.resultat" count={count}>
						{{ count }} résultats
					</Trans>
				</div>
				<div className="row pl-md-3">{results}</div>
			</div>
		);
	}
}

export default withTranslation()(ResultList);
