import React from "react";
import "./BlockLecteurAudio.scss";
import { Icones, tools } from "@mitim/react-mitim";

export default class BlockLecteurAudio extends React.Component {
	state = {
		couleurFacebook: "#fff",
		couleurTwitter: "#fff"
	};

	titre() {
		return (
			<strong>
				<div
					dangerouslySetInnerHTML={{
						__html: this.props.titre
							? this.props.titre.replace(/\,/g, "<br/>")
							: "Il n'y a pas de lecture pour aujourd'hui"
					}}
				/>
			</strong>
		);
	}

	render() {
		// const titre1 = "LECTURE DU 11 FÉVRIER";
		// const titre2 = "DEUT 10:12 À 11:32 CANT. DES CANT 1 À 2:7 LUC 9:18-36";
		return (
			<>
				<div className="container-fluid no-gutters">
					<div style={{ display: "block", position: "relative" }}>
						<div
							className="mt-BlockLecteurAudio-banner"
							style={{
								backgroundImage: `url(${tools.lienPhotos}/${this.props.imgBackground})`,
								filter: "brightness(100%)",
								backgroundColor: "#000"
							}}
						>
							<div className="row" style={{ zIndex: "2" }}>
								<div className="col-md-12">
									<div className="text-center">
										<br />
										<br />
										{/* Grand écran */}
										<div className="d-none d-xl-block">
											<h1
												className="ft-ubuntu text-uppercase"
												style={{
													fontSize: "2.2em",
													color: this.props.couleurTexte || "#fff"
												}}
											>
												lecture du {this.props.date}
											</h1>
											<div
												className="ft-ubuntu-light text-uppercase"
												style={{
													fontSize: "1.3em",
													color: this.props.couleurTexte || "#fff"
												}}
											>
												{this.titre()}
											</div>
										</div>

										{/* Mobile */}
										<div className="d-block d-xl-none">
											<h1
												className="text-white ft-ubuntu"
												style={{ fontSize: "2em" }}
											>
												lecture du {this.props.date}
											</h1>
											<div
												className="text-white ft-ubuntu-light"
												style={{ fontSize: "1em" }}
											>
												{this.titre()}
											</div>
										</div>

										<div className="pt-5 pb-2">
											{this.props.lien && (
												<audio
													controls="controls"
													controlsList="nodownload"
													preload="auto"
													src={this.props.lien}
												/>
											)}
										</div>

										{this.props.lienSoundCloud && (
											<a
												href={this.props.lienSoundCloud}
												target="_blank"
												onMouseEnter={() => {
													this.setState({ couleurTwitter: tools.VERT_FONCE });
												}}
												onMouseLeave={() => {
													this.setState({ couleurTwitter: "#fff" });
												}}
											>
												<Icones.soundCloud
													width={50}
													couleur={this.state.couleurTwitter}
												/>
											</a>
										)}

										<span className="ml-2"></span>

										{this.props.lienItunes && (
											<a
												href={this.props.lienItunes}
												target="_blank"
												onMouseEnter={() => {
													this.setState({
														couleurFacebook: tools.ORANGE_CLAIR
													});
												}}
												onMouseLeave={() => {
													this.setState({ couleurFacebook: "#fff" });
												}}
											>
												<Icones.music
													width={50}
													couleur={this.state.couleurFacebook}
												/>
											</a>
										)}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}
