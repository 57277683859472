import React from "react";
import "./RapportAG.scss";
import Page from "../Page";
import {
	ModuleCaroussel,
	Icones,
	BlockImgNlt,
	ModuleEncartListMt,
	ModuleCard,
	tools,
	auth,
	api,
	Storage
} from "@mitim/react-mitim";

export class RapportAG extends Page {
	state = {
		idRevue: this.props.match.params.idRevue,
		code: this.props.match.params.code,
		successExpedition: false
	};

	componentDidMount() {
		this.expeditionRevue();
	}

	expeditionRevue() {
		const user = Storage.getUserParsed();
		const tokenPublic = "cHVibGljOjk4SE85SE9JVUdPSTk4SA==";
		const defaultHeaders = {
			// Accept: "application/json",
			// "Content-Type": "application/json",
			Authorization: "Basic " + user.token
		};

		fetch(
			// `http://127.0.0.1:8084/timedia/public/expeditionRevue/${this.state.code}/${this.state.idRevue}`,
			`${api.timediaUrl}/votes/downloadRapport`,
			{
				method: "GET",
				headers: defaultHeaders
			}
		)
			.then(response => {
				response.blob().then(blob => {
					let url = window.URL.createObjectURL(blob);
					let a = document.createElement("a");
					a.href = url;
					a.download = "Rapport AG.xlsx";
					a.click();
				});
			})
			.catch(err => {
				console.log(err);
			});
	}

	render() {
		const { t } = this.props;

		return (
			<>
				<div className="container pt-5 pb-5">
					<h2 className="text-center pt-4 pb-4">Rapport votes AG</h2>
					{/* <h2 className="text-center">
						status : {this.state.successExpedition ? "OK" : "Problème"}
					</h2> */}
				</div>
			</>
		);
	}
}
