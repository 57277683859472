// Libraries
import React from 'react';
import { FormGroup } from '@material-ui/core';
// Components
import FilterItemCheckBox from './FilterItemCheckBox';

class FilterCheckBox extends React.Component {
	render() {
		const checkedFilterItems = this.props.filterItems
			.filter(filterItem => filterItem.value)
			.map(filterItem => (
				<FilterItemCheckBox
					onFilterItemChange={this.props.onFilterItemChange}
					key={filterItem.id}
					titleCase={this.props.titleCase}
					{...filterItem}
				/>
			));
		const filterItems = this.props.filterItems
			.filter(filterItem => !filterItem.value)
			.map(filterItem => (
				<FilterItemCheckBox
					onFilterItemChange={this.props.onFilterItemChange}
					key={filterItem.id}
					titleCase={this.props.titleCase}
					{...filterItem}
				/>
			));
		return (
			<>
				<FormGroup classes={{ root: this.props.cssClass }}>
					{checkedFilterItems}
				</FormGroup>
				<FormGroup
					classes={{ root: this.props.rootClass + ' ' + this.props.cssClass }}
				>
					{filterItems}
				</FormGroup>
			</>
		);
	}
}

export default FilterCheckBox;
