import React from "react";
import Page from "../Page";
import { withTranslation } from "react-i18next";
import "./ReunionsDirect.scss";
import { tools, api } from "@mitim/react-mitim";
import Modal from "react-bootstrap/Modal";

export const getReuDirect = (self, callback) => {
	const tokenPublic = "cHVibGljOjk4SE85SE9JVUdPSTk4SA==";
	const defaultHeaders = {
		Accept: "application/json",
		"Content-Type": "application/json",
		Authorization: "Basic " + tokenPublic
	};
	fetch(`${api.timediaUrl}/public/reuDirect`, {
		headers: defaultHeaders,
		method: "GET"
	}).then(response => {
		if (response.status !== 200) {
			console.error("Error: " + response.status);
			return;
		}
		response.json().then(json => {
			self.setState(
				{
					annonceReuDirect: json.annonceReuDirect,
					lastRetransmissionReunionUrl: json.lastRetransmissionReunionUrl,
					acces: true,
					reuDirectUrl: json.reuDirectUrl,
					reuDirectYoutubeUrl: json.reuDirectYoutubeUrl,
					reuDirectYoutubeDiffusion: json.reuDirectYoutubeDiffusion
				},
				callback
			);
		});
	});
};

class ReunionsDirect extends Page {
	constructor(props) {
		super(props);
		this.handleShow = this.handleShow.bind(this);
		this.handleClose = this.handleClose.bind(this);
	}

	state = {
		lastRetransmissionReunionUrl: "",
		annonceReuDirect: "",
		show: true,
		acces: false,
		reuDirectUrl: "",
		reuDirectYoutubeUrl: "",
		reuDirectYoutubeDiffusion: false,
		showModal: false,
		messageModal: <></>,
		lienVideo: ""
	};

	componentDidMount() {
		super.componentDidMount();
		getReuDirect(this, () => {});
	}

	handleClose() {
		this.setState({ show: false });
	}

	handleShow() {
		this.setState({ show: true });
	}

	render() {
		const { t } = this.props;

		const BoiteDialogue = () => (
			<Modal
				className="modalAlbumCd"
				show={this.state.showModal}
				onHide={() => this.setState({ showModal: false })}
				tabindex="-1"
			>
				<Modal.Body>
					<button
						type="button"
						class="close"
						data-dismiss="modal"
						aria-label="Close"
						onClick={() => this.setState({ showModal: false })}
					>
						<span aria-hidden="true">&times;</span>
					</button>
					{/* <h4 className="text-center">{this.state.messageModal}</h4> */}

					<div class="embed-responsive embed-responsive-16by9">
						<iframe
							className="embed-responsive-item"
							// width="560"
							// height="315"
							src={this.state.lienVideo}
							// src="https://www.youtube.com/embed/Fs4VmSPO_pM"
							frameborder="0"
							allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
							allowfullscreen={true}
						></iframe>
					</div>
				</Modal.Body>
			</Modal>
		);

		return (
			<>
				{this.state.acces && (
					<>
						<BoiteDialogue />
						<div className="container reunionsDirect">
							<div className="mt-4">
								<h2 className="text-center mb-5"></h2>
								{tools.titreSousTitre(
									"ÉCOUTE EN DIRECT",
									this.state.annonceReuDirect
								)}
								{this.state.reuDirectYoutubeDiffusion && (
									<div className="video-container" style={{ top: "24px" }}>
										<div className="contenu-page">
											<div className="container pt-5 pb-5">
												<center>
													<iframe
														width={560}
														height={315}
														// src="https://www.youtube.com/embed/playlist?list=PLvqXsLJhAiXGbMU-QMxwT4MbxeuRRkINt"
														src={this.state.reuDirectYoutubeUrl}
														frameBorder={0}
														allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
														allowFullScreen
													/>
												</center>
												<br />
											</div>
										</div>
									</div>
								)}
								<div className="text-center pt-3 pb-5 mt-5"></div>
								<h5>Voir les réunions précédentes</h5>
								<p>
									Pour visionner les réunions précédentes, il vous suffit de
									cliquer en haut à droite dans le lecteur vidéo.
								</p>
								Tutoriel :{" "}
								<span
									className="boutonA"
									onClick={() =>
										this.setState({
											showModal: true,
											lienVideo: "https://www.youtube.com/embed/Fz20Rxx2SDA"
										})
									}
								>
									Voir les réunions précédentes
								</span>
								{/* <div className="text-center pt-3 pb-5 mt-5">
									<audio
										controls="controls"
										controlsList="nodownload"
										preload="auto"
										style={{ width: "60%" }}
										// src="https://radio.timothee.fr/OIY97U8987N09Ukjh09ezrg8NHNergHNI879898IUHNKJHKJHOIHO.mp3"
										src={this.state.reuDirectUrl}
									/>
								</div>

								<p>
									Retrouvez ici la diffusion en direct de messages donnés
									pendant les réunions régulières les dimanches à 17 heures, ou
									encore lors de camps bibliques les matins à 10 heures et les
									soirs à 20h30.
								</p>
								<h5 className="pt-5 pb-3">Remarques </h5>
								<ul className="liste">
									<li>Les annonces en début de camp ne sont pas transmises.</li>
									<li>
										Si la lecture est interrompue, rafraichissez d’abord la page
										en cliquant sur "Affichage" puis "Actualiser".
									</li>
									<li>
										La diffusion des réunions du matin n’est pas garantie en
										raison de la forte consommation du réseau, il est donc
										possible qu'elle soit interrompue. Merci de votre
										compréhension.
									</li>
								</ul> */}
								{/* <h5>Aide en ligne</h5>
								<p>
									Si vous rencontrez un problème technique (uniquement pour les
									réunions du soir), merci d’appeler le 07 70 10 83 91 (pendant
									la réunion) ou de laisser un message.
								</p> */}
								{/* {tools.titreSousTitre(
									"RETRANSMISSION DE LA DERNIÈRE RÉUNION",
									""
								)} */}
								{/* <div className="text-center pt-3 pb-3">
									<audio
										controls="controls"
										controlsList="nodownload"
										preload="auto"
										style={{ width: "60%" }}
										ref={this.playerRetransmission}
										src={this.state.lastRetransmissionReunionUrl}
									/>
									<br />
									<br />
								</div>

								<p>
									Retrouvez ici la diffusion en streaming de messages de la
									Mission. Depuis les débuts de la Mission Timothée, il y a des
									prédications presque tous les soirs au centre d’accueil
									d’Anduze. Les messages donnés lors des camps de familles et de
									jeunes sont transmis en direct via Internet et peuvent être
									écoutés en cliquant sur le lien ci-après.
								</p>
								<ul className="liste pb-5 pt-3">
									<li>
										Notez que cet audio est remplacé à la fin de toute nouvelle
										diffusion en direct. Toutes les précédentes réunions sont
										disponibles en téléchargement ou en CD auprès de la
										Librairie Jean Calvin :{" "}
										<a
											href="https://www.librairiejeancalvin.fr"
											target="_blank"
											className="lien"
										>
											Site de la Librairie Jean Calvin
										</a>
									</li>
									<li>
										En raison de problèmes techniques, il se peut que ce service
										soit momentanément indisponible. Nous vous prions de bien
										vouloir nous excuser.
									</li>
								</ul> */}
								<p className="pt-5 pb-5"></p>
							</div>
						</div>
						<Modal show={this.state.show} onHide={this.handleClose}>
							<Modal.Body>
								<button
									type="button"
									class="close"
									data-dismiss="modal"
									aria-label="Close"
									onClick={this.handleClose}
								>
									<span aria-hidden="true">&times;</span>
								</button>
								<h3 className="text-center">{this.state.annonceReuDirect}</h3>
							</Modal.Body>
						</Modal>
					</>
				)}
			</>
		);
	}
}

export default withTranslation()(ReunionsDirect);
