import React from "react";
import { withTranslation } from "react-i18next";
import ImageMapper from "react-image-mapper";
import Modal from "react-bootstrap/Modal";

class ImageMapperWrapper extends React.Component {
	state = {
		hoveredArea: null,
		msg: null,
		moveMsg: null,
		show: false,
		popupValue: ""
	};

	getInitialState() {
		return { hoveredArea: null, msg: null, moveMsg: null };
	}
	load() {
		this.setState({ msg: "Interact with image !" });
	}
	clicked(area) {
		// console.log(area);
		if (area.popup) {
			this.setState({
				popupValue: area.popupValue,
				show: "true"
			});
		} else {
			window.open("https://" + area.lien, "_blank");
		}
	}
	clickedOutside(evt) {
		const coords = { x: evt.nativeEvent.layerX, y: evt.nativeEvent.layerY };
		this.setState({
			msg: `You clicked on the image at coords ${JSON.stringify(coords)} !`
		});
	}
	moveOnImage(evt) {
		const coords = { x: evt.nativeEvent.layerX, y: evt.nativeEvent.layerY };
		this.setState({
			// moveMsg: `You moved on the image at coords ${JSON.stringify(coords)} !`
			moveMsg: JSON.stringify(coords)
		});
	}
	enterArea(area) {
		this.setState({
			hoveredArea: area,
			msg: `You entered ${area.shape} ${area.name} at coords ${JSON.stringify(
				area.coords
			)} !`
		});
		console.log("tata !!" + area);
	}
	leaveArea(area) {
		this.setState({
			hoveredArea: null,
			msg: `You leaved ${area.shape} ${area.name} at coords ${JSON.stringify(
				area.coords
			)} !`
		});
		console.log(area);
	}
	moveOnArea(area, evt) {
		const coords = { x: evt.nativeEvent.layerX, y: evt.nativeEvent.layerY };
		this.setState({
			moveMsg: `You moved on ${area.shape} ${
				area.name
			} at coords ${JSON.stringify(coords)} !`
		});
	}

	getTipPosition(area) {
		return { top: `${area.center[1]}px`, left: `${area.center[0]}px` };
	}

	handleClose() {
		this.setState({ show: false });
	}

	handleShow() {
		this.setState({ show: true });
	}
	render() {
		const data = this.props.data;
		const { t } = this.props;

		return (
			<>
				<div style={{ position: "relative" }}>
					<ImageMapper
						className="img-fluid"
						src={this.props.imgSrc}
						map={this.props.map}
						width={this.props.width}
						onLoad={() => this.load()}
						onClick={area => this.clicked(area)}
						onMouseEnter={area => this.enterArea(area)}
						onMouseLeave={area => this.leaveArea(area)}
						onMouseMove={(area, _, evt) => this.moveOnArea(area, evt)}
						onImageClick={evt => this.clickedOutside(evt)}
						onImageMouseMove={evt => this.moveOnImage(evt)}
						lineWidth={4}
						strokeColor={"white"}
					/>
					{this.state.hoveredArea && (
						<>
							<div
								className="myTooltip"
								style={{ ...this.getTipPosition(this.state.hoveredArea) }}
							>
								<div>
									{this.state.hoveredArea && this.state.hoveredArea.name}
								</div>
								<div style={{ textAlign: "center" }}>
									<div className="flecheBas text-center"></div>
								</div>
							</div>
						</>
					)}
				</div>

				{/* <pre className="message">
					{this.state.msg ? this.state.msg : null}
				</pre> */}
				{/* <pre>{this.state.moveMsg ? this.state.moveMsg : null}</pre> */}
				<Modal show={this.state.show} onHide={this.handleClose.bind(this)}>
					<Modal.Body>
						<button
							type="button"
							class="close"
							data-dismiss="modal"
							aria-label="Close"
							onClick={this.handleClose.bind(this)}
						>
							<span aria-hidden="true">&times;</span>
						</button>
						<div dangerouslySetInnerHTML={{ __html: this.state.popupValue }} />
					</Modal.Body>
				</Modal>
			</>
		);
	}
}
export default withTranslation()(ImageMapperWrapper);
