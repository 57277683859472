import React from "react";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import Page from "./Page";
// import ModuleBlocImagesCroise from './ModuleBlocImagesCroise/ModuleBlocImagesCroise';
import { ModuleBlocImagesCroise } from "@mitim/react-mitim";

class Historique extends Page {
	constructor(props) {
		super(props);
	}

	render() {
		const { t } = this.props;
		return (
			<div className="container-fluid no-gutters">
				{/* <Carouselle /> */}

				<ModuleBlocImagesCroise
					data={{
						textPosition: "droite",
						imgSrc: "historique-1.jpg",
						titreImg: "Les fondateurs",
						titre: "LA CRÉATION DE LA MISSION TIMOTHÉE",
						sousTitre: "",
						titleBackgroundColor: "#b11f27",
						contenu: (
							<>
								<p className="pt-3">
									Quelques amis qui se sont rencontrés à la faculté de théologie
									de Vaux-sur-Seine (Yvelines), touchés par le désir de servir
									le Seigneur auprès des délaissés, décident en 1972, de créer
									une maison d’accueil sur une colline à Anduze : l’Entreprise
									Timothée est née. Des camps bibliques y sont organisés pendant
									les vacances, mais la maison est ouverte toute l’année pour
									recevoir des routards, des marginaux, des jeunes en perdition.
									On leur offre, sans conditions, un repas, le coucher et une
									méditation de la Parole de Dieu.
								</p>
								<p className="">
									Peu à peu, des personnes se convertissent, repartent chez
									elles pour constituer des groupes de maison et demandent à
									l’Entreprise Timothée (devenue Mission Timothée en 1986 pour
									éviter toute confusion avec une société privée) l’envoi de
									pasteurs. Une formation biblique est alors créée en
									collaboration avec la Mission Foi Évangile. Plusieurs églises
									naissent de ce travail, qui n’est pas le fruit d’une
									stratégie, mais simplement la réponse aux demandes et aux
									besoins là où ils se sont manifestés.
								</p>
							</>
						)
					}}
				/>

				<ModuleBlocImagesCroise
					data={{
						textPosition: "gauche",
						imgSrc: "historique-2.jpg",
						titreImg: "Premiers bâtiments",
						// titre: 'DES SÉJOURS ADAPTÉS',
						sousTitre: "",
						titleBackgroundColor: "#b11f27",
						contenu: (
							<>
								<p>
									Au fil des années, de nombreux cantiques sont composés,
									témoignant de l’importance accordée au ministère de la louange
									et de l’adoration durant les cultes, camps et colonies de
									vacances. À partir de 2005, sans que la Mission cherche un
									quelconque développement, des demandes de collaboration et de
									soutien affluent de pays étrangers tels que Madagascar, la
									Bulgarie, la Guinée Conakry et la Corée du Sud.
								</p>
								<p>
									L’ensemble du travail de la Mission Timothée est orienté par
									ces versets :
								</p>
								<p>
									« Partage ton pain avec celui qui a faim, et fais entrer dans
									ta maison les malheureux sans asile ; si tu vois un homme nu,
									couvre-le, et ne te détourne pas de ton semblable. » (Es 58:7)
								</p>
								<p>
									« Élargis l’espace de ta tente ; qu’on déploie les couvertures
									de ta demeure [...] Allonge tes cordages et affermis tes pieux
									! » (Es 54:2)
								</p>
								<p>
									« Et ce que tu as entendu de moi en présence de beaucoup de
									témoins, confie-le à des hommes fidèles, qui soient capables
									de l’enseigner aussi à d’autres. » (2 Tm 2:2)
								</p>
							</>
						)
					}}
				/>
				<ModuleBlocImagesCroise
					data={{
						textPosition: "droite",
						imgSrc: "historique-3.jpg",
						titreImg: "Premiers camps",
						// titre: 'ASSOCIATIONS PARTENAIRES',
						sousTitre: "",
						titleBackgroundColor: "#b11f27",
						contenu: (
							<>
								<p>
									Les autres principes de la Mission sont les mêmes qu’au
									commencement : un accueil avec une participation financière
									libre, aucun recours à des financements extérieurs, de telle
									sorte que l’œuvre est financée par les dons.
								</p>
								<p>
									La vocation de la Mission Timothée se résume ainsi :
									travailler unis dans la lumière et l’amour fraternel, au
									service de l’Église de Jésus-Christ dans son ensemble et pour
									amener tout homme à la connaissance personnelle du Seigneur.
								</p>
								<p>
									À lire :{" "}
									<Link
										className=""
										to={"/la-mission-timothee-n-est-pas-une-secte"}
									>
										La Mission Timothée classée dans les sectes ?
									</Link>
								</p>
							</>
						)
					}}
				/>
			</div>
		);
	}
}

export default withTranslation()(Historique);
