import React from "react";
import Page from "../Page";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import "./Podcats.scss";
import {
	ModuleEncartMtCustom,
	ModuleEncartListMt,
	ModuleCard,
	tools,
	api,
	Icones
} from "@mitim/react-mitim";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { ThemeProvider } from "@material-ui/core";
import BlockLecteurAudio from "../BlockLecteurAudio/BlockLecteurAudio";
import { element } from "prop-types";
import { Bouton } from "./Bouton";
import { BlocLectureLivre } from "./BlocLectureLivre";

import * as data from "./data";
import { PanToolSharp } from "@material-ui/icons";

class Podcats extends Page {
	constructor(props) {
		super(props);
		this.handleShow = this.handleShow.bind(this);
		this.handleClose = this.handleClose.bind(this);
	}

	state = {
		date: "",
		titre: "",
		lien: "",
		couleurFacebook: "#fff",
		couleurTwitter: "#fff"
	};

	componentDidMount() {
		super.componentDidMount();
		api.getBibleEnUnAn(this, () => {});
	}

	handleClose() {
		this.setState({ show: false });
	}

	handleShow() {
		this.setState({ show: true });
	}

	titre() {
		return (
			<>
				<div className="pt-5">
					<Bouton texte="ÉCOUTER LE LIVRE" lien="/lecture-nehemie" />
				</div>
			</>
		);
	}

	render() {
		const { t } = this.props;

		const Bible = props => (
			<tr>
				<Link
					className="lien"
					style={{ fontSize: "1.2em" }}
					to={
						"/podcast-bible-en-un-an-lecture/	" +
						props.element.idMediaLivre +
						"/" +
						props.element.couleur.replace("#", "") +
						"/" +
						props.element.nom
					}
					key={props.element.nom}
				>
					<td className="pl-3">{props.element.nom}</td>
				</Link>
			</tr>
		);

		const AncienTestementCol = props => (
			<div className="col-lg-6 ">
				<table className="text-left pl-5" style={{ width: "100%" }}>
					{props.data.map(element => (
						<Bible key={element.nom} element={element} />
					))}
				</table>
			</div>
		);

		return (
			<>
				<BlocLectureLivre
					titre="lecture du livre"
					sousTitre="Revue lumières reçues au fil du temps n°24"
					description={
						<>
							Mission Timothée
							<br />
							S'abonner à la
						</>
					}
					lienLivre="https://www.missiontimothee.fr/revue"
					nomLienLivre="revue lumières reçues au fil du temps"
					lien="/lecture-revue-n-24"
					imageBackground={`${tools.lienPhotos}/neige.jpg`}
					couleurBouton={"#555"}
				/>
				<BlocLectureLivre
					titre="lecture du livre"
					sousTitre="Revue lumières reçues au fil du temps n°23"
					description={
						<>
							Mission Timothée
							<br />
							S'abonner à la
						</>
					}
					lienLivre="https://www.missiontimothee.fr/revue"
					nomLienLivre="revue lumières reçues au fil du temps"
					lien="/lecture-revue-n-23"
					imageBackground={`${tools.lienPhotos}/mer2.jpg`}
					couleurBouton={"#fb8033"}
				/>

				<BlocLectureLivre
					titre="lecture du livre"
					sousTitre="Revue lumières reçues au fil du temps n°20"
					description={
						<>
							Mission Timothée
							<br />
							S'abonner à la
						</>
					}
					lienLivre="https://www.missiontimothee.fr/revue"
					nomLienLivre="revue lumières reçues au fil du temps"
					lien="/lecture-revue-n-20"
					imageBackground={`${tools.lienPhotos}/MesYeuxLeVerront.jpg`}
					couleurBouton={"#87bc3d"}
				/>

				<BlocLectureLivre
					titre="lecture du livre"
					sousTitre="Au milieu de tant d'épreuves"
					description={
						<>
							Jean Calvin, Paul Wells (préface)
							<br />
							En savoir plus sur le livre
						</>
					}
					lienLivre="https://www.librairiejeancalvin.fr/index.php/ljc/Publications/AU-MILIEU-DE-TANT-D-EPREUVES_26304"
					nomLienLivre="Au milieu de tant d'épreuves"
					lien="/lecture-au-milieu-de-tant-d-epreuves"
					imageBackground={`${tools.lienPhotos}/AuMilieuDeTantDEpreuves.jpg`}
					couleurBouton={"#333"}
					// couleurBouton={tools.BLEU}
				/>

				<BlocLectureLivre
					titre="lecture du livre"
					sousTitre="Mes yeux le verront et non ceux d'un autre"
					description={
						<>
							André de Chabannes, d'après le récit de Thérèse et Jean-Jacques
							Hanniquet.
							<br />
							En savoir plus sur le livre
						</>
					}
					lienLivre="/media/151/Mes-yeux-le-verront-et-non-ceux-d'un-autre"
					nomLienLivre="Mes yeux le verront et non ceux d'un autre"
					lien="/lecture-mes-yeux-le-verront-et-non-ceux-d-un-autre"
					imageBackground={`${tools.lienPhotos}/MesYeuxLeVerront.jpg`}
					couleurBouton="#87bc3d"
				/>

				<div className="container">
					{tools.titreSousTitre(
						"LA BIBLE EN UN AN",
						"Lecture quotidienne de la Bible"
					)}
					<p className="text-center">
						Retrouvez ici la lecture quotidienne de l'intégralité de la Bible,
						Ancien et Nouveau Testament, qui révèle la personne de Jésus-Christ,
						fondement de notre foi.
					</p>
					<p className="text-center pb-5">
						Tiré du texte traduit par la Société Biblique de Genève (NEG 1979),
						lu par Renaud FESTAL
						<br />
						pour la Mission Timothée.
					</p>
				</div>
				<div
					className="container-fluid pb-lg-5 no-gutters bibleEnUnAn"
					style={{ minHeight: "480px", backgroundColor: "#e2e3e4" }}
				>
					<BlockLecteurAudio
						date={this.state.date}
						titre={this.state.titre}
						lien={this.state.lien}
						imgBackground="bibleLecteur.jpg"
						lienSoundCloud="https://soundcloud.com/bible-missiontimothee"
						lienItunes="https://podcasts.apple.com/us/podcast/la-bible-en-1-an-par-la-mission-timoth%C3%A9e/id1350076716"
					/>
					<div className="container pt-5">
						<h3 className="text-center pt-3 pb-5">LA BIBLE LUE</h3>

						<div className="row">
							<ModuleEncartMtCustom
								element={{
									titre: "ANCIEN TESTAMENT",
									couleurTitre: tools.VERT_FONCE,
									lireLaSuiteHidden: true,
									imgSrc: "mt-encart-bible-1.jpg",
									brightness: "100%",
									contenu: (
										<>
											<div className="row pl-5 pr-5 pb-4">
												<AncienTestementCol data={data.ANCIEN_TESTAMENT_1} />
												<AncienTestementCol data={data.ANCIEN_TESTAMENT_2} />
											</div>
										</>
									)
								}}
							/>
							<ModuleEncartMtCustom
								element={{
									titre: "NOUVEAU TESTAMENT",
									couleurTitre: tools.BLEU,
									lireLaSuiteHidden: true,
									imgSrc: "mt-encart-bible-2.jpg",
									brightness: "100%",
									contenu: (
										<>
											<div className="row pl-5 pr-5 pb-4">
												<AncienTestementCol data={data.NOUVEAU_TESTAMENT_1} />
												<AncienTestementCol data={data.NOUVEAU_TESTAMENT_2} />
											</div>
										</>
									)
								}}
							/>
						</div>
					</div>
				</div>

				<BlocLectureLivre
					titre="lecture du livre de Néhémie"
					sousTitre="Ils rebâtiront sur d'anciennes ruines"
					description={
						<>
							Il ne s’agit pas d’un ouvrage théologique, mais de la
							retranscription de prédications données au sein de la Mission.
							<br />
							En savoir plus sur le livre
						</>
					}
					lienLivre="/media/132/Commentaire-du-livre-de-Néhémie-"
					nomLienLivre="Ils rebâtiront sur d'anciennes ruines"
					lien="/lecture-nehemie"
					imageBackground={`${tools.lienPhotos}/anciennesRuines.jpg`}
					lienSoundcloud="https://soundcloud.com/bible-missiontimothee"
					lienItunes="https://podcasts.apple.com/fr/podcast/commentaire-du-livre-la-bible-n%C3%A9h%C3%A9mie-par-la-mission/id1503923000"
					couleurBouton="#b11f27"
				/>
			</>
		);
	}
}

export default withTranslation()(Podcats);
