// Libraries
import React from 'react';
import { FormControlLabel, Checkbox } from '@material-ui/core';
import { titleCase } from '../../utils/functions';

class FilterItem extends React.Component {
	handleChange = event => {
		this.props.onFilterItemChange({
			id: this.props.id,
			value: event.target.checked
		});
	};

	render() {
		return (
			<FormControlLabel
				control={
					<Checkbox
						checked={this.props.value ? true : false}
						onChange={this.handleChange}
						name={'filterItem' + this.props.id}
						classes={{ root: 'pl-3 pr-2 py-1' }}
					/>
				}
				label={
					this.props.titleCase ? titleCase(this.props.label) : this.props.label
				}
				classes={{ root: 'mb-0' }}
			/>
		);
	}
}

export default FilterItem;
