import React from "react";
import { withTranslation } from "react-i18next";
import { tools, api } from "@mitim/react-mitim";
import mtIconeCarteFrance from "../../resources/icons/carteFrance.svg";
import ImageMapper from "react-image-mapper";
import { Redirect } from "react-router-dom";
import { ModuleBlocGaucheDroite2 } from "@mitim/react-mitim";
import Page from "../Page";

class Enfants extends Page {
	render() {
		const data = this.props.data;
		const { t } = this.props;

		return (
			<>
				<div className="container-fluid bg-gris1 no-gutters pt-5">
					<ModuleBlocGaucheDroite2
						data={{
							textPosition: "droite",
							imgSrc: "mt-Enfants-1.jpg",
							titre: "TIMOTHÉE KIDS",
							sousTitre: "Un site dédié aux enfants !",
							contenu: (
								<p>
									Viens découvrir une nouvelle page chaque mercredi matin ! Tu y
									trouveras une histoire racontée en plusieurs épisodes, un
									verset du jour, une parole à partager, des photos et vidéos
									souvenirs des colonies et camps d'ados...
									<br />
								</p>
							),
							boutons: [
								{
									nom: "VISITER TIMKIDS",
									lien: "https://kids.timothee.fr"
								}
							]
						}}
					/>
				</div>

				<div className="container-fluid bg-gris1 no-gutters pt-5">
					<ModuleBlocGaucheDroite2
						data={{
							textPosition: "gauche",
							imgSrc: "mt-Enfants-2.jpg",
							titre: "ENSEIGNER LES ENFANTS",
							sousTitre: "Une aide pour les moniteurs",
							roles: ["ROLE_ENSEIGNEMENT_ENFANTS"],
							contenu: (
								<p>
									Retrouvez ici les programmes d'enseignement des enfants
									classés par âge ainsi que diverses ressources.
									<br />
								</p>
							),
							boutons: [
								{
									nom: "RESSOURCES",
									lien: "/enseignementEnfants"
								}
							]
						}}
					/>
				</div>
			</>
		);
	}
}
export default withTranslation()(Enfants);
