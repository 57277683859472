// Libraries
import React from "react";
import {
	BrowserRouter as Router,
	Switch,
	Route,
	Redirect
} from "react-router-dom";
import $ from "jquery";
import Footer from "./Footer/Footer";
import BarrePolitique from "./PolitiqueConfidentialite/BarrePolitique";
import HeaderAndRoute from "./HeaderAndRoute";
import Home from "./Home";
import Login from "./Login/Login";
import Inscription from "./Login/Inscription";
import MonCompte from "./Login/MonCompte";
import CompteOublie from "./Login/CompteOublie";
import ChangerMotDePasse from "./Login/ChangerMotDePasse";
import ConnectLink from "./Login/ConnectLink";
import Contact from "./Contact";
import PolitiqueConfidentialite from "./PolitiqueConfidentialite/PolitiqueConfidentialite";
import { Soutien } from "./Soutien/Soutien";
import Eglises from "./Eglises/Eglises";
import ParolePartagee from "./ParolePartagee/ParolePartagee";
import { AffichageLivres } from "./ParolePartagee/AffichageLivres";
import ParolePartageeBaseDonnee from "./ParolePartagee/ParolePartageeBaseDonnee";
import { ReunionInformation } from "./ReunionInformation/ReunionInformation";
import { Pastorale } from "./ReunionInformation/Pastorale";
import { AGVote2021 } from "./AGVote2021/AGVote2021";
import { AGVote2022 } from "./AGVote2022/AGVote2022";
import { Sondage } from "./Sondage/Sondage";
import { RapportAG } from "./RapportAG/RapportAG";

import Historique from "./Historique";
import Enfants from "./Enfants/Enfants";
import EnseignementEnfants from "./EnseignementEnfants/EnseignementEnfants";
import Formation from "./Formation/Formation";
import EspaceCandidatsCours from "./EspaceCandidats/EspaceCandidatsCours";
// import Sandbox from "./Sandbox";
// import { Sandbox } from "./Sandbox/Sandbox";
// import { PrivateRoute, LoginRoute, ROLE_CANDIDAT } from "./Login/Auth";
import { PrivateRoute, LoginRoute } from "./Login/PrivateHeaders";
import "@mitim/react-mitim/dist/styles.css";
import { MediaFactory } from "./Media";

// CSS
import "../styles/App.scss";
import "../styles/Font.scss";
import "../styles/Media.scss";
import ReunionsDirect from "./ReunionsDirect";
import EspaceCandidats from "./EspaceCandidats";
import Actualites from "./Actualites";
import BibleEnUnAn from "./Podcats/Podcats";
import BibleEnUnAnLectureLivre from "./Podcats/BibleEnUnAnLectureLivre";
import LectureLivre from "./Podcats/LectureLivre";
import MediatationQuotidienne from "./MediatationQuotidienne/MediatationQuotidienne";
import ParolePartageeSimple from "./ParolePartagee/ParolePartageeSimple";
import { DecouvrirLEvangile } from "./ParolePartagee/DecouvrirLEvangile";
import { Enseignement } from "./ParolePartagee/Enseignement";
import { Temoignages } from "./ParolePartagee/Temoignages";
import { MessagesVideo } from "./ParolePartagee/MessagesVideo";
import ConfessionFoi from "./ConfessionFoi/ConfessionFoi";
import { Lrft } from "./Revue/Lrft";
import { ExpeditionRevue } from "./ExpeditionRevue/ExpeditionRevue";
import { DiffusionDirectCoursFormation } from "./DiffusionDirectCoursFormation/DiffusionDirectCoursFormation";
import { PanToolSharp } from "@material-ui/icons";
import { tools, Storage, api } from "@mitim/react-mitim";
import { PourquoiSecte } from "./Divers/PourquoiSecte";
import { DownloadFiles } from "./Divers/DownloadFiles";
import { AffichageRevues } from "./AffichageRevues/AffichageRevues";
import { AGVote2024 } from "./AGVote2024/AGVote2024";

$(document).ready(function() {
	/* ---------------------------------------------- /*
		* Scroll top
		/* ---------------------------------------------- */

	$(window).scroll(function() {
		if ($(this).scrollTop() > 100) {
			$(".scroll-up").fadeIn();
		} else {
			$(".scroll-up").fadeOut();
		}
	});

	$('a[href="#totop"]').click(function() {
		$("html, body").animate(
			{
				scrollTop: 0
			},
			"slow"
		);
		return false;
	});
	function receiveMessage(event) {
		// Faisons-nous confiance à l'expéditeur de ce message ?  (il pourrait être
		// différent de la fenêtre que nous avons ouverte au départ, par exemple).
		if (event.origin !== "https://storage.timothee.fr") return;
		// console.log(event.data);
		if (event.data.token !== null) {
			// token existe ?

			if (event.data.cookies === "true") {
				localStorage.setItem("cookies", "true");
			}
			const user = Storage.getUserParsed();
			if (user !== null && user.token === event.data.token) {
				console.log("le token est le même");
			} else {
				console.log("le token est différent");
				//loadUserByToken(event.data.token);
			}
		} else {
			// si le token n'existe pas
			console.log("pas de token !");
			const user = Storage.getUserParsed();
			if (user !== null) {
				let packet = {
					methode: "postUser",
					user: user
				};
				iframeStorage.postMessage(packet, "https://storage.timothee.fr");
			}
		}
	}
	window.addEventListener("message", receiveMessage, false);

	var iframeStorage = document.getElementById("iframeStorage").contentWindow; // on récupere l'iframe du storage.timothee.fr
	let packet = {
		methode: "getToken"
	};
	iframeStorage.postMessage(packet, "https://storage.timothee.fr");
});

export default class App extends React.Component {
	render() {
		return (
			<Router>
				<div className="App">
					<Switch>
						{/* DIVERS *************************************************** */}
						{/* cookies */}
						<HeaderAndRoute
							path="/cookies"
							component={PolitiqueConfidentialite}
							slide={{
								template: 2,
								image: "Contact.jpg",
								icone: "icone_contactlettres.svg",
								h1: "Politique de confidentialité",
								descriptionMeta: "Politique de confidentialité"
							}}
						/>

						<Route path="/media/:id" component={MediaFactory} />

						{/* Connexion */}
						<LoginRoute
							path="/login"
							component={Login}
							titre="menu.login"
							slide={{
								template: 2,
								image: "Contact.jpg",
								// icone: "icone_contactlettres.svg",
								h1: "Page d'authentification",
								descriptionMeta: "Page d'authentification"
							}}
						/>
						<LoginRoute
							path="/inscription"
							component={Inscription}
							titre="menu.inscription"
							slide={{
								template: 2,
								image: "Contact.jpg",
								// icone: "icone_contactlettres.svg",
								h1: "Créer un compte",
								descriptionMeta: "Créer un compte"
							}}
						/>

						<PrivateRoute
							privateRoute="true"
							authorities={["ROLE_CONNECTED"]}
							path="/mon-compte"
							component={MonCompte}
							titre="menu.inscription"
							slide={{
								template: 2,
								image: "Contact.jpg",
								// icone: "icone_contactlettres.svg",
								h1: "Mon compte",
								descriptionMeta: "Créer un compte"
							}}
						/>

						<LoginRoute
							path="/compte-oublie"
							component={CompteOublie}
							titre="menu.inscription"
							slide={{
								template: 2,
								image: "Contact.jpg",
								// icone: "icone_contactlettres.svg",
								h1: "Informations de compte oubliées",
								descriptionMeta: "Informations de compte oubliées"
							}}
						/>
						<HeaderAndRoute
							path="/changer-mot-de-passe/:token"
							component={ChangerMotDePasse}
							titre="menu.inscription"
							slide={{
								template: 2,
								image: "Contact.jpg",
								// icone: "icone_contactlettres.svg",
								h1: "Changement de mot de passe",
								descriptionMeta: "Changement de mot de passe"
							}}
						/>

						<HeaderAndRoute
							path="/connexion/:token"
							component={ConnectLink}
							titre="menu.inscription"
							slide={{
								template: 2,
								image: "Contact.jpg",
								// icone: "icone_contactlettres.svg",
								h1: "Connexion",
								descriptionMeta: "Connexion"
							}}
						/>

						{/* ******************************************************************* */}

						{/* Eglises */}
						<HeaderAndRoute
							path="/eglises"
							component={Eglises}
							slide={{
								template: 2,
								image: "eglises.jpg",
								// icone: "icone_maison.svg",
								h1: "Églises de la Mission",
								h2: "EN FRANCE | À L’ÉTRANGER",
								h3: "Partager sa foi",
								descriptionMeta:
									"Formées par des personnes ayant été touchées par l'Evangile, la Mission compte une trentaine d'églises et groupes de maison en France et à l'étranger. "
							}}
						/>

						{/* Bible en un an */}
						<HeaderAndRoute
							path="/podcasts"
							component={BibleEnUnAn}
							slide={{
								template: 2,
								image: "mt-bible-en-un-an.jpg",
								// icone: "icone_maison.svg",
								h1: "Livres à écouter",
								// h2: "EN FRANCE | À L’ÉTRANGER",
								// h3: "Partager sa foi",
								descriptionMeta: ""
							}}
						/>

						{/* Meditation quotidienne */}
						<HeaderAndRoute
							path="/meditation-quotidienne"
							component={MediatationQuotidienne}
							slide={{
								template: 2,
								image: "mt-bible-en-un-an.jpg",
								// icone: "icone_maison.svg",
								h1: "Méditation quotidienne",
								// h2: "MÉDITATION QUOTIDIENNE",
								// h3: "Partager sa foi",
								descriptionMeta: ""
							}}
						/>

						<HeaderAndRoute
							path="/podcast-bible-en-un-an-lecture/:id/:couleur"
							component={BibleEnUnAnLectureLivre}
							slide={{
								template: 2,
								image: "mt-bible-en-un-an.jpg",
								// icone: "icone_maison.svg",
								h1: "Lecture de la Bible",
								// h2: "EN FRANCE | À L’ÉTRANGER",
								// h3: "Partager sa foi",
								descriptionMeta: ""
							}}
						/>

						<HeaderAndRoute
							path="/lecture-nehemie"
							// component={LectureLivre}
							render={() => (
								<LectureLivre
									idMedia="1588"
									prefixText="chapitre "
									couleur="#ff9f1f"
								/>
								// <LectureLivre idMedia="1588" prefixText="chapitre "/>
							)}
							// component={LectureNehemie}
							slide={{
								template: 2,
								image: "mt-bible-en-un-an.jpg",
								// icone: "icone_maison.svg",
								h1: "Commentaire du livre de Néhémie",
								// h2: "EN FRANCE | À L’ÉTRANGER",
								// h3: "Partager sa foi",
								descriptionMeta: ""
							}}
						/>

						<HeaderAndRoute
							path="/lecture-mes-yeux-le-verront-et-non-ceux-d-un-autre"
							// component={LectureLivre}
							render={() => (
								<LectureLivre
									idMedia="1772"
									prefixText="- "
									couleur="#87bc3d"
								/>
								// <LectureLivre idMedia="1588" prefixText="chapitre "/>
							)}
							// component={LectureNehemie}
							slide={{
								template: 2,
								image: "mt-bible-en-un-an.jpg",
								// icone: "icone_maison.svg",
								h1: "Lecture de livre",
								// h2: "EN FRANCE | À L’ÉTRANGER",
								// h3: "Partager sa foi",
								descriptionMeta: ""
							}}
						/>

						<HeaderAndRoute
							path="/lecture-au-milieu-de-tant-d-epreuves"
							// component={LectureLivre}
							render={() => (
								<LectureLivre
									idMedia="1779"
									prefixText="- "
									couleur="#87bc3d"
								/>
								// <LectureLivre idMedia="1588" prefixText="chapitre "/>
							)}
							// component={LectureNehemie}
							slide={{
								template: 2,
								image: "mt-bible-en-un-an.jpg",
								// icone: "icone_maison.svg",
								h1: "Lecture de livre",
								// h2: "EN FRANCE | À L’ÉTRANGER",
								// h3: "Partager sa foi",
								descriptionMeta: ""
							}}
						/>

						<HeaderAndRoute
							path="/lecture-revue-n-24"
							// component={LectureLivre}
							render={() => (
								<LectureLivre
									idMedia="1982"
									prefixText="- "
									couleur="#87bc3d"
								/>
								// <LectureLivre idMedia="1588" prefixText="chapitre "/>
							)}
							// component={LectureNehemie}
							slide={{
								template: 2,
								image: "mt-bible-en-un-an.jpg",
								// icone: "icone_maison.svg",
								h1: "Lecture de la revue n°24",
								// h2: "EN FRANCE | À L’ÉTRANGER",
								// h3: "Partager sa foi",
								descriptionMeta: ""
							}}
						/>
						<HeaderAndRoute
							path="/lecture-revue-n-23"
							// component={LectureLivre}
							render={() => (
								<LectureLivre
									idMedia="1965"
									prefixText="- "
									couleur="#87bc3d"
								/>
								// <LectureLivre idMedia="1588" prefixText="chapitre "/>
							)}
							// component={LectureNehemie}
							slide={{
								template: 2,
								image: "mt-bible-en-un-an.jpg",
								// icone: "icone_maison.svg",
								h1: "Lecture de la revue n°23",
								// h2: "EN FRANCE | À L’ÉTRANGER",
								// h3: "Partager sa foi",
								descriptionMeta: ""
							}}
						/>

						<HeaderAndRoute
							path="/lecture-revue-n-20"
							// component={LectureLivre}
							render={() => (
								<LectureLivre
									idMedia="1966"
									prefixText="- "
									couleur="#87bc3d"
								/>
								// <LectureLivre idMedia="1588" prefixText="chapitre "/>
							)}
							// component={LectureNehemie}
							slide={{
								template: 2,
								image: "mt-bible-en-un-an.jpg",
								// icone: "icone_maison.svg",
								h1: "Lecture de la revue n°20",
								// h2: "EN FRANCE | À L’ÉTRANGER",
								// h3: "Partager sa foi",
								descriptionMeta: ""
							}}
						/>

						<HeaderAndRoute
							path="/decouvrir-l-evangile-l-esperance-chretienne"
							// component={LectureLivre}
							render={() => (
								<LectureLivre
									idMedia="1780"
									prefixText="- "
									couleur={tools.JAUNE}
									texteEcouter="Écouter l'album en entier"
								/>
								// <LectureLivre idMedia="1588" prefixText="chapitre "/>
							)}
							// component={LectureNehemie}
							slide={{
								template: 2,
								image: "mer.jpg",
								// icone: "icone_maison.svg",
								h1: "Découvrir l'évangile",
								h2: "L'espérance chrétienne",
								// h3: "Cantiques & textes bibliques",
								descriptionMeta: ""
							}}
						/>

						<HeaderAndRoute
							path="/decouvrir-l-evangile-au-sein-de-la-detresse"
							// component={LectureLivre}
							render={() => (
								<LectureLivre
									idMedia="1933"
									prefixText="- "
									couleur={tools.JAUNE}
									texteEcouter="Écouter l'album en entier"
								/>
								// <LectureLivre idMedia="1588" prefixText="chapitre "/>
							)}
							// component={LectureNehemie}
							slide={{
								template: 2,
								image: "mer.jpg",
								// icone: "icone_maison.svg",
								h1: "Découvrir l'évangile",
								h2: "Au sein de la détresse",
								// h3: "Cantiques & textes bibliques",
								descriptionMeta: ""
							}}
						/>

						<HeaderAndRoute
							path="/sd"
							// component={LectureLivre}
							render={() => (
								<LectureLivre
									idMedia="1933"
									prefixText="- "
									couleur={tools.BLEU}
									texteEcouter="Écouter l'album en entier"
								/>
								// <LectureLivre idMedia="1588" prefixText="chapitre "/>
							)}
							// component={LectureNehemie}
							slide={{
								template: 2,
								image: "mer.jpg",
								// icone: "icone_maison.svg",
								h1: "Découvrir l'évangile",
								h2: "Au sein de la détresse",
								// h3: "Cantiques & textes bibliques",
								descriptionMeta: ""
							}}
						/>

						<HeaderAndRoute
							path="/ec"
							// component={LectureLivre}
							render={() => (
								<LectureLivre
									idMedia="1780"
									prefixText="- "
									couleur={tools.JAUNE}
									texteEcouter="Écouter l'album en entier"
								/>
								// <LectureLivre idMedia="1588" prefixText="chapitre "/>
							)}
							// component={LectureNehemie}
							slide={{
								template: 2,
								image: "mer.jpg",
								// icone: "icone_maison.svg",
								h1: "Découvrir l'évangile",
								h2: "L'espérance chrétienne",
								// h3: "Cantiques & textes bibliques",
								descriptionMeta: ""
							}}
						/>

						<HeaderAndRoute
							path="/decouvrir-l-evangile-a-ceux-qui-aiment-la-lumiere"
							// component={LectureLivre}
							render={() => (
								<LectureLivre
									idMedia="1781"
									prefixText="- "
									couleur={tools.VIOLET}
								/>
								// <LectureLivre idMedia="1588" prefixText="chapitre "/>
							)}
							// component={LectureNehemie}
							slide={{
								template: 2,
								image: "mer.jpg",
								// icone: "icone_maison.svg",
								h1: "Découvrir l'évangile",
								h2: "À ceux qui aiment la lumière",
								// h3: "Cantiques & réflexion biblique",
								descriptionMeta: ""
							}}
						/>

						<HeaderAndRoute
							path="/decouvrir-l-evangile-l-evangile-de-jean"
							// component={LectureLivre}
							render={() => (
								<LectureLivre
									idMedia="1782"
									prefixText="- "
									couleur={tools.BLEU}
								/>
								// <LectureLivre idMedia="1588" prefixText="chapitre "/>
							)}
							// component={LectureNehemie}
							slide={{
								template: 2,
								image: "mer.jpg",
								// icone: "icone_maison.svg",
								h1: "Découvrir l'évangile",
								h2: "L'Évangile de Jean",
								// h3: "Cantiques & réflexion biblique",
								descriptionMeta: ""
							}}
						/>

						{/* Formation */}
						<HeaderAndRoute
							path="/formation"
							component={Formation}
							slide={{
								template: 2,
								image: "formation.jpg",
								// icone: "icone_maison.svg",
								h1: "Formation missionnaire",
								h2: "DIACONAT, ENSEIGNEMENT",
								h3: "Apprendre à servir ensemble",
								descriptionMeta:
									"La formation biblique s’adresse aux chrétiens qui, tout en ayant reçu un appel clair pour servir Dieu, désirent se former en vue de la prédication ou du diaconat."
							}}
						/>

						{/* Enfants */}
						<HeaderAndRoute
							path="/enfants"
							component={Enfants}
							slide={{
								template: 2,
								image: "mt-enfants.jpg",
								// icone: "icone_maison.svg",
								h1: "Enfants",
								h2: "TIMOTHÉE KIDS",
								h3: "Bien grandir",
								descriptionMeta: ""
							}}
						/>

						{/* EnseignementEnfants */}
						<PrivateRoute
							authorities={["ROLE_ENSEIGNEMENT_ENFANTS"]}
							path="/enseignementEnfants"
							component={EnseignementEnfants}
							render={() => <EnseignementEnfants privateRoute="true" />}
							slide={{
								template: 2,
								image: "mt-enseignementEnfants.jpg",
								// icone: "icone_maison.svg",
								h1: "Enfants",
								h2: "RESSOURCES",
								h3: "Bien enseigner",
								descriptionMeta: "Enseigner les enfants"
							}}
						/>

						<HeaderAndRoute
							path="/parole-partagee"
							// component={ParolePartagee}
							slide={{
								template: 2,
								image: "parolePartagee.jpg",
								// icone: "icone_maison.svg",
								h1: "Parole partagée",
								// h2: "EN FRANCE | À L’ÉTRANGER",
								h3: "Nourrir sa foi et sa réflexion",
								descriptionMeta:
									"La Mission Timothée propose des contenus à lire ou à écouter afin de nourrir sa foi et sa réflexion."
							}}
							render={({ match, location }) => (
								<ParolePartagee location={location} match={match} />
							)}
						/>
						<HeaderAndRoute
							path="/parole-partagee-bdd"
							// component={ParolePartagee}
							slide={{
								template: 2,
								image: "parolePartagee.jpg",
								// icone: "icone_maison.svg",
								h1: "Parole partagée",
								// h2: "EN FRANCE | À L’ÉTRANGER",
								h3: "Nourrir sa foi et sa réflexion",
								descriptionMeta:
									"La Mission Timothée propose des contenus à lire ou à écouter afin de nourrir sa foi et sa réflexion."
							}}
							render={({ match, location }) => (
								<ParolePartageeBaseDonnee location={location} match={match} />
							)}
						/>

						<HeaderAndRoute
							path="/parole-partagee-spl/:type"
							component={ParolePartageeSimple}
							slide={{
								template: 2,
								image: "parolePartagee.jpg",
								// icone: "icone_maison.svg",
								h1: "Parole partagée",
								// h2: "DEVOIRS | COURS",
								h3: "Nourrir sa foi et sa réflexion",
								descriptionMeta:
									"La Mission Timothée propose des contenus à lire ou à écouter afin de nourrir sa foi et sa réflexion."
							}}
						/>

						<HeaderAndRoute
							path="/decouvrir-l-evangile/:type"
							component={DecouvrirLEvangile}
							slide={{
								template: 2,
								image: "mer.jpg",
								// icone: "icone_maison.svg",
								h1: "Découvrir l'Évangile",
								// h2: "DEVOIRS | COURS",
								// h3: "Nourrir sa foi et sa réflexion",
								descriptionMeta:
									"La Mission Timothée propose des contenus à lire ou à écouter afin de nourrir sa foi et sa réflexion."
							}}
						/>

						<HeaderAndRoute
							path="/enseignement/:type"
							component={Enseignement}
							slide={{
								template: 2,
								image: "enseignement.jpg",
								// icone: "icone_maison.svg",
								h1: "Enseignement",
								// h2: "DEVOIRS | COURS",
								// h3: "Nourrir sa foi et sa réflexion",
								descriptionMeta: ""
							}}
						/>

						<HeaderAndRoute
							path="/messages-video"
							component={MessagesVideo}
							slide={{
								template: 2,
								image: "automne.jpg",
								// icone: "icone_maison.svg",
								h1: "Messages en vidéo",
								// h2: "DEVOIRS | COURS",
								// h3: "Nourrir sa foi et sa réflexion",
								descriptionMeta: ""
							}}
						/>

						<HeaderAndRoute
							path="/temoignages"
							component={Temoignages}
							slide={{
								template: 2,
								image: "temoignage.jpg",
								// icone: "icone_maison.svg",
								h1: "Témoignages",
								// h2: "DEVOIRS | COURS",
								// h3: "Nourrir sa foi et sa réflexion",
								descriptionMeta: ""
							}}
						/>

						{/* Reunion en direct */}
						<PrivateRoute
							privateRoute="true"
							authorities={["ROLE_REU_DIRECT"]}
							path="/reunions-direct"
							component={ReunionsDirect}
							slide={{
								template: 2,
								image: "reuDirect.jpg",
								// icone: "icone_maison.svg",
								h1: "Messages bibliques à écouter",
								h2: "RETRANSMISSION | DIRECT",
								h3: "Nourrir sa foi",
								descriptionMeta: "Messages bibliques à écouter"
							}}
						/>
						{/* Espace candidats */}
						<PrivateRoute
							privateRoute="true"
							authorities={["ROLE_CANDIDAT"]}
							path="/espace-candidats"
							component={EspaceCandidats}
							slide={{
								template: 2,
								image: "mt-candidats.jpg",
								// icone: "icone_maison.svg",
								h1: "Espace étudiants en formation",
								h2: "DEVOIRS | COURS",
								// h3: "Nourrir sa foi",
								descriptionMeta: "Espace étudiants en formation"
							}}
						/>
						{/* Espace candidats cours*/}
						<PrivateRoute
							privateRoute="true"
							authorities={["ROLE_CANDIDAT"]}
							path="/espace-candidats-cours/:type"
							component={EspaceCandidatsCours}
							slide={{
								template: 2,
								image: "mt-candidats.jpg",
								// icone: "icone_maison.svg",
								h1: "Espace étudiants en formation",
								h2: "DEVOIRS | COURS",
								// h3: "Nourrir sa foi",
								descriptionMeta: "Espace étudiants en formation"
							}}
						/>

						{/* Actualités */}
						<HeaderAndRoute
							path="/actualites"
							component={Actualites}
							slide={{
								template: 2,
								image: "mt-actu.jpg",
								// icone: "icone_maison.svg",
								h1: "Page Actualités",
								h2: "EN CE MOMENT SUR LE SITE",
								h3: "Être informé",
								descriptionMeta:
									"Soyez informés de toutes les actualités de la Mission Timothée : événements, nouveaux ouvrages, vidéos."
							}}
						/>

						{/* Soutien (faire un don) */}
						<HeaderAndRoute
							path="/soutien"
							component={Soutien}
							slide={{
								template: 2,
								image: "soutien.jpg",
								// icone: "icone_maison.svg",
								h1: "Soutien financier",
								// h2: "France et étranger",
								// h3: "France, Bulgarie, Afrique",
								descriptionMeta:
									"Depuis sa fondation, la Mission Timothée fonctionne et subsiste grâce aux seuls dons d’amis et de collaborateurs, selon le principe biblique mentionné dans 2 Corinthiens 8 : 13 « […] le surplus des uns pourvoira aux besoin des autres »."
							}}
						/>

						{/* Affichage livres */}
						<HeaderAndRoute
							path="/livres/:type"
							component={AffichageLivres}
							slide={{
								template: 2,
								image: "parolePartagee.jpg",
								// icone: "icone_maison.svg",
								h1: "Parole partagée",
								// h2: "EN FRANCE | À L’ÉTRANGER",
								h3: "Nourrir sa foi et sa réflexion",
								descriptionMeta:
									"La Mission Timothée propose des contenus à lire ou à écouter afin de nourrir sa foi et sa réflexion."
							}}
						/>

						{/* Affichage des revues */}
						<HeaderAndRoute
							path="/revues/:type"
							component={AffichageRevues}
							slide={{
								template: 2,
								image: "parolePartagee.jpg",
								// icone: "icone_maison.svg",
								h1: "Parole partagée",
								// h2: "EN FRANCE | À L’ÉTRANGER",
								h3: "Nourrir sa foi et sa réflexion",
								descriptionMeta:
									"La Mission Timothée propose des contenus à lire ou à écouter afin de nourrir sa foi et sa réflexion."
							}}
						/>

						{/* Assemblée générale */}
						<PrivateRoute
							privateRoute="true"
							authorities={["ROLE_REUNION_MISSIONNAIRE"]}
							path="/reunion-information"
							component={ReunionInformation}
							slide={{
								template: 2,
								image: "parolePartagee.jpg",
								// icone: "icone_maison.svg",
								h1: "Réunion",
								// h2: "EN FRANCE | À L’ÉTRANGER",
								h3: "3 octobre 2020",
								descriptionMeta: ""
							}}
						/>

						{/* Pastorale */}
						<PrivateRoute
							privateRoute="true"
							authorities={["ROLE_PASTORALE"]}
							path="/pastorale"
							component={Pastorale}
							slide={{
								template: 2,
								image: "parolePartagee.jpg",
								// icone: "icone_maison.svg",
								h1: "Pastorale",
								// h2: "EN FRANCE | À L’ÉTRANGER",
								h3: "23 mai 2021",
								descriptionMeta: ""
							}}
						/>

						{/* Ateliers de Pelissou */}
						{/* <PrivateRoute
							privateRoute="true"
							authorities={["ROLE_AG_ATELIERS_PELISSOU"]}
							path="/ag-ateliers-pelissou"
							component={AgAteliersPelissou}
							slide={{
								template: 2,
								image: "parolePartagee.jpg",
								// icone: "icone_maison.svg",
								h1: "Assemblée générale Ateliers de Pelissou",
								// h2: "EN FRANCE | À L’ÉTRANGER",
								h3: "3 octobre 2020",
								descriptionMeta: ""
							}}
						/> */}

						{/* Cours de formation en direct */}
						<PrivateRoute
							privateRoute="true"
							authorities={["ROLE_CANDIDAT"]}
							path="/direct-cours-formation"
							component={DiffusionDirectCoursFormation}
							slide={{
								template: 2,
								image: "parolePartagee.jpg",
								// icone: "icone_maison.svg",
								h1: "Diffusion en direct",
								// h2: "EN FRANCE | À L’ÉTRANGER",
								h3: "Cours de formation",
								descriptionMeta: ""
							}}
						/>

						{/* sondage */}
						<HeaderAndRoute
							path="/sondage/:token"
							component={Sondage}
							slide={{
								template: 2,
								image: "parolePartagee.jpg",
								// icone: "icone_maison.svg",
								h1: "Sondage chorale et orchestre",
								// h2: "EN FRANCE | À L’ÉTRANGER",
								h3: "2022",
								descriptionMeta: ""
							}}
						/>

						{/* AGVote */}
						<PrivateRoute
							privateRoute="true"
							authorities={["ROLE_AG_VOTE"]}
							path="/vote"
							component={AGVote2021}
							slide={{
								template: 2,
								image: "parolePartagee.jpg",
								// icone: "icone_maison.svg",
								h1: "Vote Assemblée générale",
								// h2: "EN FRANCE | À L’ÉTRANGER",
								h3: "2021",
								descriptionMeta: ""
							}}
						/>

						{/* AGVote */}
						<PrivateRoute
							privateRoute="true"
							authorities={["ROLE_AG_VOTE"]}
							path="/vote2024"
							component={AGVote2024}
							slide={{
								template: 2,
								image: "parolePartagee.jpg",
								// icone: "icone_maison.svg",
								h1: "Vote Assemblée générale",
								// h2: "EN FRANCE | À L’ÉTRANGER",
								h3: "2024",
								descriptionMeta: ""
							}}
						/>
						{/* AGVote */}
						<PrivateRoute
							privateRoute="true"
							authorities={["ROLE_AG_VOTE_ADMIN"]}
							path="/rapportAG"
							component={RapportAG}
							slide={{
								template: 2,
								image: "parolePartagee.jpg",
								// icone: "icone_maison.svg",
								h1: "Vote Assemblée générale",
								// h2: "EN FRANCE | À L’ÉTRANGER",
								h3: "",
								descriptionMeta: ""
							}}
						/>
						{/* DownloadFiles */}
						<PrivateRoute
							privateRoute="true"
							authorities={["ROLE_ADMIN"]}
							path="/df"
							component={DownloadFiles}
							slide={{
								template: 2,
								image: "parolePartagee.jpg",
								h1: "Téléchargement",
								h3: "",
								descriptionMeta: ""
							}}
						/>

						{/* Page de test */}
						{/* <HeaderAndRoute
							path="/sandbox"
							component={Sandbox}
							slide={{
								template: 2,
								image: "soutien.jpg",
								h1: "Page de dev",
								descriptionMeta: ""
							}}
						/> */}

						{/* ************************************************************ */}

						{/* Historique */}
						<HeaderAndRoute
							path="/historique"
							component={Historique}
							metaTagKeyword="metatag.keywords-home"
							slide={{
								template: 2,
								image: "historique.jpg",
								// icone: 'icone_handicap.svg',
								h1: "L’histoire de la Mission",
								h2: "",
								h3: "",
								descriptionMeta:
									"En 1972 quelques amis, touchés par le désir de servir le Seigneur auprès des délaissés, créent une maison d’accueil sur une colline à Anduze. Elle deviendra la Mission Timothée."
							}}
						/>

						{/* ConfessionFoi */}
						<HeaderAndRoute
							path="/confession-de-foi"
							component={ConfessionFoi}
							slide={{
								template: 2,
								image: "mt-confessionDeFoi.jpg",
								// icone: "icone_contactlettres.svg",
								h1: "Confession de foi",
								h3: "Ce que nous croyons",
								descriptionMeta: "Confession de foi"
							}}
						/>

						{/* Lumière reçues au fil du temps */}
						{/* <HeaderAndRoute
							path="/revue/lumieres-recues-au-fil-du-temps"
							component={Lrft}
							slide={{
								template: 2,
								image: "mt-confessionDeFoi.jpg",
								// icone: "icone_contactlettres.svg",
								h1: "Confession de foi",
								h3: "Ce que nous croyons",
								descriptionMeta: "Confession de foi"
							}}
						/> */}
						<HeaderAndRoute
							path="/revue"
							component={Lrft}
							slide={{
								template: 2,
								image: "parolePartagee.jpg",
								// icone: "icone_contactlettres.svg",
								h1: "Lumières reçues au fil du temps",
								h3: "Revue",
								descriptionMeta: "Lumière reçues au fil du temps"
							}}
						/>

						<HeaderAndRoute
							path="/exp/:code/:idRevue"
							component={ExpeditionRevue}
							slide={{
								template: 2,
								image: "parolePartagee.jpg",
								// icone: "icone_contactlettres.svg",
								h1: "Lumière reçues au fil du temps",
								h3: "Revue",
								descriptionMeta: "Lumière reçues au fil du temps"
							}}
						/>

						<HeaderAndRoute
							path="/la-mission-timothee-n-est-pas-une-secte"
							component={PourquoiSecte}
							slide={{
								template: 2,
								image: "Contact.jpg",
								icone: "icone_contactlettres.svg",
								h1: <>La MT classée dans les sectes ?</>,
								descriptionMeta: "Politique de confidentialité"
							}}
						/>

						{/* CONTACT */}
						<HeaderAndRoute
							path="/contact"
							component={Contact}
							slide={{
								template: 2,
								image: "Contact.jpg",
								icone: "icone_contactlettres.svg",
								h1: "Contact",
								descriptionMeta:
									"Vous pouvez nous contacter par voie postale, par téléphone ou en envoyant un message via le formulaire."
							}}
						/>
						{/* HOME */}
						<HeaderAndRoute
							path="/"
							slide={{
								num: 0,
								nom: "Page accueil",
								template: 1,
								image: "mt-home.jpg",
								icone: "icone_maison.svg",
								h1: "Page d'accueil",
								h2: "Œuvre protestante",
								h3: "Fédération entraide protestante",
								slideBackgroud: "video",
								srcVideo:
									"https://www.dropbox.com/s/rk8odsgwwp71lkg/6eb5182146d8dcd909811df8e8309a57a827d867.webm?raw=1",
								descriptionMeta:
									"La Mission Timothée est une œuvre protestante ayant pour but l’exercice du culte chrétien, par des rencontres bibliques dans différents lieux en France et à l’étranger."
							}}
							component={Home}
						/>
					</Switch>
					<Footer />
					<BarrePolitique />
					<div className="scroll-up">
						<a href="#totop">
							<i className="fa fa-angle-double-up" />
						</a>
					</div>
				</div>
			</Router>
		);
	}
}

export const loadUserByToken = token => {
	fetch(`${api.timediaUrl}/public/getUser`, {
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
			Authorization: "Basic " + token
		},
		method: "GET"
	}).then(response => {
		if (response.status !== 200) {
			console.error("Error: " + response.status);
			return;
		}
		response.json().then(json => {
			var authorities = new Array();
			json.data.authorities.forEach(function(authority) {
				authorities.push(authority.name);
			});
			Storage.setAuthoritiesJson(JSON.stringify(authorities));
			localStorage.setItem("user", JSON.stringify(json.data));
			document.location.reload();
		});
	});
};
